import {
  addDays,
  format,
  setHours,
  setMinutes,
  startOfISOWeek
} from "date-fns";
export const getDateTime = (date, time) => {
  const timestring = typeof time === "string" && time.length < 9 ? time : new Date(time).getFullYear() > 1970 ? new Date(time).toLocaleTimeString() : (/* @__PURE__ */ new Date(
    format(new Date(date), "yyyy-MM-dd") + "T" + time.split("T")[1]
  )).toLocaleTimeString();
  return setMinutes(
    setHours(new Date(date), Number(timestring.toString().split(":")[0])),
    time.toString().split(":")[1]
  );
};
export const isSameDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};
export const asDay = (date) => {
  const d1 = new Date(date);
  d1.setHours(0, 0, 0, 0);
  return d1;
};
export const isAfterDay = (date1, date2) => {
  return asDay(date1).getTime() > asDay(date2).getTime();
};
export const isBeforeDay = (date1, date2) => {
  return asDay(date1).getTime() < asDay(date2).getTime();
};
export const isOnOrBeforeDay = (date1, date2) => {
  return asDay(date1).getTime() <= asDay(date2).getTime();
};
export const isOnOrAfterDay = (date1, date2) => {
  return asDay(date1).getTime() >= asDay(date2).getTime();
};
export const isOnOrBetweenDates = (date, start, end) => {
  return asDay(date).getTime() >= asDay(start).getTime() && asDay(date).getTime() <= asDay(end).getTime();
};
export const formatDateToISO = (_date, fullString = false) => {
  const date = new Date(_date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return fullString ? `${year}-${month}-${day}T00:00:00.000+00:00` : `${year}-${month}-${day}`;
};
export const getTime = (time, timeFormat = "HH:mm") => {
  if (!time) return "00:00";
  const datetime = getDateTime(/* @__PURE__ */ new Date(), time);
  return format(datetime, timeFormat);
};
export const getStartOfWeek = (date) => startOfISOWeek(new Date(date));
export const getWeekdayIndex = (date) => {
  const day = new Date(date).getDay();
  return day === 0 ? 6 : day - 1;
};
export const weekdaysIn = (date) => Array(7).fill(0).map((x, i) => {
  const dte = addDays(startOfISOWeek(new Date(date)), i);
  return dte;
});
export const getWeekday = (date) => weekdays[getWeekdayIndex(date)];
export const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];
export const getTimeOfDay = (date = /* @__PURE__ */ new Date()) => {
  const now = new Date(date);
  const hour = now.getHours();
  return hour < 12 ? "morning" : hour < 17 ? "afternoon" : "evening";
};
export const timeToNumber = (s) => parseInt(s.split(":")[0]) * 60 * 1e3 + parseInt(s.split(":")[1]) * 1e3;
export const numberToTime = (n) => {
  const hours = Math.floor(n / 60 / 1e3);
  const minutes = Math.floor((n - hours * 60 * 1e3) / 1e3);
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};
export const one = (string) => {
  switch (string) {
    case "second":
      return 1e3;
    case "minute":
      return 60 * 1e3;
    case "hour":
      return 60 * 60 * 1e3;
    case "day":
      return 24 * 60 * 60 * 1e3;
    case "week":
      return 7 * 24 * 60 * 60 * 1e3;
    case "month":
      return 30 * 24 * 60 * 60 * 1e3;
    case "year":
      return 365 * 24 * 60 * 60 * 1e3;
  }
};
