import { EventPrefix } from 'apis/flex/notifications';
import { getSqlEditProps } from 'components/common/advance-table-v2/sql';
import { domainConfigs } from 'components/notification/config';
import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import { useWatch } from 'react-hook-form';
export default ({ index, questionType }) => {
  const domain = useWatch({ name: 'domain' }) as EventPrefix;
  const crudHook = domainConfigs[domain]?.crudHook;
  const resp = crudHook?.();
  if (!resp) return null;
  const typeColumns = resp.meta?.columns.filter(
    c =>
      getSqlEditProps(c).inputType === questionType &&
      !c.COLUMN_NAME.includes('.')
  );
  return (
    !!typeColumns?.length && (
      <WizardInput
        type="select"
        multiple
        label={`Update ${domain} field`}
        instruction={`If specified, and the form is used in the context of the ${domain} domain, the answer given to this question will be used to update these fields.`}
        options={typeColumns.map(c => c.COLUMN_NAME)}
        name={`questions.${index}.domainFields`}
        registerProps={{ required: false }}
      />
    )
  );
};
