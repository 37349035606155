import { distance } from "fastest-levenshtein";
export function parseUKCurrency(number) {
  const numberString = String(number).replace(/[^\d.-]/g, "");
  const [wholePart, decimalPart] = numberString.split(".");
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let currencyString = "\xA3" + formattedWholePart;
  if (decimalPart !== void 0) {
    currencyString += "." + decimalPart.padEnd(2, "0");
  } else {
    currencyString += ".00";
  }
  return currencyString;
}
export function removeHtmlTags(str) {
  return str?.replace(/<\/?[^>]+(>|$)/g, "") || "";
}
export function ensureURLFormat(url) {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  const urlObject = new URL(url);
  if (!/^www\./i.test(urlObject.hostname)) {
    urlObject.hostname = "www." + urlObject.hostname;
  }
  if (!urlObject.pathname.endsWith("/")) {
    urlObject.pathname += "/";
  }
  return urlObject.toString();
}
export function parseUKCurrencyToNumber(currencyString) {
  const cleanedString = currencyString.replace(/[^\d.-]/g, "");
  const number = parseFloat(cleanedString);
  return number;
}
export function matchWildcard(pattern, str) {
  if (pattern.length === 0 && str.length === 0) {
    return true;
  }
  if (pattern.length === 0) {
    return false;
  }
  if (pattern[0] === "*") {
    return matchWildcard(pattern.substring(1), str) || str.length > 0 && matchWildcard(pattern, str.substring(1));
  }
  if (pattern[0] === str[0] || pattern[0] === "?") {
    return matchWildcard(pattern.substring(1), str.substring(1));
  }
  return false;
}
export function truncateMiddle(fullStr, strLen, separator = "...") {
  if (fullStr.length <= strLen) return fullStr;
  const sepLen = separator.length, charsToShow = strLen - sepLen, frontChars = Math.ceil(charsToShow / 2), backChars = Math.floor(charsToShow / 2);
  return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
}
export function compareStrings(string1, string2) {
  const editDistance = distance(string1, string2);
  const maxDistance = Math.max(string1.length, string2.length);
  const levenshteinScore = 1 - editDistance / maxDistance;
  return levenshteinScore;
}
export const extractTextFromChildren = (children) => {
  if (typeof children === "string") {
    return children;
  }
  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join("");
  }
  if (typeof children === "object" && children !== null) {
    return extractTextFromChildren(children.props?.children);
  }
  return "";
};
export const replaceHandlebarsWithValues = (text, values) => {
  return text.replace(/{{(.*?)}}/g, (match, key) => values[key] || "");
};
export const getElementFromHtml = (html, selector) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const el = doc.querySelector(selector);
  return el;
};
export const getTextFromHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
export function validateIframeEmbedCode(embedCode) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(embedCode, "text/html");
  const iframes = doc.querySelectorAll("iframe");
  if (iframes?.length !== 1) {
    console.log(
      "No iframe or more than 1 iframe found in embed code",
      doc,
      iframes
    );
    return false;
  }
  const iframe = iframes[0];
  const src = iframe.getAttribute("src");
  const width = iframe.getAttribute("width");
  const height = iframe.getAttribute("height");
  try {
    new URL(src);
  } catch {
    console.log("Invalid URL in embed code");
    return false;
  }
  if (width && (isNaN(width) || Number(width) <= 0)) {
    console.log("Invalid width in embed code");
    return false;
  }
  if (height && (isNaN(height) || Number(height) <= 0)) {
    console.log("Invalid height in embed code");
    return false;
  }
  return true;
}
export const camelCase = (...strings) => strings.map(
  (s, i) => !s ? "" : i === 0 ? s.charAt(0).toLowerCase() + s.slice(1) : s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
).join("");
export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
