import React, { useState } from 'react';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { CourseEnrollModal } from './Enroll';
import { ProgressBar } from 'react-bootstrap';
import DomainTable from 'components/common/DomainTable';
import { UserTrainingCourse } from 'apis/flex/hr';
export default ({
  courseId,
  userId
}: {
  courseId?: number;
  userId?: number;
}) => {
  const [showAdd, setShowAdd] = useState<boolean>(false);
  return (
    <DomainTable<UserTrainingCourse>
      domain="user-training"
      title="Enrolled Courses"
      canClone={false}
      sqlDb="HR"
      crudProps={{
        filters: {
          userId,
          courseId
        }
      }}
      sqlTables={['UserTrainingCourses']}
      onNewClick={() => setShowAdd(true)}
      columns={[
        !userId && { id: 'userId', header: 'Trainee', domain: 'user' },
        !courseId && {
          id: 'courseId',
          header: 'Course',
          domain: 'training-course'
        },
        {
          id: 'resources',
          visible: false
        },
        {
          id: 'progress',
          accessorFn: row => {
            const numberOfResources = row?.resources?.length;
            const resourcesDone = row?.resources?.filter(
              r => r?.approvedDate
            ).length;
            return resourcesDone / numberOfResources;
          },
          formatter: (v, d) => {
            const prog = v();
            const failed = d.resources.some(r => r.failedDate);
            return (
              <ProgressBar
                className="w-100"
                variant={failed ? 'danger' : prog === 1 ? 'success' : 'warning'}
                animated={prog < 1}
                now={prog}
                max={1}
                label={failed ? 'Failed' : prog === 1 ? 'Completed' : ''}
              />
            );
          },
          header: 'Progress',
          size: 200
        },
        {
          id: 'status',
          header: 'Status',
          formatter(valuefn) {
            const v = valuefn();
            return (
              <>
                {v === 'Pending approval' && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-warning me-2"
                  />
                )}
                {v}
              </>
            );
          },
          accessorFn: d =>
            d.resources?.length
              ? d.resources?.every(r => r.approvedDate)
                ? 'Completed'
                : d.resources?.some(r => r.failedDate)
                ? 'Failed'
                : d.resources?.some(
                    r => r.finishedDate && !r.approvedDate && !r.failedDate
                  )
                ? 'Pending approval'
                : d.expiryDate && new Date(d.expiryDate) < new Date()
                ? 'Expired'
                : 'In Progress'
              : 'Not started'
        },
        { id: 'enrolledDate', header: 'Enrolled', type: 'datetime' },
        { id: 'deadlineDate', header: 'Deadline', type: 'date' },
        { id: 'expiryDate', header: 'Expires', type: 'date' },
        { id: 'lastSubmittedDate', header: 'Last Submitted', type: 'datetime' }
      ]}
    >
      <AdvanceTable />
      <CourseEnrollModal
        courseIds={courseId ? [courseId] : null}
        userIds={userId ? [userId] : null}
        show={showAdd}
        setShow={setShowAdd}
      />
    </DomainTable>
  );
};
