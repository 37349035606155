import SoftBadge from 'components/common/SoftBadge';
import WizardInput from 'components/wizard/WizardInput';
import { camelToSentence } from 'helpers/utils';
import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import useShifts from './useShifts';
import useEmployees from '../staff/hooks/useEmployees';
import {
  formatDateToISO,
  getTime,
  getWeekdayIndex,
  timeToNumber,
  weekdays,
  weekdaysIn
} from 'helpers/dates';
import { addDays, areIntervalsOverlapping, format } from 'date-fns';
import { Shift } from 'apis/flex/hr';
import {
  getMaxShiftHours,
  getMinShiftHours,
  ShiftValue
} from 'helpers/validation/validate';
import { uniqBy } from 'lodash';
import WizardInputWrapper, {
  WizardInputWrapperProps
} from 'components/wizard/WizardInputWrapper';
import { BookingType } from 'apis/flex/projects';
import QueryStateDisplay from 'components/common/QueryStateDisplay';
import ShiftSelector, {
  AvailableShiftsPerDaySelector,
  AvailableShiftsPerDaySelectorProps
} from './ShiftSelector';
import { DomainItemSelectorProps } from 'components/common/DomainItemSelector';
import { ColProps } from '@fullcalendar/core/internal';

export const getShiftName = (shift: {
  startTime: Date | string;
  endTime: Date | string;
}) => getTime(shift.startTime) + '-' + getTime(shift.endTime);
const validateDayShifts =
  (shifts: Shift[], allowOverlap?: boolean) => (selected: number[]) => {
    if (!selected) return;
    const isOverlap = selected?.some(id => {
      const shift = shifts.find(s => s.id === id);
      const otherShifts = selected
        .filter(otherId => otherId !== id)
        .map(otherId => shifts.find(s => s.id === otherId));
      return otherShifts.some(s =>
        areIntervalsOverlapping(
          {
            start: timeToNumber(s.startTime),
            end: timeToNumber(s.endTime)
          },
          {
            start: timeToNumber(shift?.startTime),
            end: timeToNumber(shift?.endTime)
          }
        )
      );
    });
    return allowOverlap || !isOverlap || 'Shifts cannot overlap';
  };
export const DayShiftsPicker = ({
  registerProps,
  allowOverlap,
  ...props
}: Partial<DomainItemSelectorProps<Shift>> & {
  allowOverlap?: boolean;
}) => {
  const { data: shifts } = useShifts({ useFilter: true });
  return (
    <ShiftSelector
      multiple
      registerProps={{
        validate: validateDayShifts(shifts, allowOverlap),
        ...registerProps
      }}
      {...props}
    />
  );
};
/** Will create a form value structure of {'2020-01-01': [shiftId, shiftId], '2020-01-02': [shiftId]} */
export const AvailableDayShiftsPicker = ({
  registerProps,
  availableTo,
  formatDate = date => format(new Date(date), 'EEEE dd/MMM'),
  colProps,
  allowOverlap,
  name,
  ...props
}: Omit<AvailableShiftsPerDaySelectorProps, 'render'> & {
  formatDate?: (date: Date) => string;
  colProps?: ColProps;
  allowOverlap?: boolean;
}) => {
  const { data: shifts } = useShifts({ useFilter: true });
  return (
    <Row>
      <AvailableShiftsPerDaySelector
        multiple
        name={name}
        render={(input, date) => (
          <Col key={date} xs={12} md={4} xl={3} {...colProps}>
            {input({ label: formatDate(new Date(date)) })}
          </Col>
        )}
        availableTo={availableTo}
        registerProps={{
          validate: validateDayShifts(shifts, allowOverlap),
          ...registerProps
        }}
        {...props}
      />
    </Row>
  );
};
