import { domains } from 'apis/flex/notifications';
import { UserGuide } from 'apis/flex/support';
import { FieldArrayList } from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import CustomTabs from 'components/common/CustomTabs';
import DetailCard from 'components/common/detail/DetailCard';
import DomainCustomTags from 'components/common/DomainCustomTags';
import DomainDetail from 'components/common/DomainDetail';
import { getDomainInput } from 'components/common/DomainInput';
import DomainTags from 'components/common/DomainTags';
import { domainToSentence } from 'components/common/DomainTimeline';
import TagSelector from 'components/common/TagSelector';
import VideoUploader from 'components/wizard/inputs/VideoUploader';
import WithFormValues from 'components/wizard/WithFormValues';
import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
const Input = getDomainInput<UserGuide>();
const Sidebar = () => {
  return (
    <>
      <DomainTags domain="user-guide" label="Tags" />
      <WizardInput
        name="domains"
        label="Features"
        type="select"
        multiple
        registerProps={{ required: false }}
        options={domains.map(d => ({ value: d, label: domainToSentence(d) }))}
      />
    </>
  );
};
export default () => {
  return (
    <DomainDetail<UserGuide, UserGuide & { availabilityRules?: never }>
      sidebar={<Sidebar />}
      domain="user-guide"
    >
      <DetailCard title="Info" id="info">
        <Input name="title" />
        <Input name="description" type="texteditor" />
        <VideoUploader
          label="Video"
          fileIdField="videoFileId"
          required={false}
        />
      </DetailCard>
      <Sections />
    </DomainDetail>
  );
};
const Sections = () => {
  const data = useWatch<UserGuide>();
  const fieldArray = useFieldArray<UserGuide, 'sections'>({
    name: 'sections'
  });
  return (
    <DetailCard id="sections" title="Sections">
      {!data.sections?.length && !!data?.videoFileId && (
        <>
          <Input name="createFromVideo" ai type="checkbox" />
        </>
      )}
      {!!data.createFromVideo && !!data.id && !data.sections?.length ? (
        <div>Awaiting creation from video. Please check back later...</div>
      ) : (
        <FieldArrayList
          tabs
          defaultValues={{
            // ...f,
            id: undefined,
            orderIndex: fieldArray.fields.length,
            guideId: data.id,
            title: '',
            description: '',
            content: '',
            createdBy: 0,
            createdDate: new Date()
          }}
          fields={fieldArray.fields.map(f => ({
            name: f.title,
            id: f.id,
            ...f
          }))}
          remove={i => fieldArray.remove(i)}
          append={f => fieldArray.append(f)}
          item={(s, i) => (
            <>
              <WizardInput name={`sections.${i}.title`} label="Title" />
              <WizardInput
                name={`sections.${i}.description`}
                type="texteditor"
                label="Description"
                pluginProps={{
                  height: 200
                }}
              />
              <DomainCustomTags
                domain={'user-guide'}
                tagField={`sections.${i}.tags`}
                localKey="itemId"
                label="Tags"
                tableName={'UserGuideSections'}
                registerProps={{
                  required: false
                }}
              />
              <VideoUploader
                fileIdField={`sections.${i}.videoFileId`}
                required={false}
                label="Video"
              />
              <WizardInput
                label="Content"
                name={`sections.${i}.content`}
                type="texteditor"
                pluginProps={{
                  height: 600
                }}
              />
            </>
          )}
        />
      )}
    </DetailCard>
  );
};
