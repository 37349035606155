import React, {
  useEffect,
  Suspense,
  useState,
  useMemo,
  ReactNode,
  HTMLInputTypeAttribute,
  JSXElementConstructor,
  HTMLAttributes,
  lazy
} from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import {
  Form,
  Button,
  Spinner,
  ButtonGroup,
  FormGroupProps,
  FormControlProps
} from 'react-bootstrap';
import {
  ControllerProps,
  RegisterOptions,
  useFormContext
} from 'react-hook-form';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInputConfig } from './InputConfig';
const WizardDate = lazy(() => import('./inputs/WizardDate'));
const WizardInputWrapper = lazy(() => import('./WizardInputWrapper'));
const WizardDateRange = lazy(() => import('./inputs/WizardDateRange'));
const WizardCheck = lazy(() => import('./inputs/WizardCheck'));
const WizardSelect = lazy(() => import('./inputs/WizardSelect'));
const WizardTextArea = lazy(() => import('./inputs/WizardTextArea'));
const WizardFile = lazy(() => import('./inputs/WizardFile'));
const WizardText = lazy(() => import('./inputs/WizardText'));
const WizardIcon = lazy(() => import('./inputs/WizardIcon'));
const WizardSign = lazy(() => import('./inputs/WizardSign'));
const WizardAddress = lazy(() => import('./inputs/WizardAddress'));
const WizardName = lazy(() => import('./inputs/WizardName'));
const WizardEmail = lazy(() => import('./inputs/WizardEmail'));
const WizardRange = lazy(() => import('./inputs/WizardRange'));
const WizardTextEditor = lazy(() => import('./inputs/WizardTextEditor'));
const WizardCurrency = lazy(() => import('./inputs/WizardCurrency'));
const WizardCurrencyRange = lazy(() => import('./inputs/WizardCurrencyRange'));
const WizardRating = lazy(() => import('./inputs/WizardRating'));
const WizardVideo = lazy(() => import('./inputs/WizardVideo'));
const WizardAudio = lazy(() => import('./inputs/WizardAudio'));
const WizardImage = lazy(() => import('./inputs/WizardImage'));
const WizardTranscribe = lazy(() => import('./inputs/WizardTranscribe'));
const WizardInterview = lazy(() => import('./inputs/WizardInterview'));
const WizardSpeaking = lazy(() => import('./inputs/WizardSpeaking'));
const WizardHardware = lazy(() => import('./inputs/WizardHardware'));
const WizardShifts = lazy(() => import('./inputs/WizardShifts'));
const WizardTags = lazy(() => import('./inputs/WizardTags'));
const WizardLiveness = lazy(() => import('./inputs/WizardLiveness'));

import type { RenderProps } from './WizardInputWrapper';
import { UseMediaFormReturn } from 'hooks/useMediaFormContext';
import { AsProp } from 'react-bootstrap/esm/helpers';
import { camelToSentence } from 'helpers/utils';
import WizardQuickfire from './inputs/WizardQuickfire';
import WizardVariant from './inputs/WizardVariant';
import WizardCrudList from './inputs/WizardCrudList';
import WizardDocument from './inputs/WizardDocument';
const inputByType = {
  date: WizardDate,
  datetime: WizardDate,
  daterange: WizardDateRange,
  futureDate: WizardDate,
  futureDatetime: WizardDate,
  futureDaterange: WizardDateRange,
  checkbox: WizardCheck,
  switch: WizardCheck,
  radio: WizardCheck,
  select: WizardSelect,
  multiSelect: WizardSelect,
  textarea: WizardTextArea,
  file: WizardFile,
  icon: WizardIcon,
  sign: WizardSign,
  video: WizardVideo,
  audio: WizardAudio,
  postalAddress: WizardAddress,
  name: WizardName,
  email: WizardEmail,
  range: WizardRange,
  texteditor: WizardTextEditor,
  currency: WizardCurrency,
  currencyrange: WizardCurrencyRange,
  rating: WizardRating,
  image: WizardImage,
  transcribe: WizardTranscribe,
  interview: WizardInterview,
  speaking: WizardSpeaking,
  hardware: WizardHardware,
  shifts: WizardShifts,
  tags: WizardTags,
  liveness: WizardLiveness,
  quickfire: WizardQuickfire,
  variant: WizardVariant,
  crudList: WizardCrudList,
  document: WizardDocument
} as const;
export type WizardInputComponent<TPluginProps = WizardPluginProps> =
  JSXElementConstructor<
    Partial<
      WizardInputComponentProps<TPluginProps> & {
        renderProps: RenderProps;
      }
    >
  >;
const InputTypeSelector = ({ type, props, renderProps }) => {
  const allProps = { ...props, renderProps };
  const Component: WizardInputComponent = inputByType[type];
  if (Component) {
    return <Component {...allProps} />;
  }
  return <WizardText {...allProps} />;
};
export type WizardFormGroupProps = FormGroupProps &
  AsProp &
  any & {
    noMb?: boolean;
    disabled?: boolean;
  };
export type WizardInputType =
  | (string & keyof typeof inputByType)
  | HTMLInputTypeAttribute
  | 'mobile'
  | 'landline';
export type WizardPluginProps = Record<string, any>;
export type WizardInputOptions =
  | string[]
  | {
      value: number | string | boolean;
      label: string;
      isDisabled?: boolean;
      options?: never;
      description?: string;
      context?: any;
      exclusive?: boolean;
      specify?: string;
    }[]
  | {
      value?: never;
      label: string;
      isDisabled?: boolean;
      options: WizardInputOptions;
      description?: string;
      context?: any;
      exclusive?: never;
      specify?: never;
    }[];
export type WizardInputComponentProps<TPluginProps = WizardPluginProps> = {
  name: string;
  placeholder?: string;
  type?: WizardInputType;
  formControlProps?: Partial<
    FormControlProps & HTMLAttributes<HTMLInputElement> & any
  >;
  pluginProps?: Partial<TPluginProps>;
  id: string;
  options?: WizardInputOptions;
  multiple?: boolean;
  value: any;
  floatingLabel?: boolean;
  registerProps?: RegisterOptions & { isMedia?: boolean; readOnly?: boolean };
};
export type WizardInputProps<TFormValues extends Record<string, any> = any> = {
  loading?: boolean;
  label?: ReactNode;
  id?: string;
  name: string & keyof TFormValues;
  type?: WizardInputType;
  options?: WizardInputOptions;
  placeholder?: string;
  formControlProps?: Partial<
    FormControlProps & HTMLAttributes<HTMLInputElement> & any
  >;
  formGroupProps?: WizardFormGroupProps;
  pluginProps?: any;
  value?: any;
  instruction?: ReactNode;
  floatingLabel?: boolean;
  multiple?: boolean;
  scrollOnRender?: boolean;
  endEl?: ReactNode;
  hideLabel?: boolean;
  registerProps?: RegisterOptions & { isMedia?: boolean; readOnly?: boolean };
  updateQuery?: any;
  disabled?: boolean;
  readOnly?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  flush?: boolean;
  autoComplete?: string;
  /** Styles the input as AI-enabled, where possible */
  ai?: boolean;
};
const WizardInputControl = ({
  label,
  name,
  type = 'text',
  options = [],
  placeholder,
  formControlProps = {},
  formGroupProps = {},
  pluginProps = {},
  value,
  instruction,
  floatingLabel,
  multiple,
  scrollOnRender,
  endEl,
  hideLabel,
  registerProps = {},
  updateQuery,
  disabled,
  readOnly,
  prefix,
  suffix,
  flush,
  autoComplete,
  id,
  ai
}: Partial<WizardInputProps>) => {
  if (!name) {
    throw Error('No field name specified');
  }
  const [initialType, setInitialType] = useState(type);
  const { registerMedia, resetField } = useFormContext() as UseMediaFormReturn;
  useEffect(() => {
    if (!initialType) {
      return setInitialType(type);
    }
    //if the type was definitely set previously, but is now different, reset the field, otherwise the value probably won't be compatible
    if (initialType !== type) {
      resetField(name);
      setInitialType(type);
    }
  }, [type]);
  const processedRegisterProps = useMemo(() => {
    const r = registerProps;
    if (
      (type === 'file' ||
        type === 'video' ||
        type === 'image' ||
        type === 'audio' ||
        type === 'speaking' ||
        type === 'interview' ||
        type === 'liveness') &&
      !registerProps.isMedia
    ) {
      registerProps.isMedia = true;
    }
    r.disabled = disabled;
    r.readOnly = readOnly;
    if (
      registerProps.required === undefined &&
      type !== 'checkbox' &&
      type !== 'switch'
    ) {
      r.required = true;
    }
    return r;
  }, [registerProps, type, disabled, readOnly]);
  // console.log('readonly?', readOnly);
  formControlProps.autoComplete = formControlProps.autoComplete || autoComplete;
  formControlProps.disabled = disabled;
  formGroupProps.disabled = disabled;
  pluginProps.disabled = disabled;
  formControlProps.readOnly = readOnly;
  formGroupProps.readOnly = readOnly;
  pluginProps.readOnly = readOnly;
  const idMemo = useMemo(
    () => id || name + Math.floor(Math.random() * 100000),
    [id]
  );
  if (scrollOnRender) {
    useEffect(() => {
      setTimeout(() => {
        document
          .querySelector(`input[name="${name}"]`)
          ?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }, []);
  }
  if (updateQuery) {
    endEl = <UpdateButtonWrapper name={name} updateQuery={updateQuery} />;
  }
  const controllerProps: Omit<ControllerProps, 'render' | 'name'> =
    useMemo(() => {
      const c: {
        defaultValue?: any;
        rules?: Omit<
          RegisterOptions<any, string>,
          | 'setValueAs'
          | 'disabled'
          | 'valueAsNumber'
          | 'valueAsDate'
          | 'readOnly'
        >;
      } = {};
      const validatePattern = v => {
        if (processedRegisterProps.pattern instanceof RegExp) {
          return processedRegisterProps.pattern.test(v);
        }
        if (
          processedRegisterProps.pattern?.value &&
          !new RegExp(processedRegisterProps.pattern.value).test(v)
        ) {
          return processedRegisterProps.pattern.message;
        }
        return true;
      };
      const customValidate = (v, d) => {
        return processedRegisterProps.validate &&
          typeof processedRegisterProps.validate === 'function'
          ? processedRegisterProps.validate(v, d)
          : true;
      };
      if ((type === 'checkbox' || type === 'switch') && options?.length) {
        c.defaultValue = [];
        c.rules = {
          validate: (v, d) =>
            customValidate(v, d) ||
            !processedRegisterProps.required ||
            (v && v.length > 0) ||
            'At least one selection is required',
          ...processedRegisterProps
        };
      }
      if (type === 'document') {
        c.defaultValue = {
          fileId: '',
          fields: []
        };
        c.rules = {
          ...processedRegisterProps,
          validate: (v, d) => {
            console.log('validating document', v, d);
            const custom = customValidate(v, d);
            if (typeof custom === 'string' || !custom) return custom;
            if (!processedRegisterProps.required) return true;
            if (!v.fileId?.length) return 'Must upload a file.';
            if (!v.fields?.length) return 'At least one field is required';
            return true;
          }
        };
      }
      if (type === 'email') {
        c.defaultValue = '';
        c.rules = {
          ...processedRegisterProps,
          validate: (v, d) => {
            if (
              !new RegExp(
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{2,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i
              ).test(v)
            ) {
              return 'Not a valid email address.';
            }
            if (!processedRegisterProps?.pattern) {
              return validatePattern(v);
            }
            return customValidate(v, d);
          }
        };
      }
      if (type === 'mobile') {
        c.defaultValue = '';
        c.rules = {
          ...processedRegisterProps,
          validate: (v, d) => {
            if (!new RegExp(/^(00447|\+447|07)\d{9}$/).test(v)) {
              return 'Not a valid UK mobile number.';
            }
            if (!processedRegisterProps?.pattern) {
              return validatePattern(v);
            }
            return customValidate(v, d);
          }
        };
      }
      if (type === 'landline') {
        c.defaultValue = '';
        c.rules = {
          ...processedRegisterProps,
          validate: (v, d) => {
            if (!new RegExp(/^(0044|\+44|0)\d{10}$/).test(v)) {
              return 'Not a valid UK landline number.';
            }
            if (!processedRegisterProps?.pattern) {
              return validatePattern(v);
            }
            return customValidate(v, d);
          }
        };
      }
      if (type.includes('range')) {
        c.rules = {
          ...processedRegisterProps,
          validate: (v, d) => {
            return (
              customValidate(v, d) ||
              (v[0] && v[1] && v[0] < v[1]) ||
              'Maximum must be higher than the minimum'
            );
          }
        };
      }
      if (registerMedia && processedRegisterProps.isMedia) {
        c.rules = c.rules || { ...processedRegisterProps };
        c.rules.onChange = () => {
          registerMedia(name, pluginProps);
        };
      }
      return c;
    }, [
      type,
      options,
      registerMedia,
      processedRegisterProps,
      disabled,
      readOnly
    ]);
  const defaultLabel = useMemo(
    () => !hideLabel && (label || (!hideLabel && camelToSentence(name))),
    [hideLabel, name, label]
  );
  return (
    <WizardInputWrapper
      {...{
        name,
        floatingLabel:
          type === 'select' || type === 'multiSelect' ? false : floatingLabel,
        hideLabel: floatingLabel ? true : hideLabel,
        label: defaultLabel,
        formGroupProps,
        instruction,
        endEl,
        controllerProps,
        id: idMemo,
        registerProps: processedRegisterProps,
        options,
        prefix,
        suffix,
        flush,
        ai,
        onLoad: () => {
          if (processedRegisterProps.isMedia && registerMedia) {
            registerMedia(name, pluginProps);
          }
        }
      }}
    >
      {renderProps => (
        <InputTypeSelector
          type={type}
          props={{
            name,
            placeholder,
            type,
            formControlProps,
            pluginProps,
            id: idMemo,
            options,
            multiple,
            value,
            floatingLabel: floatingLabel ? defaultLabel : undefined,
            registerProps: processedRegisterProps,
            ai
          }}
          renderProps={renderProps}
        />
      )}
    </WizardInputWrapper>
  );
};

const UpdateButtonWrapper = ({ updateQuery, name }) => {
  const { trigger, getValues, resetField, setError, getFieldState } =
    useFormContext();
  const [show, setShow] = useState(false);
  const fieldState = getFieldState(name);
  useEffect(() => {
    setShow(getFieldState(name).isDirty);
  }, [fieldState]);
  const { mutate, isLoading, error } = useMutation<any, Error, any>(
    updateQuery
  );
  const handleConfirm = async () => {
    const valid = await trigger(name);
    if (valid) {
      const values = getValues();
      const value = getValues(name);
      return mutate(
        { field: name, value, values },
        {
          onSuccess: () => {
            return resetField(name, { defaultValue: value });
          },
          onError: err => {
            console.log('error in wrapper', err);
            return setError(name, { type: 'custom', message: err.message });
          }
        }
      );
    }
  };
  const handleCancel = () => {
    return resetField(name);
  };
  return (
    <UpdateButton
      show={show}
      loading={isLoading && !error}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
};
UpdateButtonWrapper.propTypes = {
  updateQuery: PropTypes.func,
  name: PropTypes.string
};
export const UpdateButton = ({ show, onConfirm, onCancel, loading }) => {
  return (
    show && (
      <ButtonGroup className={'position-absolute end-0'} style={{ zIndex: 5 }}>
        <Button
          onClick={onConfirm}
          disabled={loading}
          variant={!loading && 'primary'}
          className="w-50"
        >
          {loading ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faCheck} />}
        </Button>
        <Button onClick={onCancel} variant="secondary" className="w-50">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </ButtonGroup>
    )
  );
};
UpdateButton.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool
};
const Loader = inputProps => (
  <Form.Group className="mb-3" {...inputProps.formGroupProps}>
    {!inputProps.hideLabel && (
      <div className="form-label text-center" style={{ lineHeight: 1.7 }}>
        <Skeleton />
      </div>
    )}
    <div className="form-control text-center">
      <Skeleton />
    </div>
  </Form.Group>
);
Loader.propTypes = {
  inputProps: PropTypes.object
};
export const useInputProps = <TFormValues extends Record<string, any> = any>(
  inputProps: WizardInputProps<TFormValues>
) => {
  const configProps = useInputConfig();
  const definedProps = Object.keys(inputProps).reduce((a, b) => {
    if (inputProps[b] !== undefined) {
      a[b] = inputProps[b];
    }
    return a;
  }, {});
  return useMemo(() => ({ ...configProps, ...definedProps }), [definedProps]);
};
const WizardInput = <TFormValues extends Record<string, any> = any>(
  props: WizardInputProps<TFormValues>
) => {
  const inputProps = useInputProps<TFormValues>(props);
  // console.log('inputProps', inputProps);
  return (
    <Suspense fallback={<Loader inputProps={inputProps} />}>
      {inputProps.loading ? (
        <Loader inputProps={inputProps} />
      ) : (
        <WizardInputControl {...inputProps} />
      )}
    </Suspense>
  );
};
export default WizardInput;
