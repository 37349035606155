import Lottie from 'lottie-react';
import celebration from 'assets/img/animated-icons/celebration.json';
import useTimeout from 'hooks/useTimeout';
import React, { useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import { CloseButton } from 'react-bootstrap';
import { uniqueId } from 'lodash';
type EmoteFunction = (opts?: {
  duration?: number;
  whenDone?: () => void;
  message?: string;
}) => {
  stop: () => void;
};
const Context = React.createContext<{ celebrate: EmoteFunction }>({
  celebrate: undefined
});
export default ({ children }) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [doneCb, setDoneCb] = useState<() => void>(() => {});
  const [message, setMessage] = useState('');
  const { set } = useTimeout();

  const handleClose = () => {
    setShow(false);
    if (doneCb) {
      doneCb();
    }
  };
  const emote = (type: string): EmoteFunction =>
    useCallback(({ whenDone, duration = 2000, message: _message } = {}) => {
      setDoneCb(() => whenDone);
      set(() => {
        setShow(false);
        if (whenDone) {
          whenDone();
        }
      }, duration);
      setType(type);
      setShow(true);
      if (_message) {
        setMessage(_message);
      } else {
        setMessage('');
      }
      return { stop: () => setShow(false) };
    }, []);
  const celebrate = emote('celebrate');
  return (
    <Context.Provider value={{ celebrate }}>
      {children}
      {/* <AnimatePresence> */}
      {show &&
        createPortal(
          <motion.div
            initial={{ opacity: 0, y: -1000 }}
            transition={transitions.lightBounce}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -1000 }}
            className="position-fixed top-50 start-50 w-100 translate-middle text-center bg-shape"
          >
            <CloseButton
              variant="white"
              onClick={handleClose}
              className="position-absolute top-0 end-0 m-2 z-1"
            />
            <AnimatePresence>
              {message && (
                <motion.div
                  className="position-absolute start-50 mt-2 top-0 translate-middle-x"
                  initial={{ y: -1000, opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -1000 }}
                  transition={transitions.lightBounce}
                >
                  <h3 className="py-2 text-light fw-bold">{message}</h3>
                </motion.div>
              )}
              {type === 'celebrate' && (
                <Emoter id="celebration">
                  <div
                    className="overflow-y-hidden w-100 position-relative"
                    style={{ height: 280 }}
                  >
                    <Lottie
                      className="position-absolute bottom-0 start-50 translate-middle-x w-100"
                      style={{
                        height: '100vh'
                      }}
                      animationData={celebration}
                      loop={true}
                    />
                  </div>
                </Emoter>
              )}
            </AnimatePresence>
          </motion.div>,
          window.document.body
        )}
      {/* </AnimatePresence> */}
    </Context.Provider>
  );
};
const Emoter = ({ children, id }) => {
  return (
    <motion.div
      id={uniqueId(id)}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={transitions.lightBounce}
      className="w-100 h-100"
    >
      {children}
    </motion.div>
  );
};
export const useEmote = () => useContext(Context);
