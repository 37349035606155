import { useMutation } from "@tanstack/react-query";
import { mutateAsyncToast } from "./helpers";
export default ({
  api,
  onSuccess
}) => {
  const {
    mutate: addTags,
    isLoading: isAddingTags,
    error: addTagsError,
    mutateAsync: addTagsAsync
  } = useMutation({
    mutationFn: async ({ itemIds, tagIds }) => {
      await api.addTags(itemIds, tagIds);
    },
    onSuccess
  });
  const {
    mutate: removeTags,
    isLoading: isRemovingTags,
    error: removeTagsError,
    mutateAsync: removeTagsAsync
  } = useMutation({
    mutationFn: async ({ itemIds, tagIds }) => {
      await api.removeTags(itemIds, tagIds);
    },
    onSuccess
  });
  return {
    addTags,
    isAddingTags,
    addTagsError,
    addTagsAsync: mutateAsyncToast(addTagsAsync, false),
    removeTagsAsync: mutateAsyncToast(removeTagsAsync, false),
    removeTags,
    isRemovingTags,
    removeTagsError
  };
};
