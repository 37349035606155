import { useEffect } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
export default (when = true, afterPrompt = () => {
}) => {
  const navigate = useNavigate();
  const navBlocker = useBlocker(!when);
  useEffect(() => {
    if (navBlocker.state === "blocked") {
      if (confirm(
        "You may have unsaved data on this page. Are you sure you want to leave?"
      )) {
        afterPrompt(true);
        return navBlocker.proceed();
      }
      navBlocker.reset();
      afterPrompt(false);
    }
  }, [navBlocker, when]);
  useEffect(() => {
    if (navBlocker.state === "blocked" && when) {
      navBlocker.reset();
    }
  }, [navBlocker, when]);
  useEffect(() => {
    if (!when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = void 0;
    }
    return () => window.onbeforeunload = void 0;
  }, [when]);
  useEffect(() => {
    return () => {
      navBlocker?.reset?.();
    };
  }, []);
  return navigate;
};
