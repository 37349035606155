import { RoleDomain } from 'apis/flex/users';
import React, { useCallback } from 'react';
import CustomReportTiles, { CustomReportTilesProps } from './CustomReportTiles';
import { domainConfigs } from 'components/notification/config';
import { DomainConfig } from 'config';
import { callIfFunction } from 'helpers/utils';
import { ReportConfig } from 'apis/flex/reporting';

export default ({
  domain,
  itemId,
  data,
  reportFilters,
  filters,
  ...rest
}: {
  domain: RoleDomain;
  itemId?: number;
  data?: any;
} & CustomReportTilesProps) => {
  const domainConfig: DomainConfig = domainConfigs[domain];
  if (!domainConfig) {
    console.warn('Config for domain ' + domain + ' is not found');
    return null;
  }
  const filtersCallback = useCallback(
    (config: ReportConfig) => {
      if (!filters) return [];
      if (config.table === domainConfig.sqlTable) {
        return [
          ...callIfFunction(filters, { ...config }),
          itemId
            ? [{ question: 'id', type: '==', value: itemId.toString() }]
            : []
        ];
      } else {
        return [
          ...callIfFunction(filters, { ...config }),
          itemId
            ? [
                {
                  question: domainConfig.foreignKey,
                  type: '==',
                  value: itemId.toString()
                }
              ]
            : []
        ];
      }
    },
    [domainConfig, filters, itemId]
  );
  return (
    <CustomReportTiles
      variables={data}
      defaultValues={{
        db: domainConfig?.sqlDb,
        table: domainConfig?.sqlTable
      }}
      reportFilters={{
        db: domainConfig?.sqlDb,
        table: domainConfig?.sqlTable,
        ...reportFilters
      }}
      filters={filtersCallback}
      {...rest}
    />
  );
};
