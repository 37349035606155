import React from 'react';
import LeaveTable from './LeaveTable';

export default () => {
  return (
    <>
      <LeaveTable />
    </>
  );
};
