import React, { useEffect } from 'react';
import useDependencySelect from 'hooks/useDependencySelect';
import { EventPrefix } from 'apis/flex/notifications';
import { domainConfigs } from 'components/notification/config';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ensureArray } from 'helpers/utils';
import { isArraysEqual } from '@fullcalendar/core/internal';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { useInputConfig } from 'components/wizard/InputConfig';
export default ({
  domain,
  dependencyDomain,
  foreignKey,
  localKey,
  name,
  children,
  component: Component,
  ...rest
}: {
  /** If you specify a domain, that will autopoulate the foreignKey */
  domain?: EventPrefix;
  /** If you specify a dependencyDomain, that will autopoulate the foreignKey */
  dependencyDomain?: EventPrefix;
  /** The field in the master data, e.g. employeeId that is used find this domain item */
  foreignKey?: string;
  /** The field in the dependency data, e.g. employeeContractId that is used to find the dependency */
  localKey?: string;
  /** The name of the field in the master form */
  name: string;
  /** A render function to produce the child element. 'name', 'registerProps', and 'multiple' will be passed and should not be overridden  */
  children?: (
    inputProps: Omit<Partial<WizardInputProps>, 'name' | 'registerProps'> & {
      name: string;
      registerProps: { onChange: (e: any) => void };
    }
  ) => React.ReactNode;
  component?: React.JSXElementConstructor<any>;
} & Partial<WizardInputProps>) => {
  const config = domain && domainConfigs[domain];
  const dependencyConfig = dependencyDomain && domainConfigs[dependencyDomain];
  const data = useWatch<Record<string, any> & { id: number }>();
  const { onChange } = useDependencySelect({
    data,
    key: name,
    foreignKey: foreignKey || dependencyConfig?.foreignKey,
    localKey: localKey || config?.foreignKey
  });
  const methods = useForm();
  useEffect(() => {
    // console.log(
    //   name,
    //   data?.[name],
    //   foreignKey || dependencyConfig?.foreignKey,
    //   methods.getValues('select')
    // );
    const newVal =
      ensureArray(data?.[name])
        ?.map(d => d?.[foreignKey || dependencyConfig?.foreignKey])
        .filter(d => d) || [];
    const areEqual = isArraysEqual(newVal, methods.getValues(name) || []);
    // console.log(
    //   name,
    //   'newVal',
    //   newVal,
    //   methods.getValues('select') || [],
    //   areEqual
    // );
    if (!areEqual) {
      methods.setValue(name, newVal, {
        shouldDirty: true,
        shouldTouch: true
      });
    }
  }, [data?.[name]]);
  const inputProps = useInputConfig();
  return (
    <FormProvider {...methods}>
      {Component ? (
        <Component
          {...{
            ...inputProps,
            ...rest,
            name,
            registerProps: { onChange },
            multiple: true
          }}
        />
      ) : (
        children?.({ name, registerProps: { onChange }, multiple: true })
      )}
    </FormProvider>
  );
};
