import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { sentryInstance } from 'index';
import useSiteRoutes from 'hooks/useSiteRoutes';
import { Outlet } from 'react-router-dom';
const FalconRoutes = () => {
  const { allRoutes } = useSiteRoutes();
  const sentryCreateBrowserRouter =
    sentryInstance.wrapCreateBrowserRouterV7(createBrowserRouter);
  const router = sentryCreateBrowserRouter(allRoutes);
  return <RouterProvider fallbackElement={<Outlet />} router={router} />;
};
export default FalconRoutes;
