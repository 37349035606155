import React from 'react';
import { EmployeeShift } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import { getDomainInput, ImmutableInput } from 'components/common/DomainInput';
import { EmployeeSelector } from '../../staff/widgets/EmployeeSelector';
import { AvailableEmployeeShiftsSelector } from '../ShiftSelector';
import { useWatch } from 'react-hook-form';
import { DomainWizard } from 'components/common/DomainForm';
import {
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
const Input = getDomainInput<EmployeeShift>();
export default ({
  itemId,
  onFinished
}: {
  itemId?: number | number[];
  onFinished?: () => void;
}) => {
  return (
    <DomainWizard
      domain="employee-shift"
      itemId={itemId}
      onFinished={onFinished}
    >
      <DetailCard id="setup" title="Setup">
        <ImmutableInput
          component={EmployeeSelector}
          name="employeeId"
          label="Employee(s)"
          // 'multiple' makes it tricky to create new shifts.
          // TODO: Need to create all combinations of selected employees/dates
          // multiple
        />
        <Input type="date" name="date" pluginProps={{ futureOnly: true }} />
      </DetailCard>
      <DetailCard id="shift" title="Shift">
        <Shift />
      </DetailCard>
    </DomainWizard>
  );
};
export const EmployeeShiftSelector =
  getDomainItemSelector<EmployeeShift>('employee-shift');
export const SelectedEmployeeShifts =
  getSelectedDomainItems<EmployeeShift>('employee-shift');
const Shift = () => {
  const date = useWatch<EmployeeShift, 'date'>({ name: 'date' });
  const employeeId = useWatch<EmployeeShift, 'employeeId'>({
    name: 'employeeId'
  });
  return (
    !!(date && employeeId) && (
      <AvailableEmployeeShiftsSelector
        startDate={date}
        endDate={date}
        name="shiftId"
        employeeId={employeeId}
      />
    )
  );
};
