import DetailCard from 'components/common/detail/DetailCard';
import React from 'react';
import ShiftPreferenceForm from 'components/app/hr/shifts/shiftPreferences/ShiftPreferenceForm';
import { addDays, format, startOfISOWeek } from 'date-fns';
import ExpandableParagraph from 'components/common/ExpandableParagraph';
export const getCurrentShiftPreferenceWeek = () =>
  startOfISOWeek(addDays(new Date(), 14));
export default ({ employeeId }: { employeeId: number }) => {
  const weekToBook = getCurrentShiftPreferenceWeek();
  return (
    <DetailCard
      id="workingHours"
      title="My availability"
      subtitle={<>For the week starting {format(weekToBook, 'dd/MM/yyyy')}</>}
      bodyProps={{ className: 'pt-0' }}
      info={
        <>
          Here you can tell us when you're available to work. You can select
          multiple shifts for each day and the one best suited to the available
          projects will be selected.
          <br />
          <br />
          You can come back here and change your availability at any time, as
          long as it's at least 2 weeks before the week you want to change.
        </>
      }
    >
      <ShiftPreferenceForm
        employeeId={employeeId}
        weekCommencing={weekToBook}
      />
    </DetailCard>
  );
};
