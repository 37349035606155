import { Break } from 'apis/flex/hr';
import DomainTable from 'components/common/DomainTable';
import React from 'react';

export default () => {
  return (
    <DomainTable<Break>
      domain="break"
      columns={[
        'name',
        {
          id: 'durationMins',
          header: 'Duration',
          formatter: v => (v() ? `${v()} mins` : '')
        },
        {
          id: 'durationPercentOfWorkingTime',
          header: 'Percent of working time',
          formatter: v => (v() ? `${v()}%` : '')
        }
      ]}
    />
  );
};
