import { useState } from "react";
import useSocket from "./useSocket";
import useUuid from "./useUuid";
export default ({
  progressId: _id,
  onProgress
} = {}) => {
  const { id: progressId } = useUuid({ id: _id });
  const [itemProgress, setItemProgress] = useState({});
  useSocket({
    eventName: "progress-" + progressId,
    callback: (data) => {
      if (onProgress) {
        return onProgress(data.itemId, data.progress);
      }
      setItemProgress((p) => ({
        ...p,
        [data.itemId]: data.progress
      }));
    }
  });
  return { progress: itemProgress, progressId };
};
