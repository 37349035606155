import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import useMainRef from 'hooks/useMainRef';
import { AllocationEntry } from 'apis/flex/projects';

export default ({
  children,
  index,
  draggableId
}: {
  children: React.ReactNode;
  index: number;
  draggableId: string;
}) => {
  const ref = useMainRef();
  const Item = ({ provided, snapshot: s }) => {
    const usePortal = s.isDragging;
    const child = (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={{
          ...provided.draggableProps.style,
          minWidth: '100px'
        }}
      >
        <div
          {...provided.dragHandleProps}
          className={classNames({
            'cursor-grabbing': s.isDragging,
            'cursor-grab': !s.isDragging
          })}
        >
          {children}
        </div>
      </div>
    );
    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return createPortal(child, ref.current);
  };
  return (
    <Draggable index={index} draggableId={draggableId}>
      {(provided, s) => {
        return <Item provided={provided} snapshot={s} />;
      }}
    </Draggable>
  );
};
