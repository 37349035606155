import { Project, ProjectCostCenter } from 'apis/flex/projects';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import DomainTable from 'components/common/DomainTable';
import React from 'react';
import {
  CostCenterSelector,
  useProjectCostCenters
} from './ProjectCostCenterSelector';
import AdvanceTableProvider from 'components/common/advance-table-v2/AdvanceTableProvider';
import InlineEditor, {
  Editor
} from 'components/common/advance-table-v2/InlineEditor';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { NamePrefix } from 'components/common/DomainInput';
import { Row } from '@tanstack/react-table';
import { C } from '@fullcalendar/core/internal-common';
import FieldArrayTable from 'components/common/FieldArrayTable';
import WizardInput from 'components/wizard/WizardInput';
import { parseUKCurrency } from 'helpers/strings';
const CellEditor = ({ context, namePrefix, field, editor }) => {
  const { setValue } = useFormContext();
  return (
    <InlineEditor
      editor={editor}
      cellProps={context}
      onEdit={(row, v) => {
        setValue(`${namePrefix}${row.index}.${field}`, v);
      }}
    />
  );
};
export default ({ projectId }) => {
  const fieldArray = useFieldArray<Project, 'costCenters'>({
    name: 'costCenters'
  });
  const getCellEditor =
    (name: string & keyof ProjectCostCenter, editor?: Editor) => context =>
      (
        <CellEditor
          field={name}
          editor={editor}
          namePrefix={'costCenters.'}
          context={context}
        />
      );
  return (
    <FieldArrayTable<Project, 'costCenters'>
      defaultValues={{ projectId, units: 1, unitValue: null }}
      fieldArray={fieldArray}
      name="costCenters"
      form={(field, i) => {
        return (
          <div key={field.id}>
            <CostCenterSelector
              name={`costCenters.${i}.costCenterId`}
              label="Cost center"
            />
            <div className="d-flex align-items-end justify-content-between gap-1">
              <WizardInput
                type="currency"
                name={`costCenters.${i}.unitValue`}
                label="Per unit"
                formControlProps={{ autoFocus: true }}
              />
              <div className="mb-3 pb-2">X</div>
              <WizardInput
                type="number"
                name={`costCenters.${i}.units`}
                label="Units"
              />
            </div>
          </div>
        );
      }}
      columns={[
        {
          id: 'costCenterId'
        },
        {
          id: 'units'
          //   cell: getCellEditor('units', 'number')
        },
        {
          id: 'unitValue',
          formatter: v => parseUKCurrency(v())
          //   cell: getCellEditor('unitValue', 'number')
        },
        {
          id: 'total',
          formatter: v => parseUKCurrency(v()),
          accessorFn: d => d.units * d.unitValue
        }
      ]}
      //   globalActions={[
      //     {
      //       name: 'Delete',
      //       disabled: d => d.some(d => d.original.inUse),
      //       actionFn: (rows, done) => {
      //         rows.forEach(row => {
      //           remove(row.index);
      //         });
      //         done();
      //       },
      //       icon: 'trash',
      //       variant: 'danger'
      //     }
      //   ]}
      //   onNewClick={() => {
      //     append({
      //       units: 0,
      //       unitValue: 0,
      //       projectId,
      //       costCenterId: null,
      //       id: undefined
      //     });
      //   }}
      //   data={fields}
    />
  );
};
