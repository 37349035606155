import React from 'react';
import { ShiftPreference } from 'apis/flex/hr';
import LoadingButton from 'components/common/LoadingButton';
import { Shifts } from 'components/wizard/inputs/WizardShifts';
import { formatDateToISO, getWeekdayIndex, weekdays } from 'helpers/dates';
import { FormProvider, useForm } from 'react-hook-form';
import { addDays, startOfISOWeek } from 'date-fns';
import useShiftPreferences from './useShiftPreferences';
import useEmployees from '../../staff/hooks/useEmployees';
import { AvailableDayShiftsPicker } from '../ShiftsPicker';
import InputConfig from 'components/wizard/InputConfig';
import Countdown from 'components/common/Countdown';
import { Badge } from 'react-bootstrap';
import { useGuard } from 'hooks/useGuard';
import { getCurrentShiftPreferenceWeek } from 'components/app/users/detail/UserWorkingHours';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { groupBy } from 'lodash';
import { useUser } from 'hooks/useUser';

export const useWeekShiftPreferences = (
  employeeId: number,
  weekCommencing: Date = getCurrentShiftPreferenceWeek()
) => {
  const weekEnd = addDays(weekCommencing, 6);
  return useShiftPreferences({
    filters: { employeeId },
    useFilter: !!employeeId,
    staleTime: Infinity,
    sort: [['date', 'desc']],
    paging: {
      pageSize: 14,
      page: 1
    },
    select: d => {
      const thisWeek = d.filter(
        dd =>
          new Date(dd.date) >= weekCommencing && new Date(dd.date) <= weekEnd
      );
      if (thisWeek.length) return thisWeek;
      const latestWeek = startOfISOWeek(
        new Date(d.find(dd => new Date(dd.date) < weekEnd)?.date)
      );
      return d.filter(
        dd => new Date(dd.date) >= latestWeek && new Date(dd.date) < weekEnd
      );
    }
  });
};
// export default ({
//   isLoading,
//   isSaving,
//   onSave,
//   employeeId,
//   weekCommencing,
//   readOnly
// }: {
//   isLoading?: boolean;
//   isSaving?: boolean;
//   onSave?: (data: ShiftPreference[]) => void;
//   employeeId: number;
//   weekCommencing: Date;
//   readOnly?: boolean;
// }) => {
//   const {
//     data: preferences,
//     isLoading: preferencesLoading,
//     bulkAdd,
//     bulkRemove,
//     isBulkAdding,
//     isBulkRemoving
//   } = useWeekShiftPreferences(employeeId, weekCommencing);
//   const { data: employee, isLoading: employeeLoading } = useEmployees({
//     id: employeeId,
//     select: d => d[0]
//   });
//   const { data: resourceGroups, isLoading: resourceGroupsLoading } =
//     useResourceGroups({
//       useFilter: !!employee?.resourceGroups?.length,
//       filters: { id: employee?.resourceGroups?.map(rg => rg.resourceGroupId) }
//     });
//   const formValues = useMemo(
//     () =>
//       resourceGroups?.reduce((a, rg) => {
//         a[rg.id.toString()] = convertShiftPreferencesToFormResponse(
//           weekCommencing,
//           preferences?.filter(p => p.resourceGroupId === rg.id)
//         );
//         return a;
//       }, {}),
//     [resourceGroups, preferences, weekCommencing]
//   );
//   const methods = useForm<Record<number, Shifts>, any, Record<number, Shifts>>({
//     // shouldUnregister: true,
//     defaultValues: formValues
//   });
//   useEffect(() => {
//     if (methods.formState.isDirty) return;
//     methods.reset(formValues);
//   }, [formValues]);
//   const [showModal, setShowModal] = useState(false);
//   const handleSubmit = methods.handleSubmit(vals => {
//     // console.log('SUBMITTED', vals);
//     const converted = employee?.resourceGroups?.flatMap(rg =>
//       convertFormResponseToShiftPreferences(
//         weekCommencing,
//         employeeId,
//         preferences,
//         vals[rg.resourceGroupId],
//         rg.resourceGroupId
//       )
//     );
//     // console.log('ADDING', converted);
//     onSave?.(converted);
//     if (preferences.map(p => p.id)?.length) {
//       bulkRemove(preferences.map(p => p.id));
//     }
//     bulkAdd(converted);
//   }, console.error);
//   const { canEdit } = useGuard({ roles: ['employee'], itemIds: [employeeId] });
//   return (
//     <FormProvider {...methods}>
//       <EmployeeResourceGroupsModal
//         employeeIds={[employeeId]}
//         show={showModal}
//         setShow={setShowModal}
//       />
//       <InputConfig readOnly={readOnly}>
//         <QueryStateDisplay>
//           <CustomTabs
//             isLoading={
//               isLoading ||
//               employeeLoading ||
//               preferencesLoading ||
//               resourceGroupsLoading
//             }
//             alignment="top"
//             actions={
//               canEdit
//                 ? [
//                     {
//                       label: 'Resource Groups',
//                       icon: faEdit,
//                       fn: () => setShowModal(true)
//                     }
//                   ]
//                 : undefined
//             }
//             items={resourceGroups?.map(rg => ({
//               id: rg.id,
//               title: rg.name,
//               icon: getRenderIconFromValue(rg.icon),
//               content: f => (
//                 <div className="p-3">
//                   <ShiftsPicker
//                     // instruction={`Tell us all the ${rg.bookingType} you would be able to do.`}
//                     name={rg.id.toString()}
//                     // label={camelToSentence(rg.bookingType)}
//                     label={
//                       <Countdown
//                         endDate={addDays(startOfISOWeek(new Date()), 7)}
//                       >
//                         {(remaining, ms) =>
//                           remaining &&
//                           ms < 1000 * 60 * 24 * 2 && (
//                             <Badge
//                               pill
//                               bg={ms < 1000 * 60 * 24 ? 'danger' : 'warning'}
//                             >
//                               {remaining} until preferences are locked in
//                             </Badge>
//                           )
//                         }
//                       </Countdown>
//                     }
//                     employeeId={employeeId}
//                     weekCommencing={weekCommencing}
//                     minHours={0}
//                     maxHours={24}
//                     type={rg.bookingType}
//                     resourceGroupId={rg.id}
//                   />
//                   {!readOnly && (
//                     <div className="d-flex justify-content-end">
//                       <LoadingButton
//                         loading={isSaving || isBulkAdding || isBulkRemoving}
//                         onClick={handleSubmit}
//                       >
//                         Update
//                       </LoadingButton>
//                     </div>
//                   )}
//                 </div>
//               )
//             }))}
//           />
//         </QueryStateDisplay>
//       </InputConfig>
//     </FormProvider>
//   );
// };
export default ({
  isLoading,
  isSaving,
  onSave,
  employeeId,
  weekCommencing,
  readOnly
}: {
  isLoading?: boolean;
  isSaving?: boolean;
  onSave?: (data: ShiftPreference[]) => void;
  employeeId: number;
  weekCommencing: Date;
  readOnly?: boolean;
}) => {
  const {
    data: preferences,
    isLoading: preferencesLoading,
    bulkAdd,
    bulkSelfRemove,
    bulkRemove,
    isBulkAdding,
    isBulkRemoving
  } = useWeekShiftPreferences(employeeId, weekCommencing);
  const { data: employee, isLoading: employeeLoading } = useEmployees({
    id: employeeId,
    select: d => d[0],
    staleTime: Infinity
  });
  const convertPreferencesToForm = (data: ShiftPreference[]) => {
    if (!data?.length) return {};
    const groupedByDate = groupBy(data, d => formatDateToISO(d.date));
    const formValues = Object.keys(groupedByDate).reduce((a, b) => {
      a[b] = groupedByDate[b].map(p => p.shiftId);
      return a;
    }, {} as Record<string, number[]>);
    return formValues;
  };
  const convertFormToPreferences = (data: Record<string, number[]>) => {
    const formValues = Object.entries(data).reduce((a, [date, shiftIds]) => {
      return a.concat(
        (shiftIds || []).map(shiftId => ({
          date: new Date(date),
          shiftId,
          id: undefined,
          employeeId
        }))
      );
    }, [] as ShiftPreference[]);
    return formValues;
  };
  const methods = useForm<{ preferences: Record<string, number[]> }>({
    values: { preferences: convertPreferencesToForm(preferences) }
  });
  const { canEdit } = useGuard({ roles: ['employee'], itemIds: [employeeId] });
  const { employeeId: userEmployeeId } = useUser();
  const isSelf = employee?.id === userEmployeeId;
  const handleSubmit = methods.handleSubmit(vals => {
    if (preferences.map(p => p.id)?.length) {
      (isSelf ? bulkSelfRemove : bulkRemove)(
        preferences.map(p => p.id),
        {
          onSuccess: () => {
            bulkAdd(
              convertFormToPreferences(vals.preferences),
              { onSuccess: onSave },
              true,
              isSelf
            );
          }
        }
      );
    }
  });
  return (
    <FormProvider {...methods}>
      <Countdown endDate={addDays(startOfISOWeek(new Date()), 7)}>
        {(remaining, ms) =>
          remaining &&
          ms < 1000 * 60 * 24 * 2 && (
            <Badge pill bg={ms < 1000 * 60 * 24 ? 'danger' : 'warning'}>
              {remaining} left to submit your shifts
            </Badge>
          )
        }
      </Countdown>
      <InputConfig readOnly={readOnly}>
        <AvailableDayShiftsPicker
          name={'preferences'}
          allowOverlap
          registerProps={{ required: false }}
          label={'Select the shifts you can work'}
          startDate={weekCommencing}
          endDate={addDays(weekCommencing, 6)}
          availableTo={{
            employeeId: [employeeId],
            departmentId: [employee?.departmentId],
            trainingId: employee?.completedTrainingCourseIds,
            contractId: [employee?.contractId]
          }}
        />
        {/* <ShiftCalendarInput
          name={'preferences'}
          permitted={permitted}
          label={'Select the shifts you can work'}
          weekCommencing={weekCommencing}
          employeeId={employeeId}
        /> */}
        {!readOnly && (canEdit || isSelf) && (
          <div className="d-flex justify-content-end">
            <LoadingButton
              loading={isSaving || isBulkAdding || isBulkRemoving}
              onClick={handleSubmit}
            >
              Update
            </LoadingButton>
          </div>
        )}
      </InputConfig>
      {(isLoading || employeeLoading || preferencesLoading) && (
        <LoadingOverlay />
      )}
    </FormProvider>
  );
};
