import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomFormWizard from '../Wizard/CustomFormWizard';
import useFormResponse from '../hooks.js/useFormResponse';
import useCustomForm from '../hooks.js/useCustomForm';
import { Form, FormResponse } from 'apis/flex/customForms';
const CustomFormViewer = ({
  responseId,
  form: loadedForm,
  formId,
  response: loadedResponse = null
}: {
  responseId?: number;
  form?: Form;
  formId?: number;
  response?: FormResponse;
}) => {
  const { form: formFromId = loadedForm, isLoading } = useCustomForm({
    formId,
    enabled: !loadedForm && !!formId
  });
  const form = useMemo(
    () => loadedForm || formFromId,
    [loadedForm, formFromId, formId]
  );
  const { data: responseFromId = loadedResponse, isLoading: dataLoading } =
    useFormResponse({
      responseId,
      enabled: !!responseId && !loadedResponse
    });
  const response = useMemo(
    () => (loadedResponse || responseFromId)?.data.form,
    [loadedResponse, responseFromId, responseId]
  );
  const methods = useForm();
  useEffect(() => {
    methods.reset(response);
  }, [response]);
  return (
    <CustomFormWizard
      isFormLoading={
        (isLoading && !loadedForm) || (dataLoading && !loadedResponse)
      }
      form={form}
      view
      data={response}
      freeNav
    />
  );
};
CustomFormViewer.propTypes = {
  data: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  form: PropTypes.object,
  responseId: PropTypes.number
};
export default CustomFormViewer;
