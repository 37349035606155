import api from "./index";
import CrudApi from "./CrudApi";
export const domains = [
  "applicant",
  "applicant-stage",
  "user",
  "user-roles",
  "user-subscription",
  "form",
  "form-response",
  "ticket",
  "notification",
  "academy",
  "email",
  "socket-notification",
  "recruitment-campaign",
  "contract",
  "new-starter",
  "user-group",
  "training-course",
  "user-training",
  "employee-screening",
  "employee",
  "analysis",
  "recruitment-pipeline",
  "department",
  "mailer-template",
  "app-notification",
  "short-link",
  "report-config",
  "file-upload",
  "page-report",
  "file",
  "transaction",
  "mailer-preference",
  "client",
  "project",
  "quote",
  "target-type",
  "target-group",
  "team-role",
  "project-role",
  "shift",
  "employee-shift",
  "shift-preference",
  "resource-group",
  "access-group",
  "questionnaire",
  "shift-team",
  "tag",
  "employee-contract",
  "access-delegation",
  "job-title",
  "calendar",
  "calendar-item",
  "fixed-schedule",
  "employee-shift-change",
  "employee-medical-record",
  "employee-payroll-record",
  "leave-request",
  "leave-type",
  "lateness",
  "cost-center",
  "user-guide",
  "webhook",
  "shift-plan",
  "break"
];
export const actions = [
  "ready",
  "requested",
  "added",
  "updated",
  "removed",
  "sent",
  "submitted",
  "error",
  "accepted",
  "rejected",
  "loggedin",
  "changed"
];
export const getAppNotifications = () => api.get("/mailer/notifications/app").then((d) => d.data);
export const getNotificationsSubscriptions = () => api.get("/mailer/notifications/subscriptions/").then((d) => d.data);
export const updateNotificationsSubscriptions = (subscriptions) => api.patch("/mailer/notifications/subscriptions/", { data: subscriptions }).then((d) => d.data);
export const getUserNotifications = (page, id, filters) => api.get("/mailer/notifications/" + (id || ""), {
  params: { page, filters }
}).then((d) => d.data);
export const getAllUserNotifications = (filters) => api.get("/mailer/notifications/", {
  params: { filters }
}).then((d) => d.data);
export const getUserNotificationCounts = () => api.get("/mailer/notifications/count").then((d) => d.data);
export const markNotificationAsSeen = ({ id, eventName, domain, itemId }) => api.patch("/mailer/notifications/seen", { id, eventName, domain, itemId });
export const markNotificationAsActioned = ({ id, eventName, domain, itemId }) => api.patch("/mailer/notifications/actioned", {
  id,
  eventName,
  domain,
  itemId
});
export const messageUsers = (ids, message) => api.post("/mailer/notify", { ids, message });
export const resendEmail = ({ userId, eventName }) => api.post("/mailer/notifications/resend", { userId, eventName });
export const mailerTemplatesApi = new CrudApi(
  "/mailer/templates"
);
export const getTemplatePreview = (data, domain, action) => api.post(
  "/mailer/templates/preview/" + domain + (action ? "/" + action : ""),
  { data }
).then((d) => d.data);
export const getFakeTemplateData = () => api.get("/mailer/templates/fake-data").then((d) => d.data);
export const appNotificationsApi = new CrudApi(
  "/mailer/notifications/app"
);
export const mailerPreferencesApi = new CrudApi(
  "/mailer/preferences"
);
