import {
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import { Contract } from 'apis/flex/hr';

const ContractPicker = getDomainItemSelector<Contract>('contract', {
  crudProps: {
    filters: [{ first: 'state', second: 'active' }]
  }
});
export const SelectedContracts = getSelectedDomainItems('contract');
export default ContractPicker;
