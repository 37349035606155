import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { FC } from 'react';
import {
  getReportData,
  ReportConfig,
  ReportData,
  ReportDataRow
} from 'apis/flex/reporting';
import { uniq } from 'lodash';
import { DurationUnitType } from 'dayjs/plugin/duration';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { CustomRule } from 'helpers/validation/validate';
import BasicChart from './charts/BasicChart';
import { bigNumber } from 'helpers/number';
import { getReportDataRetry } from './useReportData';

export const fileSize = (bytes: number) => {
  if (isNaN(bytes / 1)) return '';
  if (!bytes) return '0 KB';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return 'n/a';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(1))} ${sizes[i]}`;
};

export const specialFormatters = {
  System: {
    FileStorage: {
      size: v => fileSize(v)
    }
  }
};

export type Series = {
  name: string;
  color?: string;
  data: number[];
  axisId?: string;
  stack?: boolean;
  valueFormatter?: (value: string, index: number) => string;
};
export type XAxis = {
  data: string[];
  id?: string;
  formatter?: (value: string, index: number) => string;
};
export type YAxis = {
  min: number;
  max: number;
  id?: string;
  formatter?: (value: string, index: number) => string;
};
export type ChartType = 'line' | 'pie' | 'bar';
export type ReportComponentProps = {
  series: Series[];
  xAxes?: XAxis[];
  yAxes?: YAxis[];
  simple?: boolean;
  type: ChartType;
  height?: number | string;
  stacked?: boolean;
  onClick?: (params: any) => void;
};
type GenericReportComponent = FC<Omit<ReportComponentProps, 'type'>>;
export const getComponent = (type: string): GenericReportComponent => {
  return (props: Omit<ReportComponentProps, 'type'>) => (
    <BasicChart
      type={type?.toLowerCase().replace('stacked', '') as ChartType}
      {...props}
      stacked={type?.toLowerCase().includes('stacked')}
    />
  );
};
export const needsAnXAxis = (type: string) => {
  return (
    type?.toLowerCase().includes('line') || type?.toLowerCase().includes('bar')
  );
};

export const canConfigGetData = (config: Partial<ReportConfig>) => {
  return (
    config?.db &&
    config?.table &&
    (!needsAnXAxis(config?.type) || config?.xAxes?.[0]?.field)
  );
};
const dateWrapper = (value: any, dateFormat: string) => {
  try {
    return format(new Date(value), dateFormat);
  } catch (e) {
    return '';
  }
};
export const sqlValueFormatter = type => {
  switch (type) {
    case 'number':
    case 'numeric':
    case 'decimal':
      return (value: any) => Number(value);
    case 'date':
    case 'day':
      return (value: any) => dateWrapper(value, 'dd/MM/yyyy');
    case 'datetime':
    case 'hour':
    case 'minute':
      return (value: any) => dateWrapper(value, 'dd/MM/yyyy HH:mm');
    case 'month':
      return (value: any) => dateWrapper(value, 'MMM-yy');
    case 'week':
      return (value: any) => 'W/c ' + dateWrapper(value, 'dd/MM/yyyy');
    default:
      return (value: any) => value.toString();
  }
};
const colors = new Set(['primary', 'danger', 'info', 'warning', 'success']);
let colorEnum = colors.values();
export const nextColor = i => {
  if (i === 0) {
    colorEnum = colors.values();
  }
  let color = colorEnum.next();
  if (color.done) {
    colorEnum = colors.values();
    color = colorEnum.next();
  }
  return color.value;
};

type GroupByDate = `date.${DurationUnitType}`;
export type GroupByType = GroupByDate;
export const groupByOptions: { label: string; value: GroupByType }[] = [
  { label: 'Hour', value: 'date.hour' },
  { label: 'Day', value: 'date.day' },
  { label: 'Week', value: 'date.week' },
  { label: 'Month', value: 'date.month' },
  { label: 'Year', value: 'date.year' }
];
const getMsFromGroupByDate = (groupBy: GroupByDate) => {
  return groupBy
    ? dayjs(0)
        .add(1, groupBy.split('.')[1] as DurationUnitType)
        .valueOf()
    : null;
};
export const getFormatterByGroupBy = (groupBy: GroupByType) => {
  if (groupBy.startsWith('date')) {
    return sqlValueFormatter(groupBy.split('.')[1]);
  }
  return sqlValueFormatter(groupBy.split('.')[0]);
};
const consolidateType = (type: string) => {
  switch (type) {
    case 'datetime':
      return 'date';
    default:
      return type;
  }
};
export const getGroupByOptionsByType = (type: string) => {
  const consolidatedType = consolidateType(type);
  return groupByOptions.filter(g => g.value.startsWith(consolidatedType + '.'));
};

export const resolveSeriesConfig = (data: ReportData, config: ReportConfig) => {
  return config?.seriesSplitBy === 'custom'
    ? config.series || []
    : getUniqueSeriesFields(data, config).map(s => ({
        name: s
      })) || [];
};
export const getUniqueSeriesFields = (
  data: ReportData,
  config: ReportConfig
) => {
  return data ? Object.keys(data) : [];
};
const isContinuousType = (value: any) => {
  try {
    return (
      typeof value !== 'boolean' &&
      (!!value || value === 0) &&
      new Date(value) &&
      true
    );
  } catch (e) {
    return typeof value === 'number' || !isNaN(value / 1);
  }
};
const getAxisValue = (value: any, isDate?: boolean) => {
  //parse the value in a way that can be matched between axis and data with === operator
  try {
    if (typeof value === 'string' || isDate) {
      return new Date(value).toISOString();
    }
    return typeof value === 'boolean'
      ? value.toString()
      : value === null || value === undefined
      ? '[blank]'
      : Number(value).toString();
  } catch (e) {
    return typeof value === 'number' || !isNaN(value / 1)
      ? Number(value).toString()
      : value.toString();
  }
};
const getMathableValue = (v: any) => {
  return isContinuousType(v) ? new Date(v).valueOf() : undefined;
};
const getContinuousAxisValues = (valueArray: any[], groupBy: GroupByType) => {
  const isContinuous = valueArray.every(v => {
    const c = isContinuousType(v);
    return c;
  });
  if (!isContinuous || valueArray.length < 2 || !groupBy) {
    return valueArray.map(v => {
      const av = getAxisValue(v);
      return av;
    });
  }
  const isDate =
    typeof valueArray[0] === 'string' || valueArray[0] instanceof Date;
  const mathable = valueArray.map(v => getMathableValue(v));
  const step = isDate ? getMsFromGroupByDate(groupBy) : 1;
  const min = Math.min(...mathable);
  const max = Math.max(...mathable);
  // console.log(
  //   'making continuous array with length of ',
  //   Math.ceil((max - min) / stepToUse) + 1,
  //   valueArray,
  //   mathable,
  //   min,
  //   max,
  //   step,
  //   smallestStep
  // );
  const continuous = [...Array(Math.ceil((max - min) / step) + 1).keys()].map(
    i => getAxisValue(min + i * step, isDate)
  );
  // console.log('continuous values created', continuous);
  return continuous;
};
export const getUniqueAxisValues = (
  axisId: number,
  config: ReportConfig,
  data: ReportData
) => {
  if (!config?.xAxes?.[axisId]) {
    return [];
  }
  const unique = getContinuousAxisValues(
    uniq(
      getUniqueSeriesFields(data, config).flatMap(s => {
        // console.log('mapping axis', data, s, axisId);
        return data[s]?.[axisId]?.map(r => {
          // console.log('mapping axis value', r, config, axisId);
          return r[config.xAxes[axisId].field];
        });
      })
    ),
    config.xAxes[axisId]?.groupBy
  );
  // console.log('unique axis values', unique);
  return unique;
};
export const mapCountDataToAxisData = (
  data: ReportData,
  config: ReportConfig
) => {
  // console.log('mapping count data', data, config);
  return resolveSeriesConfig(data, config).map(s => {
    return data?.[s.name]?.map((axisData, i) => {
      const axisVals = getUniqueAxisValues(i, config, data);
      if (!axisVals.length) {
        return axisData.map(d => applyCalculation(d, config));
      }
      // console.log('axis vals for ', axisData, i, axisVals);
      return axisVals.map(v => {
        const matchingAxisPointData = axisData.find(
          d => getAxisValue(d[config.xAxes?.[i]?.field]) === v
        );
        // console.log(
        //   'returning matchingAxisPointData',
        //   matchingAxisPointData,
        //   matchingAxisPointData?.count
        // );
        return applyCalculation(matchingAxisPointData, config);
      });
    });
  });
};
export const useHeadlineFigure = ({
  config,
  isStale,
  extFilters,
  isEditing
}: {
  config: ReportConfig;
  isStale?: boolean;
  extFilters?: CustomRule;
  isEditing?: boolean;
}) => {
  const { data, refetch } = useQuery<ReportData, any>({
    retry: getReportDataRetry(isEditing),
    queryFn: () =>
      getReportData({
        config: {
          db: config?.db,
          table: config?.table,
          filters: mergeFilters(config?.filters, extFilters), //config?.filters,
          aggregation: config?.aggregation,
          aggregationField: config?.aggregationField
        }
      }),
    staleTime: config?.realTime ? 1 : Infinity,
    queryKey: [
      'reportData',
      'total',
      config?.db,
      config?.table,
      config?.filters || [],
      config?.aggregation,
      config?.aggregationField,
      extFilters
    ],
    enabled: !!config
  });
  useEffect(() => {
    if (isStale) {
      refetch();
    }
  }, [isStale]);
  return (
    (data && applyCalculation(data[Object.keys(data)[0]][0][0], config)) || 0
  );
};

const resolveFilters = (
  filters: CustomRule,
  variables?: Record<string, any>
) => {
  if (!filters) return [];
  if (!variables) return filters;
  return filters.map(ands =>
    ands.map(r => ({
      ...r,
      value: r.value?.startsWith?.('_$')
        ? variables[`${r.value.slice(2)}`]
        : r.value || ''
    }))
  );
};
export const buildConfig = (
  config: ReportConfig,
  variables?: Record<string, any>
): ReportConfig => {
  if (!config) return config;
  return {
    ...config,
    series: config.series?.map(s => ({
      ...s,
      filters: resolveFilters(s.filters, variables)
    })),
    compareTo: {
      ...config.compareTo,
      filters: resolveFilters(config.compareTo?.filters, variables)
    },
    filters: resolveFilters(config.filters, variables)
  };
};
export const mergeFilters = (filters: CustomRule, ...toMerge: CustomRule[]) => {
  const merged = [...(filters || [])];
  for (const m of toMerge) {
    if (!m) continue;
    merged.push(...m);
  }
  return merged;
};
export const applyCalculation = (
  dataRow: ReportDataRow,
  config: { compareTo?: { calculation: string } }
) => {
  if (!config?.compareTo) return dataRow?.count;
  switch (config?.compareTo?.calculation) {
    case 'Difference':
      return dataRow?.count - dataRow?._comparisonValue;
    case 'Percentage':
      return !dataRow?._comparisonValue
        ? 100
        : (dataRow?.count / dataRow?._comparisonValue) * 100;
    default:
      return dataRow?.count;
  }
};
export const getValueFormatter = (config?: ReportConfig) => {
  if (!config) return bigNumber;
  switch (config?.compareTo?.calculation) {
    case 'Difference':
      return (value: string) =>
        isNaN(Number(value))
          ? ''
          : `${Number(value) > 0 ? '+' : '-'}${Math.abs(
              Math.round(Number(value) * 100) / 100
            )}`;
    case 'Percentage':
      return (value: string) =>
        isNaN(Number(value)) ? '' : `${Math.round(Number(value) * 100) / 100}%`;
    default:
      return (
        specialFormatters[config?.db]?.[config.table]?.[
          config.aggregationField
        ] || bigNumber
      );
  }
};
export const aggregateSeriesData = (
  series: Series[],
  mathFn: 'sum' | 'avg' | 'max' | 'min'
) => {
  return !series?.length
    ? 0
    : Math[mathFn](
        ...series.map(s =>
          Math[mathFn](...(s.data || [0]).filter(d => !isNaN(d)))
        )
      );
};
