import { FieldArrayList } from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

export default () => {
  const { fields, remove, append } = useFieldArray({ name: 'outputs' });
  return (
    <FieldArrayList
      defaultValues={{ type: 'Data' }}
      remove={remove}
      append={() => append({})}
      fields={fields}
      tabs
      item={(item, i) => (
        <Row>
          <Col xs={12} md={6}>
            <WizardInput label="Name" name={`outputs.${i}.name`} />
          </Col>
          <Col xs={12} md={6}>
            <WizardInput
              label="Due date"
              name={`outputs.${i}.dueDate`}
              registerProps={{ required: false }}
              pluginProps={{ futureOnly: true }}
              type="date"
            />
          </Col>
          <Col xs={12} md={6}>
            <WizardInput
              label="Output format"
              name={`outputs.${i}.outputFormat`}
              type="select"
              pluginProps={{
                creatable: true
              }}
              registerProps={{ required: false }}
              options={[
                { label: 'Excel', value: 'Excel' },
                { label: 'SPSS', value: 'SPSS' },
                { label: 'Triple-S', value: 'Triple-S' }
              ]}
            />
          </Col>
          <Col xs={12} md={6}>
            <WizardInput
              label="Type"
              name={`outputs.${i}.type`}
              type="select"
              registerProps={{ required: false }}
              pluginProps={{
                creatable: true
              }}
              options={[
                { label: 'Data', value: 'Data' },
                { label: 'Tables', value: 'Tables' }
              ]}
            />
          </Col>
          <Col xs={12}>
            <WizardInput
              label="Notes"
              name={`outputs.${i}.notes`}
              type="textarea"
              registerProps={{ required: false }}
            />
          </Col>
        </Row>
      )}
    />
  );
};
