import React, { ReactNode } from 'react';
import TooltipBadge from './TooltipBadge';
import classNames from 'classnames';
import { FiInfo } from 'react-icons/fi';
export default ({
  children,
  className,
  icon = <FiInfo />,
  ...rest
}: {
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  color?: string;
} & any) => {
  return (
    <TooltipBadge
      className={classNames('ms-1', className)}
      tooltip={children}
      {...rest}
    >
      {icon}
    </TooltipBadge>
  );
};
