import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PageLoader from 'components/common/PageLoader';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import { FileId } from 'apis/types';
import useArray from 'hooks/useArray';
import ModalVideoContent from 'components/app/recruitment/campaigns/ModalVideoContent';
import classNames from 'classnames';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { ApiError } from 'apis/errors';
import useResizeObserver from 'hooks/useResizeObserver';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

export type PdfViewerProps = Omit<
  HTMLProps<HTMLDivElement>,
  'onClick' | 'children' | 'width' | 'height'
> & {
  fileId: FileId | FileId[];
  onRendered?: ({
    pdfFile,
    doc
  }: {
    pdfFile: File;
    doc: DocumentCallback;
  }) => void;
  height?: number;
  width?: number;
  onClick?: () => void;
  navStyle?: 'bottom' | 'side';
  boxRef?: any;
  children?:
    | React.ReactNode
    | ((props: {
        page: number;
        setPage: (page: number) => void;
        file: File;
        error: ApiError;
        ref: any;
      }) => React.ReactNode);
};
const PdfViewer = ({
  fileId,
  onRendered,
  children,
  height,
  width,
  onClick,
  navStyle = 'bottom',
  boxRef: _boxRef,
  ...rest
}: PdfViewerProps) => {
  const fileIdArr = useArray(fileId);
  const { files, isLoading, error } = useFileLoader({ value: fileIdArr });
  const [pdfFile, setPdfFile] = useState<any>();
  const [page, setPage] = useState(1);
  const boxRef = _boxRef || useRef(null);
  useEffect(() => {
    if (
      files?.[0]?.preview &&
      !pdfFile &&
      files[0].preview !== pdfFile?.preview &&
      !isLoading
    ) {
      setPdfFile({
        ...files[0],
        url: files[0].preview
      });
    }
  }, [files]);
  const [doc, setDoc] = useState<DocumentCallback>();
  const onLoaded = doc => {
    setDoc(doc);
  };
  const container = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  // useResizeObserver(container, box => {
  //   if (box) {
  //     console.log('resized', container.current, box);
  //     setMaxHeight(box.height);
  //   }
  // });
  useEffect(() => {}, [container]);
  return (
    <div
      className="mb-5 rounded p-3 pt-4 d-flex justify-content-center align-items-center"
      {...rest}
      ref={container}
    >
      <div
        className={classNames('position-relative h-100 d-inline-flex', {
          'flex-column': navStyle === 'bottom'
        })}
        // style={{ minHeight: 250 }}
      >
        {navStyle === 'side' && (
          <Button
            variant="link"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
            className="btn-lg fs-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        )}
        <div className={'d-inline-block'}>
          {pdfFile && (
            <div className="position-relative" ref={boxRef}>
              <Document
                className={classNames('d-inline-block shadow rounded-3')}
                onLoadSuccess={onLoaded}
                file={pdfFile}
              >
                {/* <div className="shadow"> */}
                <Page
                  className={classNames('rounded-3 overflow-hidden', {
                    'cursor-pointer': !!onClick
                  })}
                  pageNumber={page}
                  height={height}
                  width={width}
                  onClick={onClick}
                  onRenderSuccess={() => onRendered?.({ pdfFile, doc })}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
                {/* </div> */}
              </Document>
              {typeof children === 'function'
                ? children({ page, setPage, file: pdfFile, error, ref: boxRef })
                : children}
            </div>
          )}
        </div>
        {navStyle === 'side' && (
          <Button
            variant="link"
            onClick={() => setPage(page + 1)}
            disabled={page === doc?.numPages}
            className="btn-lg fs-4"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        )}
        {navStyle === 'bottom' && (
          <Flex justifyContent={'between'} className={'w-100'}>
            <Button
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
              size="sm"
            >
              Prev
            </Button>
            <Button
              onClick={() => setPage(page + 1)}
              disabled={!doc || page === doc?.numPages}
              size="sm"
            >
              Next
            </Button>
          </Flex>
        )}
        {isLoading && !error && (
          <div className="w-100 h-100 position-absolute start-0 top-0 bg-100 d-flex opacity-75">
            <PageLoader height={'100%'} message={'Loading file...'} />
          </div>
        )}
      </div>
    </div>
  );
};
export default ({
  children,
  height,
  width,
  boxRef,
  className,
  ...props
}: PdfViewerProps) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <ModalVideoContent show={show} setShow={setShow}>
        <PdfViewer style={{ maxHeight: '90vh' }} {...props}>
          {children}
        </PdfViewer>
      </ModalVideoContent>
      <PdfViewer
        onClick={() => setShow(true)}
        {...props}
        className={className}
        boxRef={boxRef}
        width={width}
        height={height}
      >
        {children}
      </PdfViewer>
    </div>
  );
};
