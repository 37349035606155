import { EmployeeShiftChange } from 'apis/flex/hr';
import DomainTable, { RemoteDomainTable } from 'components/common/DomainTable';
import React from 'react';
import { useEmployeeShiftChanges } from '../useEmployeeShifts';
import { useGuard } from 'hooks/useGuard';
import { DomainForm } from 'components/common/DomainForm';
import WizardInput from 'components/wizard/WizardInput';
import { ensureArray } from 'helpers/utils';
import { useUser } from 'hooks/useUser';

export default () => {
  const { id: userId } = useUser();
  const { can } = useGuard({ roles: ['employee'] });
  return (
    <RemoteDomainTable
      crudHook={useEmployeeShiftChanges}
      tabs={[
        {
          label: 'Pending',
          icon: 'exclamation-triangle',
          crudFilter: { approvedDate: null, rejectedDate: null }
        },
        {
          label: 'Approved',
          icon: 'check',
          crudFilter: { first: 'approvedDate' }
        },
        {
          label: 'Rejected',
          icon: 'times',
          crudFilter: { first: 'rejectedDate' }
        }
      ]}
      domain="employee-shift-change"
      columns={[
        'requestedBy',
        'requestedDate',
        'employeeShiftId',
        {
          id: 'request',
          accessorFn: d =>
            d.isToCancel
              ? 'Cancel Shift'
              : d.shiftId
              ? 'Change times'
              : d.date
              ? 'Change date'
              : ''
        },
        'date',
        'shiftId',
        'reason',
        { id: 'responseNote', header: 'Response' }
      ]}
      globalActions={[
        {
          name: 'Approve',
          show: r => {
            const rows = ensureArray(r);
            return can(
              'edit',
              rows.map(r => r.getValue('employeeId'))
            );
          },
          icon: 'check',
          modalOnClick: getModal({
            approvedBy: userId,
            approvedDate: new Date(),
            rejectedBy: null,
            rejectedDate: null
          }),
          variant: 'success'
        },
        {
          name: 'Reject',
          show: r => {
            const rows = ensureArray(r);
            return can(
              'edit',
              rows.map(r => r.getValue('employeeId'))
            );
          },
          modalOnClick: getModal({
            approvedBy: null,
            approvedDate: null,
            rejectedBy: userId,
            rejectedDate: new Date()
          }),
          icon: 'times',
          variant: 'danger'
        }
      ]}
    />
  );
};
const getModal =
  (vals: Partial<EmployeeShiftChange>) =>
  ({ hide, rows }) => {
    return (
      <DomainForm
        domain="employee-shift-change"
        itemId={rows.map(r => r.original.id)}
        onFinished={hide}
        saveMutator={d => ({ ...d, ...vals })}
      >
        <WizardInput
          registerProps={{ required: false }}
          type="textarea"
          name="responseNote"
        />
      </DomainForm>
    );
  };
