import React from 'react';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import { Tab, Tabs } from 'react-bootstrap';
import { Employee } from 'apis/flex/hr';
import useEmployees from './hooks/useEmployees';
import { faPassport } from '@fortawesome/free-solid-svg-icons';
import useUrlParamFilters from 'components/common/advance-table-v2/useUrlParamFilters';
import { NewPrescreeningModal } from '../prescreening/NewPrescreening';
import DomainTable, { DomainTableProps } from 'components/common/DomainTable';
import DomainAvatar from 'components/common/DomainAvatar';
import blankUser from 'assets/img/team/avatar.png';
import useUsers from 'components/app/users/hooks/useUsers';
import { User } from 'components/app/users/types';

type EmployeeWithUser = Employee & { user: User };
export const EmployeesTable = (
  props: Partial<DomainTableProps<Employee, Employee, EmployeeWithUser>>
) => {
  const [showCheck, setShowCheck] = React.useState<number[]>(null);
  const { data: userLookup } = useUsers({
    useFilter: true,
    columns: ['employeeId', 'id', 'newStarterResponseId'],
    select: d => {
      return new Map(d.map(d => [d.employeeId, d]));
    }
  });
  return (
    <>
      <DomainTable
        domain="employee"
        crudHook={useEmployees}
        canClone={false}
        refreshTrigger={userLookup}
        onDataLoaded={d => d.map(d => ({ ...d, user: userLookup?.get(d.id) }))}
        stateTabs={state => ({
          tableProps: p =>
            state?.includes('started') && {
              columns: [
                ...p.columns,
                {
                  id: 'newStarterResponseId',
                  header: 'New starter form',
                  type: 'boolean',
                  accessorFn: d => !!d.user?.newStarterResponseId
                }
              ]
            }
        })}
        globalActions={[
          {
            name: 'Request screening',
            actionFn: async rows =>
              setShowCheck(rows.map(row => Number(row.original.id))),
            icon: faPassport
          }
        ]}
        columns={[
          {
            id: 'avatar',
            enableColumnFilter: false,
            maxSize: 50,
            header: '',
            formatter: v => (
              <DomainAvatar size="sm" src={v() || blankUser} domain="user" />
            )
          },
          'firstName',
          'surname',
          'email',
          'mobileNumber',
          { id: 'managerId', domain: 'user', header: 'Manager' },
          'departmentId',
          'contractId',
          'jobTitleId',
          'onboardDate'
        ]}
        {...props}
      >
        <AdvanceTable />
      </DomainTable>
      <NewPrescreeningModal
        show={!!showCheck}
        defaultValues={{ employeeIds: showCheck }}
        setShow={setShowCheck}
      />
    </>
  );
};

const Employees = () => {
  return <EmployeesTable />;
};
export default Employees;
