import React, { ReactNode, useEffect, useState } from 'react';
import {
  ArrayPath,
  FieldArray,
  FieldArrayWithId,
  FormProvider,
  Path,
  PathValue,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
  useWatch
} from 'react-hook-form';
import AdvanceTableProvider, {
  AdvanceTableProviderProps,
  Column,
  ColumnObject
} from './advance-table-v2/AdvanceTableProvider';
import AdvanceTable from './advance-table-v2/AdvanceTable';
import AdvanceEditor from './advance-table-v2/AdvanceEditor';
import { ResponsiveModal } from './Modals';
import { Button, Modal } from 'react-bootstrap';
import { faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import useMediaForm from 'hooks/useMediaForm';
import {
  flattenObject,
  flattenObjectIntoArrayOfDotNotationStrings
} from 'helpers/utils';
export const UpsertModal = ({
  show,
  setShow,
  children,
  hideButtons = false
}) => {
  const { setValue, getValues } = useFormContext();
  const methods = useMediaForm({
    values: getValues()
  });
  const handleCancel = () => {
    setShow(false);
    methods.reset({});
  };
  const handleDone = () => {
    methods.handleSubmit(v => {
      const flattened = flattenObject(v);
      Object.keys(flattened).forEach(k => setValue(k, flattened[k]));
      setShow(false);
    })();
  };
  return (
    <FormProvider {...methods}>
      <ResponsiveModal show={show} onHide={handleDone} wide>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="form-text" style={{ maxWidth: '50%' }}>
            Changes will be saved when the page is saved
          </div>
          {!hideButtons && (
            <div className="d-flex">
              <Button variant="primary" type="submit" onClick={handleDone}>
                Done
              </Button>
              <Button variant="link" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          )}
        </Modal.Footer>
      </ResponsiveModal>
    </FormProvider>
  );
};

const Table = <TData extends Record<string, any>>({
  name,
  columns,
  fieldArray,
  defaultValues,
  setEditing
}) => {
  const data = useWatch<TData>({ name: name as Path<TData> });
  return (
    <AdvanceTableProvider
      onNewClick={() => {
        fieldArray.append(defaultValues as any);
        setEditing(fieldArray.fields.length);
      }}
      // onEditClick={rows => setEditing(rows[0].index)}
      onRowClick={row => setEditing(row.index)}
      actions={[
        {
          name: 'Edit',
          icon: faEdit,
          onClick: row => setEditing(row.index)
        },
        {
          name: 'Delete',
          icon: faTrash,
          variant: 'danger',
          onClick: row => {
            fieldArray.remove(row.index);
          }
        },
        {
          name: 'Duplicate',
          icon: faCopy,
          onClick: row => {
            fieldArray.append({
              ...(row.original as Record<string, any>),
              id: undefined
            } as any);
            setEditing(fieldArray.fields.length);
          }
        }
      ]}
      data={data}
      columns={columns}
    >
      <AdvanceTable />
    </AdvanceTableProvider>
  );
};
type TableProps<
  TFieldValues extends Record<string, any> = Record<string, any>
> = Partial<
  AdvanceTableProviderProps<PathValue<TFieldValues, Path<TFieldValues>>[number]>
>;
export default <
  TFieldValues extends Record<string, any> = Record<string, any>,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>
>({
  name,
  columns,
  form,
  defaultValues,
  fieldArray,
  tableProps
}: {
  fieldArray: UseFieldArrayReturn<TFieldValues, TName>;
  name: TName;
  columns: Column<FieldArray<TFieldValues, TName>>[];
  form: (
    field: FieldArrayWithId<TFieldValues, TName, 'id'>,
    index: number
  ) => ReactNode;
  defaultValues?: Partial<FieldArray<TFieldValues, TName>>;
  tableProps?: TableProps;
}) => {
  const [editing, setEditing] = useState<number>();
  return (
    <>
      <Table
        name={name}
        columns={columns}
        fieldArray={fieldArray}
        setEditing={setEditing}
        defaultValues={defaultValues}
        {...tableProps}
      />
      {fieldArray.fields.map((field, i) => (
        <UpsertModal
          key={field.id}
          show={editing === i}
          setShow={() => setEditing(null)}
        >
          {form(field, i)}
        </UpsertModal>
      ))}
    </>
  );
};
