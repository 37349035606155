import { LeaveRequest, LeaveType } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import DomainDetail from 'components/common/DomainDetail';
import { getDomainInput, ImmutableInput } from 'components/common/DomainInput';
import React from 'react';
import { EmployeeSelector } from '../staff/widgets/EmployeeSelector';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { ApprovedStamp, RejectedStamp } from 'components/common/ApprovalStamp';
import { useUser } from 'hooks/useUser';
import { addDays } from 'date-fns';
import { useGuard } from 'hooks/useGuard';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { getAvailableItemSelector } from 'components/common/DomainAvailabilityRules';
import useEmployees from '../staff/hooks/useEmployees';
import { StatusBadge } from './LeaveTable';
const Input = getDomainInput<LeaveRequest>();
export const AvailableLeaveTypePicker = ({
  startDate,
  endDate,
  employeeId,
  departmentId,
  contractId,
  ...props
}: Partial<WizardInputProps> & {
  startDate?: Date;
  endDate?: Date;
  employeeId?: number[];
  departmentId?: number[];
  contractId?: number[];
}) => {
  const Picker = getAvailableItemSelector<LeaveType>({
    domain: 'leave-type',
    startDate,
    endDate,
    availableWithParams: {
      employeeId,
      departmentId,
      contractId
    }
  });
  return <Picker {...props} />;
};
const TypeInput = () => {
  const [startDate, endDate, employeeId] = useWatch<
    LeaveRequest,
    ['startDate', 'endDate', 'employeeId']
  >({ name: ['startDate', 'endDate', 'employeeId'] });
  const { data: employee, isLoading } = useEmployees({
    id: employeeId,
    select: d => d[0]
  });
  const isReady = !!(startDate && endDate && employeeId && employee);
  return (
    <AvailableLeaveTypePicker
      name="leaveTypeId"
      instruction={
        !isReady && 'Please select employee, start date, and end date first'
      }
      label="Leave type"
      readOnly={!isReady}
      loading={isLoading}
      employeeId={[employeeId]}
      contractId={[employee?.contractId]}
      departmentId={[employee?.departmentId]}
      startDate={startDate}
      endDate={endDate}
    />
  );
};
export default () => {
  const { employeeId } = useUser();
  const { canCreate } = useGuard({ roles: ['leave-request'] });
  const isSelf = !canCreate;
  return (
    <DomainDetail<LeaveRequest>
      saveLabel={isSelf ? 'Request' : 'Save'}
      domain="leave-request"
      defaultValues={{
        employeeId,
        startDate: addDays(new Date(), 1),
        endDate: addDays(new Date(), 2),
        reason: ''
      }}
      isSelf={isSelf}
      tags={d => (d?.id ? [<StatusBadge key={d.id} {...d} />] : [])}
    >
      <DetailCard id="info" title="Info">
        <ImmutableInput
          component={EmployeeSelector}
          name="employeeId"
          readOnly={isSelf}
        />
        <Row>
          <Col xs={12} lg={6}>
            <Input
              name="startDate"
              type="date"
              pluginProps={{ futureOnly: !!isSelf }}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Input
              name="endDate"
              type="date"
              pluginProps={{ futureOnly: !!isSelf }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Input
              name="halfDayAtStart"
              type="switch"
              label="Start is half day"
            />
          </Col>
          <Col xs={12} lg={6}>
            <Input name="halfDayAtEnd" type="switch" label="End is half day" />
          </Col>
        </Row>
        <ImmutableInput
          name="reason"
          label="Reason"
          type="textarea"
          registerProps={{ required: false }}
        />
        <TypeInput />
        {/* {!isSelf && (
        <ImmutableInput name="paid" label="Paid" type="switch" />
        )} */}
      </DetailCard>
      {!isSelf && <ResponseCard />}
    </DomainDetail>
  );
};
const ResponseCard = () => {
  const data = useWatch<LeaveRequest>();
  return (
    <DetailCard id="response" title="Response">
      {data.approvedDate && (
        <ApprovedStamp
          approvedBy={data.approvedBy}
          approvedDate={data.approvedDate}
        />
      )}
      {data.rejectedDate && (
        <RejectedStamp
          rejectedBy={data.rejectedBy}
          rejectedDate={data.rejectedDate}
        />
      )}
      <Input
        name="responseNote"
        label="Response note"
        type="textarea"
        registerProps={{ required: false }}
      />
    </DetailCard>
  );
};
