import React, {
  CSSProperties,
  HTMLProps,
  Ref,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CloseButton,
  Form,
  OverlayTrigger,
  Popover,
  Table,
  TableProps
} from 'react-bootstrap';
import {
  FilterDef,
  ProcessedCell,
  ProcessedColumn,
  ProcessedColumnDef,
  ProcessedRow
} from './AdvanceTableProvider';
import {
  Column,
  HeaderGroup,
  Row,
  Row as RowType,
  flexRender
} from '@tanstack/react-table';
import Flex from '../Flex';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import {
  FilterFormValues,
  FilterTypeInput,
  FilterValueInput,
  TableFilterProvider
} from './AdvanceTableFilters';
import { useFormContext, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from '../Tooltip';
import styled from 'styled-components';
import RenderWhenVisible from '../RenderWhenVisible';
import Wrapper from 'helpers/Wrapper';
import ReactTableChart from './ReactTableChart';
//These are the important styles to make sticky column pinning work!
//Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
//View the index.css file for more needed styles such as border-collapse: separate
export const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  if (column.columns?.length) return {};
  // // console.log('getting pinning style', column);
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');
  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned
      ? 'rgba(var(--falcon-gray-100-rgb), 1) !important'
      : undefined
  };
};
const HoverTd = styled.td`
  :hover {
  }
`;
const HoverTdComponent = ({
  ref,
  as,
  ...props
}: HTMLProps<HTMLTableCellElement>) => <HoverTd {...props} />;
const HoverTh = styled.th`
  :hover {
  }
`;
const HoverThComponent = ({
  ref,
  as,
  ...props
}: HTMLProps<HTMLTableCellElement>) => <HoverTh {...props} />;
const RowCells = ({
  row,
  onContextMenu,
  refreshTrigger,
  cellProps
}: {
  row: RowType<any>;
  onContextMenu?: (row: RowType<any>) => any;
  refreshTrigger?: boolean;
  cellProps?: HTMLProps<HTMLTableCellElement>;
}) => {
  const rowData = row.original;
  const cells = row.getVisibleCells().map((cell: ProcessedCell, i) => {
    // console.log('cell', cell, row.getVisibleCells());
    return (
      <HoverTdComponent
        key={cell.id}
        style={{
          ...cellProps?.style,
          textOverflow: 'ellipsis',
          ...getCommonPinningStyles(cell.column),
          width: cell.column.columnDef.wrap
            ? cell.column.columnDef.wrap + 'px'
            : cell.column.getSize() + 'px',
          maxWidth: cell.column.columnDef.wrap ? 300 : cell.column.getSize()
        }}
        className={classNames(
          cell.column.columnDef.cellProps?.className ||
            cellProps?.className ||
            'px-3 py-1 overflow-hidden',
          {
            'text-wrap': !!cell.column.columnDef.wrap
          }
        )}
        {...cellProps}
        {...cell.column.columnDef.cellProps}
        onContextMenu={
          process.env.NODE_ENV === 'production'
            ? onContextMenu?.(row)
            : undefined
        }
      >
        <Wrapper
          condition={i > 8}
          wrapper={c => (
            <RenderWhenVisible height="1rem">{c}</RenderWhenVisible>
          )}
        >
          {row.getCanExpand() && i === 0 ? (
            <span
              style={{ cursor: 'pointer' }}
              // onClick={() => row.toggleExpanded()}
            >
              {row.getIsExpanded() ? '▼' : '▶'}
            </span>
          ) : null}
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Wrapper>
      </HoverTdComponent>
    );
  });
  return <>{cells}</>;
};

const TableRows = ({
  rowClassName,
  getOnRowClick,
  getRows,
  onContextMenu,
  refreshTrigger,
  cellProps
}: {
  rowClassName: string;
  getOnRowClick?: () => (row: RowType<any>, e: any) => void;
  getRows: () => ProcessedRow[];
  onContextMenu?: (row: RowType<any>) => (e: any) => void;
  refreshTrigger?: boolean;
  cellProps?: HTMLProps<HTMLTableCellElement>;
}) => {
  return getRows().map(row => {
    return (
      <tr
        key={row.id}
        className={classNames(
          'align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100 cursor-pointer',
          rowClassName,
          {
            'opacity-50': !!row.original.deletedDate
          }
        )}
        onClick={e => {
          if (row.getCanExpand()) {
            return row.toggleExpanded();
          }
          getOnRowClick?.()(row, e);
        }}
      >
        <RowCells
          row={row}
          onContextMenu={onContextMenu}
          refreshTrigger={refreshTrigger}
          cellProps={cellProps}
        />
      </tr>
    );
  });
};
const TableRow = ({ children }) => <tr>{children}</tr>;
TableRow.propTypes = {
  children: PropTypes.node
};
const FilterItem = ({ column, onFinished, data }) => {
  const { handleSubmit, setValue, getValues } = useFormContext<
    any,
    any,
    FilterFormValues
  >();
  const isActive =
    useWatch({ name: column.id + '.active' }) ||
    getValues(column.id + '.active');
  const handleClear = () => {
    setValue(column.id + '.active', false);
    setTimeout(handleSubmit(onFinished), 10);
  };
  useEffect(() => {
    setTimeout(() => setValue(column.id + '.active', true), 100);
  }, [setValue, column?.id]);
  return (
    <>
      <div className="mb-3">
        <ReactTableChart
          onClick={v => {
            setValue(column.id, {
              active: true,
              value: [v],
              type: 'in'
            });
            handleSubmit(onFinished)();
          }}
          column={column}
          data={data}
        />
      </div>
      <Form onSubmit={handleSubmit(onFinished)}>
        <div className="position-relative">
          <FilterTypeInput
            getFilterName={x => column.id + '.' + x}
            column={column}
          />
          <FilterValueInput
            column={column}
            getFilterName={x => column.id + '.' + x}
          />
          {!isActive && (
            <div
              className="position-absolute w-100 top-0 start-0 h-100 bg-300 bg-opacity-10"
              onClick={() => {
                setValue(column.id + '.active', true);
              }}
            ></div>
          )}
        </div>
        <Flex justifyContent={'end'}>
          <Button
            size="sm"
            className="me-1"
            variant="secondary"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button size="sm" type="submit">
            Apply
          </Button>
        </Flex>
      </Form>
    </>
  );
};
const FilterIcon = ({ column, setShow, filters }) => {
  return (
    <Button
      variant="link"
      onClick={() => setShow(true)}
      className={classNames('p-0 ps-1', {
        'notification-indicator notification-indicator-primary': filters.some(
          x => x.id === column.id && x.value?.active
        )
      })}
    >
      <FontAwesomeIcon className={classNames('text-500')} icon={faEllipsisV} />
    </Button>
  );
};
const FilterDropdown = ({
  column,
  filters,
  getData
}: {
  column: ProcessedColumn<any>;
  filters: FilterDef<any>[];
  getData: () => any[];
}) => {
  const [show, setShow] = useState(false);
  return useMemo(
    () => (
      <OverlayTrigger
        show={show}
        // onToggle={show => setShow(show)}
        // defaultShow={show}
        placement="bottom"
        rootClose
        trigger={['click', 'focus']}
        overlay={
          <Popover show={true}>
            <div className="d-flex justify-content-end me-2 mt-2">
              <CloseButton onClick={() => setShow(false)} />
            </div>
            <Popover.Body>
              <TableFilterProvider>
                <FilterItem
                  column={column.columnDef}
                  data={getData()}
                  onFinished={() => setShow(false)}
                />
              </TableFilterProvider>
            </Popover.Body>
          </Popover>
        }
      >
        {({ ref }) => (
          <div ref={ref}>
            <FilterIcon column={column} setShow={setShow} filters={filters} />
          </div>
        )}
      </OverlayTrigger>
    ),
    [column, show]
  );
};
const Pinner = ({
  column,
  onPin
}: {
  column: Column<any, any>;
  onPin: () => void;
}) => {
  return (
    <Button variant="link" onClick={onPin} className="p-0 pe-1">
      <FontAwesomeIcon
        size="xs"
        icon={faThumbtack}
        className={classNames({
          'text-secondary': !column.getIsPinned(),
          'text-primary': column.getIsPinned()
        })}
      />
    </Button>
  );
};

const HeaderGroupRow = ({
  group,
  onPinChange,
  filters,
  headerProps,
  getData
}: {
  group: HeaderGroup<any>;
  onPinChange?: () => void;
  filters: FilterDef<any>[];
  headerProps?: HTMLProps<HTMLTableCellElement>;
  getData: () => any[];
}) => {
  return (
    <tr key={group.id}>
      {group.headers.map(header => {
        const col = header.column as ProcessedColumn;
        const sortDir = header.column.getIsSorted();
        const width = col.columnDef.wrap
          ? col.columnDef.wrap
          : header.getSize();
        return (
          <HoverThComponent
            key={header.id}
            className={classNames(headerProps?.className || 'px-3 sticky-top')}
            width={width + 'px'}
            {...headerProps}
            {...col.columnDef.headerProps}
            style={{
              ...headerProps?.style,
              verticalAlign: 'baseline',
              ...getCommonPinningStyles(header.column),
              minWidth: width,
              maxWidth: width
            }}
          >
            {header.isPlaceholder ? null : (
              <Flex
                alignItems={'baseline'}
                justifyContent={
                  headerProps?.className?.includes('center')
                    ? 'center'
                    : 'between'
                }
              >
                <div className="d-flex align-items-center">
                  {header.column.getCanPin() &&
                    !header.column.columns?.length && (
                      <Pinner
                        column={header.column}
                        onPin={() => {
                          header.column.getIsPinned()
                            ? header.column.pin(false)
                            : header.column.pin('left');
                          onPinChange?.();
                        }}
                      />
                    )}
                  <div
                    {...{
                      className:
                        'd-flex align-items-center ' +
                        (header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : ''),
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    <CustomTooltip
                      content={
                        header.column.columnDef.meta?.headerTooltip ||
                        header.column.columnDef.header
                      }
                    >
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: 150
                        }}
                        className="text-truncate"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </CustomTooltip>
                    {sortDir !== false &&
                      {
                        asc: <span className="sort asc pb-1" />,
                        desc: <span className="sort desc pb-1" />
                      }[sortDir]}
                  </div>
                </div>
                {header.column.getCanFilter() && filters && (
                  <FilterDropdown
                    column={col}
                    filters={filters}
                    getData={getData}
                  />
                )}
              </Flex>
            )}
          </HoverThComponent>
        );
      })}
    </tr>
  );
};

export type BaseReactTableProps = {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  isLoading?: boolean;
  tableRef?: Ref<HTMLTableElement>;
  headerProps?: HTMLProps<HTMLTableCellElement>;
  cellProps?: HTMLProps<HTMLTableCellElement>;
  getFooterGroups?: () => HeaderGroup<any>[];
  footerCellProps?: HTMLProps<HTMLTableCellElement>;
};
export type ReactTableProps = BaseReactTableProps & {
  getPrePaginationRowModel: () => { rows: RowType<any>[] };
  getHeaderGroups: () => HeaderGroup<any>[];
  pagination?: {
    pageIndex: number;
    pageSize: number;
    isForward: boolean;
  };
  onPinChange?: () => void;
  rows: RowType<any>[];
  refreshTrigger?: boolean;
  getOnRowClick?: () => (row: RowType<any>, e: any) => void;
  onContextMenu?: (row: RowType<any>) => (e: any) => void;
  filters?: FilterDef<any>[];
};
export default ({
  tableRef,
  headerClassName,
  headerProps,
  cellProps,
  bodyClassName,
  rowClassName,
  tableProps,
  isLoading,
  getHeaderGroups,
  pagination,
  onPinChange,
  rows,
  getPrePaginationRowModel,
  getOnRowClick,
  refreshTrigger,
  onContextMenu,
  filters,
  getFooterGroups,
  footerCellProps
}: ReactTableProps) => {
  return (
    <Table
      // ref={tableRef}
      ref={tableRef}
      className="fs--1 fs-xxl-0 mb-0 table-sm user-select-none"
      {...tableProps}
    >
      <thead
        className={classNames(
          'bg-light text-800 align-middle',
          headerClassName
        )}
      >
        {getHeaderGroups().map(group => (
          <HeaderGroupRow
            key={group.id}
            group={group}
            onPinChange={onPinChange}
            filters={filters}
            headerProps={headerProps}
            getData={() =>
              getPrePaginationRowModel?.().rows.map(row => row.original)
            }
          />
        ))}
      </thead>
      <tbody className={bodyClassName}>
        {isLoading ? (
          Array(pagination.pageSize || 10)
            .fill({})
            .map((row, i) => {
              return (
                <tr key={i}>
                  {getHeaderGroups().map(group =>
                    group.headers.map((col, key) => (
                      <td key={key} height={49}>
                        <Skeleton height={38} />
                      </td>
                    ))
                  )}
                </tr>
              );
            })
        ) : (
          <TableRows
            refreshTrigger={refreshTrigger}
            rowClassName={rowClassName}
            getRows={() => rows as ProcessedRow[]}
            getOnRowClick={getOnRowClick}
            onContextMenu={onContextMenu}
            cellProps={cellProps}
          />
        )}
      </tbody>
      <tfoot>
        {getFooterGroups?.().map(footerGroup => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map(footer => (
              <td key={footer.id} {...footerCellProps}>
                {flexRender(
                  footer.column.columnDef.footer,
                  footer.getContext()
                )}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>
    </Table>
  );
};
