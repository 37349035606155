import { EmployeeMedicalRecord } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import { useDetailPage } from 'components/common/detail/DetailPage';
import DomainDetail from 'components/common/DomainDetail';
import { getDomainInput } from 'components/common/DomainInput';
import DomainTable from 'components/common/DomainTable';
import Error403 from 'components/errors/Error403';
import Error404 from 'components/errors/Error404';
import { useGuard } from 'hooks/useGuard';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useEmployeeMedicalRecords } from './hooks/useEmployees';
const MedicalInput = getDomainInput<EmployeeMedicalRecord>();
export const NoRecordCheck = ({
  children,
  isSelf
}: {
  children: React.ReactNode;
  isSelf: boolean;
}) => {
  const { data, isLoading } = useDetailPage();
  if (isLoading) return children;
  if (!data && isSelf) return <Error404 />;
  return children;
};
const EmployeeMedicalDetail = ({ id = null }) => {
  const { canCreate } = useGuard({ roles: ['employee-medical-record'] });
  const { employeeId } = useUser();
  return (
    <DomainDetail<EmployeeMedicalRecord>
      domain="employee-medical-record"
      itemId={id}
      //d is not defined if employee is putting in their own details
      isSelf={d => !!id || (!d && !canCreate)}
      title={d =>
        !d ? 'New' : d?.employeeFirstName + ' ' + d?.employeeSurname
      }
      saveMutator={d => ({
        ...d,
        employeeId: d.employeeId || (id ? employeeId : null) || undefined
      })}
    >
      <NoRecordCheck isSelf={!!employeeId && !canCreate}>
        <EmployeeMedicalForm />
      </NoRecordCheck>
    </DomainDetail>
  );
};
export const EmployeeMedicalForm = () => {
  return (
    <>
      <DetailCard
        title="Emergency contact"
        id="emergency"
        subtitle="Someone we should contact in case of an emergency"
      >
        <Row>
          <Col xs={12}>
            <MedicalInput
              name="emergencyContactName"
              label="Name"
              type="name"
            />
          </Col>
          <Col xs={12} lg={6}>
            <MedicalInput
              name="emergencyContactRelationship"
              label="Relationship"
            />
          </Col>
          <Col xs={12} lg={6}>
            <MedicalInput
              name="emergencyContactPhone"
              type="mobile"
              label="Phone"
            />
          </Col>
        </Row>
      </DetailCard>
      <DetailCard title="Conditions" id="conditions">
        <MedicalInput
          name="conditions"
          type="textarea"
          instruction="Please list any current medical conditions or disabilities you feel we should know about. This is not compulsory, but may help us better understand your needs and deal with any emergencies."
          registerProps={{ required: false }}
        />
        <MedicalInput
          name="medications"
          type="textarea"
          instruction="Please list any medications you are currently taking, including any side effects you're aware of, and how often you should take them. This is not compulsory, but may help us better deal with any emergencies."
          registerProps={{ required: false }}
        />
        <MedicalInput
          name="allergies"
          type="textarea"
          instruction="Please list any allergies you have that we should be aware of. This is not compulsory, but may help us better understand your needs and deal with any emergencies."
          registerProps={{ required: false }}
        />
      </DetailCard>
    </>
  );
};
export const MyMedical = () => {
  const user = useUser();
  if (!user?.employeeMedicalId) return <Error403 />;
  return <EmployeeMedicalDetail id={user.employeeMedicalId} />;
};

export default EmployeeMedicalDetail;
