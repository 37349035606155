import DomainDetail from 'components/common/DomainDetail';
import React, { useEffect, useState } from 'react';
import useProjects from './useProjects';
import DetailCard from 'components/common/detail/DetailCard';
import { getDomainInput, ImmutableInput } from 'components/common/DomainInput';
import { Client, Project, Quote } from 'apis/flex/projects';
import { useFormContext, useWatch } from 'react-hook-form';
import TargetGroupsTable from './targetGroups/TargetGroupsTable';
import ProjectTeam from './ProjectTeam';
import {
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import useClients from '../clients/useClients';
import ItemSelector from 'components/common/ItemSelector';
import { FiUser } from 'react-icons/fi';
import useQuotes from '../quotes/useQuotes';
import QuestionnairesTable from './questionnaires/QuestionnairesTable';
import WithFormValues from 'components/wizard/WithFormValues';
import { mutateObject } from 'helpers/utils';
import ProjectCostCenterTable from './costs/ProjectCostCenterTable';
import Divider from 'components/common/Divider';
import { Button } from 'react-bootstrap';
import { parseUKCurrencyToNumber } from 'helpers/strings';
export const ProjectSelector = getDomainInput<Project>();
export const SelectedProjects = getSelectedDomainItems('project');
export const ClientSelector = getDomainItemSelector<Client>('client');
const ClientContactSelector = () => {
  const clientId = useWatch({ name: 'clientId' });
  const { data: client } = useClients({ id: clientId, select: d => d[0] });
  return (
    !!clientId && (
      <ItemSelector
        icon={<FiUser />}
        name="clientContactId"
        label="Contact"
        data={client?.contacts?.map(c => ({
          value: c.id,
          label: c.firstName + ' ' + c.surname
        }))}
      />
    )
  );
};
export const QuotePicker = getDomainItemSelector<Quote>('quote');
const ProjectInfo = () => {
  return (
    <DetailCard id="projectDetail" title="Info">
      <ProjectSelector
        formControlProps={{ autoFocus: true }}
        name="name"
        label="Name"
      />
      <QuotePicker
        registerProps={{ required: false }}
        readOnly
        name="quoteId"
      />
      <ClientSelector
        registerProps={{ required: false }}
        label="Client"
        name="clientId"
      />
      <ClientContactSelector />
    </DetailCard>
  );
};
const StarterCard = () => {
  const { reset } = useFormContext();
  const quoteId = useWatch({ name: 'quoteId' });
  const [clientId, name] = useWatch({ name: ['clientId', 'name'] });
  const { data: quote, isLoading } = useQuotes({
    select: d => d[0],
    id: quoteId,
    enabled: !!quoteId && !clientId && !name
  });
  useEffect(() => {
    if (!quote) return;
    const copied = mutateObject<Project>(
      quote,
      key => {
        return key === 'id' || key === 'quoteId';
      },
      () => undefined
    );
    copied.quoteId = quote.id;
    reset(copied);
  }, [quote]);
  return (
    <DetailCard
      id="starter"
      title={'Quote'}
      subtitle={'Select a quote to get started'}
      isLoading={isLoading}
    >
      <>
        <ImmutableInput
          component={QuotePicker}
          name="quoteId"
          registerProps={{ required: false }}
        />
        <Divider>Or</Divider>
        <div className="text-center">
          <Button
            variant="falcon-primary"
            onClick={() => reset({ _blankStarter: true })}
          >
            Start from a blank project
          </Button>
        </div>
      </>
    </DetailCard>
  );
};
const ProjectTargetGroups = () => {
  const [projectId, targetGroups] = useWatch({ name: ['id', 'targetGroups'] });
  return (
    <>
      <TargetGroupsTable projectId={projectId} targetGroups={targetGroups} />
    </>
  );
};
const ProjectCosts = () => {
  const projectId = useWatch({ name: 'id' });
  return <ProjectCostCenterTable projectId={projectId} />;
};
export default () => {
  return (
    <DomainDetail
      saveMutator={d => {
        return {
          ...d,
          costCenters: d.costCenters.map(c => ({
            ...c,
            unitValue: parseUKCurrencyToNumber(c.unitValue || 0)
          }))
        };
      }}
      domain="project"
      crudHook={useProjects}
    >
      <WithFormValues<Project & { _blankStarter: boolean }>
        fields={['clientId', '_blankStarter', 'id']}
      >
        {vals =>
          !vals?.clientId && !vals?._blankStarter && !vals?.id ? (
            <StarterCard />
          ) : (
            <>
              <ProjectInfo />
              <DetailCard id="team" title="Team">
                <ProjectTeam />
              </DetailCard>
              <DetailCard
                id="questionnaires"
                title="Questionnaires"
                subtitle="Questionnaires hold information like incidence, interview length, sample and data output requirements. Wherever a project has varying expectations of these values (e.g. it has customer and non-customer sample types), it will need multiple questionnaires. Each target group will need to select which questionnaire to use."
              >
                <QuestionnairesTable />
              </DetailCard>
              <DetailCard id="costCenters" title="Cost Centers">
                <ProjectCosts />
              </DetailCard>
              <DetailCard id="targetGroups" title="Target Groups">
                <ProjectTargetGroups />
              </DetailCard>
            </>
          )
        }
      </WithFormValues>
    </DomainDetail>
  );
};
