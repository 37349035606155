import React from 'react';
import { createRoot } from 'react-dom/client';
import Main from './Main';
import 'helpers/initFA';
import { msalConfig } from 'authConfig';
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import { setItemToStore } from 'helpers/utils';
import { MsalProvider } from '@azure/msal-react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { io } from 'socket.io-client';
import { OutputFormat, setDefaults } from 'react-geocode';

//must point directly to mailer when microservices are live
export const socket = io(process.env.REACT_APP_SERVER_URL, {
  transports: ['websocket']
});

export const sessionId =
  Math.random().toString(36).slice(2) +
  '' +
  Math.random().toString(36).slice(2);
Sentry.init({
  dsn:
    process.env.REACT_APP_PRODUCTION &&
    'https://7a6afd57ee58fdde711e3545c0d9c86e@o4506665385525248.ingest.sentry.io/4506665387950080',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes
    }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [
        process.env.REACT_APP_PUBLIC_URL,
        'localhost',
        process.env.REACT_APP_SERVER_URL
      ]
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
setDefaults({
  key: process.env.REACT_APP_GOOGLE_API_KEY,
  language: 'en',
  region: 'gb',
  outputFormat: OutputFormat.JSON
});

export const sentryInstance = Sentry;
export const msalInstance = new PublicClientApplication(msalConfig);
Sentry.setContext('x-session-id', { sessionId });
msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    console.log('MSAL Acquired account from cache');
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();
  msalInstance.addEventCallback(event => {
    // console.log('event', event);
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      setItemToStore('client-access-token', payload.accessToken);
    }
  });
  const container = document.getElementById('main');
  const root = createRoot(container);
  console.log('rendering root');
  root.render(
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Main />
    </MsalProvider>
    // </React.StrictMode>
  );
});
