import { socket } from "index";
import { useCallback, useEffect, useState } from "react";
export default ({
  eventName,
  callback = (d) => d
}) => {
  const [data, setData] = useState(null);
  const cb = useCallback(
    (d) => {
      const res = callback(d);
      if (res !== void 0) setData(res);
    },
    [callback]
  );
  useEffect(() => {
    socket.on(eventName, cb);
    return () => {
      socket.off(eventName, cb);
    };
  }, [socket, cb]);
  return {
    socket,
    stop: () => {
      socket.off(eventName, callback);
    },
    data
  };
};
