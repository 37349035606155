import {
  employeeMedicalRecordApi,
  employeePayrollRecordApi,
  employeesApi
} from "apis/flex/hr";
import { defaultCrudHookBuilder } from "hooks/defaultCrud/useDefaultCrud";
export default defaultCrudHookBuilder("Employees", employeesApi);
export const useEmployeeMedicalRecords = defaultCrudHookBuilder(
  "EmployeeMedicalRecords",
  employeeMedicalRecordApi
);
export const useEmployeesPayrollRecords = defaultCrudHookBuilder(
  "EmployeesPayrollRecords",
  employeePayrollRecordApi
);
