import React from 'react';
import { EventPrefix } from 'apis/flex/notifications';
import Skeleton from 'react-loading-skeleton';
import { domainIconWidths } from 'config';
import { StatusIcon } from './DomainIcon';

export type BadgeStatus =
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'light'
  | 'dark';
export default ({
  isLoading,
  src,
  size = 'md',
  subIcon,
  status
}: {
  domain?: EventPrefix;
  isLoading?: boolean;
  src: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  subIcon?: React.ReactNode;

  status?: BadgeStatus;
}) => {
  const avatarWidth = domainIconWidths[size];
  const borders = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4
  };
  return (
    <div
      style={{ width: avatarWidth, height: avatarWidth }}
      className={`rounded-circle shadow-sm position-relative`}
    >
      <div
        style={{ width: avatarWidth, height: avatarWidth }}
        className={`overflow-hidden avatar position-relative rounded-circle`}
      >
        {isLoading ? (
          <Skeleton
            height={avatarWidth}
            width={avatarWidth}
            // containerClassName="d-flex"
            containerClassName="position-absolute rounded-2 start-50 translate-middle-x"
          />
        ) : (
          <img
            // height={avatarWidth}
            className="position-absolute rounded-2 start-50 translate-middle-x"
            src={src}
          />
        )}
        <div
          style={{ width: avatarWidth, height: avatarWidth }}
          className={`position-absolute z-1 start-0 top-0 border border-${borders[size]} border-100 rounded-circle`}
        ></div>
      </div>
      {subIcon && (
        <div
          style={{ width: avatarWidth / 2, height: avatarWidth / 2 }}
          className={`position-absolute z-2 end-0 bottom-0 border border-1 border-100 rounded-circle`}
        >
          {subIcon}
        </div>
      )}
      {status && <StatusIcon status={status} iconHeight={avatarWidth} />}
    </div>
  );
};
