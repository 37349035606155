import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiError } from "apis/errors";
import {
  customFormsApi,
  customFormsResponseApi,
  getSystemForm
} from "apis/flex/customForms";
import { useUser } from "hooks/useUser";
import { useCallback } from "react";
export default ({
  formId,
  systemTag,
  meta,
  enabled = true,
  ...rest
}) => {
  const user = useUser();
  const { data: form, isLoading } = useQuery({
    queryFn: () => {
      if (systemTag) {
        return getSystemForm(systemTag);
      }
      if (!formId) {
        return Promise.resolve(null);
      }
      return customFormsApi.getOne(formId).then((d) => d[0]);
    },
    queryKey: ["CustomForms", Number(formId), systemTag],
    staleTime: enabled ? Infinity : void 0,
    enabled: (!!formId || !!systemTag) && !!enabled,
    ...rest
  });
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: isSubmitting,
    error
  } = useMutation({
    mutationFn: ({ data, formId: formId2, screenoutQuestionIds, metadata }) => customFormsResponseApi.insert(
      {
        formId: formId2,
        metadata,
        data,
        screenoutQuestionIds
      },
      //must wait and return the full data so new sections/questions can have their IDs populated
      //otherwise IDs will remain undefined and so will create a new one on subsequent saves
      false
    ),
    mutationKey: ["CustomFormSubmit", formId || systemTag],
    onSuccess: (d, { formId: formId2 }) => {
      queryClient.invalidateQueries(["CustomFormResponses", formId2]);
    }
  });
  const handleSubmit = useCallback(
    (data, screenoutQuestionIds, done, err) => {
      const formQids = form.sections?.flatMap(
        (s) => s.questions?.map((q) => q.id?.toString())
      );
      const vals = Object.keys(data).filter((k) => formQids.includes(k.toString())).reduce((a, b) => ({ ...a, [b]: data[b] }), {});
      const domainItemId = meta?.domainItemId || domainItemIdField[form?.domain] || null;
      const metadata = { ...meta, domainItemId };
      mutate(
        {
          data: vals,
          screenoutQuestionIds: screenoutQuestionIds?.length ? screenoutQuestionIds.map((id) => Number(id)) : null,
          formId: form.id,
          metadata
        },
        {
          onSuccess: (data2) => {
            return done && done(data2);
          },
          onError: (e) => {
            if (err) {
              return err(e);
            }
            apiError(e);
          }
        }
      );
    },
    [mutate, form, user, meta]
  );
  return {
    form,
    isLoading,
    handleSubmit,
    isSubmitting,
    submitError: error
  };
};
const domainItemIdField = {
  user: "id",
  employee: "employeeId",
  "employee-medical-record": "employeeMedicalId",
  "employee-payroll-record": "employeePayrollId"
};
