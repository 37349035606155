import { ErrorBoundary } from '@sentry/react';
import React, { HTMLProps } from 'react';
import { Button } from 'react-bootstrap';
export default ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <ErrorBoundary
      fallback={r => (
        <div className="text-danger" {...rest}>
          Something went wrong!
          <Button size="sm" className="ms-2" onClick={r.resetError}>
            Try again
          </Button>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};
