import { CellContext, Row } from '@tanstack/react-table';
import React from 'react';
import { sqlValueFormatter, useAdvanceTable } from './AdvanceTableProvider';
import { isReactRenderable } from 'helpers/utils';
import LoadingOverlay from '../LoadingOverlay';
type EditorFactory<TValue = any> = (
  initialValue: TValue,
  onDone: (editedValue: TValue) => void
) => React.ReactNode;
const editors: Record<string, EditorFactory> = {
  text: (data, done) => (
    <input
      autoFocus
      type="text"
      className="form-control form-control-sm"
      defaultValue={data}
      onBlur={e => done(e.target.value)}
    />
  ),
  number: (data, done) => (
    <input
      autoFocus
      type="number"
      className="form-control form-control-sm"
      defaultValue={data}
      onBlur={e => done(Number(e.target.value))}
    />
  ),
  date: (data, done) => (
    <input
      autoFocus
      type="date"
      className="form-control form-control-sm"
      defaultValue={data}
      onBlur={e => done(new Date(e.target.value))}
    />
  )
};
export default <TData extends { id?: number } = any, TValue = unknown>({
  cellProps,
  children,
  onEdit,
  editor: _editor = 'string',
  isLoading
}: {
  children?: React.ReactNode;
  onEdit?: EditedCallback<TData, TValue>;
  isLoading?: boolean;
} & EditorComponentProps<TData, TValue>) => {
  const [edit, setEdit] = React.useState(false);
  const handleDone: EditedCallback<TData, TValue> = (
    dataId,
    newValue,
    oldValue
  ) => {
    onEdit?.(dataId, newValue, oldValue);
    setEdit(false);
  };
  return (
    <div className="position-relative">
      {isLoading && <LoadingOverlay minHeight={'1rem'} />}
      {edit ? (
        <Editor
          editor={_editor}
          internalUpdate={!!onEdit}
          cellProps={cellProps}
          onDone={handleDone}
        />
      ) : (
        <div onClick={() => setEdit(true)}>
          {children ||
            sqlValueFormatter(cellProps.column.columnDef.meta.inputType)(
              cellProps.getValue()
            )}
        </div>
      )}
    </div>
  );
};
type EditedCallback<TData, TValue> = (
  row: Row<TData>,
  newValue: TValue,
  oldValue: TValue
) => void;
export type Editor<TValue = any> = keyof typeof editors | EditorFactory<TValue>;
type EditorComponentProps<TData extends { id?: number } = any, TValue = any> = {
  editor?: Editor<TValue>;
  cellProps: CellContext<TData, TValue>;
};
const Editor = <TData extends { id?: number } = any, TValue = any>({
  editor: _editor,
  cellProps,
  onDone,
  internalUpdate
}: EditorComponentProps<TData, TValue> & {
  onDone: EditedCallback<TData, TValue>;
  internalUpdate?: boolean;
}) => {
  const { updateRow } = useAdvanceTable();
  const editorGetter = typeof _editor == 'string' ? editors[_editor] : _editor;
  const oldValue = cellProps.getValue();
  const handleDone = (data: TValue) => {
    onDone(cellProps.row, data, oldValue);
    if (internalUpdate) {
      updateRow(cellProps.row, { [cellProps.cell.id]: data });
    }
  };
  const editor = editorGetter(cellProps.getValue(), handleDone);
  return editor;
};
