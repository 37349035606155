import React, { useEffect, useMemo } from 'react';
import {
  faCamera,
  faFileImage,
  faList,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { IDCheck } from 'apis/flex/hr';
import FormWizard from 'components/wizard/FormWizard';
import WizardInput from 'components/wizard/WizardInput';
import { useWatch } from 'react-hook-form';
import FileImage from 'components/files/FileImage';
import { Form } from 'react-bootstrap';
import { IDDocumentForm, LivenessPage } from './IdentityCheck';
import OneIdRightToWorkCheck from './OneIdRightToWorkCheck';
import WithFormValues from 'components/wizard/WithFormValues';
import { FiCamera } from 'react-icons/fi';
const DocumentPage = ({ review }) => {
  const docType = useWatch({ name: 'docType' });
  const shareCodeResult = useWatch({ name: 'shareCodeResult' });
  return (
    <>
      {docType === 'Share code' ? (
        <>
          <WizardInput
            type="text"
            label="Enter your share code below"
            name="shareCode"
            formControlProps={{ maxLength: 11 }}
            placeholder="A12 345 67G"
          />
          {review && (
            <div className="d-flex justify-content-center align-items-center w-100 p-3">
              {shareCodeResult ? (
                <FileImage
                  style={{ height: '50vh' }}
                  fileId={shareCodeResult}
                />
              ) : (
                <div>Awaiting results. Check back in a minute...</div>
              )}
            </div>
          )}
        </>
      ) : (
        <WizardInput
          type="image"
          pluginProps={{
            maxFiles: 5,
            height: 300,
            placeholder: <FiCamera />
          }}
          label="Upload scans or photos of the appropriate documentation that proves your right to work in the UK"
          name="fileIds"
        />
      )}
    </>
  );
};

export default ({
  onSubmit,
  review,
  check
}: {
  check?: IDCheck;
  onSubmit?: (data: any, done: () => void) => void;
  review?: boolean;
}) => {
  // const urlData = useOneIdRedirectData();
  // const formData = useMemo(() => ({ ...check, ...urlData }), [check, urlData]);
  return (
    <FormWizard
      allowPrev
      // store={localStorage}
      storeId={'right-to-work-check-' + check?.screeningId}
      authItemCollection="employee-screening"
      authItemId={check?.screeningId}
      data={check}
      readOnly={review}
      onSubmit={({ data, done }) => {
        if (onSubmit) {
          return onSubmit(data, done);
        }
        done();
      }}
    >
      <FormWizard.Page icon={faList} label="Document type">
        <WizardInput
          type="radio"
          options={[
            {
              value: 'Passport',
              label: 'Passport issued in the UK',
              description: `Select this option even if your passport is expired`
            },
            {
              value: 'Birth certificate',
              label: 'Birth certificate issued in the UK',
              description: `You will also need to provide an official document showing your National Insurance number`
            },
            {
              value: 'Share code',
              label: 'Gov.uk share code',
              description: `You can get a share code if you do not have either of the above documents by going to https://www.gov.uk/view-prove-immigration-status`
            },
            {
              value: 'Other',
              label: 'Other documents',
              description: `Visit https://www.gov.uk/prove-right-to-work/using-immigration-documents to find out which documents are accpetable.`
            }
          ]}
          label="Choose how you would like to prove your right to work in the UK"
          name="docType"
        />
      </FormWizard.Page>
      <FormWizard.Page icon={faFileImage} label="Verify">
        <WithFormValues fields={['docType']}>
          {({ docType }) => (
            <>
              {check.autoScreen && docType !== 'Share code' ? (
                <OneIdRightToWorkCheck
                  // onSubmit={onSubmit}
                  review={review}
                  check={check}
                />
              ) : (
                <>
                  <DocumentPage review={review} />
                  <Form.Label className="mt-4 mb-3">
                    Please upload your preferred <b>photo</b> identity document.
                    This must be a passport, driving license, or official photo
                    ID, and must not be expired
                  </Form.Label>
                  <IDDocumentForm />
                  <LivenessPage review={review} />
                </>
              )}
            </>
          )}
        </WithFormValues>
      </FormWizard.Page>
    </FormWizard>
  );
};
