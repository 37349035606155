import api from ".";
import { getMediaStreamUrl } from "helpers/utils";
import CrudApi from "./CrudApi";
export const decryptParams = (data, iv) => api.get("/helpers/decrypt", { params: { data, iv } });
export const encryptParams = (data) => api.get("/helpers/encrypt", { params: { data } }).then((d) => d.data);
export const uploadMedia = (fileObject, settings = {}, isPublic, isTemp, authItemCollection, authItemId, progressId) => {
  if (!fileObject) return Promise.resolve({});
  const formData = new FormData();
  Object.keys(fileObject).forEach((k) => {
    let arr;
    const obj = fileObject[k];
    if (Array.isArray(obj)) {
      arr = obj;
    } else {
      arr = [obj];
    }
    arr.forEach((file) => {
      formData.append(k, file, file.name);
    });
  });
  formData.append("settings", JSON.stringify(settings));
  if (isTemp) {
    formData.append("isTemp", String(isTemp));
  }
  if (authItemCollection) {
    formData.append("authItemCollection", authItemCollection);
  }
  if (authItemId) {
    formData.append("authItemId", authItemId && String(authItemId));
  }
  return api.post(
    "/helpers/media" + (isPublic ? "/public" : ""),
    formData,
    {
      headers: {
        "x-progressid": progressId
      },
      timeout: 1e3 * 60 * 20
    }
  ).then((d) => d.data);
};
export const commitMedia = (ids, authItemCollection, authItemId, progressId) => api.post(
  "/helpers/media/commit",
  { ids, authItemCollection, authItemId },
  {
    headers: {
      "x-progressid": progressId
    }
  }
);
export const updateMedia = (ids, data) => api.patch("/helpers/media/batch", { ids, data });
export const getMedia = (id) => api.get("/helpers/media", {
  params: { id: id.split(".")[0], ext: id.split(".")[1] }
}).then((d) => d.data);
export const getMediaUrl = (id, fileName) => api.get("/helpers/media/url", {
  params: { id: id.split(".")[0], ext: id.split(".")[1], fileName }
}).then((d) => d.data);
export const getZippedMediaUrl = (fileIds, zipName) => api.post("/helpers/media/zip", {
  zipName,
  ids: fileIds.map((id) => ({
    id: id.split(".")[0],
    ext: id.split(".")[1]
  }))
}).then((d) => d.data);
export const getMediaStream = (id) => api.get(getMediaStreamUrl(id), { responseType: "blob" });
export const regexAssist = (prompt) => api.get("/helpers/ai/regex", { params: { prompt } }).then((d) => d.data);
export const aiApi = {
  regexAssist,
  generateHtml: (prompt, settings) => api.post("/helpers/ai/html", { prompt, settings }).then((d) => d.data),
  generateJson: (prompt, settings) => api.post("/helpers/ai/json", { prompt, settings }).then((d) => d.data),
  generateText: (prompt, settings) => api.post("/helpers/ai/text", { prompt, settings }).then((d) => d.data),
  transcribe: ({ fileUrl, fileId }) => api.get("/helpers/ai/transcribe", {
    params: { url: fileUrl, fileId }
  }).then((d) => d.data),
  speechAnalysis: (fileId) => api.get("/helpers/ai/speech-analysis", {
    params: { fileId }
  }).then((d) => d.data)
};
export const getOneTimeTours = () => api.get("/helpers/tours/").then((d) => d.data);
export const setTourDone = (name) => api.post("/helpers/tour/done", { name }).then((d) => d.data);
export const eventsApi = new CrudApi("/helpers/dev/events");
export const getDomainItemEvents = ({
  domain,
  itemId,
  actions,
  authorIds,
  extraFilters,
  customFilters
}, { page, pageSize }) => api.get("/helpers/domain-events", {
  params: {
    filters: {
      domain,
      itemId,
      action: actions,
      authorId: authorIds
    },
    extraFilters,
    page,
    pageSize,
    customFilters
  }
}).then((d) => d.data);
export const shortLinksApi = new CrudApi("/helpers/short-urls");
export const shortLinkClicksApi = new CrudApi(
  "/helpers/short-urls/clicks"
);
export const resolveShortLink = (hash) => api.get("/helpers/short-urls/resolve/" + hash).then((d) => d.data);
export const devApi = {
  resetCache: () => {
    return api.get("/helpers/dev/clear-cache");
  },
  refireEvent: (id, data, blockNotifications) => {
    return api.post("/helpers/dev/events/" + id + "/refire", {
      data,
      blockNotifications
    });
  },
  refireEvents: (filters, data) => {
    return api.post("/helpers/dev/events/refire", { data, filters });
  },
  refireTransaction: (id, data) => {
    return api.post("/helpers/dev/transactions/" + id + "/refire", { data });
  },
  testSocket: () => {
    return api.post("/mailer/test/socket/");
  },
  refreshEmplyeeSchedules: () => {
    return api.patch("/hr/shifts/schedules");
  }
};
export const filesApi = new CrudApi("/helpers/files");
export const updateFileAuth = (fileIds, authItemCollection, authItemId) => {
  return api.patch("/helpers/files/auth", {
    fileIds,
    authItemCollection,
    authItemId
  });
};
export const tagsApi = new CrudApi("/helpers/tags");
function isConsecutiveIndexes(obj) {
  const keys = Object.keys(obj).map(Number);
  const sortedKeys = keys.sort((a, b) => a - b);
  for (let i = 0; i < sortedKeys.length; i++) {
    if (sortedKeys[i] !== i) {
      return false;
    }
  }
  return true;
}
export function convertObjectToArray(obj) {
  if (typeof obj !== "object") {
    return obj;
  }
  if (!obj) return obj;
  if (isConsecutiveIndexes(obj)) {
    const keys = Object.keys(obj).map(Number);
    return keys.map((key) => obj[key]);
  } else {
    return obj;
  }
}
export const calendarApi = new CrudApi("/helpers/calendars");
export const calendarItemsApi = new CrudApi(
  "/helpers/calendars/items"
);
export const visitsApi = new CrudApi("/helpers/visits", {
  saveTransactions: false
});
export const visitPagesApi = new CrudApi(
  "/helpers/visits/pages",
  { saveTransactions: false }
);
export const startVisit = (data) => api.post("/helpers/visits/start", data).then((d) => d.data);
export const webhooksApi = new CrudApi("/helpers/webhooks");
export const webhookTransactionsApi = new CrudApi(
  "/helpers/webhooks/transactions"
);
