import React, { useMemo } from 'react';
import useTags from 'hooks/useTags';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { domainConfigs } from 'components/notification/config';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import SoftBadge from './SoftBadge';
import { SoftBadgeProps } from './SoftBadge';
import classNames from 'classnames';
import { ResponsiveModal } from './Modals';
import { Modal, ModalBody } from 'react-bootstrap';
import ActionFooter from './ActionFooter';

export const Tags = ({
  ids,
  className,
  children,
  ...rest
}: {
  ids: number[];
  children?: React.ReactNode;
} & Omit<SoftBadgeProps, 'children'>) => {
  const { data: tags } = useTags({
    filters: { id: ids },
    useFilter: !!ids?.length
  });
  return tags?.map(tag => (
    <SoftBadge
      pill
      key={tag.id}
      bg="info"
      className={classNames(className, 'me-1')}
      {...rest}
    >
      {children}
      {tag.name}
    </SoftBadge>
  ));
};

const TagSelector = ({
  tableName,
  name = 'tagIds',
  registerProps,
  ...rest
}: { tableName: string; name?: string } & Omit<
  WizardInputProps,
  'type' | 'name'
>) => {
  const config = domainConfigs.tag;
  const {
    data: tags,
    add,
    isAdding
  } = useTags({
    filters: { tableName },
    useFilter: !!tableName,
    select: d => d.map(config.format),
    noReturnOnChange: false,
    afterSave: added => {
      const oldVal = getValues(name);
      // console.log(name, 'oldVal', oldVal);
      const newVal = [
        ...(!oldVal || Array.isArray(oldVal) ? oldVal : [oldVal]),
        added.id
      ];
      // console.log('newVal', newVal);
      setValue(name, newVal, {
        shouldDirty: true,
        shouldTouch: true
      });
    }
  });
  const { setValue, getValues } = useFormContext();
  const handleCreate = (val: string) => {
    add({ name: val, tableName });
  };
  const pluginProps = useMemo(
    () => ({
      creatable: true,
      onCreate: handleCreate,
      isLoading: isAdding
    }),
    [tableName, add]
  );
  return (
    <>
      <WizardInput
        type="select"
        name={name}
        label="Tags"
        registerProps={{ required: false, ...registerProps }}
        multiple
        {...rest}
        loading={isAdding || rest.loading}
        options={tags}
        pluginProps={pluginProps}
      />
    </>
  );
};
export const AddTagForm = ({
  tableName,
  onAdd,
  isSaving,
  onFinished,
  defaultValues,
  ...rest
}: {
  tableName: string;
  onAdd: (tagIds: number[]) => void;
  isSaving: boolean;
  onFinished?: () => void;
  defaultValues?: number[];
} & Omit<WizardInputProps, 'type' | 'name'>) => {
  const methods = useForm<{ tagIds: number[] }>({
    defaultValues: {
      tagIds: defaultValues
    }
  });
  return (
    <>
      <FormProvider {...methods}>
        <TagSelector tableName={tableName} name="tagIds" {...rest} />
      </FormProvider>
      <ActionFooter
        onCancel={onFinished}
        isLoading={isSaving}
        onSubmit={methods.handleSubmit(({ tagIds }) => {
          // setShow(false)
          onAdd(tagIds);
          // bulkUpdateAsync({id:rows.map(r=>r.original.id),data:{tagIds:uniq(rows[0].original.tagIds.concat(tagIds)}});
        })}
      />
    </>
  );
};
export const AddTagModal = ({
  show,
  tableName,
  setShow,
  onAdd,
  isSaving,
  ...rest
}: {
  show: boolean;
  tableName: string;
  setShow: (show: boolean) => void;
  onAdd: (tagIds: number[]) => void;
  isSaving: boolean;
  defaultValues?: number[];
} & Omit<WizardInputProps, 'type' | 'name'>) => {
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <AddTagForm
        tableName={tableName}
        onAdd={onAdd}
        isSaving={isSaving}
        onFinished={() => setShow(false)}
        {...rest}
      />
    </ResponsiveModal>
  );
};

export default TagSelector;
