import { ReactNode, createContext } from 'react';
import {
  FieldValues,
  FormProvider,
  UseFormReturn,
  useFormContext
} from 'react-hook-form';
import React from 'react';
import PropTypes from 'prop-types';
const MediaFormContext = createContext(null);
export type UseMediaFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = TFieldValues
> = UseFormReturn<TFieldValues, TContext, TTransformedValues> & {
  registerMedia: (field: string, settings?: any) => void;
  setAuthItemId: (
    id: number,
    done?: () => void,
    error?: (err: any) => void
  ) => void;
  triggerUpload: () => void;
  isSubmitting?: boolean;
};
export default () => {
  return useFormContext() as UseMediaFormReturn;
};
export const MediaFormProvider = ({
  children,
  ...methods
}: { children: ReactNode } & UseMediaFormReturn) => (
  <FormProvider {...methods}>
    <MediaFormContext.Provider value={methods}>
      {children}
    </MediaFormContext.Provider>
  </FormProvider>
);
MediaFormProvider.propTypes = {
  children: PropTypes.node
};
