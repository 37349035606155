import React, { useCallback, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import IconButton from 'components/common/IconButton';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { UseFieldArrayReturn, useWatch } from 'react-hook-form';
import { ShiftPlan } from 'apis/flex/hr';
import { formatDateToISO } from 'helpers/dates';
import useResourcePlan from 'components/app/pm/projects/resourcing/useResourcePlan';
import { AllocationEntry } from 'apis/flex/projects';
import UnallocatedItem from './UnallocatedItem';
import { Col, Row } from 'react-bootstrap';
import { uniqBy } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { devApi } from 'apis/flex/helpers';
export const useUnallocated = ({
  showForecasted,
  showPreferences
}: {
  showForecasted?: boolean;
  showPreferences?: boolean;
} = {}) => {
  const [resourceGroupId, date] = useWatch<
    ShiftPlan,
    ['resourceGroupId', 'date']
  >({
    name: ['resourceGroupId', 'date']
  });
  const allocations = useWatch<ShiftPlan, 'allocations'>({
    name: 'allocations'
  });
  const select = useCallback<(d: AllocationEntry[]) => AllocationEntry[]>(
    d => {
      return uniqBy(d || [], 'employeeId').filter(
        a => !allocations?.find(aa => aa && aa.employeeId === a.employeeId)
      );
    },
    [allocations]
  );
  const enabledMemo = useMemo(
    () => !!resourceGroupId && !!date,
    [resourceGroupId, date]
  );
  const filtersMemo = useMemo(() => {
    const f: any[] = [
      { first: 'date', second: formatDateToISO(date) },
      {
        first: 'resourceGroupId',
        second: resourceGroupId
      }
    ];
    if (showForecasted) {
      f.push({
        first: 'isForecast',
        second: true
      });
    }
    if (!showPreferences) {
      f.push({
        first: 'isBooked',
        second: true
      });
    }
    return f;
  }, [date, resourceGroupId, showForecasted, showPreferences]);

  const {
    data: allowableAllocations,
    refetch,
    isRefetching
  } = useResourcePlan({
    filters: filtersMemo,
    enabled: enabledMemo,
    select
  });
  const allocationsMemo = useMemo(() => {
    return (allowableAllocations || []).map((a, i) => ({ ...a, id: i }));
  }, [allowableAllocations]);
  const { mutate, isLoading } = useMutation(async () => {
    await devApi.refreshEmplyeeSchedules();
    refetch();
  });
  return useMemo(
    () => ({
      unallocated: allocationsMemo,
      refresh: mutate,
      isRefreshing: isLoading || isRefetching
    }),
    [allocationsMemo, refetch, isLoading, isRefetching]
  );
};
export default ({
  fieldArray,
  showForecasted,
  showPreferences
}: {
  showForecasted?: boolean;
  showPreferences?: boolean;
  fieldArray: UseFieldArrayReturn<ShiftPlan, 'allocations'>;
}) => {
  return (
    <Droppable droppableId={'unallocated'} type="droppableItem">
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="d-flex flex-column border-2 border-dashed border rounded-3 p-3 mb-3"
        >
          <Inner
            showForecasted={showForecasted}
            showPreferences={showPreferences}
            provided={provided}
          />
        </div>
      )}
    </Droppable>
  );
};
const Inner = ({ showForecasted, showPreferences, provided }) => {
  const { unallocated, refresh, isRefreshing } = useUnallocated({
    showForecasted,
    showPreferences
  });
  return (
    <>
      <div className="w-100 text-center text-muted">
        <IconButton
          onClick={() => refresh()}
          loading={isRefreshing}
          icon={faSyncAlt}
          variant="link"
          size="sm"
        >
          Refresh
        </IconButton>
      </div>
      {!unallocated?.length && (
        <div className="w-100 text-center text-muted p-4">
          No-one else is available
        </div>
      )}
      {provided.placeholder}
      <Row className="w-100">
        {unallocated.map((alloc, i) => (
          <Col key={alloc.employeeId} xs={6} md={4} lg={3}>
            <UnallocatedItem allocation={alloc} index={i} />
          </Col>
        ))}
      </Row>
    </>
  );
};
