import React, { useEffect } from 'react';
import SelectOptionsBuilder from '../SelectOptionsBuilder';
import InfoBadge from 'components/common/InfoBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { useFormContext, useWatch } from 'react-hook-form';
import useUuid from 'hooks/useUuid';

export default ({ index }) => {
  const vals = useWatch({ name: 'questions.' + index + '.options' });
  const { setValue } = useFormContext();
  const { getId } = useUuid();
  useEffect(() => {
    if (!vals?.filter(Boolean).length) {
      setValue('questions.' + index + '.options', [
        {
          label: '',
          options: [
            { label: '', value: getId() },
            { label: '', value: getId() },
            { label: '', value: getId() }
          ]
        }
      ]);
    }
  }, [vals]);
  return (
    <div>
      <SelectOptionsBuilder
        index={index}
        label="Option Sets"
        instructions={
          <>
            Option sets will be displayed one at a time. Any options not in sets
            will be shown as instructional text
            <InfoBadge>
              Use the <FontAwesomeIcon icon={faFolderPlus} /> icon to add a set
            </InfoBadge>{' '}
          </>
        }
        optionPlaceholder="Option label"
        groupPlaceholder="Set label"
        name={'questions.' + index + '.options'}
      />
    </div>
  );
};
