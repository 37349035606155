import React, {
  ComponentType,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { SelectorItem } from 'components/common/ItemSelector';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import DomainItemIcon from 'components/common/DomainItemIcon';
import { domainConfigs } from 'components/notification/config';
import { EventPrefix } from 'apis/flex/notifications';
import {
  CrudFilters,
  DefaultCrudData,
  defaultCrudHookBuilder,
  UseCrudProps
} from 'hooks/defaultCrud/useDefaultCrud';
import classNames from 'classnames';
import { CustomRule, mergeRules } from 'helpers/validation/validate';
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { ModalConfirm, ResponsiveModal } from './Modals';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { DebouncedSearchInput } from './Search';
import DomainAvatar from './DomainAvatar';
import DomainIcon from './DomainIcon';
import LockedContent from './LockedContent';
import Truncate from './Truncate';
import { WizardInputProps } from 'components/wizard/WizardInput';
import WizardInputWrapper from 'components/wizard/WizardInputWrapper';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Skeleton from 'react-loading-skeleton';
import { getSqlInputType } from './advance-table-v2/sql';
import { ItemSelectorGroupProps } from './ItemSelectorGroup';
import SettingsBox from './SettingsBox';
import Flex from './Flex';
import { getDomainHome } from 'hooks/useDomainRouter';
import useArray from 'hooks/useArray';
import { camelToSentence } from 'helpers/utils';
import { ApiField } from 'apis/types';
import { useInputConfig } from 'components/wizard/InputConfig';
import InfiniteScroller from './InfiniteScroller';
import { memoize } from 'lodash';
import { useUser } from 'hooks/useUser';
import { domainToSentence } from './DomainTimeline';
import { useGuard } from 'hooks/useGuard';
import LoadingButton from './LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import IconButton from './IconButton';

export type CrudHook<TData extends DefaultCrudData> = ReturnType<
  typeof defaultCrudHookBuilder<TData>
>;
export const useDomainItems = <TData extends DefaultCrudData = any>(
  domain: EventPrefix,
  crudHook: CrudHook<TData>,
  {
    ids,
    select,
    ...rest
  }: {
    ids?: number[];
  } & UseCrudProps<TData, SelectorItem<TData>[]>
) => {
  return crudHook<SelectorItem<TData>[]>({
    staleTime: 1000 * 60,
    id: ids,
    select: d => {
      // console.log(domain, rest, 'returning selected', d);
      return d
        .map(
          (emp: TData) =>
            emp && {
              ...domainConfigs[domain].format(emp),
              ...select?.([emp]),
              data: emp
            }
        )
        .filter(d => d);
    },
    ...rest
  });
};
export const usePagedItems = <TData extends DefaultCrudData = any>({
  domain,
  crudHook,
  pageSize,
  select,
  ...rest
}: {
  pageSize: number;
  crudHook: CrudHook<TData>;
  domain: EventPrefix;
} & UseCrudProps<TData, SelectorItem[]>) => {
  const [filters, setFilters] = useState<CustomRule>();

  const {
    isLoading,
    counts,
    meta,
    infiniteData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    refetch
  } = useDomainItems(domain, crudHook, {
    useFilter: true,
    customFilter: filters,
    count: true,
    infinite: { pageSize },
    ...rest
  });
  const total = counts?.[0]?.count;
  return {
    data: infiniteData?.pages,
    isLoading: isLoading || (isFetching && !infiniteData?.pages),
    totalRows: total,
    totalPages: Math.ceil(total / pageSize),
    filters,
    setFilters,
    page: infiniteData?.pages.length,
    meta,
    isLoadingNext: isFetchingNextPage,
    getNext: () => hasNextPage && fetchNextPage(),
    isFetching,
    refetch
  };
};
export type CrudItemSelectorProps<TData extends DefaultCrudData = any> =
  Partial<WizardInputProps> & {
    domain: EventPrefix;
    filter?: CrudFilters<TData>;
    pageSize?: number;
    crudHook?: CrudHook<TData>;
    crudProps?: UseCrudProps<TData, SelectorItem[]>;
    searchFields?: ApiField<TData>[];
    mutator?: (data: TData) => Partial<SelectorItem>;
    confirmText?: string;
    onNewClick?: false | (() => void);
    selectedText?: (data: SelectorItem[]) => string;
  };
export const DomainItemSelector = <TData extends DefaultCrudData = any>({
  crudHook,
  domain,
  filter,
  pageSize = 40,
  searchFields,
  mutator = d => d as any,
  confirmText = 'Done',
  ...rest
}: CrudItemSelectorProps<TData>) => {
  const config = domainConfigs[domain];
  const configCrudHook = config.crudHook;
  const configProps = useInputConfig();
  return (
    <CrudItemSelector
      domain={domain}
      pageSize={pageSize}
      crudProps={{
        filters: filter,
        useFilter: true,
        asList: true,
        select: d => d.map(mutator) as SelectorItem[]
      }}
      crudHook={crudHook || configCrudHook}
      searchFields={searchFields}
      confirmText={confirmText}
      label={domainToSentence(domain)}
      name={config.foreignKey}
      {...configProps}
      {...rest}
    />
  );
};
export const useDomainSearch = <TData extends DefaultCrudData = any>({
  search,
  searchFields,
  customFilter,
  domain,
  ...rest
}: {
  domain: EventPrefix;
  search: string;
  /** Will use domain configs search fields as well as any specified here */
  searchFields?: string[];
  /** Other custom filters to be merged with the search ones */
  customFilter?: CustomRule;
} & UseCrudProps<TData, any>) => {
  if (!search)
    return {
      customFilter,
      ...rest
    };

  const allSearchFields = (domainConfigs[domain]?.searchFields || []).concat(
    searchFields || []
  );
  const searchFilter = search.split(' ').map(search =>
    allSearchFields.map(c => ({
      value: search,
      question: c,
      type: 'contains'
    }))
  );

  return {
    customFilter: mergeRules(searchFilter, customFilter),
    ...rest
  };
};
export const InfiniteCrudScroller = <TData extends DefaultCrudData = any>({
  domain,
  crudHook,
  pageSize,
  formatter,
  search,
  crudProps,
  searchFields,
  ...rest
}: {
  domain: EventPrefix;
  crudHook: CrudHook<TData>;
  pageSize: number;
  formatter: (items: SelectorItem) => ReactNode;
  search?: string;
  searchFields?: string[];
  crudProps?: UseCrudProps<TData, SelectorItem[]>;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'onChange'>) => {
  const crudPropsWithSearch = useDomainSearch({
    domain,
    search,
    searchFields,
    ...crudProps
  });
  const {
    data,
    isLoadingNext,
    getNext,
    isLoading,
    totalPages,
    refetch,
    isFetching
  } = usePagedItems({
    domain,
    crudHook,
    pageSize,
    ...crudPropsWithSearch
  });
  return (
    <>
      <div className="w-100 text-center fs--2">
        <IconButton
          loading={isFetching}
          icon={faSync}
          onClick={() => refetch()}
          size="sm"
          variant="link"
        >
          {/* <FontAwesomeIcon icon={faSync} className='me-1' /> */}
          Refresh
        </IconButton>
      </div>
      <InfiniteScroller
        data={data}
        totalPages={totalPages}
        isLoadingNext={isLoadingNext}
        getNext={getNext}
        placeholder={
          <Row>
            {[0, 0, 0, 0].map((_, i) => (
              <Col xs={12} lg={6} key={i}>
                <Item domain={domain} item={null} field={null} isLoading />
              </Col>
            ))}
          </Row>
        }
        {...rest}
      >
        {({ data }) => (
          <>
            {isLoading ? (
              <Row>
                {[0, 0, 0, 0].map((_, i) => (
                  <Col xs={12} lg={6} key={i}>
                    <Item domain={domain} item={null} field={null} isLoading />
                  </Col>
                ))}
              </Row>
            ) : (
              data?.map((item: SelectorItem) => (
                <React.Fragment key={item.value}>
                  {formatter(item)}
                </React.Fragment>
              ))
            )}
          </>
        )}
      </InfiniteScroller>
    </>
    // <InfiniteScroller
    //   data={data}
    //   getNext={() => setPage(p => p + 1)}
    //   isLoadingNext={isLoadingNext}
    //   totalPages={totalPages}
    //   {...rest}
    // >
    //   {({ data }) => formatter(data)}
    // </InfiniteScroller>
  );
};
export const useSelectedDomainItems = <TData extends DefaultCrudData = any>({
  domain,
  crudHook,
  idArr
}) =>
  useDomainItems<TData>(domain, crudHook, {
    ids: idArr,
    asList: true,
    getPublic: true
  });
export const SelectedDomainItems = <TData extends DefaultCrudData = any>({
  domain,
  crudHook,
  ids,
  isLoading: extLoading,
  ...props
}: Omit<ItemSelectorGroupProps, 'data'> & {
  crudHook?: CrudHook<TData>;
  ids: number[];
  domain: EventPrefix;
}) => {
  const idArr = useArray(ids);
  const configCrudHook = domainConfigs[domain].crudHook;
  const { data, isLoading } = useSelectedDomainItems<TData>({
    domain,
    crudHook: crudHook || configCrudHook,
    idArr
  });
  // console.log('selected domain items', ids, props, domain, data);
  return (
    <DomainItemIcon
      domain={domain}
      isLoading={isLoading || extLoading}
      data={data}
      {...props}
    />
  );
};
export const getSelectedDomainItems = <TData extends DefaultCrudData = any>(
  domain: EventPrefix,
  defaultProps?: Partial<SelectedDomainItemsProps<TData>>
) => {
  const config = domainConfigs[domain];
  if (!config?.crudHook) return null;
  return (props: SelectedDomainItemsProps<TData>) => {
    return (
      <SelectedDomainItems
        {...defaultProps}
        {...props}
        domain={domain}
        crudHook={config.crudHook}
      />
    );
  };
};
export type DomainItemSelectorProps<TData extends DefaultCrudData = any> = Omit<
  CrudItemSelectorProps<TData>,
  'domain' | 'crudHook'
> &
  Omit<ItemSelectorGroupProps, 'data' | 'isLoading'>;
export const getDomainItemSelector = <TData extends DefaultCrudData = any>(
  domain: EventPrefix,
  defaultProps?: Partial<DomainItemSelectorProps<TData>>
) => {
  const config = domainConfigs[domain];
  if (!config?.crudHook) return null;
  return (props: DomainItemSelectorProps<TData>) => {
    return (
      <DomainItemSelector
        domain={domain}
        crudHook={config?.crudHook}
        {...defaultProps}
        {...props}
      />
    );
  };
};
const CrudItemSelector = <TData extends DefaultCrudData = any>({
  domain,
  crudHook,
  name,
  crudProps,
  pageSize = 40,
  readOnly,
  label,
  onNewClick,
  multiple,
  selectedText,
  placeholder,
  searchFields: passedSearchFields,
  confirmText,
  registerProps,
  loading: isLoading,
  ...rest
}: CrudItemSelectorProps<TData> &
  // {
  //   domain: EventPrefix;
  //   crudHook: CrudHook<TData>;
  //   crudProps: UseCrudProps<TData, SelectorItem[]>;
  //   pageSize: number;
  //   onNewClick?: () => void;
  //   selectedText?: (data: SelectorItem[]) => string;
  //   searchFields?: string[];
  //   confirmText?: string;
  // }
  Partial<WizardInputProps>) => {
  const [show, setShow] = useState(false);
  const { setValue } = useFormContext();
  const user = useUser();
  const domainHome = getDomainHome(domain, user, {});
  const searchFields =
    passedSearchFields ?? domainConfigs[domain]?.searchFields;
  const handleNewClick = () => {
    if (onNewClick) return onNewClick();
    window.open(
      domainHome + '/new?after=close',
      'blank',
      'noopener,noreferrer'
    );
  };
  const { canCreate } = useGuard({ roles: [domain] });
  return (
    <>
      <WizardInputWrapper
        name={name}
        registerProps={{ required: true, ...registerProps }}
        label={
          <Flex alignItems={'baseline'}>
            <span>{label || camelToSentence(name)}</span>
            {onNewClick !== false && domainHome && !readOnly && canCreate && (
              <Button
                size="sm"
                onClick={handleNewClick}
                variant="link"
                className="p-0 ms-2"
              >
                Add new
              </Button>
            )}
          </Flex>
        }
        id={name}
        {...rest}
      >
        {({ input, label, form: { field, error, errors } }) => {
          return (
            <>
              {label}
              {input(
                <div
                  className={classNames('form-control cursor-pointer', {
                    'is-invalid': !!error,
                    'is-valid': Object.keys(errors).length && !error,
                    'bg-200': readOnly
                  })}
                  // style={{ minHeight: 35 }}
                  ref={field.ref}
                  onClick={() => setShow(true)}
                >
                  <SelectedDomainItems
                    domain={domain}
                    crudHook={crudHook}
                    ids={field.value}
                    selectedText={selectedText}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </>
          );
        }}
      </WizardInputWrapper>
      <ResponsiveModal show={show} onHide={() => setShow(false)} wide>
        <Modal.Body>
          {show && (
            <ItemSelectorInput
              searchFields={searchFields}
              pageSize={pageSize}
              domain={domain}
              crudHook={crudHook}
              name={name}
              setShow={setShow}
              multiple={multiple}
              selectedText={selectedText}
              placeholder={placeholder}
              confirmText={confirmText}
              onChange={v => {
                // console.log(domain, 'selector', name, ' changed', v);
                registerProps?.onChange?.({ target: { value: v } });
                setValue(name, v, {
                  shouldDirty: true,
                  shouldTouch: true
                });
              }}
              readOnly={readOnly}
              {...crudProps}
            />
          )}
        </Modal.Body>
      </ResponsiveModal>
    </>
  );
};
const ItemSelectorInput = <TData extends DefaultCrudData = any>({
  setShow,
  crudHook,
  domain,
  name,
  // registerProps,
  multiple,
  pageSize,
  selectedText,
  placeholder,
  searchFields,
  confirmText,
  onChange,
  readOnly,
  ...crudProps
}: {
  setShow: (show: boolean) => void;
  crudHook: CrudHook<TData>;
  domain: EventPrefix;
  pageSize?: number;
  selectedText?: (data: SelectorItem[]) => string;
  searchFields?: string[];
  confirmText?: string;
  onChange: (val: any) => void;
  readOnly?: boolean;
} & Pick<WizardInputProps, 'multiple' | 'name' | 'placeholder'> &
  UseCrudProps<TData, SelectorItem[]>) => {
  const [search, setSearch] = useState<string>();
  const methods = useForm();
  const handleDone = (val?: any) => {
    // console.log('done. setting main form', name, methods.getValues(name));
    onChange(val ?? methods.getValues(name));
    setShow(false);
  };
  const handleCancel = () => {
    setShow(false);
  };
  const main = useWatch({ name });
  useEffect(() => {
    // console.log('main value changed', main, 'setting inner form', {
    //   [name]: main
    // });
    methods.reset({ [name]: main });
  }, [main]);
  const handleClear = () => {
    handleDone(multiple ? [] : '');
  };
  const render = useCallback(
    ({ field }) => {
      // // console.log('field.value', field.value);
      return (
        <div style={{ maxHeight: '95%' }}>
          {searchFields && (
            <div className="d-flex w-100 mb-3">
              <div className="flex-1">
                <DebouncedSearchInput autoFocus onChange={setSearch} />
              </div>
            </div>
          )}
          {field?.value && (
            <SettingsBox title="Selected" className="my-2">
              <SelectedDomainItems
                domain={domain}
                crudHook={crudHook}
                ids={field.value}
                selectedText={selectedText}
                placeholder={placeholder}
              />
            </SettingsBox>
          )}
          <InfiniteCrudScroller
            searchFields={searchFields}
            search={search}
            domain={domain}
            crudHook={crudHook}
            pageSize={pageSize}
            className="row g-1"
            style={{
              maxHeight: field?.value ? '50vh' : '65vh',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            formatter={(item: SelectorItem) => (
              <Col xs={12} lg={6} key={item.value}>
                <Item
                  domain={domain}
                  multiple={multiple}
                  onChange={() => !multiple && handleDone()}
                  field={field}
                  item={item}
                />
              </Col>
            )}
            crudProps={{
              ...crudProps,
              id: readOnly ? field.value : crudProps?.id
            }}
          />
        </div>
      );
    },
    [search]
  );
  return (
    <FormProvider {...methods}>
      <Controller
        control={methods.control}
        name={name}
        // rules={registerProps}
        render={render}
      />
      <div className="d-flex justify-content-end mt-3 gap-1">
        <Button variant="link" onClick={handleCancel}>
          Cancel
        </Button>
        <div>
          <ModalConfirm
            disabled={readOnly}
            variant="warning"
            onConfirm={handleClear}
          >
            <Button disabled={readOnly} variant="warning">
              Clear
            </Button>
          </ModalConfirm>
        </div>
        <Button
          disabled={readOnly}
          variant="primary"
          onClick={() => handleDone()}
        >
          {confirmText}
        </Button>
      </div>
    </FormProvider>
  );
};
const Item = ({
  domain,
  item,
  field,
  multiple,
  isLoading,
  onChange,
  readOnly
}: {
  domain: EventPrefix;
  item: SelectorItem;
  field: ControllerRenderProps<any, string>;
  multiple?: boolean;
  isLoading?: boolean;
  onChange?: () => void;
  readOnly?: boolean;
}) => {
  const val = field?.value || [];
  // // console.log(field, val);
  const isSelected = () =>
    multiple
      ? val.some(v => Number(v) === Number(item?.value))
      : Number(val) === Number(item?.value);
  const updateValue = () => {
    if (isSelected()) {
      if (!multiple) return field.onChange('');
      field.onChange(val.filter(v => v != item.value));
    } else {
      if (!multiple) return field.onChange(item.value);
      field.onChange(val.concat(item.value));
    }
  };
  const handleClick = () => {
    if (readOnly) return;
    if (!item) return;
    updateValue();
    setTimeout(() => {
      onChange?.();
    }, 100);
  };
  const { breakpoints } = useBreakpoints();
  return (
    <LockedContent
      locked={!!item?.locked}
      tooltip={typeof item?.locked === 'string' && item?.locked}
    >
      <div
        onClick={handleClick}
        className={classNames(
          'position-relative flex-column rounded-3 p-2 justify-content-between d-flex cursor-pointer hover-bg-200 cursor-pointer',
          {
            'bg-primary-subtle': isSelected(),
            'align-items-start': !breakpoints.up('lg'),
            'align-items-center': breakpoints.up('lg')
          }
        )}
      >
        <div
          className={classNames('d-flex flex-lg-column align-items-center', {
            'flex-column justify-content-between': breakpoints.up('lg'),
            'justify-content-start gap-2': !breakpoints.up('lg')
          })}
        >
          <div>
            {item?.avatar || isLoading ? (
              <DomainAvatar
                domain={domain}
                src={item?.avatar}
                isLoading={isLoading}
                size={breakpoints.up('lg') ? 'lg' : 'sm'}
              />
            ) : (
              <DomainIcon
                domain={domain}
                icon={item.icon}
                size={breakpoints.up('lg') ? 'lg' : 'sm'}
                // bg={isSelected() ? 'primary-subtle' : '100'}
              />
            )}
          </div>
          <h6
            className={classNames('fw-semi-bold mb-0', {
              'fs-0': !breakpoints.up('lg'),
              'fs-1 mt-2 text-center': breakpoints.up('lg')
            })}
          >
            <div className="text-900 stretched-link">
              {isLoading ? (
                <Skeleton width={120} height={'1rem'} />
              ) : (
                <Truncate middle>{item.label}</Truncate>
              )}
            </div>
          </h6>
        </div>
        {item?.info &&
          breakpoints.up('lg') &&
          Object.keys(item.info)
            .filter(name => !!item.info[name])
            .filter((_, i) => i < 4)
            .map((name, i) => (
              <span
                key={name}
                className={classNames(
                  'fs--2 lh-1 mb-0 pt-1 text-500 text-center',
                  {
                    'fw-light': i > 0
                  }
                )}
              >
                {item.info[name]}
              </span>
            ))}
        {isLoading ? (
          <TagWrapper className="my-1">
            <Skeleton containerClassName="d-flex gap-1" count={2} width={50} />
          </TagWrapper>
        ) : (
          item.tags && <TagWrapper className="my-1">{item.tags}</TagWrapper>
        )}
      </div>
    </LockedContent>
  );
};
export const TagWrapper = ({
  children,
  className
}: {
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames('d-flex gap-1', className)}>{children}</div>
  );
};
