import DomainReportTiles from 'components/app/reporting/DomainReportTiles';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import EmployeeShiftsTable from '../employeeShifts/EmployeeShiftsTable';
import UserWorkingHours from 'components/app/users/detail/UserWorkingHours';
import { isContractFlexible } from 'components/app/documents/contracts/ContractForm';
import { Guard } from 'hooks/useGuard';

export default () => {
  const { employeeId, contract } = useUser();
  return (
    <Row>
      <Col xs={12}>
        <DomainReportTiles domain="employee-shift" itemId={employeeId} />
      </Col>
      {isContractFlexible(contract) && (
        <Guard roles={['employee-shift']}>
          <Col>
            <UserWorkingHours employeeId={employeeId} />
          </Col>
        </Guard>
      )}
      <Col xs={12}>
        <EmployeeShiftsTable filters={{ employeeId }} />
      </Col>
    </Row>
  );
};
