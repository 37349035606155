import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { checkUserEmails } from 'apis/flex/users';
import SelectedApplicants from './widgets/SelectedApplicants';
import useApplicants from './hooks/useApplicants';
import { Applicant } from 'apis/flex/recruitment';
import useJobRoles from 'components/app/hr/jobRoles/useJobRoles';
import { useFormContext, useFormState } from 'react-hook-form';
import NewEmployeeForm from 'components/app/users/detail/NewEmployeeForm';
import { Link } from 'react-router-dom';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
export const AcceptForm = ({
  ids: accepting = [],
  name = ''
}: {
  ids?: number[];
  name?: '' | `${string}.`;
}) => {
  const { data: applicantData, isLoading: applicantLoading } = useApplicants({
    id: accepting,
    enabled: !!accepting?.length
  });
  const { data: duplicates } = useQuery<
    { email: string; user: boolean }[],
    Error,
    Applicant[]
  >({
    queryKey: ['duplicates', accepting],
    enabled: !!applicantData?.length,
    queryFn: () => checkUserEmails(applicantData?.map(d => d.email)),
    select: d =>
      d
        .filter(d => !!d.user)
        .map(d => applicantData?.find(a => a.email == d.email))
  });
  const { data: jobRole, isLoading: defaultsLoading } = useJobRoles({
    id: applicantData?.[0]?.jobTitleId,
    select: d => d[0]
  });
  const { reset } = useFormContext();
  const { isDirty } = useFormState();

  useEffect(() => {
    if (isDirty) return;
    if (!jobRole) return;
    reset(jobRole.defaults);
  }, [jobRole]);
  const user = useUser();
  return (
    <>
      {jobRole && (
        <p className="form-text">
          Defaults have been set by the{' '}
          <Link
            target="_blank"
            referrerPolicy="no-referrer"
            to={getDomainItemUrl('job-title', user, jobRole)}
          >
            {jobRole?.jobTitle}
          </Link>{' '}
          job role
        </p>
      )}
      <NewEmployeeForm
        name={name}
        includeDates
        isLoading={applicantLoading || defaultsLoading}
      />
      {!!duplicates?.length && (
        <>
          <div className="bg-warning-subtle rounded-4 p-3 form-text text-warning">
            <div className="mb-2">
              <p>
                Some applicants already exist in Flex according to their email
                addresses.
              </p>
              <p>
                These applicants will not have new user accounts created. They
                should log in using their existing account details.
              </p>
              <p>
                Any settings configured above will be applied to their existing
                accounts.
              </p>
            </div>
            <SelectedApplicants ids={duplicates?.map(d => d.id)} />
          </div>
        </>
      )}
    </>
  );
};
