import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, CloseButton, Col, Form, Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Controller } from 'react-hook-form';
import Divider from 'components/common/Divider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ResponsiveModal } from 'components/common/Modals';
import ItemSelectorGroup from './ItemSelectorGroup';
import { groupBy } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { settings } from 'config';
import Truncate from './Truncate';
import DomainIcon from './DomainIcon';
import LockedContent from './LockedContent';
import { FormCheckInputProps } from 'react-bootstrap/esm/FormCheckInput';
import {
  useInputProps,
  WizardFormGroupProps
} from 'components/wizard/WizardInput';
import { SelectorItemData } from './ItemSelectorItem';
import InfiniteScroller from './InfiniteScroller';
import { DebouncedSearchInput } from './Search';
const Item = ({
  className,
  avatar,
  onClick = () => {},
  icon,
  color,
  name,
  info,
  locked,
  tags = null
}) => {
  return (
    <LockedContent
      locked={!!locked}
      tooltip={typeof locked === 'string' && locked}
      className={'h-100'}
    >
      <Flex
        className={classNames(
          'flex-column position-relative align-items-center rounded-3 p-2 w-100 h-100 justify-content-between',
          className
        )}
      >
        {avatar ? (
          <Avatar
            src={avatar}
            className={`status-offline`}
            mediaClass={'border border-3 rounded-circle border-200 bg-100'}
            size="4xl"
          />
        ) : (
          <DomainIcon size="lg" icon={icon} color={color} />
        )}
        <div className="mx-2">{tags}</div>
        <h6 className="fs-1 fw-semi-bold mb-0 mt-2">
          <div className="text-900 stretched-link" onClick={onClick}>
            <Truncate middle>{name}</Truncate>
          </div>
        </h6>
        {info &&
          Object.keys(info)
            .filter(name => !!info[name])
            .filter((_, i) => i < 4)
            .map((name, i) => (
              <span
                key={name}
                className={classNames(
                  'fs--2 lh-1 mb-0 pt-1 text-500 text-center',
                  {
                    'fw-light': i > 0
                  }
                )}
              >
                {info[name]}
              </span>
            ))}
      </Flex>
    </LockedContent>
  );
};
Item.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  color: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.object
};
const domainSettings = settings.domains;
export type SelectorItem<T = any> = {
  icon?: ReactNode;
  label: string;
  avatar?: any;
  info?: Record<string, ReactNode>;
  group?: string;
  value: number | string;
  locked?: boolean | string;
  tags?: ReactNode;
  data?: T;
  subIcon?: ReactNode;
};
const Selector = ({
  children,
  value,
  disabled,
  data,
  placeholder,
  avatar,
  icon,
  color,
  size,
  compact,
  selectedText,
  onNameClick
}: {
  children?: (value: any, disabled: boolean) => ReactNode;
  value: any;
  disabled: boolean;
  data: SelectorItem[];
  placeholder: string;
  avatar: any;
  icon: any;
  color: any;
  size: any;
  compact: boolean;
  selectedText: (value: any) => ReactNode;
  onNameClick: (value: any) => void;
}) =>
  children ? (
    children(value, disabled)
  ) : (
    <ItemSelectorGroup
      data={data}
      selectedText={selectedText}
      avatar={avatar}
      icon={icon}
      placeholder={placeholder}
      color={color}
      size={size}
      compact={compact}
      onNameClick={onNameClick}
    />
  );
const SelectorDataBox = ({
  data: dataItems,
  paging,
  isSelected,
  multiple,
  value,
  onChange,
  formControlProps,
  setShow,
  avatar,
  icon,
  color,
  onChange: handlePageChange
}: {
  data: (SelectorItem | SelectorItem[] | 'loading')[];
  paging?: {
    page: number;
    totalPages: number;
  };
  isSelected: (item: SelectorItem) => boolean;
  multiple: boolean;
  value: any;
  onChange: (v: any) => void;
  formControlProps: any;
  setShow: (show: boolean) => void;
  avatar?: any;
  icon?: any;
  color?: any;
}) => {
  const scrollableData = (paging ? dataItems : [dataItems]) as (
    | 'loading'
    | SelectorItem[]
  )[];
  const totalPages = paging?.totalPages || 1;
  return (
    <InfiniteScroller
      data={scrollableData}
      totalPages={totalPages}
      getNext={() => paging && handlePageChange(paging?.page + 1)}
      className="gy-4 row"
      style={{
        maxHeight: '76vh',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      {({ data }) => (
        <>
          {data?.map((item, i) => (
            <Col key={i} xs={6} xxl={4}>
              <Form.Check
                id={'itemSelect-' + item.value}
                className="p-0 text-center w-100 h-100"
              >
                <Form.Check.Input
                  value={item.value}
                  checked={isSelected(item)}
                  onChange={e => {
                    const c = e.target.checked;
                    const v = e.target.value;
                    const current = multiple ? value || [] : value || null;
                    if (c) {
                      if (!multiple) {
                        onChange(v);
                        setShow(false);
                        return;
                      }
                      onChange(current.concat([v]) || [v]);
                    } else {
                      if (!multiple) {
                        return onChange('');
                      }
                      onChange(
                        current.filter(f => f?.toString() !== v?.toString()) ||
                          []
                      );
                    }
                  }}
                  className="d-none"
                  {...formControlProps}
                />
                <Form.Check.Label className="w-100 h-100 mb-0">
                  <Item
                    locked={item.locked}
                    name={item.label}
                    className={classNames('cursor-pointer w-100 ', {
                      'bg-primary-subtle': isSelected(item)
                    })}
                    info={item.info}
                    avatar={item.avatar || avatar}
                    icon={item.icon || icon}
                    color={color}
                    tags={item.tags}
                  />
                </Form.Check.Label>
              </Form.Check>
            </Col>
          ))}
        </>
      )}
    </InfiniteScroller>
  );
};
export type ItemSelectorProps = {
  name: string;
  data: (SelectorItem | SelectorItem[] | 'loading')[];
  paging?: {
    page: number;
    perPage: number;
    total: number;
    filters: any;
    totalPages: number;
  };
  setPaging?: (data: {
    page: number;
    perPage: number;
    total: number;
    filters: any;
    totalPages: number;
  }) => void;
  multiple?: boolean;
  label?: ReactNode;
  isLoading?: boolean;
  children?: any;
  registerProps?: any;
  placeholder?: string;
  avatar?: string;
  icon?: ReactNode;
  color?: string;
  instruction?: ReactNode;
  onNewClick?: () => void;
  domain?: keyof typeof domainSettings;
  open?: boolean;
  onClose?: () => void;
  confirmText?: string;
  formControlProps?: FormCheckInputProps;
  formGroupProps?: WizardFormGroupProps;
  hideLabel?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  compact?: boolean;
  readOnly?: boolean;
  selectedText?: (data: SelectorItemData[]) => ReactNode;
  onNameClick?: (data: SelectorItemData) => void;
};
const ItemSelector = (props: ItemSelectorProps) => {
  const inputProps = useInputProps(props);
  const {
    name,
    data = [],
    multiple,
    label,
    isLoading,
    children,
    registerProps,
    placeholder,
    avatar,
    icon,
    color = 'primary',
    instruction,
    onNewClick,
    domain,
    open,
    onClose = () => {},
    confirmText = 'Done',
    formControlProps = {},
    formGroupProps = {},
    hideLabel,
    disabled,
    size = 'md',
    compact,
    readOnly,
    selectedText,
    onNameClick,
    paging,
    setPaging,
    prefix,
    // searchFields,
    ...rest
  } = { ...props, ...inputProps };
  const domainIcon = settings.domains[domain]?.icon;
  const domainColor = settings.domains[domain]?.color;
  const [show, setShow] = useState<boolean>();
  useEffect(() => {
    setShow(open);
  }, [open]);
  useEffect(() => {
    if (!show) {
      onClose();
    }
  }, [show]);
  const [search, setSearch] = useState<string>();
  const required = registerProps?.required ?? true;
  const handlePageChange = page => {
    setPaging?.({ ...paging, page });
  };
  useEffect(() => {
    setPaging?.({ ...paging, filters: { search } });
  }, [search, setPaging]);
  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field, fieldState: { error }, formState: { errors } }) => {
        const isSelected = (item: 'loading' | SelectorItem) => {
          return (
            item !== 'loading' &&
            (multiple
              ? Array.isArray(field.value) &&
                field.value?.some(v => v?.toString() == item.value?.toString())
              : field.value?.toString() === item.value.toString())
          );
        };
        const groupDef = groupBy(
          data.flat().filter(d => d !== 'loading') as SelectorItem[],
          f => f.group || 'All'
        );
        const groups = {
          ...groupDef
        };
        const selectorBox = useMemo(
          () => (
            <SelectorDataBox
              data={data}
              onChange={handlePageChange}
              paging={paging}
              isSelected={isSelected}
              multiple={multiple}
              formControlProps={formControlProps}
              setShow={setShow}
              {...field}
              avatar={avatar}
              icon={icon || domainIcon}
              color={color || domainColor}
            />
          ),
          [field, data, paging, multiple, formControlProps, avatar, icon, color]
        );
        const selector = useMemo(
          () => (
            <Selector
              value={field.value}
              data={
                data
                  .flat()
                  .filter(d => d !== 'loading')
                  .filter(d => isSelected(d)) as SelectorItem[]
              }
              disabled={disabled || readOnly}
              onNameClick={onNameClick}
              placeholder={placeholder}
              avatar={avatar}
              icon={icon || domainIcon}
              color={color || domainColor}
              compact={compact}
              selectedText={selectedText}
              size={size}
            />
          ),
          [field, data, disabled, readOnly]
        );
        return (
          <Form.Group
            {...formGroupProps}
            className={classNames(
              { 'mb-3': !formGroupProps?.noMb },
              formGroupProps?.className
            )}
          >
            {label && !hideLabel && (
              <Form.Label aria-required={required}>
                <div className="d-flex">
                  {required && (
                    <p className="d-inline-block text-danger mb-0">*</p>
                  )}
                  <Flex alignItems={'baseline'}>
                    <span>{label}</span>
                    {onNewClick && (
                      <Button
                        size="sm"
                        onClick={onNewClick}
                        variant="link"
                        className="p-0 ms-2"
                      >
                        Add new
                      </Button>
                    )}
                  </Flex>
                </div>
                {instruction && (
                  <div className="form-text lh-base fw-normal flex-1">
                    {instruction}
                  </div>
                )}
              </Form.Label>
            )}
            <div
              {...rest}
              className={classNames('form-control cursor-pointer', {
                'is-invalid': !!error,
                'is-valid': Object.keys(errors).length && !error,
                'bg-200 pointer-events-none': disabled || readOnly
              })}
              // style={{ minHeight: 35 }}
              ref={field.ref}
              onClick={() => !disabled && !readOnly && setShow(true)}
            >
              {isLoading ? <Skeleton /> : selector}
            </div>
            <Form.Control.Feedback type="invalid">
              {error?.message}
            </Form.Control.Feedback>
            <ResponsiveModal wide show={show} onHide={() => setShow(false)}>
              <Modal.Body className="position-relative">
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    //   setShow(false);
                  }}
                >
                  {/* {searchFields && ( */}
                  <div style={{ width: '90%' }} className="search-box">
                    <DebouncedSearchInput autoFocus onChange={setSearch} />
                  </div>
                  {/* )} */}
                  <CloseButton
                    className="position-absolute end-0 top-0 mt-2 me-2"
                    onClick={() => setShow(false)}
                  />

                  {paging
                    ? selectorBox
                    : Object.keys(groups).map(g => (
                        <div key={g}>
                          <Divider>
                            <h6>{g}</h6>
                          </Divider>
                          {selectorBox}
                        </div>
                      ))}
                </Form>
                {/* {paging && (
                  <PaginationComponent
                    data={data as SelectorItem[][]}
                    total={paging.total}
                    perPage={paging.perPage}
                    onChange={setPaging}
                  />
                )} */}
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                {selector}
                <div>
                  <Button onClick={() => setShow(false)} variant={'link'}>
                    Cancel
                  </Button>
                  <Button onClick={() => setShow(false)}>{confirmText}</Button>
                </div>
              </Modal.Footer>
            </ResponsiveModal>
          </Form.Group>
        );
      }}
    />
  );
};

export default ItemSelector;
