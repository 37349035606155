import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import companyConfig from 'companyConfig';
import { ApiError } from 'apis/errors';
export const MissingPermissions = ({
  permissions: _permissions,
  error
}: {
  permissions?: string[];
  error?: ApiError;
}) => {
  const permissions = _permissions || error?.response.data?.requiredPermissions;
  return (
    <div className="text-700">
      One or more of the following permissions are needed:{' '}
      {permissions.map(p => {
        return <SoftBadge key={p}>{p}</SoftBadge>;
      })}
    </div>
  );
};
const Error403 = ({
  error,
  button,
  permissions: _permissions
}: {
  error?: ApiError;
  button?: ReactNode;
  permissions?: string[];
}) => {
  const permissions = _permissions || error?.response.data?.requiredPermissions;
  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">403</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Unauthorised!
        </p>
        <hr />
        <p>Your account is not authorised to view this content</p>
        {!!permissions && (
          <>
            <hr />
            <MissingPermissions permissions={permissions} />
          </>
        )}
        {!!error?.message && (
          <>
            <hr />
            <p className="text-700">The error received was {error.message}.</p>
          </>
        )}
        <p>
          {' '}
          If you believe this is an error, please
          <a href={'mailto:' + companyConfig.supportEmail} className="ms-1">
            contact us
          </a>
          .
        </p>
      </Card.Body>
      {button && <Card.Footer>{button}</Card.Footer>}
    </Card>
  );
};
export default Error403;
