import { Card, Col, ColProps, Row } from 'react-bootstrap';
import React, { ReactNode, useMemo } from 'react';
import { Link, Params, useMatches, useNavigate } from 'react-router-dom';
import { motion, useAnimate } from 'framer-motion';
import { transitions } from 'helpers/animations';
import { useState } from 'react';
import { useEffect } from 'react';
import { SearchInput } from 'components/navbar/top/SearchBox';
import Fuse from 'fuse.js';
import useUserRoles from 'hooks/useUserRoles';
import classNames from 'classnames';
import DomainIcon from './DomainIcon';

export const QuickLink = ({
  item,
  index,
  setSelected,
  selected = null,
  size = 'md',
  ...rest
}: {
  item: RouteHandle;
  index: number;
  setSelected?: () => void;
  selected?: number;
  size?: 'md' | 'sm';
} & Omit<ColProps, 'children' | 'size'>) => {
  const [scope, animate] = useAnimate();
  const nav = useNavigate();
  const handleClick = () => {
    // console.log('clicked', setSelected, item);
    if (setSelected) {
      setSelected();
      //allow animation to complete before navigating away
      //would be best to start page load at this point, but haven't worked it out yet
      setTimeout(() => nav(item.path), 200);
    } else {
      nav(item.path);
    }
  };
  useEffect(() => {
    //when an option has been selected, ignore it if the animation has already begun
    if (selected !== null && selected !== index) {
      animate(
        scope.current,
        { scale: 0.8, opacity: 0 },
        { delay: 0, duration: 0.15 }
      );
    }
  }, [selected]);
  return (
    <Col
      md={size === 'md' ? 6 : 4}
      xxl={size === 'md' ? 4 : 3}
      className="overflow-hidden tour-quick-link"
      data-tour={item.name}
      {...rest}
      onClick={handleClick}
    >
      <motion.div
        className={classNames(
          'hover-bg-100 text-center rounded-3 position-relative cursor-pointer d-flex flex-column align-items-center',
          { 'mt-3': size === 'md', 'mt-2': size === 'sm' }
        )}
        initial={{ opacity: 0, y: '-100%' }}
        animate={{ opacity: 1, y: 0 }}
        transition={transitions.lightBounceStagger(index)}
        ref={scope}
      >
        <DomainIcon
          size={size}
          domain={item.domain}
          icon={item.icon}
          // defaultColor={item.color}
        />
        <h5
          className={classNames({
            'mt-3 mb-2': size === 'md',
            'mt-2 mb-1 fs-0': size === 'sm'
          })}
        >
          <span className="text-900 hover-primary stretched-link">
            {item.name}
          </span>
          <div className="w-auto m-auto fs--1 text-500 fw-normal mt-1">
            {item.breadcrumbTexts &&
              item.breadcrumbTexts
                .filter((c, i) => i < item.breadcrumbTexts.length - 1)
                .join(' > ')}
          </div>
        </h5>
        <h6 className="w-75 mx-auto text-600 mb-x1">{item.description}</h6>
      </motion.div>
    </Col>
  );
};
type RouteHandle = {
  children?: any;
  icon?: ReactNode;
  path?: string;
  name?: string;
  breadcrumbTexts?: string[];
  domain?: string;
  menu?: boolean;
  description?: string;
};
type RouteMatch = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: RouteHandle;
};
const getChildren = (r): RouteHandle[] => r?.handle?.children || [];
const useRouteSearchItems = ({ scoped = true } = {}) => {
  const matches: RouteMatch[] = useMatches();
  const thisRoute = scoped ? matches[matches.length - 2] : matches[2];
  const children = getChildren(thisRoute);
  const icon = thisRoute?.handle?.icon;
  const mapChild = (
    child: RouteHandle,
    parent: RouteHandle = { icon }
  ): RouteHandle => ({
    ...child,
    breadcrumbTexts: (parent?.breadcrumbTexts || []).concat(child.name),
    path: (parent?.path ? parent.path + '/' : '') + child.path,
    icon: child.icon || parent?.icon,
    domain: child.domain || parent?.domain
  });
  const { isAllowed } = useUserRoles();
  const isLinkable = c =>
    !c.index &&
    c.path &&
    c.name &&
    c.path.indexOf(':') === -1 &&
    (c.element || (c.children && c.children[0].index)) &&
    isAllowed(c.roles) &&
    (!c.children || c.children.some(c => !c.index || c.element.type));

  //items to show without any search input; direct children of current route
  const defaultItems = children
    .filter(isLinkable)
    .filter(c => c.menu !== false)
    .map(c => mapChild(c));
  const recurse = (
    children: RouteHandle[],
    parent: RouteHandle = null
  ): RouteHandle[] => {
    return (
      children
        // .filter(isLinkable)
        .map(child => {
          const links = [];
          if (isLinkable(child)) {
            links.push(mapChild(child, parent));
          }
          const subChildren = getChildren(child);
          if (subChildren.length) {
            return links.concat(recurse(subChildren, mapChild(child, parent)));
          }
          return links;
        })
        .flat(2)
    );
    // .filter(c => c);
  };

  //get only children who point to an indexable page, that the user is allowed to view
  const allChildren = recurse(getChildren(thisRoute));
  // console.log('quicklinks routes', { thisRoute, children, allChildren });

  return useMemo(() => ({ defaultItems, allItems: allChildren }), [matches]);
};
export const useRouteSearch = ({
  search,
  limit = 4,
  scoped = true,
  showDefault = true
}: {
  search: string;
  limit?: number;
  scoped?: boolean;
  showDefault?: boolean;
}) => {
  const { defaultItems, allItems } = useRouteSearchItems({ scoped });
  const fuseJsOptions = {
    includeScore: true,
    keys: ['description', 'name'],
    includeMatches: true,
    minMatchCharLength: 4,
    ignoreLocation: true
  };
  const searcher = useMemo(() => new Fuse(allItems, fuseJsOptions), [allItems]);
  const searchResult = searcher.search(search, {
    limit
  });
  return useMemo(
    () =>
      searchResult.length
        ? searchResult.map(item => item.item)
        : showDefault
        ? defaultItems
        : [],
    [searchResult, defaultItems]
  );
};
const QuickLinks = ({
  title,
  items
}: {
  title?: string;
  items?: RouteHandle[];
}) => {
  const [search, setSearch] = useState('');
  const results = useRouteSearch({ search });

  const [selected, setSelected] = useState<number>(null);
  const matches: RouteMatch[] = useMatches();
  const thisRoute = matches[matches.length - 2];
  const nav = useNavigate();
  return (
    // thisRoute.handle.path.indexOf(':id') === -1 && (
    results.length > 0 ? (
      <Card className="bg-100 border shadow-none my-3 quick-links">
        {title && (
          <Card.Header className="border-bottom border-200">
            <h6 className="mb-0">{title}</h6>
          </Card.Header>
        )}
        <Card.Body>
          <>
            <div className="w-100 search-box">
              <SearchInput
                value={search}
                setValue={setSearch}
                placeholder={
                  thisRoute.handle?.name !== 'Home'
                    ? 'Search in ' + thisRoute.handle?.name + '...'
                    : 'Search...'
                }
              />
            </div>
            <Row className="gx-3 justify-content-around">
              {results.map((item, index) => (
                <QuickLink
                  key={index}
                  selected={selected}
                  setSelected={() => setSelected(index)}
                  item={item}
                  index={index}
                />
              ))}
            </Row>
          </>
        </Card.Body>
      </Card>
    ) : (
      <div>
        {/* <div>That's all for now!</div>
            <div className="text-end">
              <Link className="mt-3" onClick={() => nav(-1)} to={'#'}>
                <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
                Go back
              </Link>
            </div> */}
      </div>
    )
    // )
  );
};
export default QuickLinks;
