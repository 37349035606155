import { ticketsApi } from "apis/flex/support";
import {
  defaultCrudHookBuilder
} from "hooks/defaultCrud/useDefaultCrud";
const useTickets = defaultCrudHookBuilder("Tickets", ticketsApi);
export const useOpenTickets = ({ ...props }) => useTickets({
  ...props,
  filters: [
    [
      { first: "status", negate: true, second: "Done" },
      { first: "status", second: "null" }
    ]
  ]
});
export const useClosedTickets = ({
  ...props
}) => useTickets({
  ...props,
  filters: [{ first: "status", second: "Done" }]
});
export default useTickets;
