import React from 'react';
import classNames from 'classnames';

const Divider = ({
  className,
  children,
  bg = null
}: {
  className?: string;
  children?: React.ReactNode;
  bg?: number | string;
}) => (
  <div
    className={classNames(
      'w-100 position-relative text-center mb-2',
      className
    )}
  >
    <hr className="text-300" />
    <div className={`divider-content-center ${bg ? 'bg-' + bg : ''}`}>
      {children}
    </div>
  </div>
);

export default Divider;
