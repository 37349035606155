import { FileId } from 'apis/types';
import classNames from 'classnames';
import ModalVideoContent from 'components/app/recruitment/campaigns/ModalVideoContent';
import FileImage from 'components/files/FileImage';
import useArray from 'hooks/useArray';
import React from 'react';

export default ({
  fileIds,
  imgProps,
  disabled,
  className,
  width,
  height,
  ...rest
}: {
  width?: string | number;
  height?: string | number;
  fileIds: FileId[];
  disabled?: boolean;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const [showGallery, setShowGallery] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const fileIdArr = useArray(fileIds);
  return (
    <div
      className={classNames(
        'cursor-pointer d-flex flex-wrap gap-2 w-100 align-items-center justify-content-center p-2',
        className
      )}
      {...rest}
    >
      <ModalVideoContent
        attachment={fileIdArr?.map(id => ({ fileId: id }))}
        show={showGallery}
        setShow={setShowGallery}
        galleryIndex={index}
      />
      {fileIds.map((id, index) => (
        <div
          key={id}
          className="img-thumbnail d-block m-auto shadow-sm rounded-4"
        >
          <FileImage
            fileId={id}
            width={width || 'auto'}
            height={height || 'auto'}
            className={classNames(imgProps?.className)}
            {...imgProps}
            //stops the inbuilt lightbox showing
            disabled
            onClick={() => {
              setIndex(index);
              !disabled && setShowGallery(true);
            }}
          />
        </div>
      ))}
    </div>
  );
};
