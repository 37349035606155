import DetailCard from 'components/common/detail/DetailCard';
import React from 'react';
import { useParams } from 'react-router-dom';
import TargetGroupsTable from './TargetGroupsTable';
import useProjects from '../useProjects';

export default () => {
  const { projectId } = useParams();
  const { data: project, isLoading } = useProjects({
    id: Number(projectId),
    columns: ['name', 'quoteRef']
  });
  return (
    <DetailCard
      isLoading={isLoading}
      title={project ? project?.[0].quoteRef + ' ' + project?.[0].name : ''}
      id="target-groups"
    >
      <TargetGroupsTable
        projectId={Number(projectId)}
        targetGroups={project?.[0].targetGroups}
      />
    </DetailCard>
  );
};
