import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import useResourceValues from './hooks/useResourceValues';
import { useUser } from 'hooks/useUser';
import { useState } from 'react';
import WizardInput from 'components/wizard/WizardInput';
import IconButton from 'components/common/IconButton';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useDetailPage } from 'components/common/detail/DetailPage';

export default ({ onFinished = () => {}, index }) => {
  const { setValue, getValue } = useResourceValues({ index });
  const { submit } = useDetailPage();
  const { id } = useUser();
  const ensureFinishDate = () => {
    //because a manager can approve/fail an unfinished resource, and other checks rely on 'finishedDate'
    if (!getValue('finishedDate')) {
      setValue('finishedDate', getValue('lastSubmittedDate'));
    }
  };
  const handleFail = () => {
    ensureFinishDate();
    setValue('approvedDate', null);
    setValue('approvedBy', null);
    setValue('failedDate', new Date());
    setValue('failedBy', id);
    submit();
    onFinished();
  };
  const handleApprove = () => {
    ensureFinishDate();
    setValue('failedDate', null);
    setValue('failedBy', null);
    setValue('failedReason', null);
    setValue('approvedDate', new Date());
    setValue('approvedBy', id);
    submit();
    onFinished();
  };
  const [showReason, setShowReason] = useState(false);
  return (
    <>
      <IconButton
        disabled={getValue('failedDate')}
        variant="danger"
        icon={faThumbsDown}
        onClick={() => setShowReason(true)}
      >
        Fail
      </IconButton>
      <IconButton
        disabled={getValue('approvedDate')}
        icon={faThumbsUp}
        variant="success"
        onClick={handleApprove}
      >
        Approve
      </IconButton>
      <Modal show={showReason} onHide={() => setShowReason(false)}>
        <Modal.Body>
          <WizardInput
            type="textarea"
            label="Reason for failure (optional)"
            instruction={'If given, this will be shown to the trainee'}
            name={`resources.${index}.failedReason`}
            registerProps={{ required: false }}
          />
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button variant="link" onClick={() => setShowReason(false)}>
            Cancel
          </Button>
          <Button onClick={handleFail}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
