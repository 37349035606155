import React from 'react';
import { UserGuide } from 'apis/flex/support';
import DomainDetail from 'components/common/DomainDetail';
import useUserGuides from './useUserGuides';
import DetailCard from 'components/common/detail/DetailCard';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import DetailPageIntro from 'components/common/DetailPageIntro';
import { Link } from 'react-router-dom';
import VideoPlayer from 'components/common/VideoPlayer';
import { getMediaStreamUrl } from 'helpers/utils';
import DetailSidebar from 'components/common/detail/DetailSidebar';
import DomainBadge from 'components/common/DomainBadge';
import { EventPrefix } from 'apis/flex/notifications';
import { PDFButton } from 'components/app/documents/PDFCreator';
import { getTextFromHtml } from 'helpers/strings';
import useUsers from 'components/app/users/hooks/useUsers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
const PdfDownload = () => {
  const data = useWatch<UserGuide>();
  const { data: author } = useUsers({
    id: data?.createdBy,
    select: d => d[0]
  });
  return (
    <>
      {data && (
        <PDFButton
          toc
          title={'User Guide: ' + data.title}
          subtitle={getTextFromHtml(data.description)}
          sections={(data.sections || []).map(sec => ({
            title: sec.title,
            description: sec.description,
            content: getTextFromHtml(sec.content)
          }))}
          author={author?.firstName + ' ' + author?.surname}
          creationDate={data.createdDate}
          url={window.location.href.split('#')[0].split('?')[0]}
          version={data.version}
        />
      )}
    </>
  );
};
export default () => {
  return (
    <DomainDetail
      crudHook={useUserGuides}
      tags={d =>
        d?.domains?.map(dom => (
          <DomainBadge key={dom} domain={dom as EventPrefix} />
        ))
      }
      domain="user-guide"
      readOnly
      headerChildren={<PdfDownload />}
    >
      <Col>
        <Row>
          <Col xl={8} className={'order-1 order-xl-0'}>
            <Intro />
            <Sections />
          </Col>
          <Col xl={4} className={'order-0 order-xl-1'}>
            <DetailSidebar title="Table of Contents">
              <TOC />
            </DetailSidebar>
          </Col>
        </Row>
      </Col>
    </DomainDetail>
  );
};
const Intro = () => {
  const data = useWatch<UserGuide>();
  return (
    <DetailPageIntro description={data?.description}>
      {data.videoFileId && (
        <div className="mt-3">
          <h6>Video</h6>
          <VideoPlayer url={getMediaStreamUrl(data.videoFileId)} />
        </div>
      )}
    </DetailPageIntro>
  );
};
const TOC = () => {
  const data = useWatch<UserGuide>();
  const scrollToElement = id => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title className="text-center">Table of Contents</Card.Title>
        <Card.Text className="d-flex flex-column flex-wrap">
          <ol>
            {(data?.sections || []).map(sec => (
              <Link
                key={sec.id}
                to={`#section${sec.id}`}
                onClick={() => scrollToElement(`section${sec.id}`)}
              >
                <li className="mb-2">{sec.title}</li>
              </Link>
            ))}
          </ol>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
const Sections = () => {
  const data = useWatch<UserGuide>();
  return (
    <>
      {(data?.sections || []).map((sec, i) => (
        <DetailCard
          forceId
          key={sec.id}
          id={`section${sec.id}`}
          title={sec.title}
          subtitle={sec.description}
          buttons={
            <Button
              variant="link"
              size="sm"
              onClick={() => window.scrollTo(0, 0)}
            >
              <FontAwesomeIcon icon={faChevronUp} className="me-1" />
              Back to top
            </Button>
          }
        >
          {sec.videoFileId && (
            <VideoPlayer url={getMediaStreamUrl(sec.videoFileId)} />
          )}
          <div dangerouslySetInnerHTML={{ __html: sec.content }} />
        </DetailCard>
      ))}
    </>
  );
};
