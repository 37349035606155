import { useEffect, useMemo, useState } from "react";
import useStages from "./useStages";
export default ({
  campaignId,
  stages: _stages
}) => {
  const [items, setItems] = useState([]);
  const { data } = useStages({
    filters: { campaignId: Number(campaignId) },
    useFilter: true,
    enabled: !!campaignId && !_stages
  });
  const stages = useMemo(() => _stages || data, [data, _stages]);
  const basicItems = [
    {
      title: "New",
      filter: { acceptReject: "null", stageId: "null" }
    },
    {
      title: "Accepted",
      filter: { acceptReject: "accepted" }
    },
    {
      title: "Rejected",
      filter: { acceptReject: "rejected" }
    }
  ];
  useEffect(() => {
    const newNavItems = [...basicItems];
    stages?.forEach((stage) => {
      newNavItems.splice(1, 0, {
        title: stage.name,
        filter: { acceptReject: "null", stageId: stage.id }
      });
    });
    setItems([...newNavItems]);
  }, [stages]);
  return items;
};
