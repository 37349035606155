import { useState, useEffect, useMemo } from "react";
import useResizeObserver from "./useResizeObserver";
import useMainRef from "./useMainRef";
export const useBreakpoints = () => {
  const gridBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1540
  };
  const [widthToCheck, setWidthToCheck] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [breakpoints, setBreakpoints] = useState({
    up: (bp) => {
      return width > gridBreakpoints[bp];
    },
    down: (bp) => {
      return width < gridBreakpoints[bp];
    }
  });
  useEffect(() => {
    if (widthToCheck !== width) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, [widthToCheck]);
  const mainRef = useMainRef();
  useResizeObserver(mainRef, (box) => {
    if (!box) return;
    setWidthToCheck(box.width);
  });
  useEffect(() => {
    setBreakpoints({
      up: (bp) => width >= gridBreakpoints[bp],
      down: (bp) => width < gridBreakpoints[bp]
    });
  }, [width]);
  return useMemo(
    () => ({ width, height, breakpoints }),
    [width, height, breakpoints]
  );
};
