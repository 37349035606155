import DomainReportTiles from 'components/app/reporting/DomainReportTiles';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import EmployeeShiftsTable from './EmployeeShiftsTable';

export default () => {
  return (
    <Row>
      <Col xs={12}>
        <DomainReportTiles domain="employee-shift" />
      </Col>
      <Col xs={12}>
        <EmployeeShiftsTable />
      </Col>
    </Row>
  );
};
