import { LeaveType } from 'apis/flex/hr';
import DomainTable from 'components/common/DomainTable';
import React from 'react';

export default () => {
  return (
    <DomainTable<LeaveType>
      domain="leave-type"
      columns={[
        'name',
        'requestable',
        'paid',
        'allowanceDays',
        'annualIncrease',
        'maxAllowanceDays',
        'createdBy',
        'createdDate'
      ]}
    />
  );
};
