import api from ".";
import CrudApi from "./CrudApi";
const baseUrl = "pm/";
export const clientsApi = new CrudApi(baseUrl + "clients");
export const clientContactsApi = new CrudApi(
  baseUrl + "clients/contacts"
);
export const costCentersApi = new CrudApi(baseUrl + "costcenters");
export const projectsApi = new CrudApi(baseUrl + "projects");
export const quotesApi = new CrudApi(baseUrl + "quotes");
export const questionnaireApi = new CrudApi(
  baseUrl + "questionnaires"
);
export const targetGroupsApi = new CrudApi(
  baseUrl + "target-groups"
);
export const resourceGroupsApi = new CrudApi(
  baseUrl + "resource-groups"
);
export const getResourceGroupSchedule = ({
  filters,
  id
}) => api.get(
  `${baseUrl}resource-groups/${id}/schedule`,
  { params: { filters } }
).then((d) => d.data);
export const teamRolesApi = new CrudApi(baseUrl + "team-roles");
export const projectRolesApi = new CrudApi(
  baseUrl + "project-roles"
);
export const targetGroupStatsApi = {
  rpu: ({
    id,
    target,
    costCenters
  }) => api.get(`${baseUrl}target-groups/stats/${id}/rpu`, {
    params: { target, costCenters }
  }).then((d) => d.data)
};
export const getResourcePlan = ({ filters }) => api.get(`${baseUrl}resource`, { params: { filters } }).then((d) => d.data);
export const refreshResourcePlan = ({
  startDate,
  endDate
} = {}) => api.post(`${baseUrl}resource`, { startDate, endDate }).then((d) => d.data);
