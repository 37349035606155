import React, { useEffect } from 'react';
import CustomFormWizard, {
  CustomFormWizardProps
} from 'components/common/customForms/Wizard/CustomFormWizard';
import PropTypes from 'prop-types';
import useCustomForm from './hooks.js/useCustomForm';
import { Form } from 'apis/flex/customForms';
import { OnWizardSubmit } from 'components/wizard/FormWizard';
import { Card, Col, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import Lottie from 'lottie-react';
import comingSoon from 'assets/img/animated-icons/coming-soon.json';
import locked from 'assets/img/animated-icons/locked.json';
import usePublicCampaign from 'components/app/recruitment/campaigns/hooks/usePublicCampaign';
import Error500 from 'components/errors/Error500';
import useApplicantStages from 'components/app/recruitment/applicants/hooks/useApplicantStages';
import Countdown from '../Countdown';
import { useUser } from 'hooks/useUser';

export const FormClosed = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <Lottie animationData={locked} />
          </Col>

          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
            <h4>Closed</h4>
            <p>
              It looks like this form is now closed. Thank you for your interest
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
/**
 *Responsible only for loading a custom form and handling submit functionality
 **/
const CustomFormClient = ({
  formId,
  onSubmitted = ({ done }) => done(),
  onScreenout = ({ goToNext }) => goToNext(),
  meta,
  formMutator = (form: Form) => form,
  isLoading: parentLoading,
  recaptcha,
  ...rest
}: Omit<CustomFormWizardProps, 'onSubmit' | 'form'> & {
  onSubmitted?: OnWizardSubmit;
  formId: number;
  formMutator?: (form: Form) => Form;
  meta?: {
    campaignId: number;
    campaignType: string;
    applicantStageId: number;
    domainItemId?: number;
  };
}) => {
  const {
    handleSubmit: _handleSubmit,
    form,
    isLoading
  } = useCustomForm({
    formId: formId,
    enabled: !!formId,
    meta,
    staleTime: Infinity
  });

  const {
    data: campaign,
    isLoading: campaignLoading,
    error
  } = usePublicCampaign({
    id: meta?.campaignId,
    staleTime: Infinity,
    meta: false
  });
  const {
    data: stage,
    error: stageError,
    isLoading: stageLoading
  } = useApplicantStages({
    id: meta?.applicantStageId,
    select: d => d[0],
    getPublic: true
  });
  const startDates = [campaign?.startDate, form?.startDate];
  const endDates = [campaign?.endDate, stage?.dueDate, form?.endDate];
  const openDate = startDates
    .filter(Boolean)
    //latest date
    .sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf())[0];
  const closeDate = endDates
    .filter(Boolean)
    //earliest date
    .sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf())[0];
  if (closeDate && new Date() > new Date(closeDate)) {
    return <FormClosed />;
  }
  if (campaign?.maxApplicantsReached) {
    return <FormClosed />;
  }
  return (
    <>
      {(!!meta?.campaignId && !!error) ||
      (!!meta?.applicantStageId && !!stageError) ? (
        <Error500
          error={{
            ...error,
            ...stageError,
            message: 'Could not load campaign'
          }}
        />
      ) : (
        <>
          {!!openDate && new Date() < new Date(openDate) ? (
            <Card>
              <Card.Body className="overflow-hidden p-lg-6">
                <Row className="align-items-center justify-content-between">
                  <Col lg={6}>
                    <Lottie animationData={comingSoon} />
                  </Col>
                  <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
                    <Countdown
                      endDate={new Date(openDate)}
                      format={['days', 'hours']}
                    >
                      {duration => <h4>Ready in {duration}</h4>}
                    </Countdown>
                    <p className="lead">
                      This form isn't available until{' '}
                      {format(new Date(openDate), 'dd/MM/yyyy')}. Please check
                      back later
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : (
            <CustomFormWizard
              form={formMutator(form)}
              continueOnScreenout={!form?.exitScreenouts}
              isFormLoading={
                isLoading ||
                parentLoading ||
                (campaignLoading && !!meta?.campaignId) ||
                (stageLoading && !!meta?.applicantStageId)
              }
              companyId={form?.companyId}
              storeId={'CustomForm-' + formId}
              recaptcha={
                recaptcha === false
                  ? false
                  : recaptcha || (form && !form?.requireLogin)
              }
              onSubmit={props => {
                _handleSubmit(
                  props.data,
                  props.screenoutIds.map(id => Number(id)),
                  resp => {
                    props.setAuthItemId(resp.id, () => {
                      onSubmitted({
                        ...props,
                        data: resp,
                        setAuthItemId: id => props.setAuthItemId(id)
                      });
                    });
                  },
                  err => {
                    console.log(err, 'error heard in customformclient');
                    props.err(err);
                  }
                );
              }}
              onScreenout={onScreenout}
              {...rest}
            />
          )}
        </>
      )}
    </>
  );
};
CustomFormClient.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleScreenout: PropTypes.func
};
export default CustomFormClient;
