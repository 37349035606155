import classNames from 'classnames';
import ModalVideoContent from 'components/app/recruitment/campaigns/ModalVideoContent';
import React, { HTMLProps } from 'react';

export default ({
  src,
  onClick,
  disabled,
  className,
  children,
  ...props
}: Omit<HTMLProps<HTMLImageElement>, 'onClick'> & {
  onClick?: (e?: any) => void;
}) => {
  const [showGallery, setShowGallery] = React.useState(false);
  return (
    <>
      <ModalVideoContent
        attachment={{ image: src }}
        show={showGallery}
        setShow={setShowGallery}
      >
        {children}
      </ModalVideoContent>
      <div
        onClick={() => {
          onClick && onClick();
          !disabled && setShowGallery(true);
        }}
        className={classNames(
          'cursor-pointer rounded-3 overflow-hidden',
          className
        )}
      >
        {children || <img src={src} {...props} />}
      </div>
    </>
  );
};
