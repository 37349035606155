import api from ".";
import { cleanFilePath } from "helpers/utils";
import {
  create,
  keyResolver,
  windowScheduler
} from "@yornaath/batshit";
export default class CrudApi {
  constructor(baseUrl, options = {}) {
    this.baseUrl = baseUrl;
    this.options = options;
    this.#baseUrl = cleanFilePath(baseUrl) + "/";
    this.batcher = create({
      resolver: keyResolver("id"),
      scheduler: windowScheduler(1e3),
      name: cleanFilePath(baseUrl),
      fetcher: async (ids) => {
        return this.getByIds(ids.map(Number));
      }
    });
    this.publicBatcher = create({
      resolver: keyResolver("id"),
      scheduler: windowScheduler(1e3),
      name: cleanFilePath(baseUrl),
      fetcher: async (ids) => {
        return this.getPublicByIds(ids.map(Number));
      }
    });
    this.listBatcher = create({
      resolver: keyResolver("id"),
      scheduler: windowScheduler(1e3),
      name: cleanFilePath(baseUrl),
      fetcher: async (ids) => {
        return this.getByIds(ids.map(Number), true);
      }
    });
  }
  #baseUrl;
  batcher;
  publicBatcher;
  listBatcher;
  options;
  getOne = async (id, aggregations) => {
    return api.get(this.#baseUrl + id, { params: { aggregations } }).then((d) => d.data);
  };
  getByIds = async (ids, list) => {
    return api.get(this.#baseUrl + (list ? "list" : ""), {
      params: {
        filters: { first: "id", second: ids },
        includeDeleted: true
      }
    }).then((d) => d.data);
  };
  getPublicByIds = async (ids) => {
    return api.get(this.#baseUrl + "public", {
      params: {
        filters: { first: "id", second: ids },
        includeDeleted: true
      }
    }).then((d) => d.data);
  };
  get = async (filters, sort, customFilter, paging, includeDeleted, aggregations, columns, availableWith) => {
    return api.get(this.#baseUrl, {
      params: {
        filters,
        includeDeleted,
        paging,
        customFilter,
        sort,
        aggregations,
        columns,
        availableWith
      }
    }).then((d) => d.data);
  };
  getList = async (filters, sort, customFilter, paging, includeDeleted, aggregations, columns, availableWith) => {
    return api.get(this.#baseUrl + "list", {
      params: {
        filters,
        includeDeleted,
        paging,
        customFilter,
        sort,
        aggregations,
        columns,
        availableWith
      }
    }).then((d) => d.data);
  };
  counts = async (by, filters, allowedOnly, includeDeleted, customFilter) => {
    return api.get(
      this.#baseUrl + (allowedOnly ? "allowed-counts" : "counts"),
      {
        params: { filters, includeDeleted, by, customFilter }
      }
    ).then((d) => d.data);
  };
  getPublic = async (filters, sort, customFilter, paging, includeDeleted, aggregations, columns, availableWith) => {
    return api.get(this.#baseUrl + "public", {
      params: {
        filters,
        includeDeleted,
        paging,
        customFilter,
        sort,
        aggregations,
        columns,
        availableWith
      }
    }).then((d) => d.data);
  };
  insert = async (data, noReturn) => {
    return api.post(
      this.#baseUrl,
      { data, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  selfInsert = async (data, noReturn) => {
    return api.post(
      this.#baseUrl + "me",
      { data, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  clone = async (id, noReturn) => {
    return api.post(
      this.#baseUrl + id + "/clone",
      { noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  insertBulk = async (data, noReturn, self) => {
    return api.post(
      this.#baseUrl + (self ? "me/" : "") + "bulk",
      { data, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  update = async (id, data, noReturn) => {
    return api.patch(
      this.#baseUrl + id,
      { data, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  updateBulk = async (filters, data, noReturn) => {
    return api.patch(
      this.#baseUrl,
      { data, filters, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  selfUpdate = async (id, data, noReturn) => {
    return api.patch(
      this.#baseUrl + "me/" + id,
      { data, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  remove = async (id) => {
    return api.delete(this.#baseUrl + id, { headers: { "x-savetransaction": "true" } }).then((d) => d.data);
  };
  bulkRemove = async (ids, self) => {
    return api.delete(this.#baseUrl + (self ? "me/" : "") + "bulk", {
      data: { ids },
      headers: {
        "x-savetransaction": this.options.saveTransactions ?? "true"
      }
    }).then((d) => d.data);
  };
  bulkRestore = async (filters, self, noReturn) => {
    return api.patch(
      this.#baseUrl + "restore" + (self ? "/me" : ""),
      { filters, noReturn },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    ).then((d) => d.data);
  };
  meta = async () => {
    return api.get(this.#baseUrl + "meta").then((d) => d.data);
  };
  availability = async ({ startDate, endDate, params }) => {
    return api.get(
      this.#baseUrl + "availability",
      {
        params: {
          startDate,
          endDate,
          params
        }
      }
    ).then((d) => d.data);
  };
  refireEvents = async (filters, eventAction) => {
    return api.post(
      this.#baseUrl + "refire",
      { filters, eventAction },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    );
  };
  addTags = async (itemIds, tagIds) => {
    return api.post(
      this.#baseUrl + "/tags",
      { itemIds, tagIds },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    );
  };
  removeTags = async (itemIds, tagIds) => {
    return api.delete(this.#baseUrl + "/tags", {
      params: { itemIds, tagIds },
      headers: { "x-savetransaction": this.options.saveTransactions ?? "true" }
    });
  };
  addListValue = async (field, value) => {
    return api.post(
      this.#baseUrl + "/list-value",
      { field, value },
      {
        headers: {
          "x-savetransaction": this.options.saveTransactions ?? "true"
        }
      }
    );
  };
}
