import React from 'react';
import { WizardInputComponent } from 'components/wizard/WizardInput';
import WizardSelect from './WizardSelect';
import { uniq, uniqBy } from 'lodash';
import { ServiceMeta } from 'apis/flex/CrudApi';
const WizardCrudList: WizardInputComponent<{
  crudHook: any;
}> = ({ pluginProps, renderProps, name, options, ...rest }) => {
  const mutation = pluginProps?.crudHook();
  const meta: ServiceMeta = mutation?.meta;
  const [tempOptions, setTempOptions] = React.useState([]);
  const handleCreate = (val: string) => {
    setTempOptions(uniq([...tempOptions, { value: val, label: val }]));
    mutation?.addListValueAsync({ field: name, value: val });
    renderProps.form.field.onChange(val);
  };
  const column = meta?.columns.find(c => c.COLUMN_NAME === name);
  console.log('meta', meta, name, 'column', column);
  return (
    <>
      <WizardSelect
        type="select"
        renderProps={renderProps}
        options={uniqBy<any>(
          (column?.options || []).concat(tempOptions),
          'value'
        )}
        pluginProps={{
          onCreate: handleCreate
        }}
        name={name}
        {...rest}
      />
    </>
  );
};
export default WizardCrudList;
