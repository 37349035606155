import React, { useState } from 'react';
import { TrainingCourse } from 'apis/flex/hr';
import { getMediaStreamUrl } from 'helpers/utils';
import coverSrc from 'assets/img/generic/training.jpg';
import DomainAvatar from 'components/common/DomainAvatar';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { CourseEnrollModal } from './Enroll';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import DomainTable from 'components/common/DomainTable';
import { formatDuration } from 'date-fns';
const CoursesTable = () => {
  const [showEnroll, setShowEnroll] = useState<{
    courseIds: number[];
    userIds?: number[];
  }>(null);
  return (
    <DomainTable<TrainingCourse>
      domain="training-course"
      columns={[
        {
          id: 'profileBackground',
          header: ' ',
          maxSize: 35,
          cellProps: {
            className: 'ps-2 pe-0'
          },
          headerProps: {
            className: 'ps-2 pe-0'
          },
          enablePinning: false,
          enableColumnFilter: false,
          formatter: d => {
            const src = d() ? getMediaStreamUrl(d()[0]) : coverSrc;
            return (
              <DomainAvatar size="sm" domain="training-course" src={src} />
            );
          }
        },
        'name',
        { id: 'resources', accessorFn: d => d.resources?.length || 0 },
        {
          id: 'expectedDurationMins',
          header: 'Duration',
          formatter: d =>
            d() ? formatDuration({ minutes: Number(d()) }) : 'Unknown'
        },
        'isPublic',
        'createdDate',
        { id: 'createdBy', domain: 'user' }
      ]}
      actions={[
        {
          name: 'Enroll trainess',
          icon: faUserPlus,
          onClick: row => setShowEnroll({ courseIds: [row.original.id] })
        }
      ]}
      bulkActions={[
        {
          name: 'Enroll trainess',
          icon: faUserPlus,
          actionFn: async data =>
            setShowEnroll({ courseIds: data.map(r => r.original.id) })
        }
      ]}
    >
      <AdvanceTable />
      <CourseEnrollModal
        courseIds={typeof showEnroll !== 'boolean' && showEnroll?.courseIds}
        show={!!showEnroll}
        setShow={setShowEnroll}
      />
    </DomainTable>
  );
};
export default () => {
  return (
    <>
      <CoursesTable />
    </>
  );
};
