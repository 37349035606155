import {
  useInfiniteQuery,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import { useMemo } from "react";
import useCrudConfig from "../useCrudConfig";
import { ensureArray } from "helpers/utils";
import { convertObjectToArray } from "apis/flex/helpers";
import useRefire from "./useRefire";
import useMutations from "./useMutations";
import useDefaultCrudLists from "./useDefaultCrudLists";
import { getErrorCode } from "apis/errors";
export const getCrudFilterFields = (filter) => {
  if (!filter) return [];
  if ("first" in filter) return [filter.first];
  if (Array.isArray(filter)) return filter.map(getCrudFilterFields).flat();
  return Object.keys(filter);
};
const mergeFilter = (filters, filtersToMerge) => {
  const ensure = (arr) => Array.isArray(arr) ? arr : [arr];
  if (!filtersToMerge) return ensure(filters);
  if (!filters) return ensure(filtersToMerge);
  const arr1 = ensure(filters);
  const arr2 = ensure(filtersToMerge);
  return [...arr1, ...arr2];
};
const convertFilter = (f) => {
  if (!f) return f;
  if (Array.isArray(f))
    return f.map(
      (ff) => convertFilter(ff)
    );
  if (!("first" in f)) {
    const typed = f;
    return Object.keys(typed).reduce(
      (acc, curr) => {
        return acc.concat({
          first: curr,
          second: typed[curr] === null ? "null" : convertObjectToArray(typed[curr])
        });
      },
      []
    );
  }
  return f;
};
export const mergeFilters = (filters, ...filtersToMerge) => {
  const merged = [];
  const addFilter = (f) => {
    const filterConverted = convertFilter(f);
    if (Array.isArray(filterConverted)) {
      merged.push(...filterConverted);
    } else {
      merged.push(filterConverted);
    }
  };
  addFilter(filters);
  if (filtersToMerge.length === 0) return filters;
  filtersToMerge.forEach((filt) => {
    if (filt) {
      addFilter(filt);
    }
  });
  return merged;
};
const cleanIds = (id) => ensureArray(id)?.filter(
  (i) => (!!i || i === 0) && (typeof i == "number" || !isNaN(i / 1))
) || [];
const useDefaultCrud = (queryKey, api, {
  countBy,
  ...props
} = {}) => {
  const crudConfig = useCrudConfig();
  const prepareParams = (params) => {
    return {
      ...params,
      //because 'column' may be appended to the filter at some point to use within the client but should never be sent to the server
      customFilter: params.customFilter?.map(
        (ands) => ands?.map((or) => ({ ...or, column: void 0 })) || []
      ),
      id: ensureArray(params.id)?.map(Number),
      includeDeleted: params.includeDeleted || void 0,
      getPublic: params.getPublic || void 0,
      asList: params.asList || void 0,
      filters: !!params.state || params.state === null ? mergeFilters(params.filters, { state: params.state }) : params.filters
    };
  };
  const {
    id,
    filters,
    select,
    enabled = true,
    staleTime = 1e3 * 60,
    useFilter = false,
    afterSave,
    afterChange,
    includeDeleted,
    getPublic = false,
    //this is false and optimistic is true
    noReturnOnChange = false,
    count,
    allowedOnly,
    beforeSave = (d) => d,
    sort,
    customFilter,
    paging,
    asList,
    data = true,
    infinite,
    aggregations,
    meta: enableMeta = true,
    columns,
    invalidate: _invalidate = [],
    optimistic = true,
    silent,
    availableWith,
    state,
    ...rest
  } = prepareParams({ ...props, ...crudConfig });
  const enableMemo = useMemo(
    () => !!((!!cleanIds(id).length || !!filters || useFilter) && enabled && !infinite && data),
    [id, filters, useFilter, enabled, data, infinite]
  );
  const infiniteEnableMemo = useMemo(
    () => !!((!!cleanIds(id).length || !!filters || useFilter) && enabled && !!infinite && data),
    [id, filters, useFilter, enabled, infinite]
  );
  const _queryKey = [
    queryKey,
    paging,
    filters,
    customFilter,
    sort,
    aggregations,
    id,
    data,
    useFilter,
    getPublic,
    includeDeleted,
    count,
    countBy,
    allowedOnly,
    asList,
    infinite,
    columns,
    availableWith,
    state
  ];
  const queryClient = useQueryClient();
  const hasValidData = (props2 = {}) => {
    const prepared = prepareParams(props2);
    const checkKey = [..._queryKey];
    if (prepared.paging) {
      checkKey[1] = prepared.paging;
    }
    if (prepared.filters) {
      checkKey[2] = prepared.filters;
    }
    if (prepared.customFilter) {
      checkKey[3] = prepared.customFilter;
    }
    if (prepared.sort) {
      checkKey[4] = prepared.sort;
    }
    return !!queryClient.getQueryData(checkKey);
  };
  const getData = async (updatedIds, updatedPaging) => {
    if (updatedIds || id) {
      const batcher = getPublic ? api.publicBatcher : asList ? api.listBatcher : api.batcher;
      return await Promise.all(
        ensureArray(updatedIds || id).map(batcher.fetch)
      );
    } else if (useFilter) {
      if (asList) {
        return api.getList(
          filters,
          sort,
          customFilter,
          updatedPaging || paging,
          includeDeleted,
          aggregations,
          columns,
          availableWith
        );
      }
      if (getPublic) {
        return api.getPublic(
          filters,
          sort,
          customFilter,
          updatedPaging || paging,
          includeDeleted,
          aggregations,
          columns,
          availableWith
        );
      }
      return api.get(
        filters,
        sort,
        customFilter,
        updatedPaging || paging,
        includeDeleted,
        aggregations,
        columns,
        availableWith
      );
    } else {
      return Promise.resolve([]);
    }
  };
  const { data: meta } = useQuery({
    queryKey: [queryKey, "meta"],
    queryFn: () => api.meta(),
    staleTime: Infinity,
    enabled: enableMeta,
    select: (d) => ({
      ...d,
      columns: d.columns.map((col) => ({
        ...col,
        options: col.options?.map((opt) => ({ ...opt, label: opt.value }))
      }))
    })
  });
  const { data: availabilitySchedule, isLoading: isAvailabilityLoading } = useQuery({
    queryKey: [queryKey, "availability"],
    queryFn: () => api.availability({
      startDate: availableWith?.startDate,
      endDate: availableWith?.endDate,
      params: availableWith
    }),
    enabled: !!availableWith
  });
  const listValueMutations = useDefaultCrudLists({
    api,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey, "meta"]);
      queryClient.refetchQueries([queryKey, "meta"]);
      afterChange?.();
    }
  });
  const retry = (failureCount, error) => {
    if (getErrorCode(error) === 401) return failureCount < 3;
    return false;
  };
  const dataQueryProps = {
    queryKey: _queryKey,
    retry,
    queryFn: async () => {
      return await getData();
    },
    select: (d) => {
      if (d.pages && d.pageParams) {
        return {
          ...d,
          pages: d.pages.map((p) => select ? select(p) : p)
        };
      }
      return select ? select(d) : d;
    },
    enabled: enableMemo,
    staleTime: enabled && (staleTime || 1e3 * 10),
    ...rest
  };
  const query = useQuery(dataQueryProps);
  const infiniteQuery = useInfiniteQuery({
    ...dataQueryProps,
    enabled: infiniteEnableMemo,
    queryFn: async ({
      pageParam = { page: 1, pageSize: infinite.pageSize }
    }) => {
      if (!pageParam) return [];
      return await getData(null, pageParam);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length === infinite.pageSize ? { page: pages.length + 1, pageSize: infinite.pageSize } : void 0;
    }
  });
  const countEnableMemo = useMemo(
    () => !!((!!filters || useFilter) && enabled && (count || countBy)),
    [filters, useFilter, count, countBy, enabled]
  );
  const {
    data: counts,
    isLoading: isCountsLoading,
    error: countError
  } = useQuery({
    queryKey: [..._queryKey, "counts"],
    retry,
    queryFn: () => {
      if (useFilter) {
        return api.counts(
          countBy,
          filters,
          allowedOnly,
          includeDeleted,
          customFilter
        ).then((d) => d.map((c) => ({ ...c, count: Number(c.count) })));
      } else {
        return Promise.resolve(null);
      }
    },
    enabled: countEnableMemo,
    staleTime: enabled && (staleTime || 1e3 * 10),
    ...rest
  });
  const mutations = useMutations({
    api,
    noReturnOnChange,
    beforeSave,
    silent,
    afterSave,
    afterChange,
    queryKey,
    invalidate: _invalidate,
    _queryKey,
    optimistic
  });
  const { refire, isRefiring } = useRefire({
    afterSave,
    afterChange,
    api,
    filters
  });
  return {
    ...query,
    ...infiniteQuery,
    ...mutations,
    ...listValueMutations,
    /** only enabled when @param availableWith is set */
    availabilitySchedule,
    countError,
    refire,
    isRefiring,
    hasValidData,
    data: query.data,
    infiniteData: infiniteQuery.data,
    meta,
    counts,
    error: query.error || countError,
    mutateError: mutations.error,
    queryError: query.error,
    isLoading: enableMemo && query.isLoading || countEnableMemo && isCountsLoading || !!availableWith && isAvailabilityLoading
  };
};
export const defaultCrudHookBuilder = (key, api, defaultProps) => ({
  countBy,
  ...props
} = {}) => {
  const builder = createBuilder(key, api, {
    ...defaultProps,
    ...props
  });
  return builder(countBy);
};
const createBuilder = (key, api, props) => {
  return (countBy) => {
    return useDefaultCrud(key, api, {
      ...props,
      countBy
    });
  };
};
export default useDefaultCrud;
