import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import LoadingButton from './LoadingButton';
import { useInputConfig } from 'components/wizard/InputConfig';
import classNames from 'classnames';

export default ({
  submitText = 'Submit',
  cancelText = 'Cancel',
  isLoading,
  onSubmit,
  onCancel,
  className,
  size,
  children,
  ...rest
}: {
  submitText?: string;
  cancelText?: string;
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
  size?: ButtonProps['size'];
} & Partial<React.HTMLAttributes<HTMLDivElement>>) => {
  const configs = useInputConfig();
  return (
    <div
      className={classNames(className, 'd-flex justify-content-end')}
      {...rest}
    >
      {onCancel && (
        <Button
          disabled={configs?.readOnly}
          size={size}
          variant="link"
          onClick={onCancel}
        >
          {cancelText}
        </Button>
      )}
      {children}
      <LoadingButton
        disabled={configs?.readOnly}
        type="submit"
        size={size}
        loading={isLoading}
        onClick={onSubmit}
      >
        {submitText}
      </LoadingButton>
    </div>
  );
};
