import { AppNotification, appNotificationsApi } from 'apis/flex/notifications';
import useDefaultCrud, { UseCrudProps } from 'hooks/defaultCrud/useDefaultCrud';
import { useIsAllowed } from 'hooks/useGuard';
import { useUser } from 'hooks/useUser';

export default <T = AppNotification[],>({
  select = d => d as T,
  ...props
}: UseCrudProps<AppNotification, T> = {}) => {
  const user = useUser();
  const isAllowed = useIsAllowed();
  return useDefaultCrud('AppNotifications', appNotificationsApi, {
    staleTime: 1000 * 60 * 5,
    ...props,
    select: d => {
      return select(d.filter(n => isAllowed([n.domain])));
    }
  });
};
