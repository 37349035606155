import { LeaveType } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import { AvailabilityRulesForm } from 'components/common/DomainAvailabilityRules';
import DomainDetail from 'components/common/DomainDetail';
import { getDomainInput } from 'components/common/DomainInput';
import React from 'react';
import { EmployeeSelector } from '../staff/widgets/EmployeeSelector';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import { DepartmentSelector } from '../departments/SelectedDepartment';
import { getDomainItemSelector } from 'components/common/DomainItemSelector';
import { useWatch } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
const Input = getDomainInput<LeaveType>();
export const LeaveTypePicker = getDomainItemSelector<LeaveType>('leave-type');
const DefaultTo = () => {
  const id = useWatch({ name: 'id' });
  return (
    <LeaveTypePicker
      name="defaultTo"
      label="Reverts to"
      registerProps={{ required: false }}
      instruction="The leave type that will be reverted to if the employee exceeds their allowance days"
      filter={id ? [{ first: 'id', second: id, negate: true }] : []}
    />
  );
};
export default () => {
  return (
    <DomainDetail<LeaveType> domain="leave-type">
      <DetailCard id="info" title="Info">
        <Input name="name" />
        <Input
          registerProps={{ required: false }}
          name="allowanceDays"
          type="number"
          instruction="The number of days allowed per employee per year. Leave blank for no limit"
        />
        <Input
          registerProps={{ required: false }}
          name="annualIncrease"
          type="number"
          instruction="The number of days added to an employee's allowance for each year of service"
        />
        <Input
          name="maxAllowanceDays"
          type="number"
          instruction="The maximum number of days allowed per employee per year, regardless of years of service. Leave blank for no limit"
          registerProps={{
            required: false,
            validate: (v, d) =>
              !v ||
              !d?.allowanceDays ||
              v > (d?.allowanceDays || 0) ||
              'Max days must be greater than allowance days'
          }}
        />
        <DefaultTo />
        <Input name="paid" type="checkbox" />
        <Input
          name="requestable"
          type="checkbox"
          label="Employee can request"
          instruction="If this is unchecked, only managers can apply this leave to employees. It cannot be requested."
        />
      </DetailCard>
      <DetailCard id="availability" title="Availability Rules">
        <AvailabilityRulesForm<LeaveType>
          fields={{
            employeeId: props => <EmployeeSelector {...props} />,
            contractId: props => <ContractPicker {...props} />,
            departmentId: props => <DepartmentSelector {...props} />
          }}
        />
      </DetailCard>
    </DomainDetail>
  );
};
