import { Shift } from 'apis/flex/hr';
import {
  DomainItemSelectorProps,
  getDomainItemSelector
} from 'components/common/DomainItemSelector';
import React, { ReactNode } from 'react';
import { mergeFilters } from 'hooks/defaultCrud/useDefaultCrud';
import {
  AvailablePerDaySelector,
  getAvailableItemSelector
} from 'components/common/DomainAvailabilityRules';
import { ExtractParamsFromRule } from 'apis/flex/helpers';
import useEmployees from '../staff/hooks/useEmployees';
import { getCommonDataValues } from 'helpers/utils';

const ShiftSelector = getDomainItemSelector('shift');
type AvailableShiftConstraints = ExtractParamsFromRule<
  Shift['availabilityRules'][number]
>;
export const AvailableShiftsSelector = ({
  name,
  availableTo,
  filter: defaultFilter,
  ...defaultProps
}: DomainItemSelectorProps<Shift> & {
  availableTo: AvailableShiftConstraints;
  startDate: Date;
  endDate: Date;
}) => {
  const Picker = getAvailableItemSelector<Shift>({
    domain: 'shift',
    availableWithParams: { ...availableTo }
  });
  return <Picker onNewClick={false} name={name} {...defaultProps} />;
};
export const AvailableEmployeeShiftsSelector = ({
  name,
  startDate,
  endDate,
  employeeId
}: {
  name: string;
  startDate: Date;
  endDate: Date;
  employeeId: number;
}) => {
  const { data: employees = [] } = useEmployees({
    id: employeeId
  });
  return (
    <AvailableShiftsSelector
      name={name}
      startDate={startDate}
      endDate={endDate}
      crudProps={{
        enabled: !!employees?.length
      }}
      disabled={!employees?.length}
      availableTo={{
        employeeId: employees.map(e => e.id),
        contractId: employees.map(e => e.contractId),
        departmentId: employees.map(e => e.departmentId),
        trainingId: employees.flatMap(e => e.completedTrainingCourseIds),
        resourceGroupId: employees.flatMap(e =>
          e.resourceGroups.map(r => r.resourceGroupId)
        )
      }}
    />
  );
};
export type AvailableShiftsPerDaySelectorProps =
  DomainItemSelectorProps<Shift> & {
    render?: (
      item: (props: DomainItemSelectorProps<Shift>) => JSX.Element,
      date: `${string}-${string}-${string}`
    ) => ReactNode;
    availableTo: AvailableShiftConstraints;
    startDate: Date;
    endDate: Date;
  };
export const AvailableShiftsPerDaySelector = ({
  name,
  availableTo,
  filter: defaultFilter,
  ...defaultProps
}: AvailableShiftsPerDaySelectorProps) => {
  return (
    <AvailablePerDaySelector<Shift>
      domain="shift"
      name={name}
      availableWith={availableTo}
      {...defaultProps}
    />
  );
};
export default ShiftSelector;
