import { ShiftPlan, ShiftPlanAllocation } from 'apis/flex/hr';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import AdvanceTableProvider, {
  FilterDef
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import CustomTabs from 'components/common/CustomTabs';
import DetailCard from 'components/common/detail/DetailCard';
import DomainDetail from 'components/common/DomainDetail';
import { ImmutableInput } from 'components/common/DomainInput';
import { groupBy, uniqBy } from 'lodash';
import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import useShiftPlans from './useShiftPlans';
import { useUser } from 'hooks/useUser';
import FieldArrayTable from 'components/common/FieldArrayTable';
import EmployeeShiftWizard, {
  EmployeeShiftSelector
} from '../shifts/employeeShifts/EmployeeShiftWizard';
import TargetGroupDetail, {
  TargetGroupSelector
} from 'components/app/pm/projects/targetGroups/TargetGroupDetail';
import { formatDateToISO } from 'helpers/dates';
import { Card } from 'react-bootstrap';
import useResourceGroups from 'components/app/pm/projects/resourceGroups/useResourceGroups';
import { camelToSentence } from 'helpers/utils';
import ShiftPlanAllocator from './shiftPlanAllocations/ShiftPlanAllocator';
import AllocatorTable from './shiftPlanAllocations/Allocated';

export default () => {
  const { update, isUpdating } = useShiftPlans();
  const user = useUser();
  return (
    <DomainDetail<ShiftPlan>
      actions={[
        {
          label: 'Confirm',
          confirm: 'This will release the shift plan to employees',
          loading: isUpdating,
          disabled: d => !d?.id || !!d?.confirmedDate,
          icon: 'check',
          fn: d => {
            update({
              id: d.id,
              data: { confirmedDate: new Date(), confirmedBy: user.id }
            });
          }
        }
      ]}
      domain="shift-plan"
    >
      <DetailCard id="info" title="Info">
        <ImmutableInput name="date" type="date" />
        <ImmutableInput
          name="resourceGroupId"
          component={ResourceGroupSelector}
        />
      </DetailCard>
      <DetailCard id="allocations" title="Allocations">
        <ShiftPlanAllocator />
      </DetailCard>
    </DomainDetail>
  );
};
const AllocationsTable = () => {
  // const data = useWatch<ShiftPlan, 'allocations'>({ name: 'allocations' });
  const [date, resourceGroupId, allocations] = useWatch<
    ShiftPlan,
    ['date', 'resourceGroupId', 'allocations']
  >({
    name: ['date', 'resourceGroupId', 'allocations']
  });
  const { data: rg } = useResourceGroups({
    id: resourceGroupId,
    select: d => d[0]
  });
  const unitCol = {
    id: 'units',
    header: (camelToSentence(rg?.unitsOfResource) || 'Units') + ' booked'
  };
  const [active, setActive] = React.useState('allocations');
  const [filters, setFilters] =
    React.useState<FilterDef<ShiftPlanAllocation>[]>();
  const fieldArray = useFieldArray<ShiftPlan>({
    name: 'allocations'
  });
  return (
    <>
      <CustomTabs
        activeKey={active}
        setActiveKey={setActive}
        items={[
          {
            id: 'allocations',
            title: 'Allocations',
            content: (
              <FieldArrayTable
                name={'allocations'}
                fieldArray={fieldArray}
                tableProps={{
                  initialFilters: filters
                }}
                defaultValues={{
                  employeeShiftId: null,
                  targetGroupId: null
                }}
                columns={['employeeShiftId', 'targetGroupId', unitCol]}
                form={(field, index) => (
                  <>
                    <EmployeeShiftSelector
                      name={`allocations.${index}.employeeShiftId`}
                      filter={{ date, resourceGroupId }}
                    />
                    <TargetGroupSelector
                      name={`allocations.${index}.targetGroupId`}
                      filter={[
                        {
                          first: 'startDate',
                          second: formatDateToISO(date),
                          comparitor: '<='
                        },
                        {
                          first: 'endDate',
                          second: formatDateToISO(date),
                          comparitor: '>='
                        },
                        {
                          first: 'resourceGroups.resourceGroupId',
                          second: resourceGroupId
                        }
                      ]}
                    />
                  </>
                )}
              />
            )
          },
          {
            id: 'employeeTotals',
            title: 'Totals (Employees)',
            content: (
              <AdvanceTableProvider
                onRowClick={row => {
                  setActive('allocations');
                  setFilters([
                    {
                      id: 'employeeShiftId',
                      value: {
                        active: true,
                        type: '==',
                        value: row.getValue('employeeShiftId')
                      }
                    }
                  ]);
                }}
                data={uniqBy(allocations, 'employeeShiftId')
                  .filter(Boolean)
                  .map((emp, i) => ({
                    id: i,
                    employeeShiftId: emp.employeeShiftId,
                    units: allocations
                      .filter(a => a.employeeShiftId === emp.employeeShiftId)
                      .reduce((a, b) => a + b.units, 0)
                  }))}
                columns={['employeeShiftId', unitCol]}
              />
            )
          },
          {
            id: 'jobTotals',
            title: 'Totals (Target Groups)',
            content: (
              <AdvanceTableProvider
                onRowClick={row => {
                  setActive('allocations');
                  setFilters([
                    {
                      id: 'targetGroupId',
                      value: {
                        active: true,
                        type: '==',
                        value: row.getValue('targetGroupId')
                      }
                    }
                  ]);
                }}
                data={uniqBy(allocations, 'targetGroupId')
                  .filter(Boolean)
                  .map((tg, i) => ({
                    id: i,
                    targetGroupId: tg.targetGroupId,
                    units: allocations
                      .filter(a => a.targetGroupId === tg.targetGroupId)
                      .reduce((a, b) => a + b.units, 0)
                  }))}
                columns={['targetGroupId', unitCol]}
              />
            )
          }
        ]}
      />
    </>
  );
};
