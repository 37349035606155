import { ensureArray } from "helpers/utils";
import { useFieldArray } from "react-hook-form";
import { useFormContext } from "react-hook-form";
export default ({
  data,
  key,
  foreignKey,
  localKey
}) => {
  const { fields, replace } = useFieldArray({
    name: key
  });
  const { getValues } = useFormContext();
  return {
    onChange: (e) => {
      const val = e.target?.value;
      const current = getValues(key);
      const replacedVal = ensureArray(val)?.map((d) => ({
        id: ensureArray(current)?.find((c) => c[foreignKey] === d)?.id,
        [foreignKey]: d,
        [localKey]: data.id
      }));
      replace(replacedVal);
    },
    fields
  };
};
