import { useQuery } from "@tanstack/react-query";
import { getReportData } from "apis/flex/reporting";
import { canConfigGetData, mergeFilters } from "./helpers";
import useReportConfigs from "./useReportConfigs";
import usePageReports from "./usePageReports";
import { useEffect } from "react";
import { getErrorCode } from "apis/errors";
export default ({
  config: _config = null,
  configId: _configId = null,
  extFilters = [],
  isEditing = false,
  variables = {},
  reportId,
  onRefetch
}) => {
  const { data: pageReports } = usePageReports({ id: reportId });
  const configId = _configId || pageReports?.[0]?.reportId;
  const { data } = useReportConfigs({
    id: configId || pageReports?.[0]?.reportId,
    enabled: !!configId && !_config,
    select: (data2) => data2[0]
  });
  useEffect(() => {
    onRefetch?.();
  }, [data]);
  const config = _config || data;
  return useQuery({
    queryFn: () => getReportData({
      config: {
        ...config,
        filters: mergeFilters(config?.filters, extFilters)
      }
    }),
    retry: getReportDataRetry(isEditing),
    queryKey: [
      "reportData",
      extFilters,
      isEditing ? [
        config.db,
        config.table,
        config.filters,
        config.xAxes,
        config.series,
        config.seriesSplitBy,
        config.seriesGroupBy,
        config.compareTo,
        config.aggregation,
        config.aggregationField,
        variables
      ] : [reportId, variables]
    ],
    enabled: !!canConfigGetData(config),
    staleTime: config?.realTime ? 1 : Infinity
  });
};
export const getReportDataRetry = (isEditing) => (failureCount, error) => {
  if (isEditing) return false;
  if (getErrorCode(error) === 401) {
    return failureCount < 3;
  }
  return false;
};
