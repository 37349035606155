import FormWizard, {
  FormWizardProps,
  OnWizardSubmit,
  WizardFieldArray
} from 'components/wizard/FormWizard';
import React, { useEffect, useMemo } from 'react';
import DetailCard, { DetailCardProps } from './DetailCard';
import { useFormContext, useWatch } from 'react-hook-form';
import { NamePrefix } from '../DomainInput';
import { flatMapChildren } from 'helpers/utils';

const findTypedElementsRecursively = (
  children: React.ReactNode,
  type: (props: any) => JSX.Element
): (
  | React.ReactPortal
  | React.ReactElement<unknown, string | React.JSXElementConstructor<any>>
)[] => {
  //   console.log('recursing for type', type, children);
  return flatMapChildren(children, child => {
    // console.log('checking child', child);
    if (React.isValidElement(child) && child.type === type) {
      return child;
    }
  }).filter(Boolean);
};
export default ({
  defaultValues,
  children,
  namePrefix = '',
  onFinished,
  cards,
  onRender,
  freeNav = true,
  ...rest
}: {
  children?: React.ReactNode;
  namePrefix?: NamePrefix;
  onFinished: OnWizardSubmit;
  cards?: DetailCardProps[];
  onRender?: () => void;
  defaultValues?: any;
} & Partial<Omit<FormWizardProps, 'fieldArray' | 'children'>>) => {
  const { setValue } = useFormContext();
  const handleSubmit = vals => {
    Object.keys(vals).forEach(k => setValue(namePrefix + k, vals[k]));
  };
  const detailPages = useMemo<DetailCardProps[]>(
    () =>
      cards ||
      findTypedElementsRecursively(children, DetailCard).map(c => c.props),
    [children]
  );
  //   const otherPages = useMemo(
  //     () =>
  //         cards?children:React.Children.toArray(children).filter(
  //         child => !React.isValidElement(child) || child.type !== DetailCard
  //       ) as React.ReactElement<unknown>[],
  //     [children]
  //   );
  const data = useWatch({
    name: namePrefix === '' ? undefined : namePrefix.slice(0, -1)
  });
  useEffect(() => {
    onRender?.();
  }, []);
  return (
    <FormWizard
      onSubmit={onFinished}
      freeNav={freeNav}
      data={{ ...defaultValues, ...data }}
      defaultValues={defaultValues}
      fieldArray={{
        data: detailPages,
        label: p => p.title,
        icon: p => 'question-circle',
        output: p => {
          return <>{p.children}</>;
        },
        onSubmit(page, vals, done, fail, screenout) {
          handleSubmit(vals);
          done();
        }
      }}
      allowPrev
      {...rest}
    />
  );
};
