import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useShifts from './useShifts';
// import {
//   AvailabilityRulesForm,
//   convertShiftRulesToForm
// } from './AvailabilityRules';
import useResourceGroups from 'components/app/pm/projects/resourceGroups/useResourceGroups';
import { weekdays } from 'helpers/dates';
import { ShiftAvailabilityRulesForm } from './AvailabilityRules';
import { convertRulesToForm } from 'components/common/DomainAvailabilityRules';

export default () => {
  const { data, isLoading } = useResourceGroups({
    useFilter: true,
    select: d =>
      d.map(r => ({
        label: r.name,
        icon: r.icon,
        crudFilter: { resourceGroupId: r.id }
      }))
  });
  return (
    <DomainTable
      isLoading={isLoading}
      tabs={data}
      crudHook={useShifts}
      domain="shift"
      title="Shifts"
      columns={[
        { id: 'resourceGroupId', domain: 'resource-group', visible: false },
        'startTime',
        'endTime',
        {
          id: 'availabilityRules',
          aggregation: 'count',
          aggregationField: 'id',
          accessorFn: d => convertRulesToForm(d?.availabilityRules),
          editor: ({ inputProps }) => {
            return <ShiftAvailabilityRulesForm {...inputProps} />;
          }
        },
        { id: 'createdBy', domain: 'user' },
        'availabilityRules.employeeId',
        'availabilityRules.contractId',
        'availabilityRules.trainingId',
        'availabilityRules.departmentId',
        {
          id: 'availabilityRules.weekday',
          options: weekdays
        },
        'availabilityRules.startDate',
        'availabilityRules.endDate'
      ]}
    />
  );
};
