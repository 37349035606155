import { pageReportsApi } from "apis/flex/reporting";
import {
  defaultCrudHookBuilder
} from "hooks/defaultCrud/useDefaultCrud";
import { useUser } from "hooks/useUser";
const usePageReports = defaultCrudHookBuilder("pageReports", pageReportsApi, {
  staleTime: Infinity
});
export default usePageReports;
export const useCurrentPageReports = ({
  filters,
  section = "",
  beforeSave = (d) => d,
  location: customLocation,
  ...props
} = {}) => {
  const user = useUser();
  return usePageReports({
    useFilter: true,
    filters: {
      ...filters,
      location: (customLocation || location.pathname) + "#" + section,
      userId: user.id
    },
    beforeSave: (d) => ({
      ...beforeSave(d),
      location: (customLocation || location.pathname) + "#" + section
    }),
    customFilter: [
      [
        { question: "userId", type: "==", value: user.id.toString() },
        { question: "userId", type: "empty", value: "" }
      ]
    ],
    ...props
  });
};
