import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Card, CardProps } from 'react-bootstrap';
import classNames from 'classnames';

export default ({
  className,
  message = 'This resource has expired. Please contact the owner for more information.',
  ...rest
}: CardProps & { message?: string }) => {
  return (
    <Card className={classNames('text-center h-100', className)} {...rest}>
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">Expired</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {message}
        </p>
      </Card.Body>
    </Card>
  );
};
