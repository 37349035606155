import React from 'react';
import classNames from 'classnames';
import { domainIconWidths, settings } from 'config';
import { FiBox } from 'react-icons/fi';

export default ({
  domain = null,
  size = 'sm',
  className = null,
  icon,
  color,
  width = null,
  bg = '100',
  text = null,
  defaultColor,
  subIcon,
  status
}: {
  domain?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  icon?: React.ReactNode;
  color?: string;
  width?: number;
  bg?: string;
  text?: string;
  defaultColor?: string;
  subIcon?: React.ReactNode;
  status?:
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'light'
    | 'dark';
}) => {
  const iconWidth = width || domainIconWidths[size];
  const domainColor =
    color || settings.domains[domain]?.color || defaultColor || 'primary';
  const domainIcon = icon || (domain && settings.domains[domain]?.icon);
  const height = iconWidth;
  const borders = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4
  };
  return (
    <div
      style={{ width: height, height }}
      className="m-1 shadow-sm rounded-circle"
    >
      <div
        style={{ width: height, height }}
        className={classNames(
          className,
          `d-inline-flex align-items-center justify-content-center rounded-circle text-center position-relative overflow-hidden`,
          `border border-${borders[size]} border-100`,
          // `shadow-none`,
          // `shadow-sm`,
          {
            'p-1 fs-0': size === 'xs',
            'p-2 fs-2': size === 'sm',
            'p-2 fs-4': size === 'md',
            'p-3 fs-5': size === 'lg',
            'p-5 fs-8': size === 'xl',
            [`bg-${domainColor}-subtle`]: !bg,
            [`bg-${bg}`]: !!bg,
            [`text-${text}`]: !!text,
            [`text-${domainColor}`]: !text
          }
        )}
      >
        {domainIcon || <FiBox />}
        {subIcon && (
          <div
            style={{ width: height / 2, height: height / 2 }}
            className="position-absolute bottom-0 end-0 shadow-sm rounded-circle bg-light border border-300"
          >
            {subIcon}
          </div>
        )}
        {status && <StatusIcon status={status} iconHeight={height} />}
      </div>
    </div>
  );
};
export const StatusIcon = ({ status, iconHeight: height }) => {
  return (
    <div
      style={{
        width: height / 2,
        height: height / 2,
        marginRight: -2,
        marginBottom: -1
      }}
      className={`position-absolute bottom-0 end-0 shadow-sm rounded-circle bg-${status} border border-2 border-300`}
    />
  );
};
