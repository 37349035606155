import { ShiftPlan } from 'apis/flex/hr';
import DomainTable from 'components/common/DomainTable';
import { ConfirmedStamp } from 'components/common/Stamp';
import React from 'react';

export default () => {
  return (
    <DomainTable<ShiftPlan>
      domain="shift-plan"
      columns={[
        'resourceGroupId',
        'date',
        'allocations',
        { id: 'confirmedDate', visible: false },
        { id: 'confirmedBy', visible: false },
        {
          id: 'released',
          accessorFn: d => !!d.confirmedDate,
          formatter: (v, d) => (
            <ConfirmedStamp
              confirmedBy={d.confirmedBy}
              confirmedDate={d.confirmedDate}
            />
          )
        }
      ]}
    />
  );
};
