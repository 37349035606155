import { customFormsResponseApi } from "apis/flex/customForms";
import useDefaultCrud from "hooks/defaultCrud/useDefaultCrud";
import { useMemo } from "react";
export default ({
  responseId,
  select = (d) => d,
  ...rest
}) => {
  const enabled = useMemo(() => !!responseId, [responseId]);
  return useDefaultCrud(
    "CustomFormResponses",
    customFormsResponseApi,
    {
      enabled,
      id: responseId,
      select: (d) => select(d)[0],
      ...rest
    }
  );
};
