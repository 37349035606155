import { faCheck, faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeaveRequest } from 'apis/flex/hr';
import ApprovalStamp, { ApprovalProps } from 'components/common/ApprovalStamp';
import DomainTable from 'components/common/DomainTable';
import CustomTooltip from 'components/common/Tooltip';
import { addDays, formatDistance, isAfter, isSameDay } from 'date-fns';
import { formatDateToISO } from 'helpers/dates';
import React from 'react';
import useLeaveRequests from './useLeaveRequests';
import { useUser } from 'hooks/useUser';
import { useGuard } from 'hooks/useGuard';

export default ({ employeeId }: { employeeId?: number }) => {
  const { bulkUpdateAsync } = useLeaveRequests();
  const { id: userId } = useUser();
  const { canEdit } = useGuard({ roles: ['leave-request'] });
  return (
    <DomainTable<LeaveRequest>
      domain="leave-request"
      isSelf={!!employeeId}
      selfAdd
      title={employeeId ? 'My Leave Requests' : 'Leave Requests'}
      defaultValue={{
        employeeId,
        startDate: addDays(new Date(), 1),
        endDate: addDays(new Date(), 2)
      }}
      crudProps={
        !!employeeId && {
          filters: { employeeId }
        }
      }
      globalActions={[
        {
          name: 'Approve',
          icon: faCheck,
          show: r =>
            canEdit &&
            !r.some(
              r =>
                r.original.approvedDate &&
                isAfter(new Date(r.original.startDate), new Date())
            ),
          actionFn: r =>
            bulkUpdateAsync({
              ids: r.map(r => r.original.id),
              data: {
                approvedDate: new Date(),
                approvedBy: userId,
                rejectedDate: null,
                rejectedBy: null
              }
            })
        },
        {
          name: 'Decline',
          icon: faTimes,
          show: r =>
            canEdit &&
            !r.some(
              r =>
                r.original.rejectedDate &&
                isAfter(new Date(r.original.startDate), new Date())
            ),
          actionFn: r =>
            bulkUpdateAsync({
              ids: r.map(r => r.original.id),
              data: {
                rejectedDate: new Date(),
                rejectedBy: userId,
                approvedDate: null,
                approvedBy: null
              }
            })
        }
      ]}
      tabs={[
        {
          label: 'Upcoming',
          crudFilter: [
            {
              first: 'startDate',
              comparitor: '>=',
              second: formatDateToISO(new Date())
            }
          ]
        },
        {
          label: 'Current',
          crudFilter: [
            {
              first: 'startDate',
              comparitor: '<=',
              second: formatDateToISO(new Date())
            },
            {
              first: 'endDate',
              comparitor: '>=',
              second: formatDateToISO(new Date())
            }
          ]
        },
        {
          label: 'Past',
          crudFilter: [
            {
              first: 'endDate',
              comparitor: '<',
              second: formatDateToISO(new Date())
            }
          ]
        }
      ]}
      columns={[
        'employeeId',
        {
          id: 'startDate',
          type: 'date'
          // formatter: (v, r) => new Date(v()).toLocaleDateString()
        },
        { id: 'endDate', visible: false },
        {
          id: 'duration',
          type: 'number',
          accessorFn: (d: LeaveRequest) =>
            formatDistance(new Date(d.endDate), new Date(d.startDate))
        },
        'leaveTypeId',
        { id: 'approvedDate', visible: false },
        { id: 'approvedBy', visible: false },
        { id: 'rejectedDate', visible: false },
        { id: 'rejectedBy', visible: false },
        {
          id: 'status',
          accessorFn: (d: LeaveRequest) =>
            d.approvedDate
              ? 'Approved'
              : d.rejectedDate
              ? 'Rejected'
              : 'Pending approval',
          formatter: (v, r) => <StatusBadge {...r} />
        }
      ]}
    />
  );
};
export const StatusBadge = (data: LeaveRequest) => {
  const status = data.approvedDate
    ? 'Approved'
    : data.rejectedDate
    ? 'Rejected'
    : 'Pending approval';
  return (
    <CustomTooltip
      content={
        data.approvedDate || data.rejectedDate ? (
          <ApprovalStamp {...data} />
        ) : null
      }
    >
      <div>
        {status === 'Approved' ? (
          <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
        ) : status === 'Rejected' ? (
          <FontAwesomeIcon icon={faTimes} className="text-danger me-2" />
        ) : (
          <FontAwesomeIcon icon={faClock} className="text-warning me-2" />
        )}
        {status}
      </div>
    </CustomTooltip>
  );
};
