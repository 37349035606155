import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import React, { useMemo, useRef, useState } from 'react';
import AdvanceTableProvider from 'components/common/advance-table-v2/AdvanceTableProvider';
import useResizeObserver from 'hooks/useResizeObserver';
import Resizable from 'components/common/Resizable';
import { ResizeCallbackData } from 'react-resizable';
import {
  FormProvider,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  useWatch
} from 'react-hook-form';
import { useResourceGroupSchedule } from 'components/app/pm/projects/resourcing/useResourcePlan';
import { ShiftPlan, ShiftPlanAllocation } from 'apis/flex/hr';
import { isSameDay } from 'helpers/dates';
import useTargetGroups from 'components/app/pm/projects/useTargetGroups';
import { Droppable } from 'react-beautiful-dnd';
import MouseFollower from 'components/common/MouseFollower';
import { ResponsiveModal } from 'components/common/Modals';
import { Button, Modal } from 'react-bootstrap';
import { getDomainInput } from 'components/common/DomainInput';
import ActionFooter from 'components/common/ActionFooter';
import IconButton from 'components/common/IconButton';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import WizardInput from 'components/wizard/WizardInput';
import { SelectedEmployeeShifts } from '../../shifts/employeeShifts/EmployeeShiftWizard';
import DraggableItem from 'components/common/DraggableItem';
import { SelectedEmployees } from '../../staff/widgets/EmployeeSelector';
import classNames from 'classnames';

type EmployeeRow = {
  employeeId: number;
  allocations: (ShiftPlanAllocation & { masterIndex: number })[];
};
const useTargetGroupSchedule = () => {
  const [date, resourceGroupId] = useWatch<
    ShiftPlan,
    ['date', 'resourceGroupId']
  >({ name: ['date', 'resourceGroupId'] });
  const { data: schedule, isLoading: scheduleLoading } =
    useResourceGroupSchedule(resourceGroupId);
  const scheduleForDate = schedule?.find(s => isSameDay(s.date, date));
  const { data: targetGroups, isLoading } = useTargetGroups({
    id: scheduleForDate?.jobs?.map(j => j.targetGroupId)
  });
  const tgs = useMemo(
    () =>
      !targetGroups
        ? []
        : scheduleForDate?.jobs?.map(j => ({
            ...j,
            targetGroup: targetGroups?.find(t => t.id === j.targetGroupId)
          })),
    [schedule, targetGroups]
  );
  return {
    data: tgs,
    isLoading: scheduleLoading || isLoading
  };
};
export default (fieldArray: UseFieldArrayReturn<ShiftPlan, 'allocations'>) => {
  const { fields } = fieldArray;
  const perEmployee = useMemo(
    () =>
      fields.reduce<EmployeeRow[]>((a, b, i) => {
        if (!a.find(aa => aa.employeeId === b.employeeId)) {
          a.push({
            employeeId: b.employeeId,
            allocations: []
          });
        }
        const index = a.findIndex(aa => aa.employeeId === b.employeeId);
        a[index].allocations.push({ ...b, masterIndex: i });
        return a;
      }, []),
    [fields]
  );
  const withId = useMemo(
    () => perEmployee.map((p, i) => ({ ...p, id: i })),
    [perEmployee]
  );
  const handleUpdate = ({ masterIndex, units, targetGroupId }) => {
    const update: any = {};
    if (targetGroupId !== undefined) {
      update.targetGroupId = targetGroupId;
    }
    if (units !== undefined) {
      update.units = units;
    }
    fieldArray.update(masterIndex, {
      ...fields[masterIndex],
      ...update
    });
  };
  const handleSplit = (masterIndex: number, units: number) => {
    const current = fieldArray.fields[masterIndex];
    fieldArray.insert(masterIndex + 1, {
      ...fields[masterIndex],
      units: current.units - units
    });
    fieldArray.update(masterIndex, {
      ...fields[masterIndex],
      units: units
    });
  };
  return (
    <AdvanceTableProvider
      data={withId}
      columns={[
        {
          id: 'employeeId',
          maxSize: 100,
          size: 100,
          formatter: (v, d, r) => {
            return (
              <DraggableItem draggableId={v().toString()} index={r.index}>
                <SelectedEmployees show size="xs" compact ids={[v()]} />
              </DraggableItem>
            );
          }
        },
        {
          id: 'allocation',
          accessorFn: d => d.allocations.length,
          formatter: (v, d) => (
            <AllocationRow
              onUpdate={handleUpdate}
              onSplit={handleSplit}
              data={d}
              unitsWidth={12}
            />
          )
        }
      ]}
    >
      <Droppable droppableId={'allocated'} type="droppableItem">
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            // className="border-2 border-dashed border rounded-3 p-3 mb-3"
          >
            {provided.placeholder}
            <AdvanceTable />
          </div>
        )}
      </Droppable>
    </AdvanceTableProvider>
  );
};
const AllocationRow = ({
  data,
  unitsWidth,
  onUpdate,
  onSplit
}: {
  data: EmployeeRow;
  unitsWidth?: number;
  onUpdate: ({
    masterIndex,
    units,
    targetGroupId
  }: {
    masterIndex: number;
    units?: number;
    targetGroupId?: number;
  }) => void;
  onSplit?: (masterIndex: number, units: number) => void;
}) => {
  const totalUnits = data.allocations.reduce((a, b) => a + Number(b.units), 0);
  const ref = useRef();
  const [width, setWidth] = useState(0);
  useResizeObserver(ref, box => {
    if (box && box.width !== width) {
      setWidth(box.width);
    }
  });
  const allBlocksWidth = (totalUnits / unitsWidth) * width;
  const notches = Array.from(
    { length: unitsWidth },
    (_, i) => (i / (unitsWidth - 1)) * width
  );
  return (
    <div className="bg-100 d-flex w-100" ref={ref}>
      {data.allocations.map((a, i) => (
        <AllocationBlock
          key={a.masterIndex}
          data={a}
          width={notches[1] * a.units}
          index={i}
          totalCount={data.allocations.length}
          notches={notches}
          // unitsWidth={unitsWidth}
          // totalWidth={width}
          onUpdate={p => onUpdate({ ...p, masterIndex: a.masterIndex })}
          onSplit={u => onSplit(a.masterIndex, u)}
        />
      ))}
    </div>
  );
};
const AllocationBlock = ({
  data,
  width,
  // totalWidth,
  index,
  totalCount,
  // unitsWidth,
  onUpdate,
  onSplit,
  notches
}: {
  data: ShiftPlanAllocation;
  width: number;
  // totalWidth: number;
  index: number;
  totalCount: number;
  // unitsWidth: number;
  onUpdate: ({
    units,
    targetGroupId
  }: {
    units?: number;
    targetGroupId?: number;
  }) => void;
  onSplit?: (units: number) => void;
  notches: number[];
}) => {
  const getNearestNotch = leftPosition => {
    const { index } = notches.reduce(
      (closest, notch, index) => {
        const distance = Math.abs(notch - leftPosition);
        return distance < closest.distance ? { index, distance } : closest;
      },
      { index: -1, distance: Infinity }
    );
    return index;
  };
  const getNearestNotchX = leftPosition => {
    const notchIndex = getNearestNotch(leftPosition);
    return notches[notchIndex];
  };
  //   const { allocate } = useAllocationActions(fieldArray);
  const handleResize = (e, { size }: ResizeCallbackData) => {
    const nearestNotch = getNearestNotch(size.width);
    onUpdate({ units: nearestNotch });
    // allocate(data.tempId, data.targetGroupId, nearestNotch);
  };
  const [show, setShow] = useState(false);
  const [clickNotch, setClickNotch] = useState(0);
  return (
    <Resizable
      left={index > 0}
      right={index < totalCount - 1}
      width={width}
      height={60}
      onResize={handleResize}
      className="bg-primary-subtle position-relative cursor-grab rounded-3"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MouseFollower
        followerProps={position => {
          const nearestNotch = getNearestNotch(position.x);
          const nearestNotchX = getNearestNotchX(position.x);
          return {
            className: classNames('bg-1000 d-block', {
              'd-none':
                nearestNotch === 0 ||
                nearestNotch === notches.length ||
                nearestNotch === totalCount
            }),
            style: {
              top: 0,
              width: 2,
              height: '100%',
              left: nearestNotchX
            }
          };
        }}
        onClick={pos => {
          setClickNotch(getNearestNotch(pos.x));
          setShow(true);
        }}
        follower={<div />}
      >
        <div className="position-absolute top-0 start-0 p-1 text-900">
          <div>{Number(data.units)} hrs</div>
        </div>
      </MouseFollower>
      <BlockModal
        data={data}
        show={show}
        setShow={setShow}
        onSplit={() => onSplit(clickNotch)}
        onUpdate={onUpdate}
      />
    </Resizable>
  );
};
const BlockModal = ({ data, show, setShow, onSplit, onUpdate }) => {
  const handleSplit = () => {
    onSplit();
    setShow(false);
  };
  const { data: tgs, isLoading } = useTargetGroupSchedule();
  const methods = useForm({
    values: data
  });
  const handleDone = () => {
    methods.handleSubmit(vals => {
      setShow(false);
      onUpdate(vals);
    })();
  };
  return (
    <ResponsiveModal
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <SelectedEmployeeShifts ids={[data.employeeShiftId]} />
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <WizardInput
            name={'targetGroupId'}
            type="select"
            loading={isLoading}
            options={[{ value: null, label: 'None' } as any].concat(
              (tgs || []).map(tg => ({
                value: tg.targetGroupId,
                label: tg.targetGroup.name
              }))
            )}
            label="Target Group"
          />
          <WizardInput
            name={'units'}
            label="Units"
            type="number"
            formControlProps={{ min: 0.5, step: 0.25 }}
          />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <ActionFooter
          onSubmit={handleDone}
          onCancel={() => setShow(false)}
          submitText="Done"
        >
          <IconButton
            onClick={handleSplit}
            variant="falcon-primary"
            icon={faCut}
          >
            Split
          </IconButton>
        </ActionFooter>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
