import { Break } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import {
  AvailabilityRulesForm,
  convertRulesFromForm,
  convertRulesToForm,
  getAvailableItemSelector
} from 'components/common/DomainAvailabilityRules';
import DomainDetail from 'components/common/DomainDetail';
import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import { EmployeeSelector } from '../../staff/widgets/EmployeeSelector';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import { DepartmentSelector } from '../../departments/SelectedDepartment';
import { weekdays } from 'helpers/dates';
import ShiftSelector from '../ShiftSelector';
import {
  DomainItemSelectorProps,
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import { ExtractParamsFromRule } from 'apis/flex/helpers';
export const BreakPicker = getDomainItemSelector<Break>('break');
export const SelectedBreaks = getSelectedDomainItems<Break>('break');
export const AvailableBreakPicker = ({
  params,
  ...rest
}: {
  params: ExtractParamsFromRule<Break['availabilityRules'][number]>;
} & Partial<DomainItemSelectorProps<Break>>) => {
  const Picker = getAvailableItemSelector<Break>({
    domain: 'break',
    availableWithParams: { ...params }
  });
  return <Picker {...rest} />;
};
export default () => {
  return (
    <DomainDetail<Break>
      domain="break"
      saveMutator={d => ({
        ...d,
        availabilityRules: convertRulesFromForm(d.id, d.availabilityRules)
      })}
      loadMutator={d => ({
        ...d,
        availabilityRules: convertRulesToForm(d.availabilityRules)
      })}
    >
      <DetailCard id="info" title="Info">
        <WizardInput name="name" label="Name" />
        <WizardInput
          name="durationMins"
          label="Duration (mins)"
          instruction="How many minutes (in total) per shift can this break use?"
          registerProps={{ required: false }}
        />
        <WizardInput
          name="durationPercentOfWorkingTime"
          label="Duration (% of working time)"
          instruction="What percentage of the booked working time can this break use?"
          endEl="%"
          registerProps={{ required: false }}
        />
      </DetailCard>
      <DetailCard
        id="availabilityRules"
        title="Availability Rules"
        subtitle="Rules that define who can use this break"
      >
        <AvailabilityRulesForm<Break>
          fields={{
            shiftId: props => <ShiftSelector label="Shifts" {...props} />,
            employeeId: props => (
              <EmployeeSelector label="Employees" {...props} />
            ),
            resourceGroupId: props => (
              <ResourceGroupSelector label="Resource Groups" {...props} />
            ),
            contractId: props => (
              <ContractPicker label="Contract Types" {...props} />
            ),
            departmentId: props => (
              <DepartmentSelector label="Departments" {...props} />
            ),
            weekday: props => (
              <WizardInput
                type="select"
                label="Weekdays"
                options={weekdays}
                {...props}
              />
            )
          }}
        />
      </DetailCard>
    </DomainDetail>
  );
};
