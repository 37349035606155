import { SavedEvent } from 'apis/flex/helpers';
import {
  domainConfigs,
  eventDomainToAppDomain
} from 'components/notification/config';
import React from 'react';
import Timeline, { TimelineItem, TimelinePlaceholder } from './Timeline';
import WizardInput from 'components/wizard/WizardInput';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingButton from './LoadingButton';
import { Button, Col, Row } from 'react-bootstrap';
import usePagedDomainEvents from 'hooks/usePagedDomainEvents';
import { startCase } from 'lodash';
import Expandable from './Expandable';
import { useGuard } from 'hooks/useGuard';
import SettingsBox from './SettingsBox';
import { camelToSentence, ensureArray } from 'helpers/utils';
import { EventPrefix } from 'apis/flex/notifications';
import { RoleDomain } from 'apis/flex/users';
import { ApiCustomFilters } from 'apis/types';
import { CustomRule } from 'helpers/validation/validate';
const EventInfo = ({ event }: { event: SavedEvent }) => {
  const domain = event.domain as RoleDomain;
  const { isAdmin } = useGuard({ roles: [domain] });
  return (
    isAdmin &&
    event.data && (
      <Expandable label={e => (e ? 'Hide data' : 'Show data')}>
        {event.data
          ? Object.keys(event.data).map(k => (
              <div key={k}>
                <b>{k}</b>: {JSON.stringify(event.data[k])}
              </div>
            ))
          : ''}
      </Expandable>
    )
  );
};
export const domainToSentence = (domain: EventPrefix) => {
  return camelToSentence(
    domain
      .split('-')
      .map(s => startCase(s))
      .join('')
  );
};
export const formatDomainEvent = (event: SavedEvent): TimelineItem => {
  const dte = new Date(event.createdDate);
  return {
    title: camelToSentence(
      event.domain
        .split('-')
        .map(s => startCase(s))
        .join('') + startCase(event.action)
    ),
    date: dte,
    renderIcon: () => (
      <div className="fs-2 d-flex">
        {domainConfigs[eventDomainToAppDomain(event.domain)]?.icon}
      </div>
    ),
    userId: event.authorId,
    description: <EventInfo event={event} />
  };
};
const DomainTimelineFilter = ({ onSubmitted }) => {
  const methods = useForm();
  const handleSubmit = methods.handleSubmit(onSubmitted);
  return (
    <SettingsBox title="Filter events">
      <Row className="mb-3 w-100 align-items-center">
        <FormProvider {...methods}>
          <Col xs={10}>
            <WizardInput
              name="actions"
              hideLabel
              placeholder="Filter by action"
              options={[
                { value: 'changed', label: 'Updated' },
                // {
                //   value: 'loggedin',
                //   label: 'Logged in',
                //   description: 'Only applicable to users'
                // },
                { value: 'added', label: 'Added' },
                { value: 'removed', label: 'Deleted' }
              ]}
              type="multiSelect"
              formGroupProps={{ noMb: true }}
              registerProps={{ required: false }}
            />
          </Col>
          {/* <Col xs={6} lg={5}>
            <UserSelector
              name="authorIds"
              multiple
              placeholder="Filter by user"
              registerProps={{ required: false }}
              formGroupProps={{ noMb: true }}
            />
          </Col> */}
        </FormProvider>
        <Col xs={12} lg={2}>
          <LoadingButton size="sm" onClick={handleSubmit}>
            Apply
          </LoadingButton>
        </Col>
      </Row>
    </SettingsBox>
  );
};
export type DomainTimelineFilter = {
  actions?: string[];
  userIds?: number[];
};
export default ({
  domain,
  itemId,
  moreEvents,
  onFilter,
  pageSize = 10,
  extraFilters,
  customFilters
}: {
  domainItems?: `${EventPrefix}:${number}`[];
  domain?: EventPrefix | EventPrefix[];
  itemId?: number;
  moreEvents?: TimelineItem[];
  onFilter?: (filter: DomainTimelineFilter) => void;
  pageSize?: number;
  extraFilters?: ApiCustomFilters<SavedEvent>;
  customFilters?: CustomRule<SavedEvent>;
}) => {
  const [filter, setFilter] = React.useState<DomainTimelineFilter>();
  const {
    data: domainEvents,
    isFetchingNextPage,
    fetchNextPage,
    isLoading
  } = usePagedDomainEvents({
    domain,
    itemId: itemId + '',
    pageSize,
    extraFilters: [
      { first: 'action', second: 'loggedin', negate: true },
      { first: 'domain', second: ['transaction'], negate: true },
      ...(ensureArray(extraFilters) || [])
    ],
    ...filter
  });
  const {
    data: authorEvents,
    isFetchingNextPage: authorIsFetching,
    fetchNextPage: fetchNextAuthorPage,
    isFetching: authorLoading
  } = usePagedDomainEvents({
    pageSize,
    authorIds: [itemId],
    enabled: domain === 'user',
    extraFilters: [
      { first: 'domain', second: 'user', negate: true },
      { first: 'domain', second: 'notification', negate: true }
    ],
    ...filter
  });
  const events = (domainEvents?.pages || [])
    .concat(authorEvents?.pages || [])
    .flatMap(page => page?.map(formatDomainEvent) || [])
    .concat(moreEvents)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  const handleFilter = (filter: DomainTimelineFilter) => {
    setFilter(filter);
    onFilter(filter);
  };
  const handleNextPage = () => {
    fetchNextPage();
    fetchNextAuthorPage();
  };
  return (
    <>
      <DomainTimelineFilter onSubmitted={handleFilter} />
      {isLoading ||
      (authorLoading && domain === 'user') ||
      isFetchingNextPage ||
      authorIsFetching ? (
        <TimelinePlaceholder />
      ) : (
        <Timeline timeline={events} />
      )}
      {domainEvents?.pages[domainEvents.pages.length - 1].length ===
        pageSize && (
        <div className="text-center mt-3">
          <Button onClick={() => handleNextPage()} size="sm" variant="link">
            Load more
          </Button>
        </div>
      )}
    </>
  );
};
