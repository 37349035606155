import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { camelToSentence } from 'helpers/utils';
import Linkify from 'linkify-react';
import React, { ReactNode, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { ValidationRule } from 'react-hook-form';
const Label = ({
  label,
  instruction
}: {
  label: ReactNode;
  instruction?: ReactNode;
}) => {
  return (
    <span className="my-0">
      <Linkify options={{ target: '_blank' }}>
        {label}
        {instruction && (
          <div className="form-text lh-base fw-normal">{instruction}</div>
        )}
      </Linkify>
    </span>
  );
};
const WizardInputLabel = ({
  label,
  id,
  hideLabel,
  name,
  floatingLabel,
  instruction,
  required,
  ai
}: {
  ai?: boolean;
  label: ReactNode;
  id: string;
  hideLabel?: boolean;
  name?: string;
  floatingLabel?: boolean;
  instruction?: ReactNode;
  required?: string | ValidationRule<boolean>;
}) => {
  return (
    !floatingLabel &&
    !hideLabel && (
      <Form.Label className="d-flex" htmlFor={id}>
        {!!required && <p className="d-inline-block text-danger mb-0">*</p>}
        <Label
          instruction={instruction}
          label={
            <>
              {ai && (
                <FontAwesomeIcon
                  icon={faBolt}
                  className="me-1 text-info fs--2"
                />
              )}
              {label}
            </>
          }
        />
      </Form.Label>
    )
  );
};
export default WizardInputLabel;
