import React from 'react';
import useProjects from './useProjects';
import { RemoteDomainTable } from 'components/common/DomainTable';
import { useUser } from 'hooks/useUser';

export default () => {
  const user = useUser();
  // const { data } = useProjectRoles({
  //   filters: { employeeId: user?.employeeId }
  // });
  return (
    <RemoteDomainTable
      // stateTabs
      tabs={[
        {
          label: 'My live projects',
          crudFilter: [
            {
              first: 'team.employeeId',
              second: user?.employeeId
            }
          ],
          state: 'active'
        },
        {
          label: 'Live projects',
          state: 'active'
        },
        {
          label: 'Inactive projects',
          state: null
        }
      ]}
      columns={[
        'createdDate',
        'quoteRef',
        'name',
        'clientName',
        'contactName',
        {
          id: 'team',
          domain: 'employee',
          accessorFn: d => d.team?.map(t => t.employeeId) || [],
          remoteFilter: f => [[{ ...f, question: 'team.employeeId' }]]
        }
      ]}
      crudHook={useProjects}
      domain="project"
    />
  );
};
