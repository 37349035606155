import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Background from './Background';
import corner7 from 'assets/img/icons/spot-illustrations/corner-7.png';

export default ({
  description,
  children
}: {
  description: string;
  children?: React.ReactNode;
}) => {
  return (
    <Col className="mb-3">
      <Card>
        <Background image={corner7} />
        <Card.Body>
          <div
            // className="px-3 pt-5 pb-3"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {children}
        </Card.Body>
      </Card>
    </Col>
  );
};
