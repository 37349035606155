import companyConfig from 'companyConfig';
import Linkify from 'linkify-react';
import React, { ReactNode, useContext } from 'react';
import { toast, ToastPromiseParams } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

export const toast500 = (msg?: ReactNode) => {
  return toast.error(
    () => (
      <>
        Something went wrong! Please try again or{' '}
        <FeedbackLink>let us know</FeedbackLink>
      </>
    ),
    { containerId: 'default' }
  );
};
export const toast409 = (msg?: ReactNode) => {
  return toast.error(
    () => (
      <>
        Conflict
        <div className="fs--2">
          {msg ||
            'It looks like that already exists, or conflicts with another item.'}
        </div>
      </>
    ),
    { containerId: 'default' }
  );
};
export const toastNetwork = (msg: ReactNode) => {
  return toast.error(
    () => (
      <>
        Network Error
        <div className="fs--2">
          {msg ||
            "We're struggling to connect. Make sure your internet is working and try again."}
        </div>
      </>
    ),
    { containerId: 'default' }
  );
};
export const apiError = err => {
  Sentry.captureException(err);
  if (isNetworkError(err)) {
    return toastNetwork(getErrorMessage(err));
  }
  if (getErrorCode(err) === 403) {
    return toast.warning(
      () => (
        <>
          Unauthorised!
          {err.response?.data?.requiredPermissions && (
            <div className="form-text">
              One of the following permissions is required:{' '}
              {err.response?.data?.requiredPermissions.join(', ')}
            </div>
          )}
        </>
      ),
      {
        containerId: 'default',
        icon: <FontAwesomeIcon icon="exclamation-triangle" />
      }
    );
  }
  if (getErrorCode(err) === 409) {
    return toast409(getErrorMessage(err));
  }
  if (getErrorCode(err) === 401) {
    // setItemToStore('client-access-token', null);
    // return toast500(err.response?.data?.message);
  }
  return toast500();
};
const SpinnerIcon: React.FC = () => (
  <Spinner
    animation="border"
    style={{ height: 20 }}
    variant="primary"
    size="sm"
  />
);
export const apiPromise = (
  promise: Promise<any>,
  messages: Partial<Record<'pending' | 'success' | 'error', string>> = {
    pending: 'Working on it..',
    success: 'Done!',
    error: 'Something went wrong! Please try again'
  }
) => {
  return toast.promise(promise, messages as ToastPromiseParams<any>, {
    containerId: 'default',
    icon: <SpinnerIcon />
  });
};
export const apiSuccess = (msg?: ReactNode) => {
  if (msg && typeof msg === 'string') {
    return toast.success(msg, { containerId: 'default' });
  }
  return toast.success('Done!', { containerId: 'default' });
};
export const apiCustomError = (msg?: ReactNode) => {
  return err => {
    console.error(err);
    return toast500(msg);
  };
};
const FeedbackLink = ({ children }) => {
  const { setConfig } = useContext(AppContext);
  return (
    <Link to="#" onClick={() => setConfig('showFeedbackModal', true)}>
      {children}
    </Link>
  );
};
type ApiErrorInner = {
  message?: string;
  code?: number;
  status?: number;
  name?: string;
  sessionId?: string;
  requiredPermissions?: string[];
};
export type ApiError = AxiosError<ApiErrorInner>;
export const getErrorMessage = (err: ApiError) => {
  return err?.response?.data.message;
};
export const getErrorCode = (err: ApiError) => {
  const code = err?.response?.data.code;
  return code ? Number(code) : null;
};
export function isNetworkError(error: ApiError): boolean {
  if (!error || !error.isAxiosError) {
    return false; // Not an AxiosError
  }

  const networkErrors = [
    'ECONNABORTED', // Request timeout
    'ENOTFOUND', // DNS not resolved
    'ECONNREFUSED', // Connection refused
    'ECONNRESET', // Connection reset
    'EHOSTUNREACH', // Host unreachable
    'EAI_AGAIN', // DNS lookup timed out
    'ERR_NETWORK'
  ];

  // Check if error.code matches known network-related error codes
  if (error.code && networkErrors.includes(error.code)) {
    return true;
  }

  // Check if HTTP status indicates a gateway issue (5xx range)
  const status = error.response?.status;
  if (status && status > 500 && status < 600) {
    return true;
  }

  return false;
}
