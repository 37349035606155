import { EmployeeMedicalRecord, EmployeePayrollRecord } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import DomainDetail from 'components/common/DomainDetail';
import { getDomainInput } from 'components/common/DomainInput';
import Error403 from 'components/errors/Error403';
import { Guard, useGuard } from 'hooks/useGuard';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { NoRecordCheck } from './EmployeeMedicalDetail';
import { useEmployeesPayrollRecords } from './hooks/useEmployees';
const taxCodes = [
  {
    value: 'L',
    label: 'L',
    description: 'You’re entitled to the standard tax-free Personal Allowance'
  },
  {
    value: 'M',
    label: 'M',
    description:
      'Marriage Allowance: you’ve received a transfer of 10% of your partner’s Personal Allowance'
  },
  {
    value: 'N',
    label: 'N',
    description:
      'Marriage Allowance: you’ve transferred 10% of your Personal Allowance to your partner'
  },
  {
    value: 'T',
    label: 'T',
    description:
      'Your tax code includes other calculations to work out your Personal Allowance'
  },
  {
    value: '0T',
    label: '0T',
    description:
      'Your Personal Allowance has been used up, or you’ve started a new job and your employer does not have the details they need to give you a tax code'
  },
  {
    value: 'BR',
    label: 'BR',
    description:
      'All your income from this job or pension is taxed at the basic rate (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'D0',
    label: 'D0',
    description:
      'All your income from this job or pension is taxed at the higher rate (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'D1',
    label: 'D1',
    description:
      'All your income from this job or pension is taxed at the additional rate (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'NT',
    label: 'NT',
    description: 'You’re not paying any tax on this income'
  },
  {
    value: 'S',
    label: 'S',
    description: 'Your income or pension is taxed using the rates in Scotland'
  },
  {
    value: 'S0T',
    label: 'S0T',
    description:
      'Your Personal Allowance (Scotland) has been used up, or you’ve started a new job and your employer does not have the details they need to give you a tax code'
  },
  {
    value: 'SBR',
    label: 'SBR',
    description:
      'All your income from this job or pension is taxed at the basic rate in Scotland (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'SD0',
    label: 'SD0',
    description:
      'All your income from this job or pension is taxed at the intermediate rate in Scotland (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'SD1',
    label: 'SD1',
    description:
      'All your income from this job or pension is taxed at the higher rate in Scotland (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'SD2',
    label: 'SD2',
    description:
      'All your income from this job or pension is taxed at the advanced rate in Scotland (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'SD3',
    label: 'SD3',
    description:
      'All your income from this job or pension is taxed at the top rate in Scotland (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'C',
    label: 'C',
    description: 'Your income or pension is taxed using the rates in Wales'
  },
  {
    value: 'C0T',
    label: 'C0T',
    description:
      'Your Personal Allowance (Wales) has been used up, or you’ve started a new job and your employer does not have the details they need to give you a tax code'
  },
  {
    value: 'CBR',
    label: 'CBR',
    description:
      'All your income from this job or pension is taxed at the basic rate in Wales (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'CD0',
    label: 'CD0',
    description:
      'All your income from this job or pension is taxed at the higher rate in Wales (usually used if you’ve got more than one job or pension)'
  },
  {
    value: 'CD1',
    label: 'CD1',
    description:
      'All your income from this job or pension is taxed at the additional rate in Wales (usually used if you’ve got more than one job or pension)'
  }
];
const PayrollInput = getDomainInput<EmployeePayrollRecord>();
const validateAccountNumber = (value: string) =>
  (value.length === 8 && /^\d{8}$/.test(value)) ||
  'Account number must be 8 digits without spaces';
const validateSortCode = (value: string) =>
  (value.length === 6 && /^\d{6}$/.test(value)) ||
  'Sort code must be 6 digits without spaces';
const EmployeePayrollDetail = ({ employeeId = null }) => {
  const { canCreate } = useGuard({ roles: ['employee-payroll-record'] });
  const { data, isLoading } = useEmployeesPayrollRecords({
    filters: { employeeId },
    useFilter: !!employeeId,
    select: d => d[0],
    columns: ['id']
  });
  return (
    <DomainDetail<EmployeePayrollRecord>
      itemId={data?.id}
      title={d => (!d ? 'New' : d.employeeFirstName + ' ' + d.employeeSurname)}
      domain="employee-payroll-record"
      isLoading={isLoading}
      //d is not defined if employee is putting in their own details
      isSelf={d => d?.employeeId === employeeId || (!d && !canCreate)}
      saveMutator={d => ({
        ...d,
        employeeId: d.employeeId || employeeId || undefined
      })}
    >
      <NoRecordCheck isSelf={!!employeeId && !canCreate}>
        <EmployeePayrollForm />
      </NoRecordCheck>
    </DomainDetail>
  );
};
export const EmployeePayrollForm = () => {
  return (
    <>
      <DetailCard title="Banking" id="banking">
        <PayrollInput name="bankName" />
        <Row>
          <Col xs={12} lg={6}>
            <PayrollInput
              name="accountNumber"
              placeholder="12345678"
              registerProps={{
                required: false,
                validate: validateAccountNumber
              }}
            />
          </Col>
          <Col xs={12} lg={6}>
            <PayrollInput
              name="sortCode"
              placeholder="123456"
              registerProps={{ required: false, validate: validateSortCode }}
            />
          </Col>
        </Row>
        <PayrollInput
          name="buildingSocietyReference"
          instruction="Only applicable if you are having your salary paid into a building society"
          registerProps={{ required: false }}
        />
      </DetailCard>
      <DetailCard title="Other" id="other">
        <Guard roles={['employee-payroll-record']} show>
          <PayrollInput
            name="gender"
            options={['Male', 'Female', 'Other']}
            instruction="Gender as specified on legal documents"
            registerProps={{ required: false }}
          />
          <PayrollInput
            name="payrollId"
            instruction="Custom ID for your payroll system"
            registerProps={{ required: false }}
          />
          <PayrollInput
            name="studentLoanPlan"
            type="select"
            options={['Plan 1', 'Plan 2', 'Plan 4', 'Plan 5']}
            registerProps={{ required: false }}
          />
          <PayrollInput
            name="p45Type"
            label="P45 Type"
            type="select"
            options={[
              {
                value: 'A',
                label: 'A - Emergency code on a cumulative basis',
                description: `This is my first job since last 6 April and I have not been receiving taxable Jobseeker's Allowance, Employment and Support Allowance or taxable Incapacity Benefit or a state or occupational pension`
              },
              {
                value: 'B',
                label: 'B - Emergency code on a Week1 or Month 1 basis',
                description: `This is now my only job, but since last 6 April I have had another job, or have received taxable Jobseeker's Allowance, Employment and Support Allowance or Incapacity Benefit. I do not receive a state or occupational pension`
              },
              {
                value: 'C',
                label: 'C - Code BR',
                description: `I have another job or receive a state or occupational pension`
              },
              {
                value: '0',
                label:
                  'No Statement signed - Code 0T on a Week 1 or Month 1 basis'
              }
            ]}
            registerProps={{ required: false }}
          />
          <PayrollInput
            name="taxCode"
            type="select"
            options={taxCodes}
            registerProps={{ required: false }}
          />
        </Guard>
      </DetailCard>
    </>
  );
};
export const MyPayroll = () => {
  const user = useUser();
  if (!user?.employeeId) return <Error403 />;
  return <EmployeePayrollDetail employeeId={user.employeeId} />;
};

export default EmployeePayrollDetail;
