import { customFormsResponseApi } from "apis/flex/customForms";
import useDefaultCrud, {
  defaultCrudHookBuilder
} from "hooks/defaultCrud/useDefaultCrud";
import { useMemo } from "react";
export default ({
  formId,
  includeScreenouts,
  ...rest
}) => {
  const enabled = useMemo(() => !!formId, [formId]);
  return useDefaultCrud(
    "CustomFormResponses",
    customFormsResponseApi,
    {
      enabled,
      filters: {
        formId,
        screenoutQuestionIds: includeScreenouts ? null : "null"
      },
      useFilter: true,
      retryOnMount: false,
      staleTime: 1e3 * 20,
      ...rest
    }
  );
};
export const useFormResponsesHook = defaultCrudHookBuilder(
  "CustomFormResponses",
  customFormsResponseApi
);
