import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { version } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackModal from 'components/navbar/top/FeedbackModal';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
export const FeedbackButton = () => {
  const [show, setShow] = useState();
  return (
    <div
      className="position-fixed end-0 bottom-0 me-2 mb-2 w-auto"
      style={{ zIndex: 1 }}
    >
      <Button
        size="sm"
        onClick={() => setShow(true)}
        variant="falcon-default"
        className="rounded-pill"
      >
        <FontAwesomeIcon size="sm" icon={faBug} className="me-1" />
        Report bug
      </Button>
      <FeedbackModal type={'Bug/error'} {...{ show, setShow }} />
    </div>
  );
};
const Seperator = () => (
  <>
    <span className="d-none d-inline-block">
      <div style={{ width: '0.6rem' }} />{' '}
    </span>
    <br className="d-sm-none" />
  </>
);
const Footer = () => (
  <footer className="w-100 z-n1">
    <div className="d-flex justify-content-center align-items-center fs--1 mt-4 mb-3">
      <p className="mb-0 text-600 text-center">
        &copy; Teamsearch {new Date().getFullYear()} <Seperator />{' '}
        <Link to="/privacy">Privacy Policy</Link>
        <Seperator /> v{version}
      </p>
    </div>
  </footer>
);

export default Footer;
