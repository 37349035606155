import React from 'react';
import { Shift } from 'apis/flex/hr';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { DepartmentSelector } from '../departments/SelectedDepartment';
import { EmployeeSelector } from '../staff/widgets/EmployeeSelector';
import TrainingCoursePicker from '../training/widgets/TrainingCoursePicker';
import { weekdays } from 'helpers/dates';
import { AvailabilityRulesForm } from 'components/common/DomainAvailabilityRules';
export const ShiftAvailabilityRulesForm = ({
  name = 'availabilityRules'
}: Partial<WizardInputProps>) => {
  return (
    <AvailabilityRulesForm<Shift>
      fields={{
        weekday: p => (
          <WizardInput
            type="select"
            label="Weekdays"
            options={weekdays}
            {...p}
          />
        ),
        contractId: p => <ContractPicker label="Contract Types" {...p} />,
        departmentId: p => <DepartmentSelector label="Departments" {...p} />,
        employeeId: p => <EmployeeSelector label="Employees" {...p} />,
        trainingId: p => <TrainingCoursePicker label="Passed Training" {...p} />
      }}
      name={name}
    />
  );
};
