import { useMutation } from "@tanstack/react-query";
import { mutateAsyncToast } from "./helpers";
export default ({
  api,
  onSuccess
}) => {
  const {
    mutate: addListValue,
    isLoading: isAddingListValue,
    error: addListValueError,
    mutateAsync: addListValueAsync
  } = useMutation({
    mutationFn: async ({ field, value }) => {
      await api.addListValue(field, value);
    },
    onSuccess
  });
  return {
    addListValue,
    isAddingListValue,
    addListValueError,
    addListValueAsync: mutateAsyncToast(addListValueAsync, false)
  };
};
