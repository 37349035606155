import React, { useEffect, useMemo } from 'react';
import {
  buildConfig,
  getComponent,
  getFormatterByGroupBy,
  getUniqueAxisValues,
  getValueFormatter,
  GroupByType,
  mapCountDataToAxisData,
  resolveSeriesConfig,
  sqlValueFormatter
} from './helpers';
import useColumnInfo from 'components/common/advance-table-v2/useColumnInfo';
import { getSqlInputType } from 'components/common/advance-table-v2/sql';
import { ReportConfig } from 'apis/flex/reporting';
import Skeleton from 'react-loading-skeleton';
import useReportConfigs from './useReportConfigs';
import { callIfFunction, camelToSentence } from 'helpers/utils';
import ChartLayout from './charts/ChartLayout';
import { CustomRule } from 'helpers/validation/validate';
import useReportData from './useReportData';
import { Guard, GuardRole } from 'hooks/useGuard';
import Error403, { MissingPermissions } from 'components/errors/Error403';
import { getErrorCode } from 'apis/errors';

export const ChartPlaceholder = ({ simple, height = null }) => {
  return (
    <>
      <Skeleton height={height || (simple ? 85 : '20rem')} width={'100%'} />
      {/* <div className="p-3">
        <Skeleton count={4} width={'50%'} />
      </div> */}
    </>
  );
};
export default ({
  reportId,
  config: configFromProps,
  simple,
  isLoading: isTileLoading,
  height,
  isEditing,
  variables,
  filters,
  isStale,
  onRefetch
}: {
  reportId?: number;
  config?: ReportConfig;
  simple?: boolean;
  isLoading?: boolean;
  height?: number | string;
  isEditing?: boolean;
  variables?: Record<string, any>;
  filters?: CustomRule | ((config: ReportConfig) => CustomRule);
  isStale?: boolean;
  onRefetch?: () => void;
}) => {
  const { data: loadedConfig } = useReportConfigs({
    id: reportId,
    select: data => data[0]
  });
  const config = useMemo(
    () => buildConfig(configFromProps || loadedConfig, variables),
    [configFromProps, loadedConfig, variables]
  );
  const { data: sqlCols } = useColumnInfo({
    db: config?.db,
    enabled: !!config
  });
  const getFormatter = (field: string, groupBy?: GroupByType) => {
    const inputType = getSqlInputType(
      sqlCols?.find(
        c => c.COLUMN_NAME === field && c.TABLE_NAME === config?.table
      )
    );
    if (groupBy) {
      return getFormatterByGroupBy(groupBy);
    }
    return sqlValueFormatter(inputType);
  };
  const extFilters = useMemo(
    () => callIfFunction(filters, { ...config }),
    [config, filters]
  );
  const { data, isLoading, refetch, error } = useReportData({
    config,
    extFilters,
    isEditing,
    variables,
    reportId,
    onRefetch
  });
  useEffect(() => {
    if (isStale) {
      refetch();
    }
  }, [isStale]);
  const Component = useMemo(() => getComponent(config?.type), [config?.type]);
  const countData = useMemo(
    () => mapCountDataToAxisData(data, config),
    [data, config]
  );

  const isLoadingMaster = useMemo(
    () => isLoading || !Component || isTileLoading,
    [isLoading, Component, isTileLoading]
  );
  const series = useMemo(
    () =>
      resolveSeriesConfig(data, config).flatMap((series, si) =>
        (config?.xAxes || [{ field: null }]).map((_, i) => {
          return {
            name: camelToSentence(series.name),
            //if there are no valid axes, eCharts will error if it can't find the given axis ID
            axisId: config?.xAxes?.length ? i.toString() : undefined,
            data: countData[si]?.[i],
            valueFormatter: getValueFormatter(config)
          };
        })
      ),
    [config?.xAxes, data]
  );
  const xAxes = useMemo(
    () =>
      config?.xAxes?.map((axis, i) => {
        const formatter = getFormatter(axis.field, axis.groupBy);
        return {
          id: i.toString(),
          data: getUniqueAxisValues(i, config, data).map(formatter)
        };
      }),
    [config?.xAxes, data]
  );
  const yAxes = useMemo(
    () => [
      {
        id: '0',
        min: undefined,
        max: undefined,
        formatter: getValueFormatter(config)
      }
    ],
    [config]
  );
  const chart = useMemo(
    () =>
      getErrorCode(error) === 403 ? (
        <MissingPermissions error={error} />
      ) : (
        <Component
          height={height}
          simple={simple}
          series={series}
          xAxes={xAxes}
          yAxes={yAxes}
        />
      ),
    [Component, error, height, simple, series, xAxes, yAxes]
  );
  return (
    <>
      <ChartLayout
        config={config}
        isLoading={isLoadingMaster}
        height={height}
        simple={simple}
        isStale={isStale}
        filters={extFilters}
        isEditing={isEditing}
      >
        {chart}
      </ChartLayout>
    </>
  );
};
