import DomainDetail from 'components/common/DomainDetail';
import React from 'react';
import useAccessGroups from '../team/permissions/useAccessGroups';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import {
  PermissionsAdder,
  PermissionsGrid
} from '../team/permissions/UserPermissions';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';

export default () => {
  return (
    <DomainDetail
      loadMutator={d => ({
        ...d,
        roles: d.roles?.reduce((roles, role) => {
          //if there's a higher authority role in there for this collection, ignore this one (from a time where you could select multiple roles in a collection)
          if (
            d.roles.find(
              r =>
                r.collectionId === role.collectionId &&
                r.authority > role.authority
            )
          )
            return roles;
          return roles.concat(role);
        }, []),
        userIds: d.users?.map(u => u.userId) || []
      })}
      saveMutator={d => {
        return {
          ...d,
          // roles: Object.keys(d.roles || {})
          //   .filter(r => d.roles[r])
          //   .map(collectionId => ({
          //     collectionId: collectionId,
          //     levelId: d.roles?.[collectionId]
          //   })),
          users: d.userIds?.map(u => ({
            userId: u,
            groupId: d.id,
            id: undefined
          }))
        };
      }}
      domain="access-group"
      crudHook={useAccessGroups}
      defaultValues={{
        name: '',
        description: '',
        roles: [],
        users: [],
        userIds: []
      }}
    >
      <DetailCard title="Info" id="info">
        <WizardInput name="name" />
        <WizardInput name="description" type="textarea" />
      </DetailCard>
      <DetailCard id="roles" title="Permissions">
        <div className="text-600 mb-5">
          {/* <h6>Access</h6>
          <p>
            'Viewer' permissions will allow access to a particular section of
            the app.
          </p>
          <h6 className="mt-4">Ownership</h6> */}
          <p>
            Users will only be able to access items they have created, or which
            belong to a resource they have created or manage. 'Admin'
            permissions, however, will allow access to all items of that group.
          </p>
          <h6 className="mt-4">Examples</h6>
          <ul>
            <li>
              If a user creates a recruitment campaign, they will be able to
              access all applicants under that campaign.
            </li>
            <li>
              If user A is a manager of user B, they will be able to access any
              items user B can access
            </li>
            <li>
              If a user is a recruitment campaign admin, they will be able to
              and edit all recruitment campaigns in the company
            </li>
          </ul>
        </div>
        <PermissionsAdder name={'roles'} />
      </DetailCard>
      <DetailCard id="users" title="Users">
        <UserSelector
          registerProps={{ required: false }}
          name="userIds"
          multiple
        />
      </DetailCard>
    </DomainDetail>
  );
};
