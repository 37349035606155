import { actions, domains } from "apis/flex/notifications";
import { useIsAllowed } from "./useGuard";
export default () => {
  const isAllowed = useIsAllowed();
  const allowedDomains = domains.filter((d) => isAllowed([d]));
  return allowedDomains;
};
export const useAllowedActions = (domain) => {
  const isAllowed = useIsAllowed();
  return actions.filter((a) => !!domain && isAllowed([domain], a));
};
