import { useMutation } from '@tanstack/react-query';
import { apiPromise } from 'apis/errors';
import { getMediaUrl, getZippedMediaUrl } from 'apis/flex/helpers';
import { FileId } from 'apis/types';
import { mutateAsyncToast } from 'hooks/defaultCrud/helpers';
import React, { ReactNode } from 'react';
export const useFileDownloader = ({ fileName }: { fileName?: string } = {}) => {
  const {
    mutate: download,
    isLoading,
    mutateAsync: downloadAsync
  } = useMutation<string, Error, FileId | FileId[]>({
    mutationFn: fileId =>
      Array.isArray(fileId)
        ? getZippedMediaUrl(fileId, fileName)
        : getMediaUrl(fileId, fileName),
    onSuccess: url => {
      window.open(url, 'blank');
    }
  });
  return {
    download,
    isLoading,
    downloadAsync: mutateAsyncToast(downloadAsync, false)
  };
};
const FileDownloader = ({
  files,
  children
}: {
  files: FileId[];
  children?: ReactNode;
}) => {
  const { downloadAsync } = useFileDownloader();
  const handleDownload = (file: FileId) => {
    downloadAsync(file);
  };
  return (
    files && (
      <div className={'d-flex gap-2'}>
        {(Array.isArray(files) ? files : [files])?.map((file, i) => (
          <div
            key={i}
            className="link-info"
            onClick={e => {
              e.stopPropagation();
              handleDownload(file);
            }}
          >
            {children || <>File #{i + 1}</>}
          </div>
        ))}
      </div>
    )
  );
};
export default FileDownloader;
