import React, { useEffect } from 'react';
import { CloseButton, FormControl, InputGroup } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import useMainRef from 'hooks/useMainRef';
import { SelectedEmployees } from '../../staff/widgets/EmployeeSelector';
import { AllocationEntry } from 'apis/flex/projects';
import DraggableItem from 'components/common/DraggableItem';

export default ({
  allocation,
  index
}: {
  allocation: AllocationEntry;
  index: number;
}) => {
  const ref = useMainRef();
  return (
    <DraggableItem draggableId={allocation.employeeId.toString()} index={index}>
      <SelectedEmployees show size="md" ids={[allocation.employeeId]} />
    </DraggableItem>
  );
};
