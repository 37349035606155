import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomTooltip from './Tooltip';
import AuthorStamp from './AuthorStamp';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ConfirmedStamp = ({ confirmedDate, confirmedBy }) => {
  const status = confirmedDate ? 'Confirmed' : 'Pending approval';
  return (
    <CustomTooltip
      content={
        confirmedDate ? (
          <Stamp date={confirmedDate} by={confirmedBy} label={status} />
        ) : null
      }
    >
      <div>
        {confirmedDate ? (
          <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
        ) : (
          <FontAwesomeIcon icon={faClock} className="text-warning me-2" />
        )}
        {status}
      </div>
    </CustomTooltip>
  );
};
export const Stamp = ({
  date,
  by,
  label,
  icon,
  variant
}: {
  date: string;
  by: number;
  label: string;
  icon?: IconProp;
  variant?: string;
}) => {
  return (
    <div className="d-flex flex-column">
      <h6 className={'d-flex align-items-center text-' + variant}>
        <FontAwesomeIcon icon={icon || 'check-circle'} className="me-2" />
        {label}
      </h6>
      {by && (
        <AuthorStamp
          showIcon={false}
          label={false}
          date={date}
          userId={by}
          showDate
        />
      )}
    </div>
  );
};
