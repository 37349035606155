import { Webhook } from 'apis/flex/helpers';
import DetailCard from 'components/common/detail/DetailCard';
import DomainDetail from 'components/common/DomainDetail';
import { getDomainInput } from 'components/common/DomainInput';
import useAllowedDomains, { useAllowedActions } from 'hooks/useAllowedDomains';
import React from 'react';
import { useWatch } from 'react-hook-form';
import TransactionsTable from './TransactionsTable';
const Input = getDomainInput<Webhook>();
export default () => {
  const domains = useAllowedDomains();
  return (
    <DomainDetail<Webhook>
      defaultValues={{
        isActive: true
      }}
      domain="webhook"
      sidebar={true}
    >
      <DetailCard id="info" title="Info">
        <Input name="name" />
        <Input
          name="description"
          registerProps={{ required: false }}
          type="textarea"
        />
        <Input name="domain" type="select" options={domains} />
        <Actions />
        <Input name="url" type="url" />
        <Input
          name="apiKey"
          instruction="This will be added to the 'X-API-KEY' header. Leave blank to auto-generate"
          registerProps={{ required: false }}
        />
      </DetailCard>
      <DetailCard title="Transactions" id="transactions">
        <Transactions />
      </DetailCard>
    </DomainDetail>
  );
};
const Transactions = () => {
  const webhookId = useWatch({ name: 'id' });
  return !!webhookId && <TransactionsTable webhookId={webhookId} />;
};
const Actions = () => {
  const domain = useWatch({ name: 'domain' });
  const actions = useAllowedActions(domain);
  return (
    !!domain && (
      <Input
        name="action"
        label="Domain action"
        type="select"
        options={actions}
      />
    )
  );
};
