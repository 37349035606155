import React, { ReactNode, useEffect } from 'react';
import { WizardInputComponent } from '../WizardInput';
import IconButton from 'components/common/IconButton';
import {
  faBrain,
  faDesktop,
  faDownload,
  faLaptop,
  faMapMarkerAlt,
  faMicrochip,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faQuestionCircle,
  faUndoAlt,
  faUser,
  faVolumeOff,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import Flex, { FlexProps } from 'components/common/Flex';
import Map from 'components/common/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAndroid,
  faApple,
  faChrome,
  faEdge,
  faFirefoxBrowser,
  faLinux,
  faSafari,
  faWindows
} from '@fortawesome/free-brands-svg-icons';
import { camelToSentence } from 'helpers/utils';
import classNames from 'classnames';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Variant } from 'react-bootstrap/esm/types';
import { fromLatLng } from 'react-geocode';
import CustomPopover from 'components/common/CustomPopover';
import CustomTooltip from 'components/common/Tooltip';
import SoftBadge from 'components/common/SoftBadge';
import { useFormContext } from 'react-hook-form';

export const HardwareSummary = ({
  hardware,
  itemProps,
  ...rest
}: {
  hardware: HardwareTestResult;
  itemProps?: Partial<HardwareItemProps>;
} & Partial<FlexProps>) => {
  return (
    <Flex {...rest}>
      <HardwareSummaryInner
        data={hardware}
        itemProps={{ direction: 'column', ...itemProps }}
      />
    </Flex>
  );
};

type HardwareItemProps = {
  icon: IconProp;
  children?: ReactNode;
  variant?: Variant;
  badge?: ReactNode;
  size?: SizeProp;
} & Omit<Partial<FlexProps>, 'size'>;
const HardwareItem = ({
  icon,
  children,
  variant,
  badge,
  size,
  className,
  ...rest
}: HardwareItemProps) => {
  return (
    <Flex className={classNames(className, 'align-items-center')} {...rest}>
      <div className="position-relative">
        {badge && (
          <SoftBadge
            style={{ padding: 3, top: -3, right: -2 }}
            className="position-absolute"
            bg={'warning'}
          >
            {badge}
          </SoftBadge>
        )}
        <FontAwesomeIcon
          icon={icon || faQuestionCircle}
          size={size}
          style={{ width: '1.5rem' }}
          className={classNames({
            [`text-${variant}`]: variant
          })}
        />
      </div>
      {children && (
        <p className="m-0 fw-semi-bold fs--1 text-600">{children}</p>
      )}
    </Flex>
  );
};
const browserIcons = {
  chrome: faChrome,
  edge: faEdge,
  firefox: faFirefoxBrowser,
  safari: faSafari,
  other: faQuestionCircle
};
const platformIcons = {
  macOS: faApple,
  Windows: faWindows,
  Linux: faLinux,
  Android: faAndroid,
  other: faQuestionCircle
};
const HardwareSummaryInner = ({
  data,
  itemProps,
  showInfo
}: {
  data: HardwareTestResult;
  itemProps?: Partial<HardwareItemProps>;
  showInfo?: boolean;
}) => {
  const ItemWithProps = (props: HardwareItemProps) => (
    <CustomTooltip content={showInfo ? undefined : props.children}>
      <div>
        <HardwareItem {...props} {...itemProps}>
          {showInfo && props.children}
        </HardwareItem>
      </div>
    </CustomTooltip>
  );
  return (
    <>
      {data.location && (
        <>
          <CustomPopover
            body={
              <Map
                style={{ width: 400, height: 200 }}
                className="mt-0"
                zoom={10}
                initialCenter={{
                  lng: data.location.longitude,
                  lat: data.location.latitude
                }}
              >
                <ItemWithProps icon={faUser} {...itemProps}>
                  {data.location.address}
                </ItemWithProps>
              </Map>
            }
          >
            <ItemWithProps icon={faMapMarkerAlt}>
              {data.location.address}
            </ItemWithProps>
          </CustomPopover>
        </>
      )}
      <ItemWithProps
        icon={data.audioInput ? faMicrophoneAlt : faMicrophoneAltSlash}
      >
        Microphone: {data.audioInput ? 'Present' : 'Not present'}
      </ItemWithProps>

      <ItemWithProps icon={data.audioOutput ? faVolumeUp : faVolumeOff}>
        Audio: {data.audioOutput ? 'Present' : 'Not present'}
      </ItemWithProps>
      {data.browser && (
        <ItemWithProps icon={browserIcons[data.browser]}>
          Browser: {camelToSentence(data.browser)}
        </ItemWithProps>
      )}
      {data.cpu && (
        <ItemWithProps badge={data.cpu} icon={faMicrochip}>
          CP cores: {data.cpu}
        </ItemWithProps>
      )}
      {data.memory && (
        <ItemWithProps badge={data.memory} icon={faBrain}>
          RAM: {data.memory}GB
        </ItemWithProps>
      )}
      {data.platform && (
        <ItemWithProps icon={platformIcons[data.platform]}>
          Platform: {data.platform}
        </ItemWithProps>
      )}
      {data.screenWidth && (
        <ItemWithProps icon={faDesktop}>
          Screen: {data.screenWidth}x{data.screenHeight}
        </ItemWithProps>
      )}
      {data.speed && (
        <ItemWithProps badge={data.speed} icon={faDownload}>
          Download: {data.speed}MB/s
        </ItemWithProps>
      )}
    </>
  );
};
export type HardwareTestResult = {
  browser: 'firefox' | 'chrome' | 'edge' | 'safari' | 'other';
  platform: 'Windows' | 'macOS' | 'Linux' | string;
  memory: number;
  location: {
    longitude: number;
    latitude: number;
    address?: string;
  };
  cpu: number;
  speed: number;
  screenWidth: number;
  screenHeight: number;
  audioInput: boolean;
  audioOutput: boolean;
};
const WizardHardware: WizardInputComponent = ({
  renderProps,
  formControlProps
}) => {
  const {
    data: response,
    isLoading,
    mutate,
    error
  } = useMutation<HardwareTestResult>({
    mutationFn: async () => {
      const n = navigator as Navigator & {
        deviceMemory?: number;
        connection?: { effectiveType?: string; downlink?: number };
        userAgentData?: { platform?: string };
      };
      const geo: GeolocationPosition = await new Promise(resolve =>
        n.geolocation.getCurrentPosition(resolve, e => {
          console.error('Failed to get location', e);
          // Sentry.captureException(e);
          resolve(null);
        })
      );
      const coords = {
        longitude: geo?.coords?.longitude,
        latitude: geo?.coords?.latitude
      };
      const addressResponse = coords?.latitude
        ? await fromLatLng(coords.latitude, coords.longitude).catch(() => {
            console.error('Failed to get address', n, geo);
            // Sentry.captureException(error, { data: { n, geo } });
            return null;
          })
        : null;
      const devices = await n.mediaDevices.enumerateDevices();
      return {
        location: {
          ...coords,
          address: addressResponse?.results[0].formatted_address
        },
        memory: n.deviceMemory,
        speed: n.connection?.downlink,
        cpu: n.hardwareConcurrency,
        platform: n.userAgentData?.platform,
        screenWidth: window.screen?.width,
        screenHeight: window.screen?.height,
        audioInput: devices.some(d => d.kind === 'audioinput'),
        audioOutput: devices.some(d => d.kind === 'audiooutput'),
        browser: n.userAgent?.includes('Firefox')
          ? 'firefox'
          : n.userAgent?.includes('Chrome')
          ? 'chrome'
          : n.userAgent?.includes('Safari')
          ? 'safari'
          : n.userAgent?.includes('Edge')
          ? 'edge'
          : 'other'
      };
    }
  });
  useEffect(() => {
    if (response && !formControlProps?.readOnly) {
      renderProps.form.field.onChange(response);
    }
  }, [response]);
  const { setError, clearErrors } = useFormContext();
  const name = renderProps.form.field.name;
  const fieldError = renderProps.form.error;
  const data = renderProps.form.field.value;
  useEffect(() => {
    if (error && !fieldError && !data) {
      console.error(error);
      // Sentry.captureException(error);
      setError(name, {
        message:
          "It looks like we couldn't detect your hardware. Please try again later or on a different device."
      });
    }
    if (!error && !!data) {
      clearErrors(name);
    }
  }, [error, fieldError, data]);
  return (
    <>
      <>
        {renderProps.label}
        {renderProps.input(
          <>
            {!data && !formControlProps?.readOnly && (
              <IconButton
                loading={isLoading}
                onClick={() => mutate()}
                variant="falcon-primary"
                icon={faLaptop}
                breakpoint="xs"
              >
                Analyse my hardware
              </IconButton>
            )}
            {data && (
              <Flex direction={'column'} className="mb-3 gap-2">
                <HardwareSummaryInner
                  itemProps={{ size: '2x', className: 'gap-2' }}
                  showInfo
                  data={data}
                />
              </Flex>
            )}
            {data && !formControlProps?.readOnly && (
              <IconButton
                loading={isLoading}
                onClick={() => mutate()}
                variant="falcon-default"
                classname="mt-3"
                size={'sm'}
                breakpoint="xs"
                icon={faUndoAlt}
              >
                Check again
              </IconButton>
            )}
          </>
        )}
      </>
    </>
  );
};
export default WizardHardware;
