import React from 'react';
import WebhooksTable from './WebhooksTable';

export default () => {
  return (
    <>
      <WebhooksTable />
    </>
  );
};
