import React from 'react';
import LeaveTable from './LeaveTable';
import { useUser } from 'hooks/useUser';

export default () => {
  const { employeeId } = useUser();
  return (
    <>
      <LeaveTable employeeId={employeeId} />
    </>
  );
};
