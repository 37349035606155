import React from 'react';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';
import FormPicker from 'components/common/customForms/widgets/FormPicker';
import EmployeeContractForm from 'components/app/documents/contracts/EmployeeContractForm';
import TrainingCoursePicker from 'components/app/hr/training/widgets/TrainingCoursePicker';
import { NewPrescreeningGeneric } from 'components/app/hr/prescreening/NewPrescreening';
import { CourseEnrollFormGeneric } from 'components/app/hr/training/courses/Enroll';
import WithFormValues from 'components/wizard/WithFormValues';
import TemplatePicker from 'components/mailer/templates/TemplatePicker';
import { AccessGroupSelect } from 'components/app/settings/team/permissions/UserPermissions';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import InputPlaceholder from 'components/wizard/InputPlaceholder';
import WizardInput from 'components/wizard/WizardInput';
export default ({
  name = '',
  isLoading,
  includeDates
}: {
  includeDates?: boolean;
  isLoading?: boolean;
  name?: '' | `${string}.`;
}) => {
  // console.log('applicantData', applicantData);
  return isLoading ? (
    <InputPlaceholder count={5} />
  ) : (
    <>
      <TemplatePicker
        label="Acceptance email"
        domain="applicant"
        action="accepted"
      />
      <EmployeeContractForm
        includeDates={includeDates}
        name={`${name}contract.`}
      />
      <UserSelector name={name + 'managerId'} label="Manager" />
      <NewPrescreeningGeneric namePrefix={name} />
      <FormPicker
        label="New Starter Form"
        instruction={
          <>
            This form will be asked <i>in addition to</i> the system New Starter
            Form
          </>
        }
        registerProps={{ required: false }}
        name={name + 'newStarterFormId'}
      />
      <AccessGroupSelect
        name={name + 'accessGroupIds'}
        label="Access Groups"
        multiple
        registerProps={{ required: false }}
      />
      <TrainingCoursePicker
        name={name + 'trainingCourseIds'}
        multiple
        label="Training Courses"
        registerProps={{ required: false }}
        instruction="Any training selected here will need to be completed by the employee(s) before they start their first shift"
      />
      <WithFormValues fields={['trainingCourseIds']}>
        {({ trainingCourseIds }) =>
          !!trainingCourseIds?.length && (
            <CourseEnrollFormGeneric
              includeDates={includeDates}
              namePrefix="training."
            />
          )
        }
      </WithFormValues>
      {includeDates && (
        <WizardInput
          type="futureDate"
          name={name + 'firstLoginExpiry'}
          registerProps={{ required: false }}
          label="Login expiry"
          instruction="If set, the user must log into Flex before this date. After this date, their account will be blocked if they have not yet logged in"
        />
      )}
      <ResourceGroupSelector
        name={name + 'resourceGroupIds'}
        label="Resource Groups"
        multiple
        registerProps={{ required: false }}
      />
    </>
  );
};
