import React from 'react';
import useWebhookTransactions from './useWebhookTransactions';
import CrudTableProvider from 'components/common/advance-table-v2/CrudTableProvider';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';

export default ({ webhookId }: { webhookId?: number }) => {
  return (
    <CrudTableProvider
      // title="Transactions"
      crudHook={useWebhookTransactions}
      crudProps={{
        filters: { webhookId }
      }}
      columns={[{ id: 'sentDate', type: 'datetime' }, 'status', 'response']}
    >
      <AdvanceTable />
    </CrudTableProvider>
  );
};
