import api from ".";
import CrudApi from "./CrudApi";
const baseUrl = "/hr";
export const contractsApi = new CrudApi(baseUrl + "/contracts");
export const employeeContractsApi = new CrudApi(
  baseUrl + "/employee-contracts"
);
export const employeesApi = new CrudApi(baseUrl + "/employees");
export const employeeMedicalRecordApi = new CrudApi(
  baseUrl + "/employees/medical"
);
export const employeePayrollRecordApi = new CrudApi(
  baseUrl + "/employees/payroll"
);
export const trainingApi = {
  courses: new CrudApi(baseUrl + "/training/courses"),
  userCourses: new CrudApi(
    baseUrl + "/training/courses/user"
  )
};
export const screeningApi = new CrudApi(baseUrl + "/screening");
export const getOneIdResult = (accessCode, checkId) => {
  return api.get(baseUrl + "/screening/check/" + checkId + "/oneid-result", {
    params: { accessCode }
  }).then((d) => d.data);
};
export const jobRolesApi = new CrudApi(baseUrl + "/job-titles");
export const departmentsApi = new CrudApi(baseUrl + "/departments");
export const breaksApi = new CrudApi(baseUrl + "/shifts/breaks");
export const shiftsApi = new CrudApi(baseUrl + "/shifts");
export const employeeShiftsApi = new CrudApi(
  baseUrl + "/shifts/employee"
);
export const employeeShiftChangesApi = new CrudApi(
  baseUrl + "/shifts/employee/changes"
);
export const shiftPreferenceApi = new CrudApi(
  baseUrl + "/shifts/preferences"
);
export const shiftTeamsApi = new CrudApi(baseUrl + "/shift-teams");
export const fixedScheduleApi = new CrudApi(
  baseUrl + "/shifts/fixed-schedules"
);
export const latenessApi = new CrudApi(baseUrl + "/lateness");
export const leaveTypesApi = new CrudApi(baseUrl + "/leave/types");
export const leaveRequestsApi = new CrudApi(
  baseUrl + "/leave/requests"
);
export const shiftPlanApi = new CrudApi(baseUrl + "/shifts/plans");
