import React from 'react';
import SoftBadge, { SoftBadgeProps } from './SoftBadge';
import { EventPrefix } from 'apis/flex/notifications';
import classNames from 'classnames';
import DomainIcon from './DomainIcon';
import { domainToSentence } from './DomainTimeline';
import { CloseButton } from 'react-bootstrap';

export default ({
  domain,
  className,
  bg = 'info',
  onClose,
  ...rest
}: {
  domain: EventPrefix;
  className?: string;
  onClose?: () => void;
} & Omit<SoftBadgeProps, 'children'>) => {
  return (
    <SoftBadge
      bg={bg}
      className={classNames(
        className,
        'p-0 d-flex align-items-center d-inline-flex pe-2'
      )}
      {...rest}
    >
      <DomainIcon
        size="xs"
        className={`bg-${bg}-subtle text-${bg} shadow-none border-0 p-0`}
        domain={domain}
      />
      {domainToSentence(domain)}
      {onClose && (
        <CloseButton
          className="ms-1 fs--2"
          // style={{ opacity: 0.5 }}
          onClick={onClose}
        />
      )}
    </SoftBadge>
  );
};
