import { useQuery } from "@tanstack/react-query";
import {
  getResourceGroupSchedule,
  getResourcePlan
} from "apis/flex/projects";
export default ({
  filters,
  select
}) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["resourcePlan", filters],
    queryFn: () => getResourcePlan({ filters }),
    select,
    staleTime: Infinity
    // ...rest
  });
  return {
    data,
    isLoading,
    refetch,
    isFetching: isLoading,
    isRefetching
  };
};
export const useResourceGroupSchedule = (id) => {
  return useQuery({
    queryKey: ["resourceGroupSchedule", id],
    queryFn: () => getResourceGroupSchedule({ id }),
    staleTime: Infinity
  });
};
