import { UserGuide } from 'apis/flex/support';
import { RoleDomain } from 'apis/flex/users';
import DomainTable from 'components/common/DomainTable';
import { QuickLink } from 'components/common/QuickLinks';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import { useIsAllowed } from 'hooks/useGuard';
import { useUser } from 'hooks/useUser';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
const GuideRow = ({ guide, index }: { guide: UserGuide; index: number }) => {
  const user = useUser();
  return (
    <QuickLink
      index={index}
      item={{
        path: getDomainItemUrl('user-guide', user, guide),
        name: guide.title,
        description: guide.description,
        domain: guide.domains?.[0]
      }}
    />
    // <Card>
    //   <FalconCardHeader title={guide.title} subtitle={guide.description}>
    //     <FalconLink
    //       to={getDomainItemUrl('user-guide', user, guide)}
    //       title={'View'}
    //     />
    //     <FalconLink
    //       to={getDomainItemUrl('user-guide', user, guide) + '/edit'}
    //       title={'Edit'}
    //     />
    //   </FalconCardHeader>
    // </Card>
  );
};
export default () => {
  const isAllowed = useIsAllowed();
  return (
    <DomainTable<UserGuide>
      domain="user-guide"
      columns={['title', 'description', 'domains']}
    >
      {({ rows, isLoading }) => {
        const allowedRows = rows?.filter(r =>
          r.original.domains.some(d => isAllowed([d as RoleDomain]))
        );

        return (
          <div className="p-3 d-flex flex-wrap align-items-baseline justify-content-around justify-content-lg-between">
            {isLoading ? (
              // <div className="w-100 p-3">
              <Skeleton
                containerClassName="w-100 d-flex flex-wrap align-items-baseline justify-content-evenly"
                count={4}
                height={150}
                width={'40%'}
              />
            ) : // </div>
            allowedRows?.length > 0 ? (
              allowedRows?.map((row, index) => (
                <GuideRow
                  index={index}
                  key={row.original.id}
                  guide={row.original}
                />
              ))
            ) : (
              'No user guides found'
            )}
          </div>
        );
      }}
    </DomainTable>
  );
};
