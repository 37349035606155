import React from 'react';
import { Col, ColProps, Row, RowProps } from 'react-bootstrap';
import { UseCurrentPageProps, useCurrentPageReports } from './usePageReports';
import NewReportTile from './NewReportTile';
import EditableReportTile from './EditableReportTile';
import { PageReport, ReportConfig } from 'apis/flex/reporting';
import { CustomRule } from 'helpers/validation/validate';
import { ApiFilters } from 'apis/types';
import { CrudFilters } from 'hooks/defaultCrud/useDefaultCrud';
import classNames from 'classnames';
import { Guard } from 'hooks/useGuard';
export type CustomReportTilesProps = RowProps & {
  colProps?: ColProps;
  simple?: boolean;
  /**
   * Suggested defaults given to the report builder. Apart from 'db' and 'table', defaults can be overriden by the user
   */
  defaultValues?: Partial<ReportConfig>;
  /**
   * Filters to apply to any report data
   */
  filters?: CustomRule | ((config: ReportConfig) => CustomRule);
  /**
   * Filters the reports which can be chosen from the dropdown
   */
  reportFilters?: CrudFilters<ReportConfig>;
} & UseCurrentPageProps;
export default ({
  section,
  location,
  colProps,
  simple,
  defaultValues,
  filters,
  reportFilters,
  className,
  variables,
  ...props
}: CustomReportTilesProps) => {
  const { data } = useCurrentPageReports({
    section,
    location
  });
  return (
    <Row className={classNames('g-3 mb-3', className)} {...props}>
      {data?.map((d, i) => (
        <Col
          {...(colProps || simple ? { md: 6, xl: 3 } : { xl: 6, xs: 12 })}
          key={i}
        >
          <EditableReportTile
            filters={filters}
            simple={simple}
            reportId={d.reportId}
            variables={variables}
          />
        </Col>
      ))}
      {/* <Guard roles={['report-config.create']}> */}
      <Col {...colProps}>
        <NewReportTile
          variables={variables}
          reportFilters={reportFilters}
          location={location}
          section={section}
          defaultValues={defaultValues}
          indexForNewReport={data?.length}
        />
      </Col>
      {/* </Guard> */}
    </Row>
  );
};
