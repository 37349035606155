import React from 'react';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  BlobProviderParams,
  usePDF,
  Link,
  Image
} from '@react-pdf/renderer';
import logo from 'assets/img/illustrations/falcon.png';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

// Styles for the PDF document
const styles = StyleSheet.create({
  page: {
    paddingTop: 60,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  coverPage: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    padding: 30
  },
  logo: {
    width: 150,
    height: 150,
    marginBottom: 20
  },
  coverTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 10
  },
  coverSubtitle: {
    fontSize: 16,
    marginBottom: 20
  },
  coverDetails: {
    fontSize: 11,
    marginTop: 5
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 30,
    right: 30,
    fontSize: 10,
    textAlign: 'center',
    color: 'grey'
  },
  section: {
    marginBottom: 20
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8
  },
  description: {
    fontSize: 14,
    marginBottom: 20
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5
  },
  tocTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 12
  },
  tocItem: {
    fontSize: 14,
    marginBottom: 6,
    marginLeft: 20,
    color: 'blue',
    textDecoration: 'underline'
  }
});

const CoverInfo = ({ title, children }) => {
  return children ? (
    <Text style={styles.coverDetails}>
      {title}: {children}
    </Text>
  ) : null;
};
// PDF Document component
const PDFDocument = ({
  toc,
  sections,
  title,
  subtitle,
  author,
  creationDate,
  url,
  version
}: PDFCreatorProps) => {
  const Footer = ({ pageNum }) => (
    <Text style={styles.footer}>
      <>
        Page {pageNum} {author && <>| {author} </>}|
        {<> {format(new Date(), 'dd/MM/yyyy HH:mm')}</>}{' '}
        {version && <>| v{version}</>}
      </>
    </Text>
  );
  return (
    <Document title={title}>
      {/* Cover Page */}
      <Page style={[styles.page, styles.coverPage]}>
        {logo && <Image src={logo} style={styles.logo} />}
        <Text style={styles.coverTitle}>{title}</Text>
        {subtitle && <Text style={styles.coverSubtitle}>{subtitle}</Text>}
        <CoverInfo title="Author">{author}</CoverInfo>
        <CoverInfo title="Date of Creation">
          {creationDate && format(new Date(creationDate), 'dd/MM/yyyy HH:mm')}
        </CoverInfo>
        <CoverInfo title="Date of Export">
          {format(new Date(), 'dd/MM/yyyy HH:mm')}
        </CoverInfo>
        <CoverInfo title="URL">{url && <Link src={url}>{url}</Link>}</CoverInfo>
        <CoverInfo title="Version">{version}</CoverInfo>
      </Page>
      {toc && (
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.tocTitle}>Table of Contents</Text>
            {sections.map((section, index) => (
              <Link
                key={index}
                style={styles.tocItem}
                src={`#section-${index}`}
              >
                {section.title}
              </Link>
            ))}
          </View>
          <Footer pageNum={1} />
        </Page>
      )}
      {/* Sections */}
      {sections.map((section, index) => (
        <Page key={index} style={styles.page}>
          <View key={index} style={styles.section} id={`section-${index}`}>
            <Text style={styles.title}>{section.title}</Text>
            <Text style={styles.description}>{section.description}</Text>
            <Text style={styles.content}>{section.content}</Text>
          </View>
          <Footer pageNum={index + 2} />
        </Page>
      ))}
    </Document>
  );
};
export type PDFCreatorProps = {
  title: string;
  toc?: boolean;
  sections: { title: string; description?: string; content: string }[];
  subtitle?: string;
  author?: string;
  creationDate?: Date;
  url?: string;
  version?: number;
};
// Button to download the PDF
export const PDFButton = (props: PDFCreatorProps) => {
  const Text = ({ loading }: Partial<BlobProviderParams>) =>
    loading ? 'Loading document...' : 'Download PDF';
  return (
    <>
      <PDFDownloadLink
        className="btn btn-falcon-primary btn-sm"
        document={<PDFDocument {...props} />}
        fileName={`${props.title}.pdf`}
      >
        <FontAwesomeIcon icon={faFilePdf} className="me-1" />
        <Text />
      </PDFDownloadLink>
    </>
  );
};
export const usePdfCreator = (props: PDFCreatorProps) => {
  const Doc = <PDFDocument {...props} />;
  const [instance, updateInstance] = usePDF({ document: Doc });
  return {
    update: (props: PDFCreatorProps) =>
      updateInstance(<PDFDocument {...props} />),
    url: instance.url,
    isLoading: instance.loading
  };
};
