import React from 'react';
import usePrescreening from './hooks/usePrescreening';
import { useParams } from 'react-router-dom';
import DetailPage from 'components/common/detail/DetailPage';
import DetailCard from 'components/common/detail/DetailCard';
import { IDCheckForm } from './PrescreeningWizard';
import IconButton from 'components/common/IconButton';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useUser } from 'hooks/useUser';
import InputConfig from 'components/wizard/InputConfig';
import classNames from 'classnames';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGuard } from 'hooks/useGuard';
import { Prescreening } from './PrescreeningModal';
import { IDCheck, Screening } from 'apis/flex/hr';
import Skeleton from 'react-loading-skeleton';
export const CheckCard = ({ check, index, screeningId }) => {
  const [rejectedDate, approvedDate] = useWatch({
    name: [`checks.${index}.rejectedDate`, `checks.${index}.approvedDate`]
  });
  const { setValue: setFormValue } = useFormContext();
  const setValue = (field, value) => {
    // console.log(field, value);
    setFormValue(`checks.${index}.${field}`, value, { shouldDirty: true });
  };
  const { id: userId } = useUser();
  const handleReject = () => {
    setValue('rejectedDate', new Date());
    setValue('rejectedBy', userId);
    setValue('approvedDate', null);
    setValue('approvedBy', null);
  };
  const handleApprove = () => {
    setValue('approvedDate', new Date());
    setValue('approvedBy', userId);
    setValue('rejectedDate', null);
    setValue('rejectedBy', null);
  };
  const { canEdit } = useGuard({
    roles: ['employee-screening'],
    itemIds: [screeningId]
  });
  return (
    <DetailCard
      id="check"
      className={classNames({
        'badge-soft-danger': !!rejectedDate,
        'badge-soft-success': !!approvedDate
      })}
      buttons={
        canEdit && (
          <>
            <IconButton
              disabled={!!rejectedDate}
              variant="danger"
              icon={faThumbsDown}
              onClick={handleReject}
            >
              Reject
            </IconButton>
            <IconButton
              disabled={!!approvedDate}
              icon={faThumbsUp}
              variant="success"
              onClick={handleApprove}
            >
              Approve
            </IconButton>
          </>
        )
      }
      title={check?.checkType}
      subtitle={<ScreeningStatusBadge check={check} />}
    >
      <CheckForm check={check} />
    </DetailCard>
  );
};
export const ScreeningStatusBadge = ({
  check: { approvedDate, rejectedDate }
}: {
  check: IDCheck;
}) => {
  return rejectedDate ? (
    <Badge bg={'danger'}>
      <FontAwesomeIcon icon={faThumbsDown} /> Rejected
    </Badge>
  ) : approvedDate ? (
    <Badge bg={'success'}>
      <FontAwesomeIcon icon={faThumbsUp} /> Approved
    </Badge>
  ) : null;
};
export const CheckForm = ({ check }: { check?: IDCheck }) => {
  return (
    <InputConfig formControlProps={{ readOnly: true }}>
      <IDCheckForm review check={check} />
    </InputConfig>
  );
};
export default ({ id: extId = null }) => {
  const { id: pId } = useParams();
  const id = extId || pId;
  const { data, isLoading, update, isUpdating } = usePrescreening({
    id,
    select: d => d[0],
    staleTime: Infinity
  });
  return (
    <DetailPage
      title="Employee screening"
      domain="employee-screening"
      authItemCollection="employee-screening"
      authItemId={data?.id}
      isSaving={isUpdating}
      onSave={(vals, done) => {
        delete vals.id;
        update({ id: data.id, data: vals }, { onSuccess: done });
      }}
      // readOnly={!data || userId === data?.userId}
      createdBy={data?.userId}
      createdDate={
        data?.checks &&
        new Date(
          Math.max(
            ...(data?.checks?.map(c => new Date(c.submittedDate).getTime()) ||
              [])
          )
        )
      }
      isLoading={isLoading}
      data={data}
    >
      <ScreeningCards data={data} />
    </DetailPage>
  );
};
export const ScreeningCards = ({ data }: { data: Screening }) => {
  const { id: userId } = useUser();
  return userId === data?.userId ? (
    <DetailCard id="prescreening">
      <Prescreening screening={data} />
    </DetailCard>
  ) : (
    data?.checks?.map((d, i) => (
      <CheckCard key={d.id} check={d} index={i} screeningId={data?.id} />
    ))
  );
};
export const LatestPrescreeningCards = ({ employeeId }) => {
  const { data, isLoading } = usePrescreening({
    select: d => d[0],
    staleTime: 1000 * 60 * 5,
    useFilter: !!employeeId,
    filters: { employeeId }
  });
  return (
    <div>
      <h5 className="mb-3">Latest Screening</h5>
      <div>
        {isLoading ? (
          <Skeleton height={200} />
        ) : (
          data?.checks?.map(c => (
            <div key={c.id} className="mb-3 position-relative">
              <CheckForm check={c} />
              <div className="position-absolute top-0 start-0 m-2">
                <ScreeningStatusBadge check={c} />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
