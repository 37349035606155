import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'apis/errors';
import {
  SiteVisit,
  startVisit,
  visitPagesApi,
  visitsApi
} from 'apis/flex/helpers';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import { defaultCrudHookBuilder } from 'hooks/defaultCrud/useDefaultCrud';
import useUrlSearchParams from 'hooks/useUrlSearchParams';
import React, { useEffect, useState } from 'react';
export const useVisits = defaultCrudHookBuilder('SiteVisits', visitsApi);
export const useVisitPages = defaultCrudHookBuilder(
  'SiteVisitPages',
  visitPagesApi
);
export const getCurrentVisit = (): SiteVisit => {
  const v = getItemFromStore('visit', null, sessionStorage);
  return v;
};
const useLocationListener = () => {
  const getLastPage = () => {
    const visit = getCurrentVisit();
    if (!visit?.pages?.length) return;
    return visit.pages[visit.pages.length - 1];
  };
  const updateLastPageDuration = () => {
    const visit = getCurrentVisit();
    if (!visit?.pages?.length) return;
    const lastPage = getLastPage();
    if (lastPage) {
      update(
        {
          id: lastPage.id,
          data: {
            duration: Math.round(
              (Date.now() - new Date(lastPage.date).getTime()) / 1000
            )
          }
        },
        {
          onSuccess: d => {
            const visit = getCurrentVisit();
            visit.pages[visit.pages.length - 1] = d;
            setItemToStore('visit', visit, sessionStorage);
          }
        }
      );
    }
  };
  const {
    add: addPage,
    update,
    isAdding
  } = useVisitPages({
    silent: true
  });

  useEffect(() => {
    const visit = getCurrentVisit();
    if (visit?.id && location.pathname !== getLastPage()?.page) {
      updateLastPageDuration();
      addPage(
        {
          siteVisitId: visit.id,
          page: location.pathname
        },
        {
          onSuccess: d => {
            const visit = getCurrentVisit();
            visit.pages.push(d);
            setItemToStore('visit', visit, sessionStorage);
          }
        }
      );
    }
  }, [location.pathname]);
  useEffect(() => () => updateLastPageDuration(), []);
  useEffect(() => {
    const cb = () => {
      if (document.hidden) {
        updateLastPageDuration();
      }
    };
    //detect hidden
    document.addEventListener('visibilitychange', cb);

    return () => {
      document.removeEventListener('visibilitychange', cb);
    };
  }, []);
};
export default () => {
  const { mutate: add, isLoading: isAdding } = useMutation<
    Partial<SiteVisit>,
    ApiError,
    Partial<SiteVisit>
  >({
    mutationFn: d => startVisit(d),
    onSuccess: d => {
      setItemToStore('visit', d, sessionStorage);
    },
    onError: () => {}
  });
  const { utm_source, ...meta } = useUrlSearchParams();
  const visit = getCurrentVisit();
  useEffect(() => {
    if (!visit || (utm_source && visit.source !== utm_source && !isAdding)) {
      add({
        browser:
          (window.navigator as any).userAgentData?.brands?.[0]?.brand ||
          window.navigator.userAgent,
        os:
          (window.navigator as any).userAgentData?.platform ||
          window.navigator.platform,
        device: (window.navigator as any).userAgentData?.mobile
          ? 'mobile'
          : 'desktop',
        source: utm_source || 'unknown',
        meta,
        language: window.navigator.language
      });
    }
  }, [visit]);
  useLocationListener();
  return <></>;
};
