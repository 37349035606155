import useFormResponse from 'components/common/customForms/hooks.js/useFormResponse';
import React, { useMemo } from 'react';
import useResourceValues from '../hooks/useResourceValues';
import ResourceWizard from './ResourceWizard';
import ApprovalButtons from '../ApprovalButtons';
import Flex from 'components/common/Flex';
import { Button } from 'react-bootstrap';
import InputConfig from 'components/wizard/InputConfig';
import { UserTrainingResource } from 'apis/flex/hr';
import { last } from 'lodash';
export default ({ resource, index, onFinished, review = false }) => {
  const { getValues } = useResourceValues({ index });
  const resourceValues: UserTrainingResource = getValues(`resources.${index}`);
  const responseId =
    resourceValues.formResponseId ||
    last(resourceValues.quizAttempts)?.formResponseId;
  const { data: formResponse } = useFormResponse({
    responseId,
    staleTime: 1000 * 60 * 5
  });
  const formData = useMemo(
    () => ({ ...formResponse?.data.form, ...resourceValues }),
    [resourceValues, formResponse]
  );
  return (
    <>
      <ResourceWizard
        resource={resource}
        index={index}
        review
        onFinished={onFinished}
        data={formData}
      />
      <Flex justifyContent={'end'} className={'gap-2 p-3'}>
        <Button variant="link" onClick={onFinished}>
          Cancel
        </Button>
        <InputConfig readOnly={false}>
          {review && <ApprovalButtons onFinished={onFinished} index={index} />}
        </InputConfig>
      </Flex>
    </>
  );
};
