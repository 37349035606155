import { useDomainSearch } from "components/common/DomainItemSelector";
import { domainConfigs } from "components/notification/config";
import { getItemFromStore } from "helpers/utils";
import { getDomainItemUrl } from "hooks/useDomainRouter";
import { useUser } from "hooks/useUser";
import { uniqBy } from "lodash";
import { useMemo } from "react";
export const getRecentPages = () => {
  const visited = getItemFromStore("visitedPages", {});
  const pages = Object.keys(visited).map((p, i) => ({
    id: "p" + i,
    url: visited[p].url,
    breadCrumbTexts: visited[p].crumbs,
    visitedDate: new Date(visited[p].visitedDate),
    catagories: "recentlyBrowsedItems"
  })).sort((a, b) => b.visitedDate.valueOf() - a.visitedDate.valueOf());
  return useMemo(() => uniqBy(pages, "url"), [getItemFromStore]);
};
export const useRemoteDomainSearch = (search, domain) => {
  const crudHook = domainConfigs[domain].crudHook;
  const user = useUser();
  const crudProps = useDomainSearch({
    search,
    domain,
    asList: true,
    useFilter: !!search,
    select: (d) => d.map((dd) => ({
      ...dd,
      url: getDomainItemUrl(domain, user, dd),
      ...domainConfigs[domain].format(dd)
    })),
    paging: { page: 1, pageSize: 5 }
  });
  return crudHook(crudProps);
};
