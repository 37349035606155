import React from 'react';
import { diffWords } from 'diff';

const TextDiff = ({
  referenceText,
  children,
  diffColor = 'danger'
}: {
  referenceText: string;
  children: string;
  diffColor?: string;
}) => {
  const diff = diffWords(referenceText, children, {
    ignoreCase: true,
    ignoreWhitespace: true
  });
  return (
    <div>
      {diff.map((part, index) => {
        // Style added/removed text differently
        const className = part.added
          ? 'bg-' + diffColor + '-subtle'
          : part.removed
          ? 'bg-secondary-subtle'
          : '';
        return (
          <span key={index} className={className}>
            {part.removed ? <del>{part.value}</del> : part.value}
          </span>
        );
      })}
    </div>
  );
};

export default TextDiff;
