import {
  faCopy,
  faEye,
  faEyeSlash,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import WizardInput, {
  WizardInputOptions,
  WizardInputProps
} from 'components/wizard/WizardInput';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CloseButton,
  Col,
  Dropdown,
  FloatingLabel,
  Form,
  Modal,
  Overlay,
  Popover,
  Row
} from 'react-bootstrap';
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import Flex from '../../Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import signalImg from 'assets/img/icons/signal.png';
import {
  FilterTypeInput,
  FilterValueInput
} from '../../advance-table-v2/AdvanceTableFilters';
import Divider from '../../Divider';
import LoadingButton from '../../LoadingButton';
import { useMutation } from '@tanstack/react-query';
import { regexAssist } from 'apis/flex/helpers';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import TagSelector from './TagSelector';
import { getPropertyByDotNotation } from 'helpers/utils';
import Skeleton from 'react-loading-skeleton';
import VideoUploader from 'components/wizard/inputs/VideoUploader';
import Expandable from 'components/common/Expandable';
import AudioUploader from 'components/wizard/inputs/AudioUploader';
import { aiApi } from '../../../../apis/flex/helpers';
import useTags from '../hooks.js/useTags';
import { FormQuestion } from 'apis/flex/customForms';
import { weekdays } from 'helpers/dates';
import CardDropdown from 'components/common/CardDropdown';
import SettingsBox from 'components/common/SettingsBox';
import { removeHtmlTags } from 'helpers/strings';
import { ResponsiveModal } from 'components/common/Modals';
import CustomTabs, { TabItem } from 'components/common/CustomTabs';
import { EventPrefix } from 'apis/flex/notifications';
import { getAggregationOptions } from 'helpers/validation/rules';
import SelectOptionsBuilder from './SelectOptionsBuilder';
import QuickfireSetup from './setups/QuickfireSetup';
import DomainFieldSelector from './DomainFieldSelector';

export const CustomFormQuestionItem = ({
  index,
  onRemove,
  defaultQuestion
}) => {
  const { data: tags = [], isLoading: tagsLoading } = useTags({
    useFilter: true
  });
  const prefix = 'questions.' + index + '.';
  const { setValue, getValues } = useFormContext();
  const questionTags =
    useWatch({ name: `${prefix}tagIds` }) ||
    getValues(`questions.${index}`)?.tagIds;
  const isSystem =
    !!tags.find(
      t =>
        Array.isArray(questionTags) &&
        questionTags?.some(qt => qt == t.id) &&
        t.isSystem
    ) ||
    getValues(`questions.${index}`)?.isDependant ||
    getValues('isSystem');
  const isHidden = useWatch({ name: `${prefix}isHidden` });
  const toggleHidden = () => {
    setValue(`${prefix}isHidden`, !isHidden);
  };
  return (
    <>
      {tagsLoading ? (
        <Skeleton />
      ) : (
        <Card>
          <CardDropdown className="me-3 position-absolute end-0 top-0 m-2">
            <Dropdown.Item onClick={() => onRemove(index)}>
              <FontAwesomeIcon icon={faTrashAlt} className="me-1" /> Remove
            </Dropdown.Item>
            <Dropdown.Item onClick={toggleHidden}>
              <FontAwesomeIcon
                icon={isHidden ? faEye : faEyeSlash}
                className="me-1"
              />{' '}
              {isHidden ? 'Unhide' : 'Hide'}
            </Dropdown.Item>
          </CardDropdown>
          <CustomFormQuestion
            defaultQuestion={defaultQuestion}
            isSystem={isSystem}
            index={index}
          />
          {/* <Accordion.Button className="py-2 bg-200 pe-4">
          </Accordion.Button>
          <Accordion.Body>
          </Accordion.Body> */}
        </Card>
      )}
    </>
  );
};

const mimeOptions = [
  { value: '*', label: 'Any' },
  { value: 'image/*', label: 'Any Image' },
  { value: 'image/jpeg', label: 'JPEG Image' },
  { value: 'image/png', label: 'PNG Image' },
  { value: 'audio/*', label: 'Any Audio' },
  { value: 'audio/mp3', label: 'MP3 Audio' },
  { value: 'video/*', label: 'Any Video' },
  { value: 'video/mp4', label: 'MP4 Video' },
  { value: 'text/plain', label: 'Plain Text Document' },
  { value: 'application/json', label: 'JSON Document' },
  { value: 'application/xml', label: 'XML Document' },
  { value: 'text/html', label: 'HTML Document' },
  { value: 'application/pdf', label: 'PDF Document' },
  { value: 'application/msword', label: 'Microsoft Word Document' },
  {
    value: 'application/vnd.ms-excel',
    label: 'Microsoft Excel Spreadsheet'
  },
  { value: 'application/zip', label: 'ZIP Archive' }
];
const MimeSelect = ({ index }) => {
  return (
    <WizardInput
      name={'questions.' + index + '.accept'}
      label="Allowed file types"
      type={'select'}
      multiple
      options={mimeOptions}
    />
  );
};

const RangeBounds = ({
  minName,
  minLabel = 'Min',
  maxName,
  maxLabel = 'Max',
  required,
  ...rest
}) => {
  return (
    <Flex className={'g-1 mb-3'} {...rest}>
      <WizardInput
        hideLabel
        formGroupProps={{ className: 'mb-0' }}
        placeholder={minLabel}
        name={minName}
        registerProps={{
          required
        }}
        type="number"
      />
      <WizardInput
        hideLabel
        formGroupProps={{ className: 'mb-0' }}
        placeholder={maxLabel}
        name={maxName}
        type="number"
        registerProps={{
          validate: (v, d) => {
            return v
              ? parseInt(v) > parseInt(getPropertyByDotNotation(d, minName)) ||
                  'Must be higher than the minimum'
              : !required;
          },
          required
        }}
      />
    </Flex>
  );
};
const MediaSetup = ({ index }) => {
  // const { resetField } = useFormContext();
  // useEffect(() => {
  //   resetField('questions.' + index + '.mediaCapture', true);
  //   resetField('questions.' + index + '.mediaUpload', true);
  // }, []);
  return (
    <>
      <WizardInput
        name={'questions.' + index + '.mediaCapture'}
        label="Allow live capture"
        registerProps={{ required: false }}
        type="checkbox"
      />
      <WizardInput
        name={'questions.' + index + '.mediaUpload'}
        label="Allow upload"
        registerProps={{ required: false }}
        type="checkbox"
      />
    </>
  );
};
const ImageSetup = ({ index }) => {
  // const { resetField } = useFormContext();
  // useEffect(() => {
  //   resetField('questions.' + index + '.mediaCapture', true);
  //   resetField('questions.' + index + '.mediaUpload', true);
  // }, []);
  return (
    <>
      <WizardInput
        name={'questions.' + index + '.pluginProps.circle'}
        label="Shape"
        registerProps={{ required: false }}
        type="radio"
        options={[
          { value: true, label: 'Circle' },
          {
            value: false,
            label: 'Square'
          }
        ]}
      />
      <WizardInput
        name={'questions.' + index + '.pluginProps.accept'}
        label="Allowed file types"
        registerProps={{ required: false }}
        type="select"
        options={mimeOptions.filter(m => m.value.includes('image'))}
      />
      {/* <WizardInput
        name={'questions.' + index + '.pluginProps.maxFiles'}
        label="Max. number of images"
        registerProps={{ required: false }}
        type="number"
      /> */}
    </>
  );
};
const RegExAssistant = ({ onComplete, setPrompt, prompt }) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: prompt => regexAssist(prompt),
    onSuccess: v => {
      onComplete(v);
    }
  });
  const handleCreate = () => {
    mutate(prompt);
  };
  return (
    <>
      <>
        <Form.Group className="mb-3">
          <Form.Label>Ensure the response...</Form.Label>
          <p className="form-text">
            Type in what you want the response to be, and AI will generate a
            regex pattern to match it. You can ask for anything, as long as it's
            possible to check just based on what's typed in.
          </p>
          <p className="form-text">
            Example 1: Has 7 characters and the second one must be a number 4.
            <br />
            Example 2: Is a valid email address.
            <br />
            Example 3: Mentions the words 'call centre' at least 2 times.
          </p>
          <textarea
            rows={4}
            cols={30}
            onChange={e => setPrompt(e.target.value)}
            placeholder="Has 7 characters and the second one must be a number 4"
            className="form-control"
          />
        </Form.Group>
        <div className="text-end">
          <LoadingButton
            loading={isLoading}
            onClick={handleCreate}
            variant="falcon-info"
            className="float-right"
          >
            Create
          </LoadingButton>
        </div>
      </>
    </>
  );
};
export const RegexInput = props => {
  const [show, setShow] = useState(false);
  const [testText, setTestText] = useState('');
  const { setValue } = useFormContext();
  const handleCreate = response => {
    setShow(false);
    setValue(props.name, response.value);
    setValue(
      props.name + 'Info',
      truncate(response.description, { length: 250 })
    );
    setValue(props.name + 'Error', truncate(response.error, { length: 250 }));
  };
  const ref = useRef();
  let regex;
  try {
    regex = new RegExp(useWatch({ name: props.name }));
  } catch (err) {
    regex = new RegExp(/./g);
  }
  const check = testText ? !!regex.test(testText) : null;
  const [aiprompt, setAiprompt] = useState('');
  const aiExplanation = useWatch({ name: props.name + 'Info' });
  return (
    <div ref={ref} className="mb-3">
      <Overlay show={show} target={ref} placement="top">
        <Popover style={{ maxWidth: '600px' }}>
          <Popover.Header className="d-flex justify-content-between">
            <div>
              <img
                src={signalImg}
                alt="intelligence"
                height={35}
                className="me-2"
              />{' '}
              AI Assistant
            </div>
            <CloseButton onClick={() => setShow(false)} />
          </Popover.Header>
          <Popover.Body>
            <RegExAssistant
              setPrompt={v => setAiprompt(v)}
              prompt={aiprompt}
              onComplete={handleCreate}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
      <WizardInput
        label="Regex pattern"
        {...props}
        type="text"
        placeholder="^([A-Z])\w+$"
        formControlProps={{ onFocus: () => setShow(true) }}
        registerProps={{
          ...props.registerProps
          // pattern: /^(?:(?:\/(?:[^\/\\]|\\.)+\/)(?:[gimyus]{0,5})?)$/
        }}
      />
      {aiExplanation && <p className="form-text">{aiExplanation}</p>}
      {regex && (
        <Form.Group>
          <FloatingLabel
            controlId="floatingInput"
            label={'Type in some text to check the pattern'}
          >
            <Form.Control
              size="sm"
              onChange={e => setTestText(e.target.value)}
              isValid={testText && check}
              isInvalid={testText && !check}
              placeholder="..."
            />
            {testText && (
              <>
                <Form.Control.Feedback type="invalid">
                  Not valid!
                </Form.Control.Feedback>
                <Form.Control.Feedback>Valid!</Form.Control.Feedback>
              </>
            )}
          </FloatingLabel>
        </Form.Group>
      )}
    </div>
  );
};
export type RuleQuestion = {
  value?: any;
  inputType: string;
  label?: string;
  questionText?: string;
  options?: WizardInputOptions;
  domain?: EventPrefix;
  isCompound?: boolean;
};
const Rule = ({
  name,
  getValues,
  questions = [],
  thisQuestion,
  includeTypes,
  excludeTypes,
  variables,
  compact,
  ...rest
}: {
  name: string;
  getValues: UseFormGetValues<any>;
  questions?: RuleQuestion[];
  thisQuestion?: RuleQuestion;
  includeTypes?: string[];
  excludeTypes?: string[];
  variables?: Record<string, any>;
  compact?: boolean;
} & Partial<WizardInputProps>) => {
  const questionId =
    useWatch({ name: name + '.question' }) || getValues(name)?.question;
  const question =
    thisQuestion || questions.find(q => (q.value || q.label) === questionId);
  const questionOptions = useMemo(
    () =>
      questions.map(q => ({
        value: q.value || q.label,
        label:
          q.label ||
          q.value ||
          +(q.questionText
            ? '. ' + truncate(removeHtmlTags(q.questionText), { length: 40 })
            : '')
      })),
    [questions]
  );
  const { setValue } = useFormContext();
  const getFilterName = x => `${name}.${x}`;
  const column = question && {
    id: question.label,
    inputType: question.inputType,
    domain: question.domain,
    isCompound: question.isCompound,
    options:
      question.options?.filter(d => d).length > 0 ? question.options : undefined
  };
  const inputClassNames = `col-md-${compact ? 12 : 6} col-xs-12 ${
    compact ? 'mb-auto' : ''
  }`;
  return (
    <Row className="g-2">
      {questions.length > 0 && (
        <WizardInput
          hideLabel
          placeholder="Question..."
          type="select"
          name={name + '.question'}
          options={questionOptions}
          registerProps={{
            onChange: e => {
              //set inputType for pipelines which need to evaluate rules on the server. Means the pipeline doesn't need to fetch inputType from the forms db
              if (!e.target.value) return setValue(name, '');
              setValue(
                name + '.inputType',
                questions.find(q => q.label === e.target.value)?.inputType
              );
            }
          }}
          formGroupProps={{ noMb: true, className: 'col-12' }}
          {...rest}
        />
      )}
      {column && (
        <>
          {column.isCompound && (
            <WizardInput
              name={getFilterName('aggregation')}
              type="select"
              hideLabel
              placeholder="Aggregation..."
              options={getAggregationOptions(column.inputType)}
              formGroupProps={{
                className: inputClassNames
              }}
              pluginProps={{
                size: 'lg'
              }}
            />
          )}
          <FilterTypeInput
            getFilterName={getFilterName}
            column={column}
            formGroupProps={{
              className: inputClassNames
            }}
            includeTypes={includeTypes}
            excludeTypes={excludeTypes}
            pluginProps={{
              size: 'lg'
            }}
            active
          />
          <FilterValueInput
            variables={variables}
            formGroupProps={{
              className: inputClassNames
            }}
            getFilterName={getFilterName}
            column={column}
            pluginProps={{
              size: 'lg'
            }}
            active
          />
        </>
      )}
    </Row>
  );
};
type FieldArrayItem = FieldArrayWithId<any>;
type FieldArrayDefaultValues = any;
export type FieldArrayListProps<T = any> = {
  tabs?: boolean;
  tabItem?: (v, i) => any;
  fields: T[];
  item: (field: T, index: number) => ReactNode;
  remove: UseFieldArrayRemove;
  append: (field: T, index?: number) => void;
  defaultValues: FieldArrayDefaultValues;
  divider?: ReactNode;
  bg?: number;
  addLabel?: ReactNode;
  maxFields?: number;
};
export const FieldArrayList = <T extends FieldArrayItem & { name?: string }>({
  tabs,
  fields,
  item,
  remove,
  append,
  defaultValues,
  divider,
  addLabel = 'Add',
  bg = 100,
  tabItem,
  maxFields
}: FieldArrayListProps<T>) => {
  const DropDown = ({ field, index, ...props }) => (
    <CardDropdown {...props}>
      <Dropdown.Item onClick={() => remove(index)}>
        <FontAwesomeIcon className="text-danger me-2" icon={faTrashAlt} />{' '}
        Remove
      </Dropdown.Item>
      <Dropdown.Item onClick={() => append({ ...field, id: undefined }, index)}>
        <FontAwesomeIcon className="me-2" icon={faCopy} /> Duplicate
      </Dropdown.Item>
    </CardDropdown>
  );
  const items: TabItem[] = fields.map((field, i) => ({
    id: field.id,
    title: (
      <>
        <div className="pe-4">{field.name || '#' + (i + 1)}</div>
        <DropDown
          index={i}
          field={field}
          className="position-absolute top-0 end-0 h-100"
        />
      </>
    ),
    content: () => item(field, i), //<CampaignPipeline campaignId={campaignId} index={i} />,
    ...tabItem?.(field, i)
  }));
  return tabs || tabItem ? (
    <CustomTabs
      addBtn={{ label: addLabel, fn: () => append(defaultValues) }}
      items={items}
    />
  ) : (
    <>
      <div className={`mb-3 ${bg ? `bg-${bg}` : ''} ps-1 ps-md-3`}>
        {fields.map((field, i) => (
          <div key={field.id}>
            {i > 0 && <Divider bg={bg}>{divider}</Divider>}
            <Row className={'gx-1 flex-nowrap'}>
              <Col xs={11}>{item(field, i)}</Col>
              <Col xs={1} className="align-self-baseline text-center ps-2">
                <DropDown index={i} field={field} />
              </Col>
            </Row>
          </div>
        ))}
      </div>
      {(!maxFields || fields.length < maxFields) && (
        <div className="text-end">
          <Button
            variant="falcon-default"
            className=""
            size="sm"
            onClick={() => append(defaultValues)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-1" />
            {addLabel}
          </Button>
        </div>
      )}
    </>
  );
};

export const CustomQuestionRules = ({
  type,
  name,
  questions,
  thisQuestion,
  level = 0,
  bg = null,
  includeTypes = null,
  excludeTypes = null,
  placeholder = null,
  variables = null,
  compact
}: {
  type?: string;
  name: string;
  questions?: RuleQuestion[];
  thisQuestion?: RuleQuestion;
  level?: number;
  bg?: number;
  includeTypes?: string[];
  excludeTypes?: string[];
  placeholder?: string;
  variables?: any;
  compact?: boolean;
}) => {
  const { fields, append, replace } = useFieldArray({ name });
  const { getValues, resetField } = useFormContext();
  const handleRemove = index => {
    const arr = getValues(name).filter((f, i) => i !== index);
    replace(arr);
    resetField(name, { defaultValue: arr });
  };
  const thisArray = getValues(name);
  useEffect(() => {
    if (!Array.isArray(thisArray) && !!thisArray) {
      resetField(name, { defaultValue: [thisArray] });
      replace([thisArray]);
    }
  }, [thisArray]);
  const defaultValues =
    level === 0 ? [{ type: '', value: '' }] : { type: '', value: '' };
  return (
    <>
      <FieldArrayList
        bg={bg}
        fields={fields}
        divider={level === 0 ? 'AND' : 'OR'}
        addLabel={level === 0 ? 'AND' : 'OR'}
        item={(field, i) => (
          <>
            {level === 0 ? (
              <div className="p-3 border rounded-4 bg-200">
                <CustomQuestionRules
                  bg={200}
                  {...{
                    type,
                    name: name + '.' + i,
                    questions,
                    thisQuestion,
                    level: level + 1,
                    includeTypes,
                    excludeTypes,
                    placeholder,
                    variables,
                    compact
                  }}
                />
              </div>
            ) : (
              <Rule
                getValues={getValues}
                key={field.id}
                name={name + '.' + i}
                questions={questions}
                thisQuestion={thisQuestion}
                includeTypes={includeTypes}
                excludeTypes={excludeTypes}
                placeholder={placeholder}
                variables={variables}
                compact={compact}
              />
            )}
          </>
        )}
        remove={handleRemove}
        defaultValues={defaultValues}
        append={(field, i) => {
          const v = getValues(name + '.' + i);
          append(i ? v : defaultValues);
        }}
      />
    </>
  );
};
const CustomValidation = ({
  type,
  name,
  index
}: {
  type: string;
  name: string;
  index: number;
}) => {
  const thisQuestion = useWatch({ name: 'questions.' + index });
  return (
    // <SettingsBox title="Custom" description="Only allow if response is...">
    <CustomQuestionRules {...{ type, name, thisQuestion }} />
    // </SettingsBox>
  );
};
const CustomScreenout = ({ type, name, thisQuestion }) => {
  return (
    <SettingsBox
      title="Screening"
      description="Pass screening if...(if blank, everyone will pass)"
    >
      <CustomQuestionRules {...{ type, name, thisQuestion }} />
    </SettingsBox>
  );
};
const CustomMasking = ({ name, index }) => {
  const questions = useWatch({ name: 'questions' });
  const previousQs = questions.filter((q, i) => i < index);
  return (
    previousQs?.length > 0 && (
      <SettingsBox title="Masking" description="Show this question only if...">
        <CustomQuestionRules name={name} questions={previousQs} />
      </SettingsBox>
    )
  );
};
const Masking = ({ index }) => {
  return (
    <CustomMasking name={'questions.' + index + '.masking'} index={index} />
  );
};
const Screenout = ({ type, options, index }) => {
  const thisQuestion = useWatch({ name: 'questions.' + index });
  return (
    <CustomScreenout
      {...{
        type,
        options,
        name: 'questions.' + index + '.screening',
        thisQuestion
      }}
    />
  );
};
const Validation = ({ type, index }) => {
  switch (type) {
    case 'multiSelect':
      return (
        <RangeBounds
          required={false}
          minLabel={'Min length'}
          maxLabel={'Max length'}
          minName={'questions.' + index + '.minLength'}
          maxName={'questions.' + index + '.maxLength'}
          className="mb-3"
        />
      );
    case 'text':
    case 'textarea':
      return (
        <div>
          <RangeBounds
            required={false}
            minLabel={'Min length'}
            maxLabel={'Max length'}
            minName={'questions.' + index + '.minLength'}
            maxName={'questions.' + index + '.maxLength'}
            className="mb-3"
          />
          <RegexInput
            name={'questions.' + index + '.pattern'}
            registerProps={{ required: false }}
          />
        </div>
      );
    case 'file':
      return <MimeSelect index={index} />;
    default:
      return <></>;
  }
};
Validation.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  index: PropTypes.number
};
const ShiftSetup = ({ name }) => {
  return (
    <Row className="gy-3 gx-1">
      <WizardInput
        type="time"
        flush
        name={name + '.start'}
        formGroupProps={{ noMb: true, as: Col, sm: 12, lg: 3 }}
        label="Start time"
      />
      <WizardInput
        type="time"
        flush
        name={name + '.end'}
        formGroupProps={{ noMb: true, as: Col, sm: 12, lg: 3 }}
        label="End time"
      />
      <WizardInput
        flush
        formGroupProps={{
          noMb: true,
          as: Col,
          sm: 12,
          lg: 6
        }}
        type="multiSelect"
        name={name + '.weekdays'}
        label="Weekdays"
        options={weekdays}
      />
    </Row>
  );
};
const ShiftsSetup = ({ index }) => {
  const name = `questions.${index}.shifts`;
  const {
    fields: shifts,
    remove,
    append
  } = useFieldArray({
    name
  });
  return (
    <SettingsBox title={'Shifts'} description="Set up shifts">
      <FieldArrayList
        fields={shifts}
        item={(field, i) => <ShiftSetup name={name + '.' + i} key={field.id} />}
        remove={remove}
        append={field => append(field)}
        defaultValues={{ start: '09:00', end: '17:00', weekdays: [] }}
      />
    </SettingsBox>
  );
};
ShiftsSetup.propTypes = {
  index: PropTypes.number
};
const NameSetup = ({ index }) => {
  return (
    <WizardInput
      type="switch"
      name={'questions.' + index + '.nameMiddle'}
      registerProps={{ required: false }}
      label="Include middle name"
    />
  );
};
NameSetup.propTypes = {
  index: PropTypes.number
};
const TranscribeSetup = ({ index }) => {
  const audioFile = useWatch({ name: `questions.${index}.audioFile` });
  const transcription = useWatch({
    name: `questions.${index}.audioTranscript`
  });
  const { setValue } = useFormContext();
  const { mutate, isLoading } = useMutation<string, Error, void>({
    mutationFn: () => {
      if (!audioFile?.[0]?.uploadPath && typeof audioFile?.[0] !== 'string')
        return Promise.resolve(null);
      return aiApi.transcribe({
        fileId: audioFile?.[0]?.uploadPath || audioFile?.[0]
      });
    },
    onSuccess: d => {
      setValue(`questions.${index}.audioTranscript`, d);
    }
  });
  useEffect(() => {
    if (!transcription) {
      mutate();
    }
  }, [audioFile]);
  return (
    <>
      <AudioUploader
        fileIdField={`questions.${index}.audioFile`}
        onChange={() => mutate()}
      />
      {audioFile && (
        <div className="mt-2">
          <WizardInput
            loading={isLoading}
            type="textarea"
            name={`questions.${index}.audioTranscript`}
            instruction={
              <>
                Auto-transcribed by AI.{' '}
                <a className="link" onClick={() => mutate()}>
                  Click here
                </a>{' '}
                to re-transcribe.
              </>
            }
            label="Correct Answer"
          />
        </div>
      )}
      <WizardInput
        name={`questions.${index}.maxMediaPlays`}
        type="number"
        label="Maximum number of plays"
        instruction="Set to 0 or leave blank for unlimited."
        registerProps={{ required: false }}
      />
    </>
  );
};
const InterviewSetup = ({ index }) => {
  const fieldArray = useFieldArray({
    name: `questions.${index}.promptFiles`
  });
  return (
    <>
      <SettingsBox title="Prompts" description="Add some prompts">
        <FieldArrayList
          {...fieldArray}
          append={field => {
            // console.log("appending", field);
            fieldArray.append(field);
          }}
          defaultValues={''}
          item={(f, i) => (
            <div>
              <Form.Label className="fs--1">Prompt #{i + 1}</Form.Label>
              <AudioUploader
                fileIdField={`questions.${index}.promptFiles.${i}`}
              />
            </div>
          )}
        />
      </SettingsBox>
    </>
  );
};
const SpeakingSetup = ({ index }) => {
  return (
    <WizardInput
      type="textarea"
      name={`questions.${index}.audioTranscript`}
      label="Prompt"
      pluginProps={{
        aiWriter: {
          preprompt: 'Give me a random paragraph.'
        }
      }}
    />
  );
};
const TypeSetup = ({ index, type }) => {
  switch (type) {
    case 'speaking':
      return <SpeakingSetup index={index} />;
    case 'transcribe':
      return <TranscribeSetup index={index} />;
    case 'select':
    case 'multiSelect':
    case 'radio':
      return (
        <SelectOptionsBuilder
          index={index}
          name={`questions.${index}.options`}
        />
      );
    case 'range':
    case 'number':
      return (
        <RangeBounds
          minName={'questions.' + index + '.min'}
          maxName={'questions.' + index + '.max'}
          required={type === 'range'}
        />
      );
    case 'audio':
    case 'video':
      return <MediaSetup index={index} />;
    case 'image':
      return <ImageSetup index={index} />;
    case 'shifts':
      return <ShiftsSetup index={index} />;
    case 'name':
      return <NameSetup index={index} />;
    case 'interview':
      return <InterviewSetup index={index} />;
    case 'quickfire':
      return <QuickfireSetup index={index} />;
    default:
      return <></>;
  }
};
TypeSetup.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string
};
export const customQuestionTypes = [
  {
    value: 'shifts',
    label: 'Weekly shifts',
    description:
      'A block of checkboxes allowing the respondent to select one or more shifts from a list of available ones across the week.'
  },
  {
    value: 'postalAddress',
    label: 'Address',
    description:
      'Collect a full postal address, including street name, city and postcode. Includes in-built postcode verification using postcodes.io'
  },
  {
    value: 'postcode',
    label: 'Postcode',
    description:
      'Collect a full postcode. Includes in-built postcode verification using postcodes.io'
  },
  {
    value: 'name',
    label: 'Full name',
    description:
      "Collect a person's full name, including title, first name, optionally middle name(s) and surname"
  },
  {
    value: 'email',
    label: 'Email address',
    description:
      'Collect email address with built-in validation. Asks respondent to type in twice for validation.'
  },
  {
    value: 'landline',
    label: 'Landline phone number',
    description: 'Collect a UK landline number with built-in validation.'
  },
  {
    value: 'mobile',
    label: 'Mobile phone number',
    description: 'Collect a UK mobile number with built-in validation.'
  },
  {
    value: 'ni',
    label: 'National Insurance number',
    description:
      'Collect a UK National Insurance number with built-in validation.'
  },
  {
    value: 'transcribe',
    label: 'Transcription test',
    description: `Give the respondent an audio file, ask them to transcribe it. Returns a 0-100 score of the quality of the transcription.`
  },
  {
    value: 'interview',
    label: 'Interview',
    description:
      'Give the respondent a series of audio prompts to give spoken responses to. Returns a 0-100 score of their language fluency. A transcription of their responses will also be available.'
  },
  {
    value: 'speaking',
    label: 'Speaking test',
    description:
      'Give the respondent some text to read out. Returns a 0-100 score of the clarity and accuracy of their speech.'
  },
  {
    value: 'hardware',
    label: 'Hardware & software test',
    subItems: [
      { value: 'location', inputType: 'location' },
      {
        value: 'memory',
        inputType: 'number',
        description: 'GB of RAM'
      },
      {
        value: 'speed',
        inputType: 'number',
        description: 'MB/s internet download speed'
      },
      { value: 'cpu', inputType: 'number', description: 'Number of CPU cores' },
      {
        value: 'platform',
        inputType: 'text',
        description: 'Operating system, e.g. Windows'
      },
      { value: 'screenWidth', inputType: 'number' },
      { value: 'screenHeight', inputType: 'number' },
      {
        value: 'audioInput',
        inputType: 'boolean',
        description: 'Microphone availability'
      },
      {
        value: 'audioOutput',
        inputType: 'boolean',
        description: 'Speakers availability'
      },
      {
        value: 'browser',
        inputType: 'text',
        description: 'Browser, e.g. Chrome'
      }
    ],
    description:
      "Records details about the respondent's machine, including location, memory, internet speed and more."
  },
  {
    value: 'quickfire',
    label: 'Quickfire',
    description:
      'Present sets of options to the respondent, asking them to choose an option from each set before the time runs out. Good for testing instictive responses'
  }
];
const types = [
  {
    label: 'Standard',
    options: [
      {
        value: 'info',
        label: 'Information',
        description:
          'Text to share info with respondents. No need for a response. Great for providing instructions or context.'
      },
      {
        value: 'text',
        label: 'Short Text',
        description:
          'Single-line text answer. Use for brief responses, like names or short answers.'
      },
      {
        value: 'textarea',
        label: 'Paragraph',
        description:
          'Multiple lines of text. Ideal for longer responses, such as comments or explanations.'
      },
      {
        value: 'checkbox',
        label: 'Checkbox',
        description:
          'A single checkbox. Perfect for yes/no questions, confirmations or agreement to T&Cs.'
      },
      // {
      //   value: 'radio',
      //   label: 'Radio',
      //   description:
      //     'Choose one option from a list. Great for questions where only one answer is possible.'
      // },
      {
        value: 'select',
        label: 'Select',
        description:
          'Pick one option from a dropdown. Useful for presenting a list of choices in a compact way.'
      },
      {
        value: 'multiSelect',
        label: 'Multi-Select',
        description:
          'Pick multiple options from a dropdown. Allows respondents to choose more than one answer.'
      },
      {
        value: 'date',
        label: 'Date',
        description:
          'Choose a date from a calendar. Perfect for questions related to dates, like birthdates or event dates.'
      },
      {
        value: 'datetime',
        label: 'Date & time',
        description:
          'Pick a date and time. Useful for scheduling or capturing specific moments.'
      },
      {
        value: 'time',
        label: 'Time',
        description:
          'Choose a specific time. Ideal for questions requiring a time-based response.'
      },
      {
        value: 'number',
        label: 'Number',
        description:
          'Type a numerical value. Use for questions that require a numeric response, like age or quantity.'
      },
      {
        value: 'range',
        label: 'Number range',
        description:
          'Slide to select a numerical range. Great for questions where respondents need to indicate a range of values.'
      },
      {
        value: 'audio',
        label: 'Audio',
        description:
          'Upload an audio file. Perfect for collecting voice responses or sound clips.'
      },
      {
        value: 'video',
        label: 'Video',
        description:
          'Upload a video file. Use for questions that involve visual responses or video feedback.'
      },
      {
        value: 'file',
        label: 'File',
        description:
          'Upload a file. Ideal for scenarios where respondents need to submit documents or images.'
      },
      {
        value: 'image',
        label: 'Image',
        description:
          'Upload an image. Use for questions that involve visual responses or images.'
      }
    ]
  },
  {
    label: 'Custom',
    options: customQuestionTypes
  }
];
const QuestionTextInput = ({ prefix }) => {
  const { getValues } = useFormContext();
  const formType = getValues('type');
  const inputType = getValues(`${prefix}inputType`);
  const label = getValues(`${prefix}label`);
  const getTypeLabel = type =>
    types.map(group => group.options.find(o => o.value === type)).find(o => o)
      ?.label;
  return (
    <WizardInput
      name={`${prefix}questionText`}
      label="Question text"
      placeholder="What is your name?"
      formControlProps={{
        height: 200
      }}
      type="texteditor"
      pluginProps={{
        aiWriter: {
          preprompt: `Write a question text for a ${getTypeLabel(
            inputType
          )} question on a ${formType} form called '${getValues(
            'name'
          )}'. For context, the form also has ${getValues('questions')
            .filter(q => q.label !== label)
            .map(
              q =>
                'a ' +
                getTypeLabel(q.inputType) +
                ' question with the text "' +
                q.questionText +
                '"'
            )
            .join(', ')}. `
        }
      }}
    />
  );
};
const CustomFormQuestion = ({ index, isSystem, defaultQuestion }) => {
  const prefix: `questions.${number}.` = `questions.${index}.`;
  const inputType = useWatch({ name: `${prefix}inputType` });
  const options = useWatch({ name: `${prefix}options` });
  const includeAudio = useWatch({ name: `${prefix}includeAudio` });
  const includeVideo = useWatch({ name: `${prefix}includeVideo` });
  const includeImage = useWatch({ name: `${prefix}includeImage` });
  const optional = useMemo(() => ({ required: false }), []);
  const { setValue, getValues } = useFormContext<{
    questions: (FormQuestion & { section: number })[];
  }>();
  const [showTypeConfirm, setShowTypeConfirm] = useState(false);
  const [pastType, setPastType] = useState(inputType);
  const handleUndoTypeChange = () => {
    setValue(`${prefix}inputType`, pastType);
    setShowTypeConfirm(false);
  };
  const handleConfirmTypeChange = () => {
    const vals = getValues(`questions.${index}`);
    const newVals = {
      ...defaultQuestion,
      id: vals.id,
      dataIndex: vals.dataIndex,
      formId: vals.formId,
      section: vals.section,
      sectionId: vals.sectionId,
      label: vals.label,
      questionText: vals.questionText,
      instruction: vals.instructions
    };
    setValue(`questions.${index}`, {
      ...newVals,
      inputType: vals.inputType
    });
    setShowTypeConfirm(false);
  };
  return (
    <Card.Body>
      <Row className="align-items-bottom">
        <Col xs={12} lg={4}>
          <WizardInput
            name={`${prefix}label`}
            label="ID"
            placeholder="name"
            registerProps={{
              maxLength: {
                value: 50,
                message: 'Cannot be longer than 50 characters'
              },
              readOnly: isSystem,
              validate: v => {
                //check if duplicate
                const questions = getValues('questions');
                if (questions.some((q, i) => q.label === v && i !== index)) {
                  return 'Name already exists';
                }
                return true;
              }
            }}
          />
        </Col>
        <Col xs={12} lg={8}>
          <ResponsiveModal
            show={showTypeConfirm}
            onHide={() => setShowTypeConfirm(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Changing this question's type will remove all settings from this
              question. Are you sure you want to continue?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" onClick={handleUndoTypeChange}>
                Cancel
              </Button>
              <LoadingButton variant="danger" onClick={handleConfirmTypeChange}>
                Confirm
              </LoadingButton>
            </Modal.Footer>
          </ResponsiveModal>
          <WizardInput
            name={`${prefix}inputType`}
            label="Type"
            // disabled={isSystem}
            type="select"
            options={types}
            registerProps={{
              readOnly: isSystem,
              onChange: () => {
                setPastType(inputType);
                if (!inputType) return;
                setShowTypeConfirm(true);
              }
            }}
          />
        </Col>
        <Col xs={12}>
          <QuestionTextInput prefix={prefix} />
        </Col>
      </Row>
      <Row>
        {inputType !== 'info' && (
          <WizardInput
            name={`${prefix}isRequired`}
            type="switch"
            registerProps={{ ...optional, readOnly: isSystem }}
            label="Required"
          />
        )}
        {!isSystem && <TypeSetup index={index} type={inputType} />}
        <Expandable
          label={e =>
            e ? (
              <>Hide settings</>
            ) : (
              <>
                Show settings
                <SettingsBadge className="mx-1" index={index} />
              </>
            )
          }
        >
          <Row>
            <Col>
              <WizardInput
                name={`${prefix}instructions`}
                label="Instructions"
                registerProps={optional}
                type="texteditor"
                pluginProps={{ height: 100, basic: true }}
                placeholder="Please be as precise as possible"
              />
              {!isSystem && (
                <>
                  <Masking index={index} />
                  <SettingsBox
                    title="Validation"
                    description="Cannot move forward unless response is..."
                  >
                    <Validation
                      index={index}
                      type={inputType}
                      options={options}
                    />
                    <CustomValidation
                      type={inputType}
                      name={'questions.' + index + '.validation'}
                      index={index}
                    />
                  </SettingsBox>
                </>
              )}

              <Screenout index={index} type={inputType} options={options} />
            </Col>
            <Col xxl={6}>
              <TagSelector name={`${prefix}tagIds`} />
              <DomainFieldSelector index={index} questionType={inputType} />
              <SettingsBox title="Image settings">
                <WizardInput
                  name={`${prefix}includeImage`}
                  type="switch"
                  registerProps={{ ...optional, readOnly: isSystem }}
                  label="Include image(s)"
                />
                {includeImage && (
                  <WizardInput
                    name={`${prefix}imageFile`}
                    disabled={isSystem}
                    type="image"
                    hideLabel
                    pluginProps={{ maxFiles: 99 }}
                  />
                )}
              </SettingsBox>
              <SettingsBox title="Audio settings">
                <WizardInput
                  name={`${prefix}includeAudio`}
                  type="switch"
                  registerProps={{ ...optional, readOnly: isSystem }}
                  label="Include audio"
                />
                {includeAudio && (
                  <AudioUploader
                    disabled={isSystem}
                    fileIdField={`questions.${index}.audioFile`}
                    urlField={`questions.${index}.audioUrl`}
                  />
                )}
              </SettingsBox>
              <SettingsBox title="Video settings">
                <WizardInput
                  name={`${prefix}includeVideo`}
                  type="switch"
                  registerProps={{ ...optional, readOnly: isSystem }}
                  label="Include video"
                />
                {includeVideo && (
                  <VideoUploader
                    disabled={isSystem}
                    fileIdField={`questions.${index}.videoFile`}
                    urlField={`questions.${index}.videoUrl`}
                  />
                )}
              </SettingsBox>
            </Col>
          </Row>
        </Expandable>
      </Row>
    </Card.Body>
    // </Card>
  );
};
const SettingsBadge = ({ index, ...rest }) => {
  const question: FormQuestion = useWatch({ name: 'questions.' + index });
  const counters = [
    question.includeAudio,
    question.includeVideo,
    question.masking?.length,
    question.validation?.length,
    question.screening?.length
  ];
  const count = counters.filter(x => x).length;
  return (
    !!count && (
      <Badge bg="warning" pill {...rest}>
        {count}
      </Badge>
    )
  );
};
CustomFormQuestion.propTypes = {
  index: PropTypes.number,
  isSystem: PropTypes.bool
};
export default CustomFormQuestion;
