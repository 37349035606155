import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { mutateObject } from 'helpers/utils';
import { DefaultCrudData } from 'hooks/defaultCrud/useDefaultCrud';
import React from 'react';
import { useWatch } from 'react-hook-form';
export type NamePrefix = `` | `${string}.`;
export const getDomainInput =
  <TData extends { id: number } = any>({
    namePrefix = ''
  }: {
    namePrefix?: NamePrefix;
  } = {}) =>
  ({
    name,
    ...props
  }: Omit<WizardInputProps, 'name'> & { name: keyof TData & string }) => {
    return <WizardInput name={namePrefix + name} {...props} />;
  };
/** Only allows editing when the record is created. Must be used in the context of a form with an id */
export const ImmutableInput = ({
  readOnly,
  component: Component,
  ...props
}: WizardInputProps & {
  component?: (props: WizardInputProps) => JSX.Element;
}) => {
  const id = useWatch({ name: 'id' });
  const Input = Component || WizardInput;
  return <Input {...props} readOnly={!!id || readOnly} />;
};
export const cloneDomainItem = <TNew, TData extends DefaultCrudData = any>(
  obj: TData,
  foreignKeys: string[] = []
) => {
  return mutateObject<TNew>(
    obj,
    key => {
      return key === 'id' || foreignKeys.includes(key);
    },
    () => undefined
  );
};
