import { EmployeePayrollRecord } from 'apis/flex/hr';
import DomainTable from 'components/common/DomainTable';
import React from 'react';

export default () => {
  return (
    <DomainTable<EmployeePayrollRecord>
      domain="employee-payroll-record"
      columns={[
        'employeeId',
        'payrollId',
        'bankName',
        'studentLoanPlan',
        'taxCode',
        { id: 'modifiedBy', domain: 'user' },
        'modifiedDate'
      ]}
      onNewClick={null}
    />
  );
};
