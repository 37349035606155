import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import AuthorStamp from './AuthorStamp';
import { Stamp } from './Stamp';

export const ApprovedStamp = ({ approvedBy, approvedDate }) => {
  return <Stamp date={approvedDate} by={approvedBy} label="Approved" />;
};
export const RejectedStamp = ({ rejectedBy, rejectedDate }) => {
  return (
    <Stamp
      date={rejectedDate}
      by={rejectedBy}
      label="Rejected"
      icon={'times-circle'}
    />
  );
};
export type ApprovalProps = {
  approvedBy?: number;
  approvedDate?: string | Date;
  rejectedBy?: number;
  rejectedDate?: string | Date;
};
export default ({
  approvedBy,
  approvedDate,
  rejectedBy,
  rejectedDate
}: ApprovalProps) => {
  return (
    <>
      {approvedDate && (
        <ApprovedStamp approvedBy={approvedBy} approvedDate={approvedDate} />
      )}
      {rejectedDate && (
        <RejectedStamp rejectedBy={rejectedBy} rejectedDate={rejectedDate} />
      )}
    </>
  );
};
