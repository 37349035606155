import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import React from 'react';
import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';

import { Col, Row } from 'react-bootstrap';
import Footer from 'components/footer/Footer';
export default ({ children }) => {
  return (
    <>
      <Section fluid className="py-0">
        <Logo className="position-fixed m-1" />
        <Row className="g-0 vh-100 flex-center align-content-start pb-7">
          <Col lg={10} xxl={8} className="pt-6 position-relative">
            <img
              className="bg-auth-circle-shape"
              src={bgShape}
              alt=""
              width="250"
            />
            <img
              className="bg-auth-circle-shape-2"
              src={shape1}
              alt=""
              width="150"
            />
            {children}
          </Col>
        </Row>
      </Section>
    </>
  );
};
