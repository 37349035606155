import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect
} from 'react';
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import WizardInputLabel from './WizardInputLabel';
import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldError,
  FieldErrors,
  RegisterOptions
} from 'react-hook-form';
import classNames from 'classnames';
import Wrapper from 'helpers/Wrapper';
import { WizardFormGroupProps } from './WizardInput';
import { ErrorBoundary } from '@sentry/react';
import InlineErrorBoundary from 'components/errors/InlineErrorBoundary';
export type RenderProps = {
  label: ReactNode;
  input: (children: ReactNode, hideFeedback?: boolean) => ReactNode;
  form: {
    field: ControllerRenderProps<any, string> & { id: string };
    errors: FieldErrors<any>;
    error: FieldError;
  };
};
export type WizardInputWrapperProps = {
  children: (
    renderProps: RenderProps
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  name: string;
  floatingLabel?: boolean;
  label: ReactNode;
  hideLabel?: boolean;
  formGroupProps?: WizardFormGroupProps;
  instruction?: ReactNode;
  endEl?: ReactNode;
  controllerProps?: Omit<ControllerProps, 'render' | 'name'>;
  registerProps?: RegisterOptions;
  id: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  onLoad?: () => void;
  flush?: boolean;
  ai?: boolean;
};
const WizardInputWrapper = ({
  children,
  name,
  floatingLabel,
  label,
  hideLabel,
  formGroupProps: { noMb, ...formGroupProps } = {},
  instruction,
  endEl,
  controllerProps,
  registerProps,
  id,
  suffix,
  prefix,
  flush = true,
  onLoad = () => {},
  ai
}: WizardInputWrapperProps) => {
  const renderFormGroup = (error: FieldError, errors: FieldErrors<any>) => {
    return (children, hideFeedback) => {
      const outerElClass = 'd-inline-grid';
      return (
        <Form.Group
          className={classNames('w-100 align-items-center', {
            'p-3 rounded-3 mb-3': !flush,
            'bg-danger-subtle rounded': error,
            'bg-success-subtle': Object.keys(errors).length > 0 && !error
          })}
        >
          <InputGroup
            className={classNames('w-100 align-items-center', {
              'is-invalid': error,
              'is-valid': Object.keys(errors).length > 0 && !error
            })}
          >
            {prefix && (
              <InputGroup.Text className={outerElClass}>
                {prefix}
              </InputGroup.Text>
            )}
            {/* <div className={'col-' + (12 - outerElCount)}> */}
            <Wrapper
              condition={!!floatingLabel}
              wrapper={c => <FloatingLabel label={label}>{c}</FloatingLabel>}
            >
              {children}
            </Wrapper>
            {/* </div> */}
            {suffix && (
              <InputGroup.Text className={outerElClass}>
                {suffix}
              </InputGroup.Text>
            )}
            {endEl && <div className={outerElClass}>{endEl}</div>}
          </InputGroup>
          {!hideFeedback && (
            <Form.Control.Feedback
              className="my-0 p-1 rounded-2"
              type="invalid"
            >
              {error?.message || error?.type}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      );
    };
  };
  const labelComponent = () => (
    <WizardInputLabel
      label={label}
      hideLabel={hideLabel}
      name={name}
      id={id}
      floatingLabel={floatingLabel}
      instruction={instruction}
      ai={ai}
      required={
        registerProps?.required === true ||
        typeof registerProps?.required === 'string' ||
        (typeof registerProps?.required !== 'boolean' &&
          !!registerProps?.required?.value)
      }
    />
  );
  useEffect(() => {
    onLoad();
  }, [name]);
  return (
    <>
      <Form.Group
        {...formGroupProps}
        className={classNames(
          'd-flex flex-column justify-content-between',
          { 'mb-3': !noMb },
          formGroupProps?.className
        )}
      >
        <WizardController
          name={name}
          registerProps={registerProps}
          controllerProps={controllerProps}
          render={({ field, formState: { errors }, fieldState: { error } }) => {
            // console.log(
            //   name,
            //   'Error checking in controller',
            //   field.value,
            //   errors,
            //   error
            // );
            return (
              <InlineErrorBoundary>
                {children({
                  label: labelComponent(),
                  input: renderFormGroup(error, errors),
                  form: {
                    field: { ...field, id },
                    errors,
                    error
                  }
                })}
              </InlineErrorBoundary>
            );
          }}
        ></WizardController>
      </Form.Group>
    </>
  );
};
const WizardController = ({ name, registerProps, controllerProps, render }) => {
  return (
    <Controller
      name={name}
      defaultValue={''}
      rules={registerProps}
      {...controllerProps}
      render={render}
    />
  );
};
export default WizardInputWrapper;
