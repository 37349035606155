import { DomainTableProps } from 'components/common/DomainTable';
import React, { ReactNode, useState } from 'react';
import useTargetGroups from '../useTargetGroups';
import { Project, TargetGroup } from 'apis/flex/projects';
import useResourceGroups from '../resourceGroups/useResourceGroups';
import { domainConfigs } from 'components/notification/config';
import DomainIcon from 'components/common/DomainIcon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Dropdown, Modal, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faCalendar,
  faClock,
  faCopy,
  faEdit,
  faTrash,
  faUserClock,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import CardDropdown from 'components/common/CardDropdown';
import { Tags } from 'components/common/TagSelector';
import { faPiedPiper } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useDomainRouter, {
  getDomainHome,
  getDomainItemUrl
} from 'hooks/useDomainRouter';
import { ActionButtons } from 'components/common/BottomBar';
import { useUser } from 'hooks/useUser';
import { useFieldArray, useWatch } from 'react-hook-form';
import IconButton from 'components/common/IconButton';
import { ResponsiveModal } from 'components/common/Modals';
import { TargetGroupWizard } from './TargetGroupDetail';
import { UpsertModal } from 'components/common/FieldArrayTable';
import { cloneDomainItem } from 'components/common/DomainInput';

export const tableProps: Omit<
  DomainTableProps<TargetGroup>,
  'domain' | 'crudHook'
> = {
  columns: [
    'projectName',
    'name',
    'target',
    { id: 'questionnaireId', domain: 'questionnaire' }
  ]
};
const KeyStat = ({ icon, value }: { icon: IconProp; value: ReactNode }) => {
  return (
    <Col xs="auto" className="text-800 h6 d-flex align-items-center">
      <FontAwesomeIcon icon={icon} transform="shrink-3 up-1" className="me-1" />
      <span>{value}</span>
    </Col>
  );
};
const TargetGroupCell = ({
  targetGroup,
  onEdit,
  onDelete,
  onClone
}: {
  targetGroup: TargetGroup;
  onEdit?: () => void;
  onDelete?: () => void;
  onClone: () => void;
}) => {
  const resource = targetGroup.resourceGroups?.[0];
  const { data: resourceGroup } = useResourceGroups({
    id: resource?.resourceGroupId,
    select: d => d[0]
  });
  const rgFormatter = domainConfigs['resource-group'].format;
  const handleEdit = () => {
    onEdit?.();
  };
  const handleDelete = () => {
    onDelete?.();
  };
  const handleClone = () => {
    onClone?.();
  };
  const user = useUser();
  const linkTo = targetGroup?.id
    ? getDomainItemUrl('target-group', user, targetGroup)
    : '#';
  const handleClick = e => {
    if (!targetGroup?.id) {
      e.stopPropagation();
      e.preventDefault();
      handleEdit();
    }
  };
  return (
    <div className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100">
      <div className="d-flex align-items-start align-items-sm-center">
        <Link to={linkTo} className="d-none d-sm-block" onClick={handleClick}>
          <DomainIcon
            icon={resourceGroup && rgFormatter(resourceGroup)?.icon}
            size={'md'}
            // bg={isSelected() ? 'primary-subtle' : '100'}
          />
        </Link>
        <div className="ms-1 ms-sm-3">
          <p className="fw-semi-bold mb-3 mb-sm-2">
            <Link to={linkTo} onClick={handleClick}>
              {targetGroup?.name}
            </Link>
          </p>
          <Row className="align-items-center gx-3 gy-2">
            <KeyStat icon={faBullseye} value={targetGroup?.target} />
            <KeyStat icon={faClock} value={targetGroup?.targetPerHour} />
            {!!targetGroup?.completedResourceUnits && (
              <KeyStat
                icon={faUserClock}
                value={
                  (
                    targetGroup.completedUnits /
                    targetGroup.completedResourceUnits
                  ).toFixed(2) + 'ph'
                }
              />
            )}
            {targetGroup.startDate && targetGroup.endDate && (
              <KeyStat
                icon={faCalendar}
                value={
                  format(new Date(targetGroup.startDate), 'dd/MM/yyyy') +
                  ' - ' +
                  format(new Date(targetGroup.endDate), 'dd/MM/yyyy')
                }
              />
            )}
            {targetGroup.methodologies && (
              <Col xs="auto" className="lh-1 me-3">
                <Tags
                  bg="warning"
                  ids={targetGroup.methodologies?.map(t => t.methodologyId)}
                >
                  <FontAwesomeIcon
                    icon={faPiedPiper}
                    transform="shrink-3 up-1 me-1"
                  />
                </Tags>
              </Col>
            )}
            {targetGroup.audiences && (
              <Col xs="auto" className="lh-1 me-3">
                <Tags
                  bg="warning"
                  ids={targetGroup.audiences?.map(t => t.audienceId)}
                >
                  <FontAwesomeIcon
                    icon={faUsers}
                    transform="shrink-3 up-1 me-1"
                  />
                </Tags>
              </Col>
            )}
            {targetGroup.tags && (
              <Col xs="auto" className="lh-1 me-3">
                <Tags ids={targetGroup.tags?.map(t => t.tagId)} />
              </Col>
            )}
          </Row>
        </div>
        <div className="d-flex justify-content-between ms-auto">
          <ProgressComparison
            main={{
              min: 0,
              max: targetGroup.target,
              now: targetGroup.completedUnits
            }}
            comparitor={{
              min: new Date(targetGroup.startDate).valueOf(),
              max: new Date(targetGroup.endDate).valueOf(),
              now: new Date().valueOf()
            }}
          />
          <ActionButtons
            drop="down"
            maxShown={0}
            actions={[
              {
                label: 'Edit',
                fn: handleEdit,
                icon: faEdit
              },
              {
                label: 'Clone',
                fn: handleClone,
                icon: faCopy
              },
              {
                label: 'Delete',
                variant: 'danger',
                fn: handleDelete,
                icon: faTrash
              }
            ]}
          />
          {/* <CardDropdown>
            <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
            <Dropdown.Item onClick={handleClone}>Clone</Dropdown.Item>
          </CardDropdown> */}
        </div>
      </div>
      <div className="border-bottom mt-4 mb-x1"></div>
    </div>
  );
};
const ProgressComparison = ({
  main,
  comparitor
}: {
  main: { min: number; max: number; now: number };
  comparitor: { min: number; max: number; now: number };
}) => {
  const getPercentage = (min: number, max: number, now: number) =>
    ((now - min) / (max - min)) * 100;
  return (
    <div>
      <ProgressBar
        min={0}
        max={main.max}
        now={main.now}
        variant={
          getPercentage(main.min, main.max, main.now) >=
          getPercentage(comparitor.min, comparitor.max, comparitor.now)
            ? 'success'
            : 'danger'
        }
      />
      <ProgressBar
        min={comparitor.min}
        max={comparitor.max}
        now={comparitor.now}
        variant="primary"
      />
    </div>
  );
};
export default ({
  projectId,
  targetGroups: _targetGroups
}: {
  projectId?: number;
  targetGroups?: TargetGroup[];
}) => {
  const { projectId: paramsId } = useParams();
  const pId = Number(projectId || paramsId);
  const { removeAsync, cloneAsync } = useTargetGroups({
    filters: { projectId: pId },
    useFilter: !!pId
  });
  const targetGroups = _targetGroups;
  const nav = useNavigate();
  const user = useUser();
  const handleEdit = (tg: TargetGroup, index: number) => () => {
    nav(getDomainItemUrl('target-group', user, tg));
    // if (pId) {
    // } else {
    //   setEditingIndex(index);
    //   setShowDetail(true);
    // }
  };
  const handleDelete = (tg: TargetGroup, index: number) => () => {
    if (tg.id) {
      removeAsync(tg.id);
    } else {
      // remove(index);
    }
  };
  const handleClone = (tg: TargetGroup, index: number) => () => {
    if (pId) {
      cloneAsync(tg.id);
    } else {
      // append(cloneDomainItem<TargetGroup>(tg, ['targetGroupId']));
    }
    // setEditingIndex(index + 1);
    // setShowDetail(true);
  };
  const handleNew = () => {
    // setEditingIndex(targetGroups.length);
    // append({} as TargetGroup);
    // setShowDetail(true);
    nav(
      getDomainItemUrl(
        'target-group',
        user,
        targetGroups?.[0] || { projectId: pId }
      )
    );
  };
  // const [showDetail, setShowDetail] = useState(false);
  // const [editingIndex, setEditingIndex] = useState<number>();
  return !pId ? (
    <div className="text-center text-muted">
      Save the project to start adding target groups
    </div>
  ) : (
    <>
      {targetGroups?.map((tg, i) => (
        <TargetGroupCell
          key={tg.name}
          targetGroup={tg}
          onEdit={handleEdit(tg, i)}
          onDelete={handleDelete(tg, i)}
          onClone={handleClone(tg, i)}
        />
      ))}
      {/* <UpsertModal hideButtons show={showDetail} setShow={setShowDetail}>
        <TargetGroupWizard
          onFinished={() => setShowDetail(false)}
          defaultValues={{ projectId: pId }}
          namePrefix={`targetGroups.${editingIndex}.`}
        />
      </UpsertModal> */}
      <div className="d-flex justify-content-end mt-2">
        <IconButton variant="falcon-primary" icon={'plus'} onClick={handleNew}>
          Add Target Group
        </IconButton>
      </div>
    </>
  );
};
