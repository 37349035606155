import { camelToSentence, isReactRenderable } from 'helpers/utils';
import React from 'react';
import CustomPopover from './CustomPopover';

const JSONLister = ({
  json,
  className,
  ulProps,
  liProps
}: {
  json: any;
  className?: string;
  ulProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  >;
  liProps?: React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  >;
}) => {
  const keys = Object.keys(json || {});
  return (
    <div className={className}>
      <ul {...ulProps}>
        {keys.map(k => (
          <li key={k} {...liProps}>
            <h6>
              {camelToSentence(k)}:{' '}
              {isReactRenderable(json[k]) ? (
                json[k]
              ) : typeof json[k] === 'boolean' ? (
                json[k].toString()
              ) : (
                <JSONLister json={json[k]} />
              )}
            </h6>
          </li>
        ))}
      </ul>
    </div>
  );
};
export const JSONCellFormatter = ({ children }) => {
  const formattedJSON = JSON.stringify(children, null, 2);

  const containerStyle = {
    fontFamily: 'monospace',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    borderRadius: '5px',
    whiteSpace: 'pre-wrap',
    border: '1px solid #ccc',
    maxHeight: '200px'
  };
  return (
    <CustomPopover
      body={
        <pre className="overflow-auto" style={containerStyle}>
          {formattedJSON}
        </pre>
      }
    >
      <div
        style={{
          maxWidth: '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {children && JSON.stringify(children)}
      </div>
    </CustomPopover>
  );
};
export default JSONLister;
