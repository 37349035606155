import { isValidJSON } from "helpers/strings";
import { callIfFunction } from "helpers/utils";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
export const useHash = (enabled, initialValue) => {
  const getHash = useCallback(
    (initialValue2) => window.location.hash ? decodeURIComponent(window.location.hash.slice(1)) : initialValue2,
    [window.location.hash]
  );
  const [hash, setHash] = useState(getHash(initialValue));
  useEffect(() => {
    if (!enabled) return;
    const windowHash = getHash(initialValue);
    if (hash !== windowHash) {
      window.location.hash = encodeURIComponent(hash);
    }
  }, [hash]);
  useEffect(() => {
    if (!hash) {
      const initialHash = getHash(initialValue);
      if (isValidJSON(initialHash)) {
        setHash(initialHash);
      } else {
        setHash("{}");
      }
    }
  }, [window.location.hash]);
  return [hash, setHash];
};
export const useHashParam = (key, initialValue, enabled = true) => {
  const [hash, setHash] = useHash(enabled, "{}");
  const [paramValue, setParamValue] = useState(initialValue);
  const parsedHash = (hash2 = "{}") => {
    if (!isValidJSON(hash2)) {
      setHash("{}");
      return {};
    } else {
      return JSON.parse(hash2);
    }
  };
  const param = useMemo(() => {
    const parsed = parsedHash(hash)?.[key];
    return parsed === void 0 ? initialValue : parsed;
  }, [hash, key]);
  useEffect(() => {
    if (!isEqual(param, paramValue)) {
      setParamValue(param);
    }
  }, [param]);
  const set = useCallback(
    (newVal) => {
      const updated = callIfFunction(newVal, param);
      const params = parsedHash(hash);
      const stateUpdated = { ...params, [key]: updated };
      setHash(JSON.stringify(stateUpdated));
      return updated;
    },
    [param, hash, key, setHash]
  );
  return [paramValue, set];
};
export const useUrlState = (key, initialValue, enabled = true) => {
  const urlKey = key;
  const [urlState, setUrlState] = useHashParam(
    urlKey,
    initialValue,
    enabled
  );
  const [state, setState] = useState(initialValue);
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    if (enabled) {
      return setValue(urlState);
    }
    setValue(state);
  }, [urlState, state]);
  const set = useCallback(
    (newVal) => {
      const updated = callIfFunction(newVal, value);
      if (enabled) {
        return setUrlState(updated);
      }
      return setState(updated);
    },
    [enabled, urlKey, value]
  );
  return [value, set];
};
