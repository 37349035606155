import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalProps, Offcanvas } from 'react-bootstrap';
import FalconCloseButton from './FalconCloseButton';
import LoadingButton from './LoadingButton';
import { useBreakpoints } from 'hooks/useBreakpoints';

export const ModalConfirm = ({
  children,
  title,
  body,
  cancelText,
  confirmText,
  variant,
  onConfirm = (done: () => void) => done(),
  onShow = () => {},
  extShow,
  hideButtons,
  isLoading,
  disabled,
  onHide = () => {},
  size = ''
}) => {
  const [show, setShow] = useState(extShow);
  const handleClose = () => {
    onHide();
    setShow(false);
  };
  const handleShow = () => {
    if (!disabled) {
      setShow(true);
    }
  };
  const handleConfirm = () => {
    onConfirm(() => {
      onHide();
      setShow(false);
    });
  };
  useEffect(() => {
    setShow(extShow);
  }, [extShow]);
  return (
    <>
      <div className="w-100 h-100" onClick={handleShow}>
        {children}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        onShow={onShow}
      >
        <Modal.Header>
          <Modal.Title>{title || 'Are you sure?'}</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        {body && <Modal.Body>{body}</Modal.Body>}
        {!hideButtons && (
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              {cancelText || 'Cancel'}
            </Button>
            <LoadingButton
              size={size as 'lg' | 'sm'}
              loading={isLoading}
              variant={variant || 'danger'}
              onClick={handleConfirm}
            >
              {confirmText || 'Confirm'}
            </LoadingButton>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
ModalConfirm.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  variant: PropTypes.string,
  onConfirm: PropTypes.func,
  onShow: PropTypes.func,
  extShow: PropTypes.bool,
  hideButtons: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onHide: PropTypes.func
};
export const ResponsiveModal = (
  props: Omit<ModalProps, 'children' | 'wide'> & {
    wide?: boolean;
    children: any;
  }
) => {
  const { breakpoints } = useBreakpoints();
  return (
    <>
      <Offcanvas
        placement="bottom"
        style={{ height: '85%', top: '15%' }}
        className="modal bg-light overflow-y-auto bottom-0 overflow-x-hidden"
        scroll
        {...props}
        show={breakpoints.down('md') ? props.show : false}
      >
        {props.children}
      </Offcanvas>

      <Modal
        fullscreen="md-down"
        {...props}
        show={breakpoints.down('md') ? false : props.show}
        dialogAs={() => (
          <Modal.Dialog
            fullscreen="md-down"
            style={{
              ...(props.wide ? { maxWidth: '60%' } : {}),
              maxHeight: '100%'
            }}
          >
            {props.children}
          </Modal.Dialog>
        )}
      ></Modal>
    </>
  );
};
ResponsiveModal.propTypes = Modal.propTypes;
