import React from 'react';
import WizardInput, { WizardInputComponent } from '../WizardInput';
import PdfOverlayer from 'components/app/documents/contracts/PdfOverlayer';
import FileUploader from 'components/files/FileUploader';
import useMediaFormContext from 'hooks/useMediaFormContext';
const WizardDocument: WizardInputComponent = ({ renderProps, name }) => {
  const {
    form: {
      field: { onChange, value }
    },
    input,
    label
  } = renderProps;
  const defaultValue = {
    fileId: null,
    fields: []
  };
  const { registerMedia } = useMediaFormContext();
  const handleChange = (value: any) => {
    registerMedia(name + '.fileId');
    onChange(value);
  };
  return (
    <>
      {label}
      {input(
        <>
          {!value.fileId?.length && (
            <FileUploader
              accept={{ 'application/pdf': [] }}
              dropzoneProps={{ maxFiles: 1 }}
              onChange={fileIds => {
                handleChange({ ...value, fileId: fileIds });
              }}
            />
          )}
          {!!value.fileId?.length && (
            <div className="w-100 text-center">
              <PdfOverlayer
                name={`${name}.fields`}
                pdf={value?.fileId}
                onReset={() => handleChange(defaultValue)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default WizardDocument;
