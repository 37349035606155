/* eslint-disable react/prop-types */
import React, {
  HTMLProps,
  JSXElementConstructor,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  AccessorColumnDef,
  Cell,
  CellContext,
  ColumnDefBase,
  ColumnFiltersColumnDef,
  CoreColumn,
  DisplayColumnDef,
  GroupColumnDef,
  HeaderContext,
  RowSelectionState,
  Row as RowType,
  SortingFn,
  Table,
  VisibilityColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel as getPaginationRowModelBase,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import { Card, CardProps, Dropdown, Form, Modal } from 'react-bootstrap';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import Error500 from 'components/errors/Error500';
import AdvanceTableHeader from './AdvanceTableHeader';
import AdvanceTableFooter from './AdvanceTableFooter';
import {
  callIfFunction,
  camelToSentence,
  getCommonDataValues,
  getMediaStreamUrl,
  mutateObject
} from 'helpers/utils';
import CardDropdown from '../CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { format, formatDistance } from 'date-fns';
import Error403 from 'components/errors/Error403';
import { getSqlEditProps } from './sql';
import {
  aggregate,
  CustomRule,
  getMinShiftHours,
  getRuleFunc,
  getValueParser,
  mergeRules,
  parseValueForComparison
} from 'helpers/validation/validate';
import _, { uniqueId } from 'lodash';
import TableTour from 'components/tours/TableTour';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ApiField, FileId } from 'apis/types';
import SelectedApplicants from 'components/app/recruitment/applicants/widgets/SelectedApplicants';
import { SelectedForms } from '../customForms/widgets/FormPicker';
import { SelectedTrainingCourses } from 'components/app/hr/training/widgets/TrainingCoursePicker';
import { SelectedContracts } from 'components/app/documents/contracts/ContractPicker';
import {
  DomainIconProps,
  SelectedDomainItemsProps,
  UsersIcon
} from 'components/app/users/widgets/selector/UserSelector';
import CustomTooltip from '../Tooltip';
import CustomPopover from '../CustomPopover';
import AnalysisScore from '../customForms/widgets/AnalysisScore';
import SoftBadge from '../SoftBadge';
import { HardwareSummary } from 'components/wizard/inputs/WizardHardware';
import Wrapper from 'helpers/Wrapper';
import { ModalConfirm, ResponsiveModal } from '../Modals';
import { SelectedDepartment } from 'components/app/hr/departments/SelectedDepartment';
import useColumnInfo from './useColumnInfo';
import Flex from '../Flex';
import CircleProgress from '../CircleProgress';
import { getRuleInputType } from 'helpers/validation/rules';
import { useBreakpoints } from 'hooks/useBreakpoints';
import FileDownloader from '../../files/FileDownloader';
import { useNewDomainNotifications } from 'components/notification/useNotifications';
import useActionNotification from 'components/notification/useActionNotification';
import {
  WizardInputOptions,
  WizardInputProps,
  WizardInputType
} from 'components/wizard/WizardInput';
import { SelectedResourceGroups } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import { EventPrefix } from 'apis/flex/notifications';
import { getSelectedDomainItems } from '../DomainItemSelector';
import { Updater } from '@tanstack/react-table';
import { CrudAggregation, CrudFilters } from 'hooks/defaultCrud/useDefaultCrud';
import { BottomBarAction } from '../detail/DetailPage';
import { domainConfigs } from 'components/notification/config';
import { domainToSentence } from '../DomainTimeline';
import useSelectionColumn from './useSelectionColumn';
import { useUrlState } from 'hooks/useSearchParamsState';
import { ApiError, getErrorCode } from 'apis/errors';

export type ProcessedColumnDef<T = any, V = unknown> = AccessorColumnDef<T, V> &
  Omit<
    ColumnDefBase<T, V> &
      Omit<DisplayColumnDef<T, V>, 'header'> &
      GroupColumnDef<any, V> &
      ColumnFiltersColumnDef<T>,
    'meta'
  > & {
    filter?: boolean | ((props: any) => ReactNode);
    isSelect?: boolean;
    isAction?: boolean;
    isChild?: boolean;
    editable?: boolean;
    header: string;
    visible?: boolean;
    download?: boolean | ((value: any) => string);
    headerDownload?: string;
    cellProps?: HTMLProps<HTMLTableCellElement>;
    wrap?: number;
    headerProps?: HTMLProps<HTMLTableCellElement>;
  };
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue = unknown>
    extends CustomFieldProps<TData> {
    editable?: boolean;
    download?: (val: TValue) => string;
    inputType?: string;
    registerProps?: {
      required?: boolean;
    };
    formControlProps?: {
      maxLength?: number;
      step?: string;
    };
    headerTooltip?: ReactNode;
    [key: string]: any;
  }
}
export type ProcessedCell<T = any, V = unknown> = Cell<T, V> & {
  column: CoreColumn<T, V> & {
    columnDef: ProcessedColumnDef<T, V>;
  };
};
export type ProcessedColumn<T = any, V = unknown> = Omit<
  CoreColumn<T, V>,
  'columnDef'
> & {
  columnDef: ProcessedColumnDef<T, V>;
};
export type ProcessedRow<T = any> = RowType<T> & {
  columns: (CoreColumn<T, unknown> & {
    columnDef: ProcessedColumnDef<T, unknown>;
  })[];
};
const domainItems: Partial<Record<EventPrefix, any>> = {
  applicant: SelectedApplicants,
  form: SelectedForms,
  user: UsersIcon,
  'training-course': SelectedTrainingCourses,
  contract: SelectedContracts,
  department: SelectedDepartment,
  'resource-group': SelectedResourceGroups
};
export type UseAdvanceTable<
  TData extends { id?: number } = any,
  TAccessed = TData
> = Omit<Table<any>, 'getAllFlatColumns'> & {
  animateDirection: 'forward' | 'backward';
  id: string;
  dataId: string;
  isLoading: boolean;
  title: string;
  domain: EventPrefix;
  getOnRowClick: () => RowClickHandler<TData>;
  getOnNewClick: () => (defaultValue?: Partial<TAccessed>) => void;
  getTableActions: () => BottomBarAction[];
  getTableSettings: () => TableSetting[];
  RowContextMenu: (any: any) => JSX.Element;
  pagination: {
    pageSize: number;
    pageIndex: number;
    isForward: boolean;
  };
  sorting: {
    id: string;
    desc: boolean;
  }[];
  filters: {
    id: string;
    value: FilterValue;
  }[];
  globalFilter: string;
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        value: FilterValue;
      }[]
    >
  >;
  getAllFlatColumns: () => ProcessedColumn[];
  editing: Partial<RowType<TAccessed>>[];
  setEditing: React.Dispatch<
    React.SetStateAction<Partial<RowType<TAccessed>>[]>
  >;
  updateRow: (row: RowType<TData>, update: Partial<TData>) => void;
  undoRowUpdate: (row: RowType<TData>) => void;
};
const TableContext = createContext<Partial<UseAdvanceTable>>({});
export const getTableContext = <
  TData extends { id?: number } = any,
  TAccessed extends { id?: number } = any
>() =>
  TableContext as React.Context<Partial<UseAdvanceTable<TData, TAccessed>>>;
export const useAdvanceTable = <
  TData extends { id?: number } = any,
  TAccessed extends { id?: number } = any
>() => useContext(getTableContext<TData, TAccessed>());

export const ActionMenu = <TData extends { id?: number } = any>({
  onEditClick,
  actions,
  row,
  dataAccessor,
  setEditing,
  globalActions
}: ActionMenuProps<TData>) => {
  return (
    <>
      {onEditClick && !actions && !globalActions && (
        <Dropdown.Item
          className="btn"
          onClick={() => onEditClick(dataAccessor(row))}
        >
          <FontAwesomeIcon icon={faEdit} /> <span className="ms-1">Edit</span>
        </Dropdown.Item>
      )}
      {globalActions &&
        row &&
        globalActions
          .filter(
            a =>
              a.name &&
              (a.show === undefined || callIfFunction(a.show, [row]) === true)
          )
          .map((action, ai) => (
            <Wrapper
              condition={!!action.confirm}
              key={ai}
              wrapper={c => (
                <ModalConfirm
                  body={callIfFunction(action.confirm, [row])}
                  onConfirm={done => action.actionFn?.([row], done)}
                >
                  {c}
                </ModalConfirm>
              )}
            >
              <GlobalActionItem
                action={action}
                row={row}
                setEditing={setEditing}
              />
            </Wrapper>
          ))}
      {/*TODO: remove & use globalActions exclusively*/}
      {actions &&
        row &&
        actions
          .filter(
            a =>
              a.name &&
              (a.show === undefined ||
                a.show === true ||
                (typeof a.show === 'function' && a.show(dataAccessor(row))))
          )
          .map((action, ai) => (
            <Wrapper
              condition={!!action.confirm}
              key={ai}
              wrapper={c => (
                <ModalConfirm
                  body={callIfFunction(
                    callIfFunction(action.confirm, dataAccessor(row)),
                    dataAccessor(row)
                  )}
                  onConfirm={done => action.onClick(dataAccessor(row), done)}
                >
                  {c}
                </ModalConfirm>
              )}
            >
              <ActionItem
                action={action}
                row={row}
                dataAccessor={dataAccessor}
                setEditing={setEditing}
              />
            </Wrapper>
          ))}
    </>
  );
};
const ActionItem = ({
  action,
  dataAccessor,
  row,
  setEditing
}: {
  action: Action;
  dataAccessor: (data: any) => any;
  row: any;
  setEditing: React.Dispatch<React.SetStateAction<Partial<RowType<any>>[]>>;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Dropdown.Item
        onClick={() => {
          if (action.isEdit) {
            // console.log('editing', dataAccessor(row));
            setEditing([dataAccessor(row)]);
          }
          if (action.modalOnClick) {
            return setShow(true);
          }
          !action.confirm && action.onClick?.(dataAccessor(row));
        }}
        className={`text-${action.variant} btn`}
        disabled={
          typeof action.disabled === 'function'
            ? action.disabled(row)
            : action.disabled
        }
      >
        {action.icon && (
          <FontAwesomeIcon
            icon={callIfFunction(action.icon, dataAccessor(row))}
            size="sm"
          />
        )}{' '}
        <div className="ms-1 d-inline">
          {callIfFunction(action.name, dataAccessor(row))}
        </div>
      </Dropdown.Item>
      {action.modal && action.modal(dataAccessor(row))}
      {action.modalOnClick && (
        <ResponsiveModal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {callIfFunction(action.name, dataAccessor(row))}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {action.modalOnClick({
              hide: () => setShow(false),
              row: dataAccessor(row)
            })}
          </Modal.Body>
        </ResponsiveModal>
      )}
    </>
  );
};
const GlobalActionItem = ({
  action,
  row,
  setEditing
}: {
  action: BulkAction<any>;
  row: any;
  setEditing: React.Dispatch<React.SetStateAction<Partial<RowType<any>>[]>>;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Dropdown.Item
        onClick={() => {
          if (action.isEdit) {
            // console.log('editing', dataAccessor(row));
            setEditing([row]);
          }
          if (action.modalOnClick) {
            return setShow(true);
          }
          !action.confirm && action.actionFn?.([row]);
        }}
        className={`text-${callIfFunction(action.variant, [row])} btn`}
        disabled={callIfFunction(action.disabled, [row])}
      >
        {action.icon && (
          <FontAwesomeIcon
            icon={callIfFunction(action.icon, [row])}
            size="sm"
          />
        )}{' '}
        <div className="ms-1 d-inline">
          {callIfFunction(action.name, [row])}
        </div>
      </Dropdown.Item>
      {action.modalOnClick && (
        <ResponsiveModal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{callIfFunction(action.name, [row])}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {action.modalOnClick({
              hide: () => setShow(false),
              rows: [row]
            })}
          </Modal.Body>
        </ResponsiveModal>
      )}
    </>
  );
};
type ActionMenuProps<T> = {
  onEditClick?: RowClickHandler<T>;
  actions: Action[];
  globalActions: BulkAction<T>[];
  row: RowType<any>;
  dataAccessor: (data: any) => any;
  setEditing: React.Dispatch<React.SetStateAction<Partial<RowType<any>>[]>>;
};
export const ActionCell = <TData extends { id?: number } = any>({
  row,
  actions,
  globalActions,
  onEditClick,
  dataAccessor,
  setEditing
}: ActionMenuProps<TData>) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <CardDropdown drop="start">
        <ActionMenu
          {...{
            row,
            actions,
            onEditClick,
            dataAccessor,
            setEditing,
            globalActions
          }}
        />
      </CardDropdown>
    </div>
  );
};
const DomainNewFlag = ({
  domain,
  row
}: {
  domain: EventPrefix;
  row: RowType<any>;
}) => {
  const { data } = useNewDomainNotifications({ domain });
  return (
    data?.some(d => d.itemId === row.original.id) && (
      <SoftBadge bg="success">New</SoftBadge>
    )
  );
};
const NotificationSeenPageListener = ({ domain }) => {
  const { data } = useNewDomainNotifications({ domain, enabled: !!domain });
  const { getPaginationRowModel, dataId } = useAdvanceTable();
  const { setSeen } = useActionNotification({ domain });
  useEffect(() => {
    const pageRows = getPaginationRowModel?.().rows;
    if (!data || !pageRows?.length || !domain) return;
    const ids = pageRows
      .map(r => r.original.id)
      .filter(i => !data?.some(d => d.itemId === i));
    if (ids?.length && domain) {
      setSeen({ itemId: ids });
    }
  }, [dataId]);
  return null;
};
const filterParsers = {
  name: (v: object) => (v ? Object.values(v).join(' ') : ''),
  postalAddress: (v: object) => (v ? Object.values(v).join(' ') : ''),
  shifts: (v: object) => (v ? Object.keys(v).join(' ') : '')
};
type FilterParserTypes = keyof typeof filterParsers;
const getFilterParser = (type: FilterParserTypes, filterType: string) => {
  return filterParsers[type] || getValueParser(type, filterType);
};
const convertRowToValue = (
  col: Partial<ColumnObject>,
  row: RowType<any>,
  colId: string
) => {
  const v = row.getValue(colId);
  return col.sortValue ? col.sortValue(v, row) : v;
};
const getSorterFn = <TData extends { id?: number } = any>(col, type) => {
  switch (type) {
    case 'date':
    case 'datetime':
      // console.log('getting sorter [date]', col, type);
      return (a, b) => {
        // console.log('sorting date', a, b);
        return new Date(a).valueOf() - new Date(b).valueOf();
      };
    case 'number':
      // console.log('getting sorter [number]', col, type);
      return (a, b) => a - b;
    case 'score':
      // console.log('getting sorter [score]', col, type);
      return (a, b) => getSorterFn<TData>(null, 'number')(a?.score, b?.score);
    case 'shifts':
      // console.log('getting sorter [shifts]', col, type);
      return (a, b) =>
        getMinShiftHours(a?.shifts) - getMinShiftHours(b?.shifts);
    default:
      // console.log('getting sorter [default]', col, type);
      return (a, b) => {
        if (Array.isArray(a)) {
          // console.log('array', a, b, a.length - b.length);
          return a?.length - b?.length;
        }
        if (!isNaN(a / 1)) {
          // console.log('number', a, b);
          return getSorterFn(null, 'number')(a, b);
        }
        // console.log(
        //   'string',
        //   a,
        //   b,
        //   parseValueForComparison(a),
        //   parseValueForComparison(b)
        // );
        return parseValueForComparison(a)?.localeCompare(
          parseValueForComparison(b)
        );
      };
  }
};
export function getSorter<TData = any>(
  col: Partial<ColumnObject<TData>>,
  type: string
): SortingFn<TData> {
  const sorterFn = getSorterFn<TData>(col, type);
  return (a, b, colId) => {
    const aVal = convertRowToValue(col, a, colId);
    const bVal = convertRowToValue(col, b, colId);
    // if (aVal===undefined|| && !bVal) return 0;
    return sorterFn(aVal, bVal);
  };
}
export function sqlValueFormatter(
  type: string,
  options?: WizardInputOptions
): (v: any) => ReactNode {
  if (options?.length > 0) {
    return (
      v:
        | string
        | { value: string; label: string }
        | string[]
        | { value: string; label: string }[]
    ) => {
      const varr = Array.isArray(v) ? v : [v];
      // console.log('matching up options', options, varr);
      const getValueLabel = (opts: WizardInputOptions) => {
        if (opts === undefined) return undefined;
        const checkForValue = (o: WizardInputOptions[number]) => {
          if (typeof o === 'string')
            return (
              varr.some(vv =>
                typeof vv == 'string' ? vv == o : vv?.value == o
              ) && { label: o }
            );
          if (o.options) return getValueLabel(o.options);
          return (
            varr.some(vv =>
              typeof vv == 'string' ? vv == o.value : vv?.value == o.value
            ) && { label: o.label }
          );
        };
        const found = opts.map(checkForValue).filter(d => d)?.[0];
        return found?.label;
      };
      return getValueLabel(options);
    };
  }
  switch (type) {
    case 'score':
      return v => {
        return (
          v && (
            <div>
              <CustomPopover
                title={'Analysis Score'}
                body={<AnalysisScore analysis={v} />}
              >
                <CircleProgress
                  now={v.score}
                  color={
                    v.score > 70
                      ? 'success'
                      : v.score > 50
                      ? 'warning'
                      : 'danger'
                  }
                />
              </CustomPopover>
            </div>
          )
        );
      };
    case 'file':
    case 'video':
    case 'audio':
      return (v: FileId[]) => v && <FileDownloader files={v} />;
    case 'range':
      return (v: number[]) => v && v[0] + ' - ' + v[2];
    case 'daterange':
      return (v: [Date, Date]) =>
        v &&
        format(new Date(v[0]), 'dd/MM/yyyy') +
          ' - ' +
          format(new Date(v[1]), 'dd/MM/yyyy');
    case 'date':
      return (v: Date) => {
        return v ? format(new Date(v), 'dd/MM/yyyy') : '';
      };
    case 'datetime':
      return (v: Date) =>
        v
          ? new Date().valueOf() - new Date(v).valueOf() < 24 * 60 * 60 * 1000
            ? formatDistance(new Date(v), new Date(), { addSuffix: true })
            : format(new Date(v), 'dd/MM/yyyy HH:mm:ss')
          : '';
    case 'bit':
    case 'switch':
    case 'checkbox':
    case 'boolean':
      return (v: boolean) => (
        <FontAwesomeIcon
          className={classNames('mx-1', v ? 'text-success' : 'text-danger')}
          icon={v ? faCheck : faTimes}
        />
      );
    case 'quickfire':
      return (v: any[]) => v.map(vv => vv).join(', ');
    case 'shifts':
      return (v: { [key: string]: string[] }) => {
        const shiftDays = v
          ? Object.keys(v).filter(k => v[k].filter(s => !!s).length > 0)
          : [];
        return (
          !!v && (
            <Flex wrap="wrap">
              {shiftDays?.map(k => (
                <CustomTooltip
                  key={k}
                  content={
                    <>
                      <div>{v[k]?.filter(s => !!s).join(', ')}</div>
                    </>
                  }
                >
                  <div>
                    <SoftBadge bg="success" pill>
                      {k}
                      <SoftBadge className="ms-1">
                        {v[k].filter(s => !!s).length}
                      </SoftBadge>
                    </SoftBadge>
                  </div>
                </CustomTooltip>
              ))}
            </Flex>
          )
        );
      };
    case 'name':
      return (v: { [key: string]: string }) => v && Object.values(v).join(' ');
    case 'postalAddress':
      return (v: { [key: string]: string }) => v && Object.values(v).join(', ');
    case 'transcribe':
      return v =>
        v && (
          <CustomTooltip content={v.text}>
            <div>{Math.round(v.score)}</div>
          </CustomTooltip>
        );
    case 'hardware':
      return v =>
        v && (
          <HardwareSummary
            hardware={v}
            className="gap-3"
            itemProps={{ size: '2x' }}
          />
        );
    default:
      return (v: any) => v;
  }
  // const getFormatter = () => {
  // };
  // const formatter = getFormatter();
  // return v => {
  //   const val = formatter(v);
  //   if (isReactRenderable(val)) {
  //     return val;
  //   }
  //   return '';
  // };
}
export function downloadValueFormatter(col: DbColumnInfo) {
  const basicParse = val => {
    if (val === null) return '';
    if (val === undefined) return '';
    if (typeof val === 'string') return val;
    if (typeof val === 'number') return val;
    if (typeof val === 'boolean') return val ? 'TRUE' : 'FALSE';
    if (Array.isArray(val)) return val.map(v => basicParse(v)).join(' | ');
    if (typeof val === 'object' && !!val) {
      Object.keys(val).forEach(k => (val[k] = basicParse(val[k])));
      return Object.entries(val)
        .map(vv => vv.join(': '))
        .join(', ');
    }
    return val.toString();
  };
  switch (col.type) {
    case 'file':
    case 'audio':
    case 'video':
      return (v: FileId[]) => v?.map(vv => getMediaStreamUrl(vv)).join(', ');
    case 'bit':
    case 'switch':
    case 'checkbox':
    case 'boolean':
      return v => (v ? 'TRUE' : 'FALSE');
    case 'transcribe':
      return v => v.score + ' | ' + v.text;
    case 'postalAddress':
      return v => v && Object.values(v).join(', ');
    case 'name':
      return v => v && Object.values(v).join(' ');
    default:
      return basicParse;
  }
}
type CustomFieldProps<
  T extends { id?: number } = any,
  TField extends (keyof T & string) | string = (keyof T & string) | string
> = {
  id?: TField;
  edit?: boolean;
  visible?: boolean;
  multiple?: boolean;
  isSqlColumn?: boolean;
  sortValue?: (
    v: TField extends keyof T & string ? T[TField] : any,
    row: RowType<T>
  ) => any;
  type?:
    | 'text'
    | 'textarea'
    | 'boolean'
    | 'number'
    | 'date'
    | 'datetime'
    | 'time'
    | 'daterange'
    | 'file'
    | 'video'
    | 'audio'
    | 'range'
    | 'icon'
    | 'currency'
    | 'currencyrange'
    | 'shifts'
    | 'name'
    | 'postalAddress'
    | 'switch'
    | 'checkbox'
    | 'rating'
    | 'bit'
    | string;
  header?: string;
  editor?:
    | WizardInputType
    | ((props: {
        inputProps: WizardInputProps;
        options: WizardInputOptions;
        active: boolean;
      }) => ReactNode);
  filterProps?: any;
  filter?: boolean | ((props: any) => ReactNode);
  optionsQuery?: QueryObserverOptions<
    any,
    Error,
    { label: string; value: any }[]
  >;
  options?: WizardInputOptions;
  sqlField?: string;
  sqlTable?: string;
  formatter?: <
    TTField extends (keyof T & string) | string = (keyof T & string) | string
  >(
    valuefn: () => any,
    rowData: T,
    row: RowType<T>
  ) => any;
  className?: string;
  headerProps?: HTMLProps<HTMLTableCellElement>;
  cellProps?: HTMLProps<HTMLTableCellElement>;
  domain?: EventPrefix;
  aggregation?: 'sum' | 'avg' | 'count' | 'min' | 'max';
  aggregationField?: string;
  isCompound?: boolean;
  headerTooltip?: ReactNode | (() => ReactNode);
};
export type ColumnObject<
  T extends { id?: number } = any,
  TField extends string = ApiField<T> | string,
  TAccessed = TField extends keyof T ? T[TField] : any
> = Omit<
  VisibilityColumnDef & GroupColumnDef<T, TField> & DisplayColumnDef<T, TField>,
  'columns'
> & {
  accessorFn?: (data: T) => TAccessed;
  id: TField;
  columns?: Column<T>[];
} & CustomFieldProps<T, TField>;
export type Column<T extends { id?: number } = any> =
  | ApiField<T>
  | ColumnObject<T>;
export type DbColumnInfo = {
  TABLE_NAME: string;
  COLUMN_NAME: string;
  DATA_TYPE: string;
  type?: string;
  options?: WizardInputOptions;
  IS_NULLABLE: boolean;
  MAXIMUM_LENGTH: number;
};
export type Action<T = any> = {
  isEdit?: boolean;
  name: ReactNode | ((row: RowType<T>) => ReactNode);
  variant?: string;
  modal?: (row: RowType<T>) => ReactNode;
  modalOnClick?: (props: { row: RowType<T>; hide: () => void }) => ReactNode;
  onClick: (row: RowType<T>, done?: () => void) => void;
  disabled?: boolean | ((row: T) => boolean);
  show?: boolean | ((row: T) => boolean);
  icon: IconProp | ((data: Partial<RowType<T>>) => IconProp);
  confirm?:
    | ActionConfirm<T>
    | ((data: Partial<RowType<T>>) => ActionConfirm<T>);
};
export type FilterValue = {
  value: any;
  type: string;
  active: boolean;
  aggregation?: CrudAggregation['type'];
};
export type ActionConfirm<T> =
  | string
  | boolean
  | ((data: Partial<RowType<T>>[]) => string | boolean);
export type BulkAction<T> = {
  name: ReactNode | ((data: Partial<RowType<T>>[]) => ReactNode);
  icon: IconProp | ((data: Partial<RowType<T>>[]) => IconProp);
  confirm?: ActionConfirm<T>;
  actionFn?: (
    data: Partial<RowType<T>>[],
    callback?: () => void
  ) => Promise<void> | void;
  modalOnClick?: (props: { rows: RowType<T>[]; hide: () => void }) => ReactNode;
  isEdit?: boolean;
  show?: boolean | ((data: Partial<RowType<T>>[]) => boolean);
  variant?: string | ((data: Partial<RowType<T>>[]) => string);
  disabled?: boolean | ((data: Partial<RowType<T>>[]) => boolean);
};
type TableSetting = {
  label: string;
  onChange: (checked: boolean) => void;
} & Partial<WizardInputProps>;
export type FilterDef<T> = {
  id: (keyof T & string) | 'remoteFilter';
  value: FilterValue;
};
export type RowClickHandler<T> = (row?: RowType<T>) => void;
export type RemoteData<TAccessed> = {
  totalCount: number;
  onFilter: (v: CustomRule, callback?: () => void) => void;
  onSort: (
    v: { id: keyof TAccessed & string; desc: boolean }[],
    callback?: () => void
  ) => void;
  onPaging: (
    v: { pageIndex: number; pageSize: number },
    callback?: () => void
  ) => void;
  paging: { pageIndex: number; pageSize: number; isLoading?: boolean };
  filters: { filter: CustomRule; isLoading?: boolean };
};
export type AdvanceTableProviderProps<
  T extends { id?: number } = any,
  TAccessed extends T = T
> = {
  flat?: boolean;
  cardProps?: CardProps;
  readOnly?: boolean;
  initialSort?: {
    id: keyof TAccessed & string;
    desc: boolean;
  }[];
  initialFilters?: FilterDef<TAccessed>[];
  children?: // | ReactNodeArray
  | ReactNode
    | JSX.Element
    | ((props: {
        rows: RowType<TAccessed>[];
        isLoading?: boolean;
      }) => ReactNode);
  /**
   * @deprecated use @param globalActions instead
   */
  actions?: Action<TAccessed>[] | ((data: TAccessed[]) => Action<TAccessed>[]);
  title?: string;
  sqlTables?: string[];
  sqlDb?: string;
  onRowClick?: RowClickHandler<TAccessed>;
  onNewClick?: (defaultValue?: Partial<T>) => void;
  remoteData?: RemoteData<TAccessed>;
  onFiltersChanged?: (v: FilterDef<TAccessed>[]) => void;
  /**
   * @deprecated use @param globalActions instead
   */
  bulkActions?:
    | BulkAction<TAccessed>[]
    | ((data: TAccessed[]) => BulkAction<TAccessed>[]);
  /** Actions that are available both per row and in bulk
   */
  globalActions?:
    | BulkAction<TAccessed>[]
    | ((data: T[]) => BulkAction<TAccessed>[]);
  selection?: boolean;
  updateFn?: (
    rows: Partial<RowType<TAccessed>>[],
    v: Partial<T>
  ) => Promise<any>;
  filterable?: ((keyof TAccessed & string) | string)[];
  notFilterable?: ((keyof TAccessed & string) | string)[];
  sortable?: ((keyof TAccessed & string) | string)[];
  notSortable?: ((keyof TAccessed & string) | string)[];
  editable?: (keyof TAccessed & string)[] | boolean;
  notEditable?: (keyof TAccessed & string)[];
  isLoading?: boolean;
  error?: ApiError | null;
  onDataLoaded?: (data: T[]) => TAccessed[];
  dataAccessor?: (d: Partial<RowType<T>>) => Partial<RowType<TAccessed>>;
  autoFocus?: boolean;
  tableActions?: BottomBarAction[];
  tableSettings?: TableSetting[];
  data?: T[];
  columns: Column<TAccessed>[];
  // dataQuery?: QueryObserverOptions<T[], any>;
  perPage?: number;
  footer?: boolean;
  header?: boolean;
  tabs?: {
    icon?: IconProp | ((count: number) => JSX.Element);
    label: string;
    filter?: (d: TAccessed) => boolean;
    crudFilter?: CrudFilters<TAccessed>;
  }[];
  onEditClick?: (
    v: Partial<RowType<TAccessed>>[],
    data: Partial<TAccessed>
  ) => void;
  isNew?: (row: RowType<TAccessed>) => boolean;
  /** @deprecated use useUrlState instead */
  searchParamsAsFilters?: boolean;
  animateDirection?: 'forward' | 'backward';
  /**
   * This can be used to decide when to re-render the whole table. Change it to force a re-render
   */
  id?: string;
  domain?: EventPrefix;
  sqlColumns?: DbColumnInfo[];
  globalSearchFields?: (keyof T & string)[];
  /** Gets passed to the onNewClick callback to populate default values for new records */
  defaultValue?: Partial<T>;
  /** Stores state information in the url */
  useUrlState?: boolean;
  refreshTrigger?: any;
};
type RowDataUpdate = Record<string, any>;
type DataUpdates = Record<string, RowDataUpdate[]>;
const AdvanceTableProvider = <
  TData extends { id?: number } = any,
  TAccessed extends TData = TData
>({
  initialSort = [],
  initialFilters = [],
  children,
  columns,
  data,
  // dataQuery,
  perPage = 10,
  footer = true,
  header = true,
  onEditClick,
  actions: _actions,
  bulkActions: _bulkActions,
  globalActions: _globalActions,
  title = '',
  sqlTables = [],
  sqlColumns,
  sqlDb,
  onRowClick,
  onNewClick,
  selection = !!_bulkActions || !!_globalActions,
  editable,
  notEditable,
  isLoading: tableLoading,
  error: tableError,
  onDataLoaded = d => d as TAccessed[],
  dataAccessor = row => row as any,
  autoFocus,
  tableActions = [],
  tableSettings,
  isNew,
  onFiltersChanged,
  searchParamsAsFilters = false,
  animateDirection,
  id: extId = null,
  domain,
  remoteData,
  filterable,
  notFilterable,
  sortable,
  notSortable,
  globalSearchFields,
  readOnly,
  cardProps,
  flat,
  defaultValue,
  useUrlState: __useUrlState = false,
  refreshTrigger
}: AdvanceTableProviderProps<TData, TAccessed>) => {
  // console.log('tableData', data, manualData, fetchedData);
  const colQueryEnabled = useMemo(
    () => sqlTables.length > 0 && !!sqlDb && !sqlColumns,
    [sqlTables, sqlDb, sqlColumns]
  );
  const { data: columnInfoData } = useColumnInfo({
    db: sqlDb,
    tables: sqlTables,
    enabled: colQueryEnabled,
    initialData: sqlColumns
  });
  const [editing, setEditingState] = useState<Partial<RowType<TAccessed>>[]>();
  const setEditing = (rows: Partial<RowType<TAccessed>>[]) => {
    // const accessed = rows.map(r => dataAccessor(r));
    if (onEditClick) {
      onEditClick(rows, getCommonDataValues(rows.map(r => r.original)));
    } else {
      setEditingState(rows);
    }
  };
  const canEdit = (!readOnly && editable) || notEditable || onEditClick;

  const [dataUpdates, setDataUpdates] = useState<DataUpdates>({});
  const updateRow = (row: RowType<TData>, update: Partial<TData>) => {
    const rowId = row.original.id?.toString() || 'index-' + row.id;
    setDataUpdates(d => {
      return {
        ...d,
        [rowId]: (d[rowId] || []).concat(update)
      };
    });
  };
  const undoRowUpdate = (row: RowType<TData>) => {
    const rowId = row.original.id?.toString() || 'index-' + row.id;
    setDataUpdates(d => {
      return {
        ...d,
        [rowId]: (d[rowId] || []).slice(1)
      };
    });
  };
  const tableData = useMemo(
    () =>
      onDataLoaded(data || []).map((d, i) => {
        const updates = dataUpdates[d.id?.toString() || 'index-' + i];
        let updatedRow = { ...d };
        updates?.forEach(update => {
          updatedRow = { ...updatedRow, ...update };
        });
        return updatedRow;
      }),
    [data, dataUpdates, refreshTrigger]
  );
  const actions = useMemo(
    () =>
      readOnly
        ? null
        : typeof _actions === 'function'
        ? _actions(
            tableData
              .map(d => dataAccessor({ original: d }))
              .map(r => r.original)
          )
        : _actions,
    [_actions, tableData, readOnly]
  );
  const bulkActions = useMemo(
    () =>
      readOnly
        ? null
        : callIfFunction(
            _bulkActions,
            tableData
              .map(d => dataAccessor({ original: d }))
              .map(r => r.original)
          ),
    [_bulkActions, tableData, readOnly]
  );
  const globalActions = useMemo(
    () => (readOnly ? null : callIfFunction(_globalActions, tableData)),
    [_globalActions, tableData, readOnly]
  );
  const RowContextMenu =
    actions === undefined && !globalActions && !canEdit
      ? null
      : ({ row }: { row: RowType<TData> }) => {
          if (!row) return null;
          return (
            <ActionMenu
              {...{
                row,
                actions,
                globalActions,
                onEditClick:
                  canEdit &&
                  function () {
                    setEditing([dataAccessor(row)]);
                  },
                dataAccessor,
                setEditing
              }}
            />
          );
        };
  // console.log('columnInfoData', columnInfoData);
  const processColumnDefs = (defs: typeof columns, level = 0) => {
    const columnsToRender = (
      defs ||
      Object.keys(tableData.length ? tableData[0] : {}).map(k => ({
        id: k as keyof TData & string
      }))
    ).filter(c => c);
    let arr: any[] = columnsToRender.map(column => {
      let col: Partial<ColumnObject<TAccessed>> = {};
      if (typeof column === 'string') {
        col.id = column;
      } else {
        col = column as ColumnObject<TAccessed, keyof TAccessed & string>;
      }
      const sqlColumnPartial: Partial<DbColumnInfo> = {
        ...((columnInfoData || []).find(
          sql =>
            (!col.sqlTable || sql.TABLE_NAME.toLowerCase() === col.sqlTable) &&
            sql.COLUMN_NAME.toLowerCase() ===
              (col.sqlField || col.id).toLowerCase()
        ) || {})
      };
      if (col.type) {
        sqlColumnPartial.DATA_TYPE = col.type;
      }
      if (col.options) {
        sqlColumnPartial.options = col.options;
      }
      sqlColumnPartial.type = sqlColumnPartial.DATA_TYPE;
      const sqlColumn = sqlColumnPartial as DbColumnInfo;
      const sqlProps = getSqlEditProps(sqlColumn);
      // console.log('sqlProps', sqlProps, sqlColumn);
      let colFormatter: any = ({ getValue }: CellContext<any, any>) => {
        return sqlValueFormatter(
          sqlProps.inputType,
          sqlColumn.options
        )(getValue());
      };
      if (col.aggregation) {
        if (!col.aggregationField) {
          throw new Error('aggregationField is required for aggregation');
        }
        colFormatter = ({ getValue }: CellContext<any, any>) => {
          const v = getValue();
          if (!Array.isArray(v)) {
            throw new Error('aggregationField must be an array');
          }
          return sqlValueFormatter('number')(
            aggregate(
              col.aggregation,
              (v as any[])?.map(v => v[col.aggregationField] as number) || []
            ).toString()
          );
        };
      }
      const domainFromKey = Object.keys(domainConfigs).find(
        domain => domainConfigs[domain].foreignKey === col.id.split('.').pop()
      ) as EventPrefix;
      if (!col.domain && !!domainFromKey) {
        col.domain = domainFromKey;
      }
      if (col.domain && domainConfigs[col.domain]?.crudHook) {
        const Icon: JSXElementConstructor<
          SelectedDomainItemsProps & DomainIconProps
        > = getSelectedDomainItems(col.domain) || domainItems[col.domain];
        colFormatter = ({ getValue }: CellContext<any, any>) => {
          const v = getValue();
          const arr = !v || Array.isArray(v) ? v : [v];
          return (
            <div
              style={{ width: 200 }}
              className="overflow-hidden"
              // onClick={e => e.stopPropagation()}
            >
              <Icon
                className="my-0 fw-normal"
                ids={arr}
                size={'xs'}
                bold={false}
                compact
                maxShown={1}
                selectedText={d =>
                  d[0]?.label +
                  (d.length > 1 ? ' +' + (d.length - 1) + ' more' : '')
                }
              />
            </div>
          );
        };

        // search fn
      }
      if (col.formatter) {
        colFormatter = ({ getValue, row }: CellContext<any, any>) => {
          return col.formatter(
            () =>
              sqlValueFormatter(
                sqlProps.inputType,
                sqlColumn.options
              )(getValue()),
            row.original,
            row
          );
        };
      }
      const isEditable = editable
        ? editable === true || !!editable.find(e => col.id == e)
        : notEditable
        ? !notEditable.find(e => col.id == e)
        : false;
      const downloadFormatter = (v: any) =>
        downloadValueFormatter(sqlColumn)(v)?.toString() || '';
      const header = col.id.split('.').map(camelToSentence).join(' > ');
      if (col.columns?.length) {
        return {
          id: col.id,
          header,
          columns: processColumnDefs(col.columns, level + 1),
          accessorFn: (d: TData) => d[col.id as keyof TData],
          meta: {}
        } as unknown as ProcessedColumnDef;
      }
      const prefix =
        header.split(' > ').length > 1
          ? header.split(' > ').slice(0, -1) + ' > '
          : '';
      const def: ProcessedColumnDef = {
        header:
          col.domain && typeof column === 'string'
            ? prefix + domainToSentence(col.domain)
            : header,
        filterFn: (row: RowType<any>, id: string, filterValue: FilterValue) => {
          // const colId = col.field;
          const { value, type, active, aggregation } = filterValue;
          if (!active) return true;
          const filterFunc = getRuleFunc(type, sqlProps.type);
          const getResult = rowVal =>
            filterFunc(
              getFilterParser(sqlProps.inputType, type)(rowVal),
              getValueParser(
                getRuleInputType(sqlProps.inputType, type),
                type
              )(value)
            );
          const rootColId = col.id.split('.')[0];
          const recursiveSome = (
            rrow: any,
            testFn: (row: any) => boolean,
            depth = 0
          ) => {
            if (!rrow) return recursiveSome(row.original, testFn, depth);
            const val = depth ? rrow[col.id.split('.')[depth]] : rrow;
            //if it's an object
            if (typeof val === 'object' && !!val && !Array.isArray(val)) {
              return recursiveSome(rrow, testFn, depth + 1);
            }
            //if it's a primitive and not an object
            if (!val || !Array.isArray(val) || typeof val[0] !== 'object') {
              const result = testFn(val);
              return result;
            }
            return val.some(r => recursiveSome(r, testFn, depth + 1));
          };
          // console.log(
          //   'applying filter',
          //   col.id,
          //   rootColId,
          //   row.original[rootColId],
          //   filterValue,
          //   sqlProps.type,
          //   filterFunc
          // );
          //if the value is an array, assume the dot notation refers to nested rows, so recursively call to get the inner value at the end
          if (Array.isArray(row.original[rootColId])) {
            return recursiveSome(row.original[rootColId], getResult);
          }
          //otherwise, dot notation refers to a single value so get it directly
          return getResult(row.getValue(col.id));
        },
        ...sqlProps,
        editable: isEditable,
        enableSorting:
          col.enableSorting !== false &&
          (!sortable?.length || sortable.includes(col.id)) &&
          (!notSortable?.length || !notSortable.includes(col.id)),
        enableColumnFilter:
          col.enableColumnFilter !== false &&
          (!filterable?.length || filterable.includes(col.id)) &&
          (!notFilterable?.length || !notFilterable.includes(col.id)),
        sortingFn: getSorter<TAccessed>(col, sqlProps.inputType),
        download: downloadFormatter,
        //hide column if contains a dot because it indicates the field is a compound one there for filtering only
        visible:
          col.visible === undefined ? !col.id.includes('.') : col.visible,
        cell: colFormatter,
        ...col,
        columns: undefined,
        accessorFn: (d: TData) => {
          if (col.accessorFn) {
            return col.accessorFn(dataAccessor({ original: d })?.original);
          }
          if (dataAccessor) {
            return dataAccessor({ original: d })?.original?.[
              col.id as keyof TData
            ];
          }

          if (col.id in d) {
            return d[col.id as keyof TData];
          }
          return null;
        },
        accessorKey: col.id,
        id: col.id,
        meta: {
          isCompound: col.isCompound || !!col.aggregation,
          ...sqlProps,
          editable: isEditable,
          download: downloadFormatter,
          ...col,
          inputType: col.options ? 'select' : col.type || sqlProps.inputType,
          headerTooltip:
            typeof col.headerTooltip === 'function'
              ? col.headerTooltip()
              : col.headerTooltip
        }
      };
      if (remoteData) {
        def.filterFn = () => true;
      }
      return def;
    });
    if ((sqlTables || sqlColumns) && level === 0) {
      arr = arr.concat(
        (columnInfoData || [])
          .filter(
            c =>
              //not already added to defs as a column
              !arr.some(
                d => d.id.toLowerCase() === c.COLUMN_NAME.toLowerCase()
              ) &&
              //if sqlColumns has been provided, it assumes the caller will decide which columns to add to defs. If not, we'll assume they want the columns of the first sql table
              (sqlColumns ||
                //bit of a fudge to check if column is a db column
                (tableData.some((d: any) => d[c.COLUMN_NAME] !== undefined) &&
                  //column exists in the first sql table
                  c.TABLE_NAME === sqlTables[0]))
          )
          .map(sqlCol => {
            const colId = sqlCol.COLUMN_NAME;
            // console.log('appending SQL column', colId);
            const isEditable = editable
              ? editable === true || !!editable.find(e => colId == e)
              : notEditable
              ? !notEditable.find(e => colId == e)
              : false;
            const sqlProps = getSqlEditProps(sqlCol);
            return {
              id: colId,
              visible: false,
              header: camelToSentence(colId),
              enableColumnFilter: true,
              filterFn:
                !remoteData &&
                ((row: RowType<any>, id: number, filterValue: FilterValue) => {
                  const { value, type, active } = filterValue;
                  if (!active) return true;
                  const filterFunc = getRuleFunc(type, sqlProps.type);
                  // console.log(
                  //   'applying filter',
                  //   colId,
                  //   row.original[colId],
                  //   filterValue,
                  //   sqlProps.type,
                  //   filterFunc
                  // );
                  return filterFunc(row.original[colId], value);
                }),
              ...sqlProps,
              editable: isEditable,
              meta: {
                //only columns fetched from the API Crud service meta endpoint will have dots in the column names (to indicate the column exists in a nested table)
                isCompound: colId.includes('.'),
                ...sqlProps,
                editable: isEditable,
                inputType: sqlProps.inputType
              }
            };
          })
      );
    }
    if ((actions || canEdit) && level === 0) {
      arr.push({
        accessorKey: 'Action',
        id: 'Action',
        size: 30,
        isAction: true,
        enablePinning: false,
        enableSorting: false,
        download: false,
        enableColumnFilter: false,
        header: '',
        headerProps: {
          className: 'text-end ps-0 pe-2'
        },
        cellProps: {
          className: 'text-end ps-0 pe-2'
        },
        cell: ({ row }: CellContext<any, any>) => (
          <div onClick={e => e.stopPropagation()}>
            <CardDropdown drop="start">
              <RowContextMenu row={row} />
            </CardDropdown>
          </div>
        )
      });
    }
    return arr;
  };
  const defs: ProcessedColumnDef[] = useMemo(() => {
    // console.log('running defs', columnInfoData);
    return processColumnDefs(columns);
  }, [columns, actions, columnInfoData]);
  // useEffect(() => {
  //   console.log('columns changed');
  // }, [columns]);
  // useEffect(() => {
  //   console.log('actions changed');
  // }, [actions]);
  // useEffect(() => {
  //   console.log('columnInfoData changed');
  // }, [columnInfoData]);
  // console.log('table data', tableData, columns, defs);

  const columnVisibility = useMemo(
    () =>
      defs.reduce((a, b) => {
        a[b.id] = b.visible === false ? false : true;
        return a;
      }, {}),
    [defs]
  );
  const [sorting, setSorting] = useState(initialSort);
  const [filters, setFilters] = useUrlState(
    'filters',
    initialFilters,
    __useUrlState
  );
  // console.log('filters in table provider', filters);
  const [search, setSearch] = useState('');
  const [pagination, setPaginationState] = useUrlState(
    'page',
    {
      pageSize: perPage,
      pageIndex: 0,
      isForward: null
    },
    __useUrlState
  );
  const [selectionState, setSelectionState] = useUrlState<RowSelectionState>(
    'selection',
    {},
    __useUrlState
  );
  const setPagination = (newPagination: {
    pageSize: number;
    pageIndex: number;
  }) => {
    setPaginationState({
      ...newPagination,
      isForward: newPagination.pageIndex > pagination.pageIndex
    });
  };
  const columnsWithNewFlag: (Omit<ProcessedColumnDef, 'header'> & {
    header: any;
  })[] = useMemo(() => {
    return isNew || domain
      ? [
          {
            id: 'isNew',
            enableColumnFilter: false,
            download: false,
            // headerProps: { style: { width: '100px' } },
            maxSize: 40,
            enablePinning: false,
            cellProps: { className: 'ps-2 p-0' },
            header: () => <></>,
            cell: ({ row }: CellContext<any, any>) =>
              isNew?.(row) ? (
                <SoftBadge bg={'success'}>New</SoftBadge>
              ) : (
                domain && <DomainNewFlag domain={domain} row={row} />
              )
          } as Omit<ProcessedColumnDef, 'header'> & { header: any }
        ].concat(defs)
      : defs;
  }, [defs]);
  const columnsWithSelect: (Omit<ProcessedColumnDef, 'header'> & {
    header: any;
  })[] = useSelectionColumn({
    selection,
    columns: columnsWithNewFlag
  });
  const { breakpoints } = useBreakpoints();
  const {
    getHeaderGroups,
    getLeftHeaderGroups,
    getRightHeaderGroups,
    getRowModel,
    setGlobalFilter,
    getFilteredSelectedRowModel,
    getIsSomeRowsSelected,
    toggleAllRowsSelected,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
    getPrePaginationRowModel,
    getAllFlatColumns,
    getAllColumns,
    getIsAllRowsSelected,
    resetRowSelection,
    getSelectedRowModel,
    getColumn,
    getPaginationRowModel,
    setSorting: setTableSort,
    setColumnFilters,
    setPagination: setTablePagination
  } = useReactTable({
    columns: columnsWithSelect,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModelBase(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enablePinning: true,
    rowCount: remoteData?.totalCount,
    manualPagination: !!remoteData,
    manualFiltering: !!remoteData,
    manualSorting: !!remoteData,
    onGlobalFilterChange: setSearch,
    onColumnFiltersChange: (updater: Updater<FilterDef<TAccessed>[]>) => {
      const vals = typeof updater === 'function' ? updater(filters) : updater;
      onFiltersChanged?.(vals);
      if (remoteData) {
        const remoteFilter = vals?.find(f => f.id === 'remoteFilter')?.value;
        const rule = remoteFilter?.value as CustomRule;
        const others = vals.filter(f => f.id !== 'remoteFilter');
        const merged = mergeRules(
          rule,
          others
            .filter(v => v.value.active)
            .map(f => {
              return [
                {
                  type: f.value.type,
                  value: f.value.value,
                  question: f.id
                }
              ];
            })
        );
        const searchFilter = search
          ? [
              (
                globalSearchFields ||
                defs.filter(
                  c => c.visible !== false && c.meta?.inputType === 'text'
                )
              ).map(c => ({
                value: search,
                question: typeof c === 'string' ? c : c.id,
                type: 'contains'
              }))
            ]
          : null;

        const withGlobal = mergeRules(merged, searchFilter);
        const withColumns = withGlobal?.map(
          and =>
            and?.map(or => ({ ...or, column: getColumn(or.question) })) || []
        );
        remoteData?.onFilter(withColumns, () => setFilters(vals));
      } else {
        setFilters(vals);
      }
      // console.log('setting filtewrs', vals);
    },
    onPaginationChange: updater => {
      const val = typeof updater === 'function' ? updater(pagination) : updater;
      if (remoteData) {
        return remoteData?.onPaging(val, () => setPagination(val));
      }
      setPagination(val);
    },
    onRowSelectionChange: setSelectionState,
    state: {
      sorting,
      columnFilters: filters,
      pagination: pagination,
      columnVisibility,
      globalFilter: search,
      rowSelection: selectionState
    },
    initialState: {
      columnPinning: breakpoints.up('sm')
        ? { right: ['Action'], left: ['select'] }
        : {}
    },
    enableRowSelection: selection,
    enableMultiRowSelection: selection,
    onSortingChange: (
      newstate: Updater<{ id: keyof TAccessed & string; desc: boolean }[]>
    ) => {
      remoteData?.onSort(
        typeof newstate === 'function' ? newstate(sorting) : newstate
      );
      setSorting(newstate);
    }
    // debugAll: true
  });
  useEffect(() => {
    // console.log('Table provider heard search change', search);
    setColumnFilters(filters);
  }, [search]);
  const { setActioned } = useActionNotification({ domain });
  const rowClickWrapper =
    (fn: (row: RowType<TData>) => void) => (row: RowType<TData>) => {
      if (domain) {
        setActioned({ domain, itemId: row.original.id });
      }
      return fn(row);
    };
  const getOnRowClick = useCallback<
    (
      onRowClick: RowClickHandler<any>,
      dataAccessor: any
    ) => RowClickHandler<any>
  >(
    (onRowClick, dataAccessor) => {
      return rowClickWrapper(
        onRowClick ||
          (selection
            ? function (row: RowType<any>) {
                row.toggleSelected();
              }
            : canEdit
            ? function (row: RowType<any>) {
                setEditing([dataAccessor(row)]);
              }
            : function () {})
      );
    },
    [domain, setActioned]
  );
  const accessedBulkActions: BulkAction<TAccessed>[] = (bulkActions || []).map(
    action => ({
      ...action,
      actionFn: (rows: Partial<RowType<any>>[], done) =>
        action.actionFn(
          rows.map(r => dataAccessor(r)),
          done
        )
    })
  );
  const error = tableError;
  const id = useMemo(() => uniqueId('table-' + (extId || '')), [extId]);
  const TableContext = getTableContext<TData, TAccessed>();
  const tableDataId = useMemo(() => uniqueId('tableData-'), [data, defs]);
  // if (!canView && !!domain && !isSelf)
  //   return (
  //     <Error403 error={{ data: { requiredPermissions: [domain + '.view'] } }} />
  //   );

  return error ? (
    getErrorCode(error) === 403 ? (
      <Error403 button={false} error={error} />
    ) : (
      <Error500 error={error} />
    )
  ) : (
    <TableContext.Provider
      value={{
        globalFilter: search,
        animateDirection,
        id,
        dataId: tableDataId,
        getHeaderGroups,
        getLeftHeaderGroups,
        getRightHeaderGroups,
        getRowModel,
        setGlobalFilter,
        getFilteredSelectedRowModel,
        getPrePaginationRowModel,
        getIsSomeRowsSelected,
        toggleAllRowsSelected,
        setPagination: setTablePagination,
        pagination,
        getCanPreviousPage,
        getCanNextPage,
        nextPage,
        previousPage,
        getAllColumns: getAllColumns,
        getAllFlatColumns: getAllFlatColumns as () => ProcessedColumn<TData>[],
        sorting,
        setSorting: setTableSort,
        setFilters: setColumnFilters,
        filters,
        isLoading: tableLoading,
        title,
        getOnRowClick: () => getOnRowClick(onRowClick, dataAccessor),
        getOnNewClick: () =>
          onNewClick
            ? () => onNewClick?.(tableData?.[0] || defaultValue)
            : undefined,
        getIsAllRowsSelected,
        getSelectedRowModel,
        resetRowSelection,
        getTableActions: () => tableActions,
        getTableSettings: () => tableSettings,
        RowContextMenu,
        editing,
        setEditing,
        getColumn,
        getPaginationRowModel,
        domain,
        updateRow,
        undoRowUpdate
      }}
    >
      {tableData && (
        <Card
          className={classNames('tour-advance-table', cardProps?.className, {
            'shadow-none': !!flat
          })}
          {...cardProps}
        >
          {header && (
            <AdvanceTableHeader
              setEditing={setEditing}
              bulkActions={accessedBulkActions
                .concat(globalActions)
                .filter(Boolean)}
              edit={!!editable || !!notEditable}
              autoFocus={autoFocus}
              remoteFilters={remoteData?.filters}
            />
          )}
          <NotificationSeenPageListener domain={domain} />
          {typeof children === 'function'
            ? children({
                rows: getRowModel().rows,
                isLoading: tableLoading
              })
            : children}
          {footer && (
            <AdvanceTableFooter
              remoteData={remoteData}
              viewAllBtn={null}
              className={null}
            />
          )}
        </Card>
      )}
      {/* <TableTour /> */}
    </TableContext.Provider>
  );
};
export default AdvanceTableProvider;
