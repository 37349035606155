import React, { ReactNode } from 'react';
import { Card, Col, Modal } from 'react-bootstrap';
import Contact from './Contact';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ReviewStage from './ReviewStage';
import CustomFormViewer from 'components/common/customForms/Reporting/CustomFormViewer';
import {
  acceptApplicants,
  Applicant,
  rejectApplicants
} from 'apis/flex/recruitment';
import ApplicantTimeline from './ApplicantTimeline';
import DomainDetail from 'components/common/DomainDetail';
import DetailCard from 'components/common/detail/DetailCard';
import {
  BottomBarAction,
  useDetailPage
} from 'components/common/detail/DetailPage';
import { useGuard } from 'hooks/useGuard';
import {
  faFastForward,
  faStar,
  faThumbsDown,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { ensureArray } from 'helpers/utils';
import { AcceptForm } from '../AcceptApplicant';
import { RejectForm } from '../RejectApplicant';
import { ApplicantScoreForm } from '../ScoreForm';
import { ApplicantStageForm } from '../MoveToStage';
import ActionFooter from 'components/common/ActionFooter';
export const ApplicantActionModal = ({
  data,
  hide,
  mutationFn,
  form
}: {
  data: Applicant[];
  hide: () => void;
  mutationFn: (props: { ids: number[]; vals: any }) => Promise<any>;
  form: (data: Applicant[], methods: UseFormReturn<Applicant>) => ReactNode;
}) => {
  const methods = useForm<Applicant>();
  const { mutate, isLoading } = useMutation<
    any,
    Error,
    { ids: number[]; vals: any }
  >({
    mutationFn: props => mutationFn(props),
    onSuccess: () => hide()
  });
  const handleSubmit = methods.handleSubmit(vals => {
    mutate({ ids: ensureArray(data).map(d => d.id), vals });
  });
  return (
    <FormProvider {...methods}>
      {form(data, methods)}
      <Modal.Footer>
        <ActionFooter
          onCancel={hide}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </Modal.Footer>
    </FormProvider>
  );
};
const Sidebar = () => {
  const { data, isLoading } = useDetailPage<Applicant>();
  return <Contact data={data} isLoading={isLoading} />;
};
const FormViewer = () => {
  const { data } = useDetailPage<Applicant>();
  return (
    <CustomFormViewer
      formId={data?.applicationFormId}
      responseId={data?.responseId}
    />
  );
};
const Stages = () => {
  const { data: applicant } = useDetailPage<Applicant>();
  return applicant?.stages
    ?.filter(stage => stage.responseId)
    .map((stage, i) => (
      <Col xs={12} key={i}>
        <Card>
          <FalconCardHeader titleTag={'h6'} title={stage.stageName} />
          <ReviewStage applicant={applicant} applicantStageId={stage?.id} />
        </Card>
      </Col>
    ));
};
const Timeline = () => {
  const { data: applicant } = useDetailPage<Applicant>();
  return <ApplicantTimeline applicant={applicant} />;
};
export const getApplicantActions = ({
  applicants = [],
  canEdit
}: {
  applicants?: Applicant[];
  canEdit?: (applicants: Applicant[]) => boolean;
}): BottomBarAction[] => {
  const disabled = d => !canEdit?.(d);
  return [
    {
      label: 'Accept',
      variant: 'success',
      isPrimary: true,
      disabled,
      icon: faThumbsUp,
      show: (d = applicants) => d.every(s => !s.acceptReject),
      modal: ({ hide, data = applicants }) => {
        return (
          <ApplicantActionModal
            data={data}
            hide={hide}
            mutationFn={props => acceptApplicants(props.ids, props.vals)}
            form={d => <AcceptForm ids={d.map(d => d.id)} />}
          />
        );
      }
    },
    {
      label: 'Reject',
      variant: 'danger',
      isPrimary: true,
      disabled,
      icon: faThumbsDown,
      show: (d = applicants) => d.every(s => !s.acceptReject),
      modal: ({ hide, data = applicants }) => {
        return (
          <ApplicantActionModal
            data={data}
            hide={hide}
            mutationFn={props =>
              rejectApplicants(
                props.ids,
                null,
                props.vals.notificationTemplateId
              )
            }
            form={() => <RejectForm />}
          />
        );
      }
    },
    {
      label: 'Add score',
      variant: 'info',
      disabled,
      icon: faStar,
      modal: ({ hide, data = applicants }) => {
        return (
          <ApplicantScoreForm
            applicantIds={data.map(a => a.id)}
            onFinished={() => hide()}
          />
        );
      }
    },
    {
      label: 'Move stage',
      icon: faFastForward,
      disabled,
      show: (d = applicants) => d.every(s => !s.acceptReject),
      modal: ({ hide, data = applicants }) => {
        return (
          <ApplicantStageForm
            campaignId={data[0]?.campaignId}
            applicantIds={data.map(a => a.id)}
            onFinished={() => hide()}
          />
        );
      }
    }
  ];
};
const ApplicantDetail = () => {
  // console.log('data', data);
  const { canEdit } = useGuard({ roles: ['applicant'] });
  return (
    <DomainDetail
      domain="applicant"
      sidebar={<Sidebar />}
      actions={(data: Applicant) =>
        getApplicantActions({
          applicants: data && [data],
          canEdit: () => canEdit
        })
      }
    >
      <DetailCard id="application" title="Application">
        <FormViewer />
      </DetailCard>
      <DetailCard id="stages" title="Stages">
        <Stages />
      </DetailCard>
      <DetailCard id="timeline" title="Timeline">
        <Timeline />
      </DetailCard>
    </DomainDetail>
  );
};
// const ApplicantBottomBar = () => {
//   return (
//     <>
//       <BottomBar
//         isLoading={isLoading}
//         title={applicant?.firstName + ' ' + applicant?.surname}
//         avatar={profileImg}
//         actions={actions}
//       />
//     </>
//   );
// };
export default ApplicantDetail;
