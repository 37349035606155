import EChartsReactCore from 'echarts-for-react/lib/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React, { forwardRef, useMemo } from 'react';
import { ReportComponentProps, Series, XAxis, YAxis } from '../helpers';
import { getChartOptions } from './chartOptions';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

export default forwardRef<EChartsReactCore, ReportComponentProps>(
  (
    {
      type,
      series = [],
      xAxes = [],
      yAxes = [],
      simple,
      height,
      stacked,
      onClick
    }: {
      type: 'line' | 'pie' | 'bar';
      series: Series[];
      xAxes: XAxis[];
      yAxes?: YAxis[];
      simple?: boolean;
      height?: number | string;
      stacked?: boolean;
      onClick?: (params: any) => void;
    },
    ref
  ) => {
    const optionMemo = useMemo(
      () => getChartOptions(type, stacked)(simple, xAxes, yAxes, series),
      [xAxes, yAxes, series]
    );
    return (
      <>
        <ReactEChartsCore
          ref={ref}
          echarts={echarts}
          option={optionMemo}
          style={{
            height: height || (simple ? 87 : '20rem'),
            width: '100%'
          }}
          onEvents={{
            click: params => {
              onClick?.(params);
            }
          }}
        />
      </>
    );
  }
);
