import { Webhook } from 'apis/flex/helpers';
import DomainTable from 'components/common/DomainTable';
import { domainToSentence } from 'components/common/DomainTimeline';
import React from 'react';

export default () => {
  return (
    <DomainTable<Webhook>
      domain="webhook"
      columns={[
        'name',
        { id: 'domain', formatter: v => domainToSentence(v()) },
        'action',
        // 'url',
        'isActive',
        'transactions',
        {
          id: 'lastTransaction',
          type: 'datetime',
          accessorFn: r => r.lastTransaction?.sentDate
        },
        { id: 'lastStatus', accessorFn: r => r.lastTransaction?.status }
        // { id: 'lastResponse', accessorFn: r => r.lastTransaction?.response }
      ]}
    />
  );
};
