import { useMutation } from "@tanstack/react-query";
export default ({ afterSave, api, filters, afterChange }) => {
  const { mutate: refire, isLoading: isRefiring } = useMutation({
    mutationFn: async ({ action }) => await api.refireEvents(filters, action),
    onSuccess: (d) => {
      if (afterSave) {
        return afterSave(d);
      }
      if (afterChange) {
        return afterChange();
      }
    }
  });
  return {
    refire,
    isRefiring
  };
};
