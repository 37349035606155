import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessGroup } from 'apis/flex/auth';
import { SystemTag } from 'apis/flex/helpers';
import {
  Department,
  EmployeeContract,
  TrainingCourse,
  UserTrainingCourse
} from 'apis/flex/hr';
import { EventPrefix } from 'apis/flex/notifications';
import {
  Client,
  Project,
  ProjectRole,
  Quote,
  TargetGroup,
  TeamRole
} from 'apis/flex/projects';
import { ApiField } from 'apis/types';
import useCalendarItems from 'components/app/calendars/useCalendarItems';
import useCalendars from 'components/app/calendars/useCalendars';
import useContracts from 'components/app/documents/contracts/hooks/useContracts';
import useDepartments from 'components/app/hr/departments/useDepartments';
import useJobRoles from 'components/app/hr/jobRoles/useJobRoles';
import usePrescreening from 'components/app/hr/prescreening/hooks/usePrescreening';
import useShiftPreferences from 'components/app/hr/shifts/shiftPreferences/useShiftPreferences';
import useShifts from 'components/app/hr/shifts/useShifts';
import useEmployees, {
  useEmployeeMedicalRecords,
  useEmployeesPayrollRecords
} from 'components/app/hr/staff/hooks/useEmployees';
import useTrainingCourses from 'components/app/hr/training/hooks/useTrainingCourses';
import useClients from 'components/app/pm/clients/useClients';
import useQuestionnaires from 'components/app/pm/projects/questionnaires/useQuestionnaires';
import useResourceGroups from 'components/app/pm/projects/resourceGroups/useResourceGroups';
import useProjectRoles from 'components/app/pm/projects/useProjectRoles';
import useProjects from 'components/app/pm/projects/useProjects';
import useTargetGroups from 'components/app/pm/projects/useTargetGroups';
import useQuotes from 'components/app/pm/quotes/useQuotes';
import useTeamRoles from 'components/app/pm/roles/useTeamRoles';
import useApplicants from 'components/app/recruitment/applicants/hooks/useApplicants';
import useApplicantStages from 'components/app/recruitment/applicants/hooks/useApplicantStages';
import useCampaigns from 'components/app/recruitment/campaigns/hooks/useCampaigns';
import useAccessGroups from 'components/app/settings/team/permissions/useAccessGroups';
import useTickets from 'components/app/support/useTickets';
import { useUserAccessDelegation } from 'components/app/users/hooks/useAccessDelegation';
import useUsers from 'components/app/users/hooks/useUsers';
import useForms from 'components/common/customForms/hooks.js/useForms';
import DomainIcon from 'components/common/DomainIcon';
import { SelectorItem } from 'components/common/ItemSelector';
import SoftBadge from 'components/common/SoftBadge';
import useMailerTemplates from 'components/mailer/templates/useMailerTemplates';
import { getRenderIconFromValue } from 'components/wizard/inputs/WizardIcon';
import { format, formatDistance } from 'date-fns';
import { getTime, weekdays } from 'helpers/dates';
import {
  DefaultCrudData,
  defaultCrudHookBuilder
} from 'hooks/defaultCrud/useDefaultCrud';
import useTags from 'hooks/useTags';
import { startCase } from 'lodash';
import React from 'react';
import trainingBg from 'assets/img/generic/training.jpg';
import {
  FiArrowDownRight,
  FiBookOpen,
  FiBriefcase,
  FiCalendar,
  FiCast,
  FiCheck,
  FiCheckSquare,
  FiClipboard,
  FiClock,
  FiCoffee,
  FiDollarSign,
  FiFileText,
  FiGrid,
  FiHeart,
  FiHelpCircle,
  FiHome,
  FiInbox,
  FiKey,
  FiLifeBuoy,
  FiList,
  FiMail,
  FiMonitor,
  FiPieChart,
  FiSettings,
  FiSun,
  FiTag,
  FiTarget,
  FiUser,
  FiUserCheck,
  FiUserPlus,
  FiUsers,
  FiX
} from 'react-icons/fi';
import useFormResponses, {
  useFormResponsesHook
} from 'components/common/customForms/hooks.js/useFormResponses';
import useFixedSchedules from 'components/app/hr/shifts/useFixedSchedules';
import { Form } from 'apis/flex/customForms';
import SimpleClock from 'components/common/widgets/SimpleClock';
import { truncateMiddle } from 'helpers/strings';
import useEmployeeShifts, {
  useEmployeeShiftChanges
} from 'components/app/hr/shifts/employeeShifts/useEmployeeShifts';
import { User } from 'components/app/users/types';
import SmallCheck from 'components/wizard/SmallCheck';
import useLeave from 'components/app/hr/leave/useLeaveRequests';
import useLeaveRequests from 'components/app/hr/leave/useLeaveRequests';
import useLateness from 'components/app/hr/shifts/employeeShifts/lateness/useLateness';
import useLeaveTypes from 'components/app/hr/leave/useLeaveTypes';
import useCostCenters from 'components/app/pm/projects/costs/useCostCenters';
import useUserGuides from 'components/app/support/userGuides/useUserGuides';
import { domainToSentence } from 'components/common/DomainTimeline';
import useWebhooks from 'components/app/settings/webhooks/useWebhooks';
import useUserTraining from 'components/app/hr/training/hooks/useUserTraining';
import useShiftPlans from 'components/app/hr/shiftPlans/useShiftPlans';
import Skeleton from 'react-loading-skeleton';
import useBreaks from 'components/app/hr/shifts/breaks/useBreaks';

export const domainIconWidths = {
  xs: 24,
  sm: 32,
  md: 50,
  lg: 98,
  xl: 150
};
export const version = process.env.REACT_APP_FLEXVERSION;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export type DomainConfig<TData extends DefaultCrudData = any> = {
  sqlDb: string;
  sqlTable: string;
  foreignKey: string;
  icon?: React.ReactNode;
  home?: string | ((d: TData, user: Partial<User>) => string);
  itemUrl?: string | ((d: TData, user: Partial<User>) => string);
  format?: (d: TData) => SelectorItem;
  color?: string;
  crudHook?: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  searchFields: ApiField<TData>[];
  /** 'true' tags are used in the system default manner, 'false' tags are not used, or a string is the name of the tags table. Tags always use tagIds as the field  */
  tags?: boolean | string;
};
const EmployeeAvatar = ({ employeeId }) => {
  const { data: employee, isLoading } = useEmployees({
    id: employeeId,
    select: d => d[0]
  });
  return isLoading ? (
    <Skeleton containerClassName="h-100 position-absolute" />
  ) : employee?.avatar ? (
    <img className="h-100 position-absolute" src={employee?.avatar} />
  ) : (
    <FiUser />
  );
};
export type Settings = {
  isFluid: boolean;
  isRTL: boolean;
  isDark: boolean;
  isDemo: boolean;
  navbarPosition: string;
  showBurgerMenu: boolean;
  currency: string;
  isNavbarVerticalCollapsed: boolean;
  navbarStyle: string;
  config: any;
  showFeedbackModal: boolean;
  isMobile: boolean;
  domains: Partial<{
    [key in EventPrefix]: DomainConfig;
  }>;
  setConfig?: (
    item: keyof Omit<Settings, 'setConfig'> & string,
    value: any
  ) => void;
  configDispatch?: any;
};
export const settings: Settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isDemo: true,
  isMobile: false,
  showFeedbackModal: false,
  navbarPosition: 'top',
  showBurgerMenu: true, // controls showing vertical nav on mobile
  currency: '£',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  config: {},
  domains: {
    ticket: {
      sqlDb: 'Support',
      sqlTable: 'Tickets',
      foreignKey: 'ticketId',
      icon: <FiLifeBuoy />,
      home: '/support/tickets',
      itemUrl: t =>
        `/support/tickets/${t.status === 'Done' ? 'closed' : 'open'}/${t.id}`,
      format: t => ({
        label: 'Ticket #' + t.id,
        info: { title: t.title, description: t.description },
        value: t.id
      }),
      searchFields: ['title', 'description'],
      crudHook: useTickets,
      tags: true
    },
    applicant: {
      sqlDb: 'Recruitment',
      foreignKey: 'applicantId',
      sqlTable: 'AgentApplicants',
      icon: <FiUserPlus />,
      home: a => '/hr/recruitment/' + a.campaignId,
      itemUrl: a => '/hr/recruitment/' + a.campaignId + '/applicants/' + a.id,
      format: d => ({ label: d.firstName + ' ' + d.surname, value: d.id }),
      crudHook: useApplicants,
      searchFields: ['firstName', 'surname', 'email', 'telephone']
    },
    'recruitment-campaign': {
      sqlDb: 'Recruitment',
      foreignKey: 'campaignId',
      sqlTable: 'Campaigns',
      icon: <FiUserPlus />,
      home: a => '/hr/recruitment/',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdBy: d.createdBy }
      }),
      crudHook: useCampaigns,
      searchFields: ['name']
    },
    form: {
      sqlDb: 'Forms',
      foreignKey: 'formId',
      sqlTable: 'CustomForms',
      icon: <FiCheckSquare />,
      home: '/forms',
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          createdDate:
            d.createdDate && format(new Date(d.createdDate), 'dd/MM/yyyy'),
          questions:
            d.sections &&
            d.sections.flatMap(s => s.questions).length + ' questions',
          responses: d.responses + ' responses'
        },
        tags: d.tags?.map(t => <SoftBadge key={t.id}>{t.tagName}</SoftBadge>)
      }),
      crudHook: useForms,
      searchFields: ['name']
    },
    'form-response': {
      sqlDb: 'Forms',
      foreignKey: 'responseId',
      sqlTable: 'CustomFormResponses',
      icon: <FiCheckSquare />,
      home: d => '/forms/' + d.formId + '/responses',
      format: d => ({
        label: d.id,
        value: d.id
      }),
      crudHook: useFormResponsesHook,
      searchFields: ['id']
    },
    // project: { icon: <FiList /> },
    user: {
      sqlDb: 'Users',
      foreignKey: 'userId',
      sqlTable: 'AppUsers',
      icon: <FiUser />,
      home: '/hr/staff',
      format: d => ({
        avatar: d.avatar,
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        info: { email: d.email, jobTitle: d.jobTitle }
      }),
      crudHook: useUsers,
      searchFields: ['firstName', 'surname', 'email']
    },
    employee: {
      sqlDb: 'HR',
      foreignKey: 'employeeId',
      sqlTable: 'Employees',
      icon: <FiUser />,
      home: '/hr/staff',
      format: d => ({
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        info: { email: d.email, jobTitle: d.jobTitle },
        avatar: d.avatar
      }),
      crudHook: useEmployees,
      searchFields: ['firstName', 'surname', 'email']
    },
    'employee-medical-record': {
      sqlDb: 'HR',
      foreignKey: 'employeeMedicalId',
      sqlTable: 'EmployeeMedicalRecords',
      icon: <FiHeart />,
      home: '/hr/staff/medical',
      format: d => ({
        label: d.employeeFirstName + ' ' + d.employeeSurname,
        value: d.id
      }),
      crudHook: useEmployeeMedicalRecords,
      searchFields: ['employeeFirstName', 'employeeSurname']
    },
    'employee-payroll-record': {
      sqlDb: 'HR',
      foreignKey: 'employeePayrollId',
      sqlTable: 'EmployeePayrollRecords',
      icon: <FiDollarSign />,
      home: '/hr/staff/payroll',
      format: d => ({
        label: d.employeeFirstName + ' ' + d.employeeSurname,
        value: d.id
      }),
      crudHook: useEmployeesPayrollRecords,
      searchFields: ['employeeFirstName', 'employeeSurname']
    },
    'new-starter': {
      sqlDb: 'HR',
      foreignKey: 'employeeId',
      sqlTable: 'Employees',
      icon: <FiUserPlus />,
      home: (d: User) => '/hr/staff',
      itemUrl: (d: User) => '/hr/staff/' + d.employeeId,
      format: d => ({
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        avatar: d.avatar
      }),
      crudHook: useEmployees,
      searchFields: ['firstName', 'surname', 'email', 'telephone']
    },
    'job-title': {
      sqlDb: 'HR',
      sqlTable: 'JobTitles',
      foreignKey: 'jobTitleId',
      icon: <FiBriefcase />,
      home: '/hr/company/roles',
      format: d => ({
        label: d.jobTitle,
        value: d.id
      }),
      crudHook: useJobRoles,
      searchFields: ['jobtitle']
    },
    contract: {
      foreignKey: 'contractId',
      sqlDb: 'HR',
      sqlTable: 'Contracts',
      icon: <FiFileText />,
      home: '/hr/contracts',
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          createdDate: format(new Date(d.createdDate), 'dd/MM/yyyy HH:mm')
        } // d.createdDate, createdBy: d.createdBy }
      }),
      crudHook: useContracts,
      searchFields: ['name']
    },
    'employee-contract': {
      foreignKey: 'employeeContractId',
      sqlDb: 'HR',
      sqlTable: 'EmployeeContracts',
      icon: <FiFileText />,
      home: (d: EmployeeContract) => `/hr/staff/${d.employeeId}`,
      itemUrl: (d: EmployeeContract) => `/hr/staff/${d.employeeId}`,
      format: d => ({
        label: d.firstName + ' ' + d.surname + ' (' + d.name + ')',
        value: d.id,
        info: { startDate: d.startDate, terminatedDate: d.terminatedDate }
      }),
      searchFields: ['firstName', 'surname', 'name']
    },
    department: {
      sqlDb: 'HR',
      crudHook: useDepartments,
      foreignKey: 'departmentId',
      sqlTable: 'Departments',
      icon: <FiHome />,
      home: '/hr/company/departments',
      format: (d: Department) => ({
        avatar: d.avatar || d.background,
        label: d.name,
        value: d.id,
        info: {
          department: d.parentName,
          employees: d.employees + ' employees',
          subDepartments: d.departments + ' sub-departments'
        }
      }),
      searchFields: ['name']
    },
    'training-course': {
      sqlDb: 'HR',
      foreignKey: 'courseId',
      sqlTable: 'TrainingCourses',
      icon: <FiBookOpen />,
      home: '/hr/training/courses',
      format: (d: TrainingCourse) => ({
        label: d.name,
        value: d.id,
        avatar: d.profileBackground || trainingBg,
        info: {
          modules: (d.resources?.length || 0) + ' resources'
        }
      }),
      crudHook: useTrainingCourses,
      searchFields: ['name', 'description']
    },
    'user-training': {
      sqlDb: 'HR',
      sqlTable: 'UserTrainingCourses',
      foreignKey: 'userTrainingId',
      icon: <FiBookOpen />,
      home: (d: UserTrainingCourse, u) => {
        // console.log('routing user-training', d, u);
        return d.userId === u.id ? '/my/training' : '/hr/training/sessions';
      },
      format: (d: UserTrainingCourse) => ({
        label: 'Session #' + d.id,
        value: d.id,
        info: {
          enrolledDate: format(new Date(d.startedDate), 'dd/MM/yyyy'),
          user: d.userId
        }
      }),
      searchFields: ['courseName'],
      crudHook: useUserTraining
    },
    'applicant-stage': {
      sqlDb: 'Recruitment',
      foreignKey: 'applicantStageId',
      sqlTable: 'ApplicantStage',
      icon: <FiList />,
      home: '/hr/recruitment',
      crudHook: useApplicantStages,
      searchFields: ['applicantFirstName', 'applicantSurname', 'stageName']
    },
    'employee-screening': {
      sqlDb: 'HR',
      foreignKey: 'screeningId',
      sqlTable: 'Screenings',
      icon: <FiUserCheck />,
      home: (d, u) =>
        d.employeeId === u.employeeId ? '/my/screening' : '/hr/staff/screening',
      searchFields: ['checks.checkType'],
      crudHook: usePrescreening
    },
    'mailer-template': {
      sqlDb: 'Notifications',
      foreignKey: 'notificationId',
      sqlTable: 'MailerTemplates',
      icon: <FiMail />,
      home: '/settings/notifications/templates',
      format: d => ({ label: d.name, value: d.id }),
      crudHook: useMailerTemplates,
      searchFields: ['name']
    },
    client: {
      sqlDb: 'Projects',
      sqlTable: 'Clients',
      foreignKey: 'clientId',
      icon: <FiBriefcase />,
      home: '/fieldwork/clients',
      format: (d: Client) => ({ label: d.name, value: d.id }),
      crudHook: useClients,
      searchFields: ['name', 'website', 'email']
    },
    project: {
      sqlDb: 'Projects',
      sqlTable: 'Projects',
      foreignKey: 'projectId',
      icon: <FiClipboard />,
      home: '/fieldwork/pm',
      format: (d: Project) => ({
        label: (d.quoteRef ? d.quoteRef + ' ' : '') + d.name,
        value: d.id
      }),
      crudHook: useProjects,
      searchFields: ['name', 'clientName', 'quoteRef']
    },
    quote: {
      sqlDb: 'Projects',
      sqlTable: 'Quotes',
      foreignKey: 'quoteId',
      icon: <FiInbox />,
      home: '/fieldwork/quotes',
      format: (d: Quote) => ({
        label: (d.reference ? d.reference + ' ' : '') + d.name,
        value: d.id
      }),
      crudHook: useQuotes,
      searchFields: ['reference', 'name']
    },
    'target-group': {
      sqlDb: 'Projects',
      sqlTable: 'TargetGroups',
      foreignKey: 'targetGroupId',
      icon: <FiTarget />,
      home: d => '/fieldwork/pm/' + d.projectId + '/target-groups',
      format: (d: TargetGroup) => ({
        label:
          (d.quoteRef ? d.quoteRef + ' ' : '') + d.projectName + ' - ' + d.name,
        value: d.id
      }),
      crudHook: useTargetGroups,
      searchFields: ['quoteRef', 'projectName', 'name']
    },
    'team-role': {
      sqlDb: 'Projects',
      sqlTable: 'TeamRoles',
      foreignKey: 'roleId',
      icon: <FiUsers />,
      home: '/fieldwork/pm/roles',
      format: (d: TeamRole) => ({ label: d.name, value: d.id }),
      crudHook: useTeamRoles,
      searchFields: ['name']
    },
    'project-role': {
      sqlDb: 'Projects',
      sqlTable: 'ProjectRoles',
      foreignKey: 'projectRoleId',
      icon: <FiUsers />,
      home: d => '/fieldwork/pm/' + d.projectId + '/roles',
      format: (d: ProjectRole) => ({ label: d.roleName, value: d.id }),
      crudHook: useProjectRoles,
      searchFields: ['roleName']
    },
    shift: {
      sqlDb: 'HR',
      sqlTable: 'Shifts',
      foreignKey: 'shiftId',
      icon: <FiClock />,
      home: '/hr/ta/patterns/',
      format: d => ({
        label: getTime(d.startTime) + '-' + getTime(d.endTime),
        value: d.id,
        icon: (
          // <SimpleClock
          //   icon={
          <FontAwesomeIcon
            // width={'25%'}
            icon={getRenderIconFromValue(d.resourceGroupIcon, faUserTie)}
          />
          // }
          // height={'100%'}
          // startTime={getTime(d.startTime)}
          // endTime={getTime(d.endTime)}
          // />
        )
      }),
      crudHook: useShifts,
      searchFields: ['startTime', 'endTime']
    },
    'employee-shift': {
      sqlDb: 'HR',
      sqlTable: 'EmployeeShifts',
      foreignKey: 'employeeShiftId',
      icon: <FiClock />,
      home: (d, u) =>
        d.employeeId === u.employeeId ? '/my/work' : '/hr/ta/shifts',
      format: d => ({
        label: getTime(d.startTime) + '-' + getTime(d.endTime),
        value: d.id,
        icon: <EmployeeAvatar employeeId={d.employeeId} />,
        info: {
          employee: d.employeeFirstName + ' ' + d.employeeSurname
        }
      }),
      searchFields: [
        'startTime',
        'endTime',
        'employeeFirstName',
        'employeeSurname'
      ],
      crudHook: useEmployeeShifts
    },
    'employee-shift-change': {
      sqlDb: 'HR',
      sqlTable: 'ShiftChanges',
      foreignKey: 'employeeShiftChangeId',
      icon: <FiClock />,
      home: (d, u) =>
        d.employeeId === u.employeeId ? '/my/work' : '/hr/ta/shifts/changes',
      format: d => ({
        label: d.requestedDate,
        value: d.id
      }),
      searchFields: [],
      crudHook: useEmployeeShiftChanges
    },
    'shift-preference': {
      sqlDb: 'HR',
      sqlTable: 'ShiftPreferences',
      foreignKey: 'shiftPreferenceId',
      icon: <FiClock />,
      itemUrl: (d, u) =>
        d.employeeId === u.employeeId
          ? '/my/work'
          : '/hr/staff/' + d.employeeId,
      format: d => ({
        label: getTime(d.startTime) + '-' + getTime(d.endTime),
        value: d.id,
        group:
          d.employeeFirstName +
          ' ' +
          d.employeeSurname +
          ' | ' +
          weekdays[d.weekday]
      }),
      crudHook: useShiftPreferences,
      searchFields: [
        'startTime',
        'endTime',
        'employeeFirstName',
        'employeeSurname'
      ]
    },
    'resource-group': {
      sqlDb: 'Projects',
      sqlTable: 'ResourceGroups',
      foreignKey: 'resourceGroupId',
      icon: <FiGrid />,
      home: '/resourcing/resource-groups/',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { description: d.description },
        icon: (
          <FontAwesomeIcon icon={getRenderIconFromValue(d.icon, faUserTie)} />
        )
      }),
      crudHook: useResourceGroups,
      searchFields: ['name', 'description']
    },
    'access-group': {
      crudHook: useAccessGroups,
      sqlDb: 'Users',
      sqlTable: 'AccessGroups',
      foreignKey: 'groupId',
      icon: <FiKey />,
      home: '/settings/permissions/groups',
      format: (d: AccessGroup) => ({
        label: d.name,
        value: d.id,
        tags: (
          <>
            {d.roles?.map(r => (
              <SoftBadge key={r.id} bg="info">
                <DomainIcon
                  size="xs"
                  className="me-1"
                  domain={r.collectionName}
                />
                {startCase(r.collectionName) + ' ' + r.levelName}
              </SoftBadge>
            ))}
          </>
        )
      }),
      searchFields: ['name', 'roles.collectionName', 'roles.levelName']
    },
    questionnaire: {
      sqlDb: 'Projects',
      sqlTable: 'Questionnaires',
      foreignKey: 'questionnaireId',
      icon: <FiFileText />,
      home: d =>
        d.quoteId
          ? '/fieldwork/quotes/' + d.quoteId
          : '/fieldwork/pm/' + d.projectId + '/questionnaires',
      format: d => ({
        label: d.name,
        value: d.id,
        tags: (
          <>
            <SoftBadge>
              {Math.round(d.actualIncidence || d.expectedIncidence)}% IR
            </SoftBadge>
            <SoftBadge>{d.expectedDuration || d.actualDuration}mins</SoftBadge>
          </>
        )
      }),
      searchFields: ['name'],
      crudHook: useQuestionnaires
    },
    tag: {
      sqlDb: 'System',
      sqlTable: 'Tags',
      foreignKey: 'tagId',
      icon: <FiTag />,
      crudHook: useTags,
      format: (d: SystemTag) => ({ label: d.name, value: d.id }),
      searchFields: ['name']
    },
    'access-delegation': {
      sqlDb: 'Users',
      sqlTable: 'AccessDelegations',
      foreignKey: 'delegationId',
      icon: <FiArrowDownRight />,
      home: '/settings/permissions/delegations',
      searchFields: ['domain', 'userFirstName', 'userSurname'],
      crudHook: useUserAccessDelegation
    },
    calendar: {
      sqlDb: 'System',
      sqlTable: 'Calendars',
      foreignKey: 'calendarId',
      icon: <FiCalendar />,
      home: '/settings/calendars',
      searchFields: ['name'],
      crudHook: useCalendars,
      format: d => ({
        label: d.name,
        value: d.id,
        icon: (
          // <div style={{ color: d.color }}>
          <FiCalendar style={{ color: d.color }} />
          // </div>
        )
      })
    },
    'calendar-item': {
      sqlDb: 'System',
      sqlTable: 'CalendarItems',
      foreignKey: 'calendarItemId',
      icon: <FiCalendar />,
      home: d => `/settings/calendars/${d.calendarId}`,
      searchFields: ['name'],
      crudHook: useCalendarItems,
      format: d => ({
        label: d.name,
        value: d.id,
        icon: (
          // <div style={{ color: d.color }}>
          <FiCalendar style={{ color: d.color }} />
          // </div>
        )
      })
    },
    'fixed-schedule': {
      sqlDb: 'HR',
      sqlTable: 'FixedSchedules',
      foreignKey: 'fixedScheduleId',
      icon: <FiCalendar />,
      home: `hr/patterns/schedules`,
      searchFields: ['name'],
      crudHook: useFixedSchedules,
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          shifts: d.shifts?.length
        }
      })
    },
    'leave-request': {
      sqlDb: 'HR',
      sqlTable: 'LeaveRequests',
      foreignKey: 'leaveRequestId',
      icon: <FiSun />,
      home: (d, a) =>
        d.employeeId === a.employeeId ? '/my/leave' : `/hr/ta/leave`,
      searchFields: [
        'employeeFirstName',
        'employeeSurname',
        'startDate',
        'endDate'
      ],
      format: d => ({
        label: `${d.leaveTypeName}: ${format(
          new Date(d.startDate),
          'dd/MM/yyyy'
        )} - ${format(new Date(d.endDate), 'dd/MM/yyyy')}`,
        value: d.id,
        status: d.approvedDate
          ? 'success'
          : d.rejectedDate
          ? 'danger'
          : 'secondary',
        info: {
          days: formatDistance(new Date(d.startDate), new Date(d.endDate))
        },
        tags: (
          <>
            {d.approvedDate && (
              <SoftBadge color="success">
                <FontAwesomeIcon icon={'check'} /> Approved
              </SoftBadge>
            )}
            {d.rejectedDate && (
              <SoftBadge color="danger">
                <FontAwesomeIcon icon={'times'} /> Rejected
              </SoftBadge>
            )}
          </>
        )
      }),
      crudHook: useLeaveRequests
    },
    'leave-type': {
      sqlDb: 'HR',
      sqlTable: 'LeaveTypes',
      foreignKey: 'leaveTypeId',
      icon: <FiList />,
      home: `/hr/ta/leave/types`,
      searchFields: ['name'],
      format: d => ({ label: d.name, value: d.id }),
      crudHook: useLeaveTypes
    },
    lateness: {
      sqlDb: 'HR',
      sqlTable: 'Lateness',
      foreignKey: 'latenessId',
      icon: <FiClock />,
      home: (d, a) =>
        d.employeeId === a.employeeId ? '/my/work' : `/hr/shifts/lateness`,
      searchFields: ['employeeFirstName', 'employeeSurname'],
      crudHook: useLateness,
      format: d => ({
        label: `${d.employeeFirstName} ${d.employeeSurname} ${format(
          new Date(d.date),
          'dd/MM/yyyy'
        )}`,
        value: d.id,
        info: {
          minutes: d.minutes + ' minutes'
        }
      })
    },
    'cost-center': {
      sqlDb: 'Projects',
      sqlTable: 'CostCenters',
      foreignKey: 'costCenterId',
      icon: <FiPieChart />,
      home: `/pm/cost-centers`,
      searchFields: ['name', 'description'],
      crudHook: useCostCenters,
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          description: d.description
        }
      })
    },
    'user-guide': {
      sqlDb: 'Support',
      sqlTable: 'UserGuides',
      foreignKey: 'guideId',
      icon: <FiHelpCircle />,
      home: `/support/documentation/knowledgebase`,
      searchFields: ['title', 'description'],
      crudHook: useUserGuides,
      tags: true,
      format: d => ({
        label: d.title,
        value: d.id,
        tags: (d.domains || [])
          .map(t => (
            <SoftBadge key={t} color="secondary">
              {t
                .split('-')
                .map(s => startCase(s))
                .join('')}
            </SoftBadge>
          ))
          .concat(
            (d.tags || []).map(t => (
              <SoftBadge key={t} color="secondary">
                {t}
              </SoftBadge>
            ))
          )
      })
    },
    webhook: {
      sqlDb: 'System',
      sqlTable: 'Webhooks',
      foreignKey: 'webhookId',
      icon: <FiCast />,
      home: `/settings/webhooks`,
      searchFields: ['name', 'description', 'url', 'domain', 'action'],
      crudHook: useWebhooks,
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          description: d.description,
          url: d.url
        },
        tags: (
          <SoftBadge color="secondary">{d.domain + '-' + d.action}</SoftBadge>
        )
      })
    },
    'shift-plan': {
      sqlDb: 'HR',
      sqlTable: 'ShiftPlans',
      foreignKey: 'shiftPlanId',
      icon: <FiGrid />,
      home: `/resourcing/shift-plans`,
      searchFields: ['date'],
      crudHook: useShiftPlans,
      format: d => ({
        label: format(new Date(d.date), 'dd/MM/yyyy'),
        value: d.id,
        icon: (
          <FontAwesomeIcon icon={getRenderIconFromValue(d.resourceGroupIcon)} />
        ),
        info: {
          resourceGroup: d.resourceGroupName
        }
      })
    },
    break: {
      sqlDb: 'HR',
      sqlTable: 'Breaks',
      foreignKey: 'breakId',
      icon: <FiCoffee />,
      home: `/hr/ta/breaks`,
      searchFields: ['name'],
      crudHook: useBreaks,
      format: d => ({
        label: d.name,
        value: d.id,
        info: {
          minutes: d.duration,
          percentageOfWorkingTime: d.durationPercentageOfWorkingTime
        }
      })
    }
  }
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
