import React from 'react';
import BreaksTable from './BreaksTable';

export default () => {
  return (
    <>
      <BreaksTable />
    </>
  );
};
