import { EmployeeMedicalRecord } from 'apis/flex/hr';
import DomainTable from 'components/common/DomainTable';
import React from 'react';

export default () => {
  return (
    <DomainTable<EmployeeMedicalRecord>
      domain="employee-medical-record"
      columns={[
        'employeeId',
        { id: 'emergencyContactName', type: 'name' },
        'emergencyContactRelationship',
        'emergencyContactPhone',
        'conditions',
        'allergies',
        'medications',
        'modifiedBy',
        'modifiedDate'
      ]}
      onNewClick={null}
    />
  );
};
