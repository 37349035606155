import { useEffect, useRef } from "react";
export default (ref, onResize) => {
  const resizeObserver = useRef(null);
  useEffect(() => {
    if (!ref?.current) return;
    resizeObserver.current = new ResizeObserver(() => {
      const innerBox = ref.current?.getBoundingClientRect();
      onResize?.(innerBox);
    });
    resizeObserver.current.observe(ref.current);
    return () => {
      resizeObserver.current?.disconnect();
    };
  }, [ref?.current]);
};
