import { Path, useWatch } from 'react-hook-form';

export default <
  TData extends Record<any, any> = Record<any, any>,
  TFields extends Path<TData>[] = Path<TData>[]
>({
  children,
  fields
}: {
  fields?: TFields;
  children: (values: Record<TFields[number], any>) => any;
}) => {
  //   const truthyFields = Object.keys(names).filter(k => names[k]);
  const values = useWatch<TData>({ name: fields });
  return children(
    values.reduce(
      (a, b, i) => ({ ...a, [fields[i]]: b }),
      {} as Record<TFields[number], any>
    )
  );
};
export const WithAllFormValues = <TData extends Record<any, any>>({
  children
}: {
  children: (values: TData) => any;
}) => {
  const values = useWatch<TData>() as TData;
  return children(values);
};
