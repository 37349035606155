import React, { useCallback, useState } from 'react';
import Unallocated, { useUnallocated } from './Unallocated';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useWatch } from 'react-hook-form';
import { ShiftPlan } from 'apis/flex/hr';
import Allocated from './Allocated';
import { FormCheck, FormControl } from 'react-bootstrap';
import SettingsBox from 'components/common/SettingsBox';

export default () => {
  const fieldArray = useFieldArray<ShiftPlan, 'allocations'>({
    name: 'allocations'
  });
  const { unallocated } = useUnallocated();
  const shiftPlanId = useWatch({ name: 'id' });
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const employeeAllocated = fieldArray.fields
        .map((a, i) => ({ ...a, index: i }))
        .filter(a => a.employeeId === Number(result.draggableId));
      if (result.destination.droppableId === 'unallocated') {
        fieldArray.remove(employeeAllocated.map(a => a.index));
        return;
      }
      if (result.destination.droppableId === 'allocated') {
        const employeeUnallocated = unallocated.filter(
          a => a.employeeId === Number(result.draggableId)
        );
        fieldArray.append(
          employeeUnallocated.map(a => ({ ...a, shiftPlanId }))
        );
      }
    },
    [unallocated]
  );
  const [showForecasted, setShowForecasted] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  return (
    <>
      <SettingsBox title="Settings">
        <FormCheck
          label="Show forecasted"
          checked={showForecasted}
          onChange={() => setShowForecasted(!showForecasted)}
        />
        <FormCheck
          label="Show preferences"
          checked={showPreferences}
          onChange={() => setShowPreferences(!showPreferences)}
        />
      </SettingsBox>
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <Unallocated
            showForecasted={showForecasted}
            showPreferences={showPreferences}
            fieldArray={fieldArray}
          />
          <Allocated {...fieldArray} />
        </div>
      </DragDropContext>
    </>
  );
};
